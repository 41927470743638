import React from 'react'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useStyles } from './BillingTableStyles'
import { unixTimeStamp } from '../../utils'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

export default function BillingTable(transactionList) {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} align="left">
                Date
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                Credits
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                Amount paid
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                Transaction ID
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {transactionList?.transactionList?.map((billing, index) => (
              <StyledTableRow key={billing.adminEmailID + index.toString(2)}>
                <StyledTableCell
                  className={classes.billingTableRow}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {unixTimeStamp(billing.createdAt, 'billingTime')}
                </StyledTableCell>
                <StyledTableCell
                  className={classes.billingTableRow}
                  align="center"
                >
                  {billing.credits}
                </StyledTableCell>
                <StyledTableCell
                  className={classes.billingTableRow}
                  align="center"
                >
                  {`$ ${billing.paymentMetaData.data.amount}`}
                </StyledTableCell>
                <StyledTableCell
                  className={classes.billingTableRow}
                  align="center"
                >
                  {billing.paymentMetaData.data.client_order_id}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
