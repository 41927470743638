import React, { useRef, useState, useEffect } from 'react'
import { Grid, Typography, Button, Paper } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import 'react-multi-carousel/lib/styles.css'
import Select from '@material-ui/core/Select'
import Loader from '../Loader/Loader'
import CandidateStream from './CandidateStream'
import sortby from '../../icons/sortby.svg'
import InfoCard from '../Dashboard/InfoCard'
import Pagination from './Pagination'
import MyViewModifyDialog from './MyViewModifyDialog'
import { styles } from './MultipleCandidateStreamStyles'

function MultipleCandidatesStream({
  totalLiveCandidates,
  candidatesList,
  offset,
  limit,
  timerObject,
  totalLiveCount,
  handleSort,
  currentMyViewCandidates,
  isCandidateListLoading,
  userInputArray,
  setUserInputArray,
  pagesArray,
  handleNext,
  handlePrevious,
  setOffset,
  isCandidateListFetching,
  myViewHandleNext,
  myViewHandlePrevious,
  myViewOffset,
  myViewPagesArray,
  setMyViewOffset,
  isMyViewCandidateListLoading,
  isMyViewCandidateListFetching,
  currentSelectedTab,
  setCurrentSelectedTab,
  isTotalCandidateListLoading,
  isTotalCandidateListFetching,
  totalCandidatesRefetch,
}) {
  const [addCandidateButton, setAddCandidateButton] = useState(false)
  const [loader, setLoader] = useState(false)
  const [openDialogBox, setOpenDialogBox] = useState(false)
  const streamsContainerRef = useRef(null)
  const classes = styles({
    currentTab: currentSelectedTab === 'defaultView' ? 'defaultView' : 'myView',
  })
  const handleChange = (event, value) => {
    if (value === 'myView') {
      setCurrentSelectedTab(() => 'myView')
      setAddCandidateButton(() => true)
    }
    if (value === 'defaultView') {
      setCurrentSelectedTab(() => 'defaultView')
      setAddCandidateButton(() => false)
    }
  }
  const handleOpenDialog = () => {
    totalCandidatesRefetch()
    setOpenDialogBox(true)
  }

  useEffect(() => {
    if (
      isCandidateListFetching ||
      isCandidateListLoading ||
      isMyViewCandidateListLoading ||
      isMyViewCandidateListFetching ||
      isTotalCandidateListLoading ||
      isTotalCandidateListFetching
    ) {
      setLoader(() => true)
    } else {
      setLoader(() => false)
    }
  }, [
    isCandidateListFetching,
    isCandidateListLoading,
    isMyViewCandidateListLoading,
    isMyViewCandidateListFetching,
    isTotalCandidateListLoading,
    isTotalCandidateListFetching,
  ])
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.multiStreamContainer}
    >
      <Grid
        item
        container
        className={classes.multiStreamHeader}
        alignItems="center"
        xs={12}
      >
        <Button
          className={classes.myViewButton}
          onClick={(event) => handleChange(event, 'myView')}
        >
          My View
        </Button>
        <Button
          className={classes.defaultViewButton}
          onClick={(event) => handleChange(event, 'defaultView')}
        >
          Default View
        </Button>
        <Grid style={{ flex: '1' }} />
        {/* <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <Grid component="button" className={classes.styleFilter}>
            <Paper className={classes.filterIcon}>
              <InfoCard avatar={Filter} />
            </Paper>
          </Grid>
          <Typography className={classes.name}> Filter:</Typography>
          <Select
            native
            variant="outlined"
            IconComponent={() => <ExpandMore className={classes.dropDown1} />}
            className={classes.selectDropdown}
            onChange={(event) => handleFilter(event?.target?.value)}
          >
            <option className={classes.selectOptions} value="filterby">
              None
            </option>
            <option className={classes.selectOptions} value="completed">
              Completed
            </option>
            <option className={classes.selectOptions} value="inprogress">
              Inprogress
            </option>
          </Select>
        </Grid> */}
        <Grid className={classes.sortByGrid}>
          <Grid className={classes.stylesort}>
            <Paper className={classes.sortIcon}>
              <InfoCard avatar={sortby} />
            </Paper>
          </Grid>
          <Typography className={classes.name}> Sort by:</Typography>

          <Select
            native
            variant="outlined"
            IconComponent={() => <ExpandMore className={classes.dropDown2} />}
            className={classes.selectDropdown}
            onChange={(event) => handleSort(event.target.value)}
          >
            <option className={classes.selectOptions} value="sortby">
              None
            </option>
            <option className={classes.selectOptions} value="quizStartTime">
              StartTime
            </option>
            <option className={classes.selectOptions} value="email">
              Email id
            </option>
          </Select>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        ref={streamsContainerRef}
        style={{
          width: '99%',
        }}
      >
        {currentSelectedTab === 'defaultView' && !loader && (
          <>
            {candidatesList?.map((candidate) => {
              return (
                <Grid
                  xs={12}
                  sm={6}
                  md={4}
                  xl={2}
                  item
                  key={candidate.email}
                  className={classes.streamContainer}
                >
                  <CandidateStream
                    username={candidate.email}
                    candidateDetails={candidate}
                    timer={timerObject?.[candidate?.email]}
                  />
                </Grid>
              )
            })}
          </>
        )}
        {currentSelectedTab === 'myView' &&
          userInputArray.length !== 0 &&
          !loader && (
            <>
              {currentMyViewCandidates?.map((candidate) => {
                return (
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    xl={2}
                    item
                    key={candidate.email}
                    className={classes.streamContainer}
                  >
                    <CandidateStream
                      username={candidate.email}
                      candidateDetails={candidate}
                      timer={timerObject?.[candidate?.email]}
                    />
                  </Grid>
                )
              })}
            </>
          )}
      </Grid>
      <MyViewModifyDialog
        setUserInputArray={setUserInputArray}
        userInputArray={userInputArray}
        openDialogBox={openDialogBox}
        setOpenDialogBox={setOpenDialogBox}
        totalLiveCandidates={totalLiveCandidates}
        totalCandidatesRefetch={totalCandidatesRefetch}
        isTotalCandidateListFetching={isTotalCandidateListFetching}
        isTotalCandidateListLoading={isTotalCandidateListLoading}
      />
      {loader && !openDialogBox && (
        <Grid className={classes.loader}>
          <div>
            <Loader size="8rem" />
          </div>
        </Grid>
      )}
      {!loader && addCandidateButton && (
        <Grid container spacing={1} className={classes.addCandidateButton}>
          <Button onClick={handleOpenDialog}>Manage View</Button>
        </Grid>
      )}
      <Grid spacing={1} container className={classes.paginationGrid}>
        {currentSelectedTab === 'defaultView' && (
          <Pagination
            totalLiveCount={totalLiveCount}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            offset={offset}
            pagesArray={pagesArray}
            limit={limit}
            setOffset={setOffset}
          />
        )}
        {currentSelectedTab === 'myView' && userInputArray.length !== 0 && (
          <Pagination
            totalLiveCount={userInputArray.length}
            handleNext={myViewHandleNext}
            handlePrevious={myViewHandlePrevious}
            offset={myViewOffset}
            pagesArray={myViewPagesArray}
            limit={limit}
            setOffset={setMyViewOffset}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default MultipleCandidatesStream
