import React from 'react'
import AceEditor from 'react-ace'
import 'ace-builds'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/mode-golang'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/keybinding-vscode'
import 'ace-builds/src-noconflict/keybinding-vim'
import 'ace-builds/src-noconflict/keybinding-emacs'
import 'ace-builds/src-noconflict/keybinding-sublime'

function CodeEditor({
  language,
  isDarkMode,
  code,
  onCodeEditorChange,
  editorSettings,
  readOnly,
  width,
  height,
}) {
  return (
    <AceEditor
      name="ACE_EDITOR"
      setOptions={{ useWorker: false }}
      mode={language}
      theme={isDarkMode ? 'dracula' : 'chrome'}
      value={code}
      onChange={onCodeEditorChange}
      fontSize={editorSettings?.fontSize}
      tabSize={editorSettings?.tabSize}
      keyboardHandler={editorSettings?.keyBinding}
      showPrintMargin
      highlightActiveLine
      enableBasicAutocompletion
      readOnly={readOnly}
      width={width}
      height={height}
      commands={[
        {
          name: 'breakTheEditor',
          bindKey:
            'ctrl-c|ctrl-v|ctrl-x|ctrl-shift-v|shift-del|cmd-c|cmd-v|cmd-x',
          exec() {},
        },
      ]}
    />
  )
}

export default CodeEditor
