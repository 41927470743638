import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '45px',
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '0px',
  },
  payButton: {
    cursor: 'pointer',
    fontWeight: 600,
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    borderRadius: '5px',
    border: 'none',
    padding: '10px',
    outline: 'none',
    width: '20px',
    height: 'max-content',
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
    '&:disabled': {
      opacity: '0.8',
    },
    '@media only screen and (max-width:568px)': {
      width: '70px',
      padding: '5px 0px 5px 0px',
    },
  },
  container: {
    '@media only screen and (min-width:640px)': {
      maxWidth: '85%',
    },
  },
  fixPaymentHeader: {
    top: 46,
    width: '100%',
    zIndex: 90,
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid #E6E6E6',
  },
  paymentsHeader: {
    width: '100%',
    paddingLeft: '135px',
    height: 75,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E6E6E6',
    '@media only screen and (max-width:598px)': {
      display: 'flex',
      width: '100%',
      paddingRight: '0px',
      paddingLeft: '35px',
    },
    '@media only screen and (min-width: 600px) and (max-width: 740px)': {
      display: 'flex',
      width: '100%',
      paddingRight: '0px',
      paddingLeft: '31px',
    },
    '@media only screen and (min-width: 741px) and (max-width: 1090px)': {
      paddingLeft: '85px',
    },
  },
  heading: {
    fontSize: '30px',
    fontWeight: 700,
  },
  paymentDescription: {
    fontSize: 20,
    paddingTop: '25px',
    paddingBottom: '15px',
    fontWeight: 700,
    paddingLeft: '135px',
    color: '#00B7A4',
    '@media only screen and (max-width: 741px)': {
      paddingLeft: '38px',
    },
    '@media only screen and (min-width: 741px) and (max-width: 1090px)': {
      paddingLeft: '85px',
    },
  },
  paymentDescriptionWrapper: {
    height: '100%',
    width: '100%',
  },
  paymentPlaceholder: {
    backgroundColor: '#FFFFFF',
    borderColor: '#D4D5DB',
    borderRadius: '1px',
  },
  button: {
    color: theme.palette.primarySelection.main,
    borderColor: theme.palette.primarySelection.main,
    backgroundColor: 'white',
    fontSize: 15,
    fontWeight: 700,
  },
  billingHeader: {
    fontSize: 20,
    fontWeight: 700,
    color: '#00B7A4',
    paddingTop: '25px',
    paddingBottom: '25px',
    paddingLeft: '15px',
    wordBreak: 'break-word',
  },
  billingDownload: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -30,
    paddingRight: '10px',
  },
  paginationGrid: {
    marginTop: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    direction: 'column',
    alignItems: 'center',
    '@media only screen and (max-width:425px)': {
      display: 'flex',
      flexDirection: 'column',
      width: 'max-content',
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
  billingBody: {
    overflow: 'hidden',
    height: 'max-content',
  },
  paymentContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    paddingLeft: '135px',
    '@media only screen and (max-width:425px)': {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '0px',
      paddingLeft: '35px',
    },
    '@media only screen and (max-width:568px)': {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '0px',
      paddingLeft: '35px',
      alignItems: 'start',
    },
    '@media only screen and (max-width:740px)': {
      paddingLeft: '35px',
    },
    '@media only screen and (min-width: 741px) and (max-width: 1090px)': {
      paddingLeft: '85px',
    },
  },
  paymentWrapper: {
    height: 'max-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F3F7F7',
    paddingBottom: '40px',
    '@media only screen and (max-width:740px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      flexDirection: 'column',
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
  amountAndPayButtonWrapper: {
    display: 'flex',
    '@media only screen and (min-width:270px) and (max-width:568px)': {
      paddingTop: '10px',
    },
    '@media only screen and (max-width:478px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '0px',
      paddingLeft: '0px',
    },
    '@media only screen and (max-width:768px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '17px',
    },
  },
  amount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '80px',
    color: '#262B4B',
    width: 'max-content',
    '@media only screen and (max-width:568px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
  paymentButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '50px',
    '@media only screen and (max-width:568px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '0px',
      paddingLeft: '10px',
    },
    '@media only screen and (max-width:700px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '30px',
    },
  },
  billingTableAndPaginationWrapper: {
    flex: 1,
    height: '100px',
    width: '100%',
    backgroundColor: 'white',
    paddingTop: '40px',
    paddingRight: '136px',
    paddingLeft: '136px',
    '@media only screen and (max-width:568px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '30px',
      paddingLeft: '30px',
      paddingTop: '120px',
    },
    '@media only screen and (max-width:741px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '30px',
      paddingLeft: '30px',
      paddingTop: '120px',
      paddingBottom: '105px',
    },
    '@media only screen and (min-width: 741px) and (max-width: 1090px) ': {
      paddingLeft: '83px',
      paddingRight: '74px',
    },
    '@media only screen and (min-height: 250px)': {
      paddingTop: '30px',
      paddingBottom: '0px',
    },
  },
  billingTableWrapper: {
    flex: 1,
    width: '100%',
    height: '100px',
  },

  billingAndPaginationDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  billingContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
    border: '1px solid #D4D5DB',
  },
  billingBodyWrapper: {
    flex: 1,
    height: '70px',
    width: '100%',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingTop: '10px',
  },
  paginationWrapper: {
    height: 'max-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '10px',
  },
  billingBodyDiv: {
    height: '100%',
    width: '100%',
  },
  billingTableHeader: {
    height: 'max-content',
    width: '100%',
    borderRadius: '3px',
    borderBottom: '1px solid #D4D5DB',
  },
  payAmount: {
    paddingLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    color: '#00B8A4',
    fontSize: '18px',
    fontWeight: '500px',
  },
  paymentHeaderWrapper: {
    height: '100%',
    width: '100%',
  },
  billingLogsMsg: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '200px',
  },
}))
export { useStyles }
