import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  Grid,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

import { useStyle } from './EditQuizConfigurationsStyles'

const AddInviteesModal = ({
  testAreaText,
  handleValidateButton,
  open,
  setOpen,
  isPublished,
  quizInfo,
  isClosed,
  privateWithInvitees,
  inviteesType,
  setInviteesType,
  quizSettings,
  handleAddInviteesTextArea,
  setValidEmails,
  setInvalidEmails,
}) => {
  const classes = useStyle({
    publish: isPublished,
    startTime: quizInfo?.startTime,
    endTime: quizInfo?.endTime,
    isClosed,
  })

  const [addInvitieesText, setAddInviteesText] = useState()
  const [validateDisabled, setValidateDisabled] = useState(true)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (testAreaText === undefined || testAreaText === '')
      setValidateDisabled(true)
    else setValidateDisabled(false)
  }, [testAreaText])

  useEffect(() => {
    setAddInviteesText(testAreaText)
  }, [testAreaText])

  const handleInviteesChange = (event) => {
    setInviteesType(event.target.value)
  }
  const renderToolTip = () => {
    if (quizSettings?.domain?.length > 0) {
      return 'Please delete Domains to add Invitees'
    }
    if (quizSettings?.invitees?.length > 0) {
      return 'Please delete Invitees to add Domains'
    }
    return ''
  }
  useEffect(() => {
    if (inviteesType === 'Email') {
      setAddInviteesText(quizSettings?.invitees)
    } else {
      setAddInviteesText(quizSettings?.domain)
    }
  }, [quizSettings?.invitees, quizSettings?.domain])
  useEffect(() => {
    setInvalidEmails([])
    setValidEmails([])
    setAddInviteesText('')
  }, [inviteesType])
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            Step 1/2 - Add {inviteesType === 'Email' ? 'Invitees' : 'Domain'}
          </Typography>
          <Typography
            style={{ fontSize: '16px', paddingTop: '8px', maxWidth: '450px' }}
          >
            Please provide the{' '}
            {inviteesType === 'Email'
              ? 'email addresses of the people '
              : 'Domain Name '}
            you would like to invite.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl
            disabled={
              quizSettings?.domain?.length > 0 ||
              quizSettings?.invitees?.length > 0
            }
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Invitees Type
            </InputLabel>
            <Tooltip
              arrow
              placement="top"
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              title={renderToolTip()}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={inviteesType}
                onChange={(e) => handleInviteesChange(e)}
                label="InviteesType"
              >
                <MenuItem
                  disabled={quizSettings?.domain?.length > 0}
                  value="Email"
                >
                  Email
                </MenuItem>
                <MenuItem
                  disabled={quizSettings?.invitees?.length > 0}
                  value="Domain"
                >
                  Domain
                </MenuItem>
              </Select>
            </Tooltip>
          </FormControl>
          {privateWithInvitees && (
            <Grid item>
              <Grid item xs={12}>
                <TextField
                  placeholder={
                    inviteesType === 'Email'
                      ? 'Enter mail addresses'
                      : 'Enter Domain Name'
                  }
                  disabled={
                    (new Date() > quizInfo?.startTime && isPublished) ||
                    isClosed
                  }
                  fullWidth
                  multiline
                  variant="outlined"
                  maxRows={8}
                  value={addInvitieesText}
                  onChange={(e) => handleAddInviteesTextArea(e)}
                />
              </Grid>
              {/* <Grid item className={classes.inviteesWrapper}>
                <div className={classes.inviteesDiv} />
                <div className={classes.clearInviteesWrapper}>
                  <Button
                    disabled={
                      (new Date() > quizInfo?.startTime && isPublished) ||
                      isClosed
                    }
                    className={classes.clearInvitees}
                    variant="text"
                    onClick={handleClearInvitees}
                  >
                    Clear all
                  </Button>
                </div>
              </Grid> */}
              {/* {invalidEmail && (
                <Grid item xs={12}>
                  <Typography className={classes.validationText}>
                    Please enter a valid email.
                  </Typography>
                </Grid>
              )} */}
              {/* <Typography className={classes.addingInfo}>
                *Please press Enter to validate candidates.
              </Typography> */}
              {/* <div className={classes.csvIconAndButtonWrapper}>
                <div>
                  <CSVIcon />
                </div>
                <div className={classes.csvButtonWrapper}>
                  <Tooltip
                    arrow
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                    title={toolTipRendering()}
                  >
                    <span>
                      <Button
                        disabled={isPublished || isClosed}
                        className={classes.uploadCSV}
                        variant="text"
                        // onClick={handleClearInvitees}
                      >
                        Upload CSV
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div> */}
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px 24px' }}>
          <Button onClick={handleClose} className={classes.dialogCancelButton}>
            Cancel
          </Button>
          <Button
            onClick={handleValidateButton}
            disabled={validateDisabled}
            color="tertiary"
            autoFocus
          >
            Validate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddInviteesModal
