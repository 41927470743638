import { Grid, Paper, Typography, Button, Tooltip } from '@material-ui/core'
import React, { useEffect } from 'react'
import moment from 'moment'
import ReviewFields from './ReviewFields'
import { useStyles } from './ReviewQuizStyles'
import Loader from '../Loader/Loader'

const ReviewQuiz = ({
  isPublishLoading,
  setIsPublishLoading,
  isDraftLoading,
  setIsDraftLoading,
  validationErrors,
  setValidationErrors,
  // updateQuiz,
  handleSaveAsDraft,
  isPublishable,
  handlePublishButton,
  setQuizSaveButtonType,
  handleSave,
  localQuiz,
  quizInfo,
  quizSettings,
  codingQuestionSwitch,
  questions,
  localQuizQuestion,
  isWriteAllowed,
}) => {
  const classes = useStyles({
    publish: localQuiz?.info?.status === 'PUBLISHED',
  })

  const handleDraftButton = () => {
    setIsDraftLoading(true)
    handleSaveAsDraft()
  }
  const handlePublish = () => {
    setIsPublishLoading(true)
    handlePublishButton()
  }

  useEffect(() => {
    setValidationErrors({
      ...validationErrors,
      title: localQuiz?.title === '',
      duration: quizInfo?.duration === '',
      startTime: quizInfo?.startTime === null,
      endTime: quizInfo?.endTime === null,
      description: quizInfo?.description === '',
      instructions: quizInfo?.instructions === '',
    })
  }, [localQuiz])

  const toolTipRender = (x) => {
    if (x === 'draft') {
      if (
        validationErrors?.title ||
        validationErrors?.description ||
        validationErrors?.instructions
      ) {
        return 'Please enter the missing details to save the test as a draft'
      }
    }
    if (x === 'publish') {
      if (localQuiz?.isClosed) {
        return 'Quiz has closed'
      }
      if (quizInfo?.startTime && new Date() > quizInfo?.startTime) {
        return ''
      }
      if (
        quizInfo?.startTime &&
        quizInfo?.endTime &&
        quizInfo?.startTime >= quizInfo?.endTime
      ) {
        return 'End time should be greater than start time'
      }

      if (
        !validationErrors?.title &&
        !validationErrors?.startTime &&
        !validationErrors?.endTime &&
        !validationErrors?.description &&
        !validationErrors?.instructions &&
        !isPublishable() &&
        questions?.length === 0
      ) {
        return 'Please add questions to publish the test'
      }
      if (codingQuestionSwitch && !localQuizQuestion?.coding) {
        return 'Please add coding questions else disable coding questions from quiz configurations tab'
      }
      if (!isPublishable()) {
        if (quizSettings?.type === 'public' && localQuizQuestion?.coding)
          return 'Please either remove the coding questions or change quiz type to private'
        return 'Please enter the missing details to save the test as a draft'
      }
    }
    return ''
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography className={classes.headerTitle}>
            Review Quiz details
          </Typography>
          {isWriteAllowed && (
            <div className={classes.buttons}>
              {localQuiz?.info?.status !== 'PUBLISHED' ? (
                <>
                  <Tooltip
                    arrow
                    placement="bottom-center"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                    title={toolTipRender('draft')}
                  >
                    <span>
                      <Button
                        disabled={
                          !isWriteAllowed ||
                          validationErrors?.title ||
                          validationErrors?.description ||
                          validationErrors?.instructions
                        }
                        color="secondary"
                        className={classes.editButton}
                        variant="outlined"
                        onClick={() => handleDraftButton()}
                      >
                        {/* {updateQuiz?.isLoading && !localQuiz?.isPublished ? ( */}
                        {isDraftLoading ? (
                          <div className={classes.loader}>
                            <Loader style={{ color: 'white' }} size="1.3rem" />
                          </div>
                        ) : (
                          'Save as Draft'
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    arrow
                    placement="bottom-center"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                    title={toolTipRender('publish')}
                  >
                    <span>
                      <Button
                        style={{ marginLeft: '8px' }}
                        disabled={!isPublishable() || !isWriteAllowed}
                        onClick={() => {
                          handlePublish()
                        }}
                      >
                        {isPublishLoading ? (
                          <div className={classes.publishLoader}>
                            <Loader size="1.3rem" />
                          </div>
                        ) : (
                          'Publish'
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                </>
              ) : (
                <Tooltip
                  arrow
                  placement="bottom-center"
                  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  title={toolTipRender('publish')}
                >
                  <span>
                    <Button
                      variant="filled"
                      // disabled={!isPublishable()}
                      onClick={() => {
                        setQuizSaveButtonType('save')
                        handleSave(true, 'save')
                      }}
                    >
                      Save Changes
                    </Button>
                  </span>
                </Tooltip>
              )}
            </div>
          )}
        </div>

        <Grid container xs={12} lg={9} style={{ marginBottom: '16px' }}>
          <ReviewFields
            title="Name"
            details={localQuiz?.info?.title}
            error={validationErrors?.title}
            errorMessage="Title is Empty"
          />
          <ReviewFields
            title="Start Time"
            details={moment(quizInfo?.startTime).format('MMMM Do YYYY, h:mm a')}
            error={validationErrors?.startTime}
            errorMessage="Start time is Empty"
          />
          <ReviewFields
            title="End Time"
            details={moment(quizInfo?.endTime).format('MMMM Do YYYY, h:mm a')}
            error={validationErrors?.endTime}
            errorMessage="End time is Empty"
          />
          <ReviewFields
            title="Duration"
            details={`${JSON.stringify(quizInfo?.duration)} minutes`}
            error={validationErrors?.duration}
            errorMessage="Duration is Empty"
          />
          <ReviewFields title="Test Type" details={quizSettings?.type} />
          <ReviewFields
            title="No of Emails/Domains"
            details={
              quizSettings?.domain?.length ||
              quizSettings?.invitees?.length ||
              0
            }
          />
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 500,
              marginBottom: '8px',
              marginTop: '16px',
            }}
          >
            Quiz Config
          </Typography>
          <ReviewFields
            title="Coding Question"
            details={codingQuestionSwitch ? 'Yes' : 'No'}
          />
          <ReviewFields
            title="Proctoring"
            details={quizSettings?.proctored ? 'Yes' : 'No'}
          />
          <ReviewFields
            title="Show Results"
            details={quizSettings?.result ? 'Yes' : 'No'}
          />
          <ReviewFields
            title="Randomize Questions"
            details={quizSettings?.randomize ? 'Yes' : 'No'}
          />

          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 500,
              marginBottom: '8px',
              marginTop: '16px',
            }}
          >
            Quiz Info
          </Typography>
          <ReviewFields
            title="Description"
            error={validationErrors?.description}
            errorMessage="Description is Empty"
            details={quizInfo?.description}
          />
          <ReviewFields
            title="Instructions"
            details={quizInfo?.instructions}
            error={validationErrors?.instructions}
            errorMessage="instructions are Empty"
          />
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 500,
              marginBottom: '8px',
              marginTop: '16px',
            }}
          >
            About question
          </Typography>
          <ReviewFields title="Total Questions" details={questions?.length} />
          {questions?.length > 0 && (
            <ReviewFields
              isQuestions
              title="List of questions"
              details={questions}
            />
          )}
        </Grid>
      </Paper>
    </div>
  )
}

export default ReviewQuiz
