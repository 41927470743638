/* eslint-disable no-param-reassign */
import { createFetcher } from '../../Fetcher/Fetcher'
import {
  MOCK_BASE_URL,
  QUIZ_ENDPOINT,
  BALANCE_ENDPOINT,
  ADMIN_ENDPOINT,
  INVITEES_END_POINT,
} from '../../constant'
import {
  identifyQuestionType,
  capiatilizeChoices,
  unixTimeStamp,
} from '../../utils'
/**
 * this function is to make url according to request parameters
 * @function urlBuilder
 ** @param {url, reqParam} default parameter
 */
export const urlBuilder = () => {}
/**
 * Creates a new Quiz.
 * @Applications
 */
export class Quiz {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Quiz(
        MOCK_BASE_URL,
        QUIZ_ENDPOINT,
        BALANCE_ENDPOINT,
        ADMIN_ENDPOINT,
        INVITEES_END_POINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(
    baseUrl,
    endpoint,
    balanceEndPoint,
    adminEndPoint,
    inviteesEndPoint,
    fetcherObj
  ) {
    this.base_url = baseUrl
    this.endpoint = endpoint
    this.balanceEndPoint = balanceEndPoint
    this.adminEndPoint = adminEndPoint
    this.inviteesEndPoint = inviteesEndPoint
    this.fetcher = fetcherObj
  }

  /**
   * this function is to get all quiz
   * @function getQuizTypeById
   ** @param {reqParam} default parameter
   */

  async getBalance() {
    const resourceId = localStorage.getItem('id')
    const url = `${this.base_url + this.adminEndPoint}/${
      resourceId + this.balanceEndPoint
    }`
    const response = await this.fetcher.getData(url)
    return response
  }

  async getQuizByFilter(reqType, reqFilter) {
    const url = `${
      this.base_url + this.adminEndPoint + this.endpoint
    }?type=${reqType}&status=${reqFilter}`
    const response = await this.fetcher.getData(url)
    if (response.status === 200) {
      const quizList = response?.data?.data?.data?.quizList
      quizList?.forEach((quiz) => {
        if (quiz?.time?.start !== null) {
          quiz.time.start = unixTimeStamp(quiz?.time?.start)
        }
        if (quiz?.time?.end !== null) {
          quiz.time.end = unixTimeStamp(quiz?.time?.end)
        }
        if (quiz?.createdAt !== null) {
          quiz.createdAt = unixTimeStamp(quiz?.createdAt)
        }
      })
    }
    return response
  }

  async getQuizPagination(reqType, reqFilter, limit, offset, titlesearch) {
    const url = `${
      this.base_url + this.adminEndPoint + this.endpoint
    }?type=${reqType}&status=${reqFilter}&limit=${limit}&offset=${offset}&title=${titlesearch}`
    const response = await this.fetcher.getData(url)
    if (response.status === 200) {
      const quizzes = response?.data?.data?.quizzes
      quizzes?.forEach((quiz) => {
        if (quiz?.time?.start !== null) {
          quiz.time.start = unixTimeStamp(quiz?.time?.start)
        }
        if (quiz?.time?.end !== null) {
          quiz.time.end = unixTimeStamp(quiz?.time?.end)
        }
        if (quiz?.createdAt !== null) {
          quiz.createdAt = unixTimeStamp(quiz?.createdAt)
        }
      })
    }
    return response
  }

  /**
   * this function is to get all applications
   * @function getQuizById
   ** @param {reqParam} default parameter
   */

  // async getQuizById(reqId, reqEmail, reqHeaders) {
  //   const url = `${
  //     this.base_url + this.endpoint
  //   }/${reqId}/admin?userEmail=${reqEmail}`
  //   const response = await Fetcher.getData(url, reqHeaders)
  //   return response
  // }

  /**
   * this function is to get all applications
   * @function postQuizById
   ** @param {reqParam} default parameter
   */

  async postQuiz(reqData) {
    const url = `${this.base_url + this.adminEndPoint + this.endpoint}`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }

  async postEndQuizById(reqId, reqData) {
    const url = `${this.base_url + this.endpoint}/${reqId}/end`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }

  async deleteQuizById(reqId) {
    const url = `${this.base_url + this.adminEndPoint + this.endpoint}/${reqId}`
    const response = await this.fetcher.deleteData(url)
    return response
  }

  async patchQuizById(reqId, reqData) {
    const url = `${this.base_url + this.adminEndPoint + this.endpoint}/${reqId}`
    const response = await this.fetcher.patchData(url, reqData)
    return response
  }

  async getQuizById(reqId) {
    const url = `${this.base_url + this.adminEndPoint + this.endpoint}/${reqId}`
    const response = await this.fetcher.getData(url)
    const quiz = response?.data?.data
    // let questions = []
    if (quiz.time.start !== null) {
      const strStartTime = quiz?.time?.start
      quiz.time.start = new Date(unixTimeStamp(strStartTime))
    }
    if (quiz.time.end !== null) {
      const strEndTime = quiz?.time?.end
      quiz.time.end = new Date(unixTimeStamp(strEndTime))
    }
    // if (quiz?.questions?.mcq) {
    //   const modifiedMCQ = quiz?.questions?.mcq?.map((question) => {
    //     const quesType = identifyQuestionType(question)
    //     const modifiedQuestion =
    //       quesType === 't/f' ? capiatilizeChoices(question) : question
    //     return {
    //       ...modifiedQuestion,
    //       type: quesType,
    //     }
    //   })
    //   questions = questions.concat(modifiedMCQ)
    // }

    // if (quiz?.questions?.coding) {
    //   const modifiedCodingQuestions = quiz?.questions?.coding?.map(
    //     (question) => {
    //       return {
    //         ...question,
    //         type: 'coding',
    //       }
    //     }
    //   )
    //   questions = questions.concat(modifiedCodingQuestions)
    // }

    // quiz = { ...quiz, questions }
    return quiz
  }

  /**
   * this function is to get all applications
   * @function getQuizQuestionById
   ** @param {reqId} default parameter
   */
  async getQuizQuestionById(reqId) {
    const url = `${this.base_url}${this.adminEndPoint}${this.endpoint}/${reqId}/questions`
    const response = await this.fetcher.getData(url)
    let quizQuestion = response?.data?.data
    let questions = []
    if (quizQuestion?.mcq) {
      const modifiedMCQ = quizQuestion?.mcq?.map((question) => {
        const quesType = identifyQuestionType(question)
        const modifiedQuestion =
          quesType === 't/f' ? capiatilizeChoices(question) : question
        return {
          ...modifiedQuestion,
          type: quesType,
        }
      })
      questions = questions.concat(modifiedMCQ)
    }
    if (quizQuestion?.essay) {
      const modifiedMCQ = quizQuestion?.essay?.map((question) => {
        const quesType = identifyQuestionType(question)
        return {
          ...question,
          type: quesType,
        }
      })
      questions = questions.concat(modifiedMCQ)
    }
    if (quizQuestion?.fillInBlanks) {
      const modifiedMCQ = quizQuestion?.fillInBlanks?.map((question) => {
        const quesType = identifyQuestionType(question)
        return {
          ...question,
          type: quesType,
        }
      })
      questions = questions.concat(modifiedMCQ)
    }
    if (quizQuestion?.coding) {
      const modifiedCodingQuestions = quizQuestion?.coding?.map((question) => {
        return {
          ...question,
          type: 'coding',
        }
      })
      questions = questions.concat(modifiedCodingQuestions)
    }
    quizQuestion = { ...quizQuestion, questions }
    return quizQuestion
  }

  async postInviteesById(reqId, reqData) {
    const url = `${this.base_url + this.adminEndPoint + this.endpoint}/${
      reqId + this.inviteesEndPoint
    }`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }

  async getInviteesById(reqId, inviteesType) {
    if (reqId && inviteesType === 'Email') {
      const url = `${this.base_url + this.adminEndPoint + this.endpoint}/${
        reqId + this.inviteesEndPoint
      }`
      const response = await this.fetcher.getData(url)
      return response?.data
    }
    return null
  }

  // async deleteInviteesById(reqId, reqData, reqHeaders) {
  //   const url = `${this.base_url + this.adminEndPoint + this.endpoint}/${
  //     reqId + this.inviteesEndPoint
  //   }?email=${reqData}`
  //   const response = await Fetcher.deleteData(url, reqHeaders)
  //   return response
  // }

  /**
   * this function is to get all applications
   * @function postDomainNameById
   ** @param {reqId, reqData} default parameter
   */
  async postDomainNameById(reqId, reqData) {
    const url = `${this.base_url}${this.adminEndPoint}${this.endpoint}/${reqId}/email-domain`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }

  /**
   * this function is to get all applications
   * @function getDomainNameById
   ** @param {reqId} default parameter
   */
  async getDomainNameById(reqId) {
    if (reqId) {
      const url = `${this.base_url}${this.adminEndPoint}${this.endpoint}/${reqId}/email-domain`
      const response = await this.fetcher.getData(url)
      return response
    }
    return null
  }

  /**
   * this function is to get all applications
   * @function deleteDomainNameById
   ** @param {reqId} default parameter
   */
  async deleteDomainNameById(reqId) {
    const url = `${this.base_url}${this.adminEndPoint}${this.endpoint}/${reqId}/email-domain`
    const response = await this.fetcher.deleteData(url)
    return response
  }
  /**
   * this function is to get user Details
   * @function deleteDomainNameById
   ** @param {reqId} default parameter
   */

  async getUserDetails() {
    const email = localStorage.getItem('email')
    const url = `${this.base_url + this.adminEndPoint}/email/${email}`
    const response = await this.fetcher.getData(url)
    return response?.data?.data
  }

  async getAllQuizzesForAQuestion(reqId) {
    const url = `${this.base_url + this.adminEndPoint}/question/${reqId}/quiz`
    const response = await this.fetcher.getData(url)
    return response?.data?.data
  }

  async updateQuestionForQuizzes(reqData) {
    const url = `${this.base_url + this.adminEndPoint}/question/${
      reqData?.oldQuestionId
    }/quiz?newQuestionID=${reqData?.newQuestionId}`
    const reqBody = {
      type: reqData?.type,
      marks: reqData?.marks,
      quizIDs: reqData?.quizIDs,
    }
    const response = await this.fetcher.putData(url, reqBody)
    return response?.data?.data
  }

  async postPermissionById(reqId, reqData) {
    const url = `${this.base_url}${this.adminEndPoint}${this.endpoint}/${reqId}/permission`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }

  async deleteQuizPermission(reqId, userId) {
    const url = `${this.base_url}${this.adminEndPoint}${this.endpoint}/${reqId}/permission?userID=${userId}`
    const response = await this.fetcher.deleteData(url)
    return response
  }

  async getAllPermissions(quizId) {
    const url = `${
      this.base_url + this.adminEndPoint
    }/quiz/${quizId}/permission`
    const response = await this.fetcher.getData(url)
    return response
  }

  async updateQuizPermission(reqId, reqData) {
    const url = `${this.base_url}${this.adminEndPoint}${this.endpoint}/${reqId}/permission`
    const response = await this.fetcher.putData(url, reqData)
    return response
  }
}

export const createQuiz = () => Quiz.getInstance()
