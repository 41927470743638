import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  IconButton,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import { Cancel } from '@material-ui/icons'
import { DialogActions } from '../Dashboard/CustomDialog'
import Loader from '../Loader/Loader'
import { styles } from './MyViewModifyDialogStyles'

function MyViewModifyDialog({
  setUserInputArray,
  userInputArray,
  openDialogBox,
  setOpenDialogBox,
  totalLiveCandidates,
  totalCandidatesRefetch,
  isTotalCandidateListFetching,
  isTotalCandidateListLoading,
}) {
  const [inputValue, setInputValue] = useState('')
  const [dialogList, setDialogList] = useState([])
  const [candidatesNotFound, setCandidatesNotFound] = useState()
  const [redundantCandidates, setRedundantCandidates] = useState()
  const classes = styles()
  const handleApplyCancel = (value) => {
    if (value === 'Apply') {
      setUserInputArray(dialogList)
      setOpenDialogBox(false)
    }
    if (value === 'Cancel') {
      setOpenDialogBox(false)
      setDialogList(userInputArray)
    }
    setCandidatesNotFound(() => '')
    setInputValue(() => '')
    setRedundantCandidates(() => '')
  }
  const handleValidation = (validationData) => {
    const validData = validationData.filter((object1) => {
      return totalLiveCandidates.some((object2) => {
        return object1 === object2?.email
      })
    })
    const invalidData = validationData.filter((object1) => {
      return !totalLiveCandidates.some((object2) => {
        return object1 === object2?.email
      })
    })
    return { validData, invalidData }
  }
  const handleRedundantCandidates = () => {
    const inputArray = inputValue?.split(',').map((element) => {
      return element.trim()
    })
    const uniqueInputArrayValue = [...new Set(inputArray)]
    const validationData = uniqueInputArrayValue?.filter((object1) => {
      return !dialogList?.includes(object1)
    })
    const redundantCandidatesData = uniqueInputArrayValue?.filter((object1) => {
      return dialogList?.includes(object1)
    })
    redundantCandidatesData.forEach((data, index) => {
      redundantCandidatesData[index] = ' '.concat(data)
    })
    return { validationData, redundantCandidatesData }
  }
  const handleClickAdd = () => {
    const { validationData, redundantCandidatesData } =
      handleRedundantCandidates()
    const { validData, invalidData } = handleValidation(validationData)
    if (validData.length !== 0) {
      setDialogList((dialogListPrev) => dialogListPrev.concat(validData))
      setCandidatesNotFound(() => '')
      setInputValue('')
    }
    if (invalidData.length !== 0) {
      setInputValue(() => invalidData.toString())
      setCandidatesNotFound(() => invalidData.toString())
      setRedundantCandidates(() => '')
    }
    if (redundantCandidatesData.length !== 0) {
      setCandidatesNotFound(() => '')
      setRedundantCandidates(() => redundantCandidatesData.toString())
    }
    if (redundantCandidatesData.length !== 0 && invalidData.length !== 0) {
      setRedundantCandidates(() => redundantCandidatesData.toString())
      setCandidatesNotFound(() => invalidData.toString())
    }
  }
  const renderAcknowledgement = () => {
    if (candidatesNotFound && candidatesNotFound.length !== 0) {
      return (
        <Typography className={classes.candidatesNotFoundText}>
          * {candidatesNotFound} not found
        </Typography>
      )
    }
    if (!candidatesNotFound && !redundantCandidates) {
      return (
        <Typography className={classes.textFieldInformationText}>
          * Click Add Icon To Add
        </Typography>
      )
    }
    return ''
  }
  const handleDelete = (indexValue) => {
    const data = dialogList?.filter((x, index) => index !== indexValue)
    setDialogList(data)
  }
  useEffect(() => {
    if (candidatesNotFound) {
      totalCandidatesRefetch()
    }
  }, [candidatesNotFound])
  return (
    <>
      <Dialog open={openDialogBox} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitle}>
          <Typography className={classes.dialogTitleText}>
            <b>My View</b>
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContentWrapper}>
          <TextField
            fullWidth
            value={inputValue}
            maxWidth="sm"
            id="outlined"
            variant="outlined"
            placeholder="Add Email Id"
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{
              endAdornment:
                isTotalCandidateListFetching || isTotalCandidateListLoading ? (
                  <div className={classes.loader}>
                    <Loader size={20} />
                  </div>
                ) : (
                  <IconButton>
                    <AddIcon onClick={handleClickAdd} />
                  </IconButton>
                ),
            }}
          />
          <Typography>{renderAcknowledgement()}</Typography>
          {redundantCandidates && redundantCandidates.length !== 0 && (
            <Typography className={classes.redundantCandidatesText}>
              {redundantCandidates} present already
            </Typography>
          )}
          <Grid className={classes.candidatesListWrapper}>
            {dialogList?.map((candidate, index) => {
              return (
                <Grid key={index} className={classes.candidateWrapper}>
                  <Typography className={classes.candidateText}>
                    {candidate}
                  </Typography>
                  <Grid style={{ flex: 1 }} />
                  <IconButton
                    value={candidate?.email}
                    onClick={() => handleDelete(index)}
                  >
                    <Cancel className={classes.cancelIcon} />
                  </IconButton>
                </Grid>
              )
            })}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              handleApplyCancel('Cancel')
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleApplyCancel('Apply')
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MyViewModifyDialog
