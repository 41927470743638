import { useQuery } from 'react-query'
import { createBillingConfigs } from '../../Repository/BillingConfig/BillingConfig'

const billingConfigs = createBillingConfigs()
/**
 * this function is to Query get All application
 * @function useBillingConfigGetQuery
 ** @param {options} default parameter
 */
export function useBillingConfigGetQuery(options) {
  return useQuery(
    ['useBillingConfigGetQuery'],
    () => billingConfigs.getBillingConfig(),
    { ...options }
  )
}
