import { useMutation, useQuery } from 'react-query'
import { createQuiz } from '../../Repository/Quiz/Quiz'

const quizReq = createQuiz()
/**
 * this function is to Query get All application
 * @function useQuizTypeGetQueryById
 ** @param {reqId, options} default parameter
 */

export function useQuizGetBalance(options) {
  return useQuery(['balance'], () => quizReq.getBalance(), { ...options })
}
/**
 * this function is to Query get All application
 * @function useQuizGetQueryById
 ** @param {reqId, options} default parameter
 */
export function useQuizGetQueryById(reqId, reqEmail, options) {
  return useQuery(
    ['quizGetQueryById', reqId, reqEmail],
    () => quizReq.getQuizById(reqId, reqEmail),
    { ...options }
  )
}

/**
 * this function is to Query get All application
 * @function useQuizQuestionGetQueryById
 ** @param {reqId, options} default parameter
 */
export function useQuizQuestionGetQueryById(reqId, options) {
  return useQuery(
    ['quizGetQuizQuestionQueryById', reqId],
    () => quizReq.getQuizQuestionById(reqId),
    { ...options }
  )
}

/**
 * this function is to Query get All application
 * @function useQuizPostQueryById
 ** @param {reqId, options} default parameter
 */
export function useQuizPostQuery(options) {
  return useMutation((args) => quizReq.postQuiz(args), {
    ...options,
  })
}
/**
 * this function is to Query get All application
 * @function useQuizDeleteQueryById
 ** @param {reqId, options} default parameter
 */

export function useQuizDeleteQueryById(reqId, reqEmail, options) {
  return useMutation(
    ['QuizDeleteQueryById', reqId, reqEmail],
    () => quizReq.deleteQuizById(reqId, reqEmail),
    {
      ...options,
    }
  )
}

export function useQuizPatchById(reqId, options) {
  return useMutation(
    ['quizPutById', reqId],
    (args) => quizReq.patchQuizById(reqId, args),
    {
      ...options,
    }
  )
}
export function useEndQuizPostById(reqId, options) {
  return useMutation(
    ['endQuizPostById', reqId],
    (args) => quizReq.postEndQuizById(reqId, args),
    {
      ...options,
    }
  )
}

export function useQuizIdGetQuizPagination(
  reqType,
  reqFilter,
  limit,
  offset,
  titlesearch,
  options
) {
  return useQuery(
    ['quizPagination', reqType, reqFilter, limit, offset, titlesearch],
    () =>
      quizReq.getQuizPagination(reqType, reqFilter, limit, offset, titlesearch),
    { ...options }
  )
}

/** This Section is belongs to email-domain calls */

// GET CALL
export function useDomainNameGetById(reqId, inviteesType, options) {
  return useQuery(
    ['getDomainNameById', reqId, inviteesType],
    () => quizReq.getDomainNameById(reqId, inviteesType),
    {
      ...options,
    }
  )
}

// POST CALL
export function useDomainNamePostById(reqId, options) {
  return useMutation(
    ['postDomainNameById', reqId],
    (args) => quizReq.postDomainNameById(reqId, args),
    {
      ...options,
    }
  )
}

// DELETE CALL
export function useDomainNameDeleteById(reqId, options) {
  return useMutation(
    ['deleteDomainNameById', reqId],
    () => quizReq.deleteDomainNameById(reqId),
    {
      ...options,
    }
  )
}

export function useQuizPostInvitees(reqId, options) {
  return useMutation((args) => quizReq.postInviteesById(reqId, args), {
    ...options,
  })
}

export function useQuizGetInviteesById(reqId, inviteesType, options) {
  return useQuery(
    ['useQuizGetInviteesById', reqId, inviteesType],
    () => quizReq.getInviteesById(reqId, inviteesType),
    { ...options }
  )
}

export function useGetUserDetails(options) {
  return useQuery(['useGetUserDetails'], () => quizReq.getUserDetails(), {
    ...options,
  })
}

export function useGetAllQuizzesForAQuestion(reqId, options) {
  return useQuery(
    ['getAllQuizzesForAQuestion', reqId],
    () => quizReq.getAllQuizzesForAQuestion(reqId),
    { ...options }
  )
}

export function useUpdateQuestionForQuizzes(options) {
  return useMutation((args) => quizReq.updateQuestionForQuizzes(args), {
    ...options,
  })
}

export function useQuizPostPermissions(reqId, options) {
  return useMutation((args) => quizReq.postPermissionById(reqId, args), {
    ...options,
  })
}

export function useQuizDeletePermisisons(reqId, options) {
  return useMutation((args) => quizReq.deleteQuizPermission(reqId, args), {
    ...options,
  })
}

export function useGetAllPermissions(quizId, options) {
  return useQuery(
    ['getAPermissions'],
    () => quizReq.getAllPermissions(quizId),
    {
      ...options,
    }
  )
}

export function useQuizUpdatePermissionByUserID(reqId, options) {
  return useMutation((args) => quizReq.updateQuizPermission(reqId, args), {
    ...options,
  })
}
