import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    width: '100%',
  },
  paper: {
    padding: '64px 72px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
    },
  },
  editPaper: {
    padding: '33px 72px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1%',
  },
  question: {
    fontSize: '20px',
    fontWeight: 400,
    marginBlockStart: '0em',
    paddingBottom: '4px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  tileMarks: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  marks: {
    background: theme?.palette?.background?.grey,
    padding: '15px 20px',
    borderRadius: '4px',
    height: '62px',
    width: '100%',
  },
  marksCount: {
    display: 'table',
    margin: '0 auto',
    color: theme?.palette?.text?.primary,
    lineHeight: 1.1,
  },
  marksLabel: {
    display: 'table',
    margin: '0 auto',
    color: theme?.palette?.text?.secondary,
    lineHeight: 1.5,
    fontSize: '12px',
    opacity: '80%',
  },
  index: {
    display: 'flex',
    alignItems: 'center',
  },
  choices: {
    marginTop: '1%',
  },
  choice: {
    height: '3rem',
  },
  checkIcon: {
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '5%',
  },
  checkIconContainer: {
    padding: '1em',
  },
  editQuestion: {
    paddingBottom: '3%',
  },
  editMcqQuestion: {
    marginBottom: '15px',
  },
  mcqChoice: {
    marginBottom: '5px',
  },
  mcqRadioButton: {
    paddingLeft: '30%',
  },
  mcqChoiceConatiner: {
    borderRadius: '0 5px 5px 0',
  },
  selectedMcqChoice: {
    // paddingTop: '4px',
    color: '#00B7A4',
    // fontSize: 'small',
  },
  buttonProgress: {
    color: 'purple',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  addChoiceButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  selectedChoiceText: {
    fontSize: '12px',
    color: '#01B78F',
    marginRight: '5%',
  },
  selectedChoiceCheckBox: {
    height: 24,
    width: 24,
    color: 'white',
    backgroundColor: '#00B7A4',
    borderRadius: 2,
    cursor: 'pointer',
  },
  inputChoiceValue: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  choiceValue: {
    fontSize: 16,
    marginLeft: '36px',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  selectedChoiceValue: {
    fontSize: 16,
    marginLeft: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  isCorrect: {
    border: '1px solid #01B7A4',
    backgroundColor: '#ECFBF6',
  },
  choiceContainer: {
    borderRadius: '4px',
    marginLeft: '20px',
    backgroundColor: theme?.palette?.background?.grey,
  },
  radioChoiceContainer: {
    textAlign: 'center',
    width: '40',
    borderLeft: '1px solid lightgrey',
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    borderRadius: '5px 0 0 5px',
  },
  mcqRadioChoice: {
    marginLeft: '30%',
  },
  marksQuestionValidationContainer: {
    padding: '0px 8px 0px 8px',
  },
  mcqMarksContainer: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  marksValidationContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  validationText: {
    fontSize: '12px',
    color: theme?.palette?.inputValidation?.main,
  },
  closeChoice: {
    height: '24px',
    width: '24px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  marksHeading: {
    width: '100%',
    textAlign: 'center',
    color: '#262A4C',
    fontSize: '12px',
  },
  titleDialog: {
    padding: '24px',
    pointerEvents: 'none',
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    paddingBottom: '4px',
  },
  dialogActions: {
    padding: '0px 24px 24px',
  },
  dialogCancelButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    // border: `1px solid ${theme?.palette?.cancelButton?.text}`,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },
  },
  dialogDeleteButton: {
    backgroundColor: theme?.palette?.dangerPrimaryButton?.main,
    color: theme?.palette?.dangerPrimaryButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.dangerPrimaryButton?.hover,
    },
  },
  deleteButton: {
    backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.dangerSecondaryButton?.main}`,
    color: theme?.palette?.dangerSecondaryButton?.main,
    lineHeight: '14px',
    padding: '10px 20px',
    marginRight: '10px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: theme?.palette?.dangerSecondaryButton?.hover,
    },
    [theme.breakpoints.down('xs')]: {
      width: '50px',
    },
  },
  marksType: {
    '@media only screen and (max-width:599px)': {
      display: 'block',
      marginTop: '24px',
    },
  },
  editButton: {
    backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.primaryButton?.main}`,
    color: theme?.palette?.primaryButton?.main,
    lineHeight: '14px',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: theme?.palette?.secondaryButton?.hover,
    },
    [theme.breakpoints.down('xs')]: {
      width: '50px',
    },
  },
  questionIndex: {
    fontSize: '20px',
    color: '#212649',
    fontWeight: 500,
    lineHeight: '1.1',
    // '@media only screen and (max-width:599px)': {
    //   marginBottom: '24px',
    // },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  questionHeader: {
    // marginBottom: '10px',
  },
  cancelButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    // border: `1px solid ${theme?.palette?.cancelButton?.text}`,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },

    marginTop: '10px',
    marginRight: '10px',
    '@media only screen and (max-width:460px)': {
      marginRight: '4px',
    },
  },
  cancelAndSaveButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  mcqToggle: {
    paddingBottom: '10px',
  },
  shortTypeInputPadding: {
    paddingLeft: '8px',
    paddingTop: '6px',
  },
  automaticEvaluationText: {
    paddingTop: '12px',
    paddingRight: '12px',
    color: '#02b7a4',
    fontStyle: 'italic',
    fontWeight: 'bolder',
  },
  automaticEvaluationGrid: {
    display: 'flex',
    justifyContent: 'end',
    marginRight: '0px',
    marginTop: '-8px',
  },
}))
export { useStyles }
