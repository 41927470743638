import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
  paginationNumberedButtonsChild: {
    fontSize: '16px',
    display: 'flex',
    paddingTop: '4px',
    color: '#262A4C',
  },
  paginationDottedButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '10px',
    maxWidth: '100px',
    '@media only screen and (min-width:350px)': {
      maxWidth: '180px',
    },
    '@media only screen and (min-width:400px)': {
      maxWidth: '220px',
    },
    '@media only screen and (min-width:450px)': {
      maxWidth: '250px',
    },
    '@media only screen and (min-width:600px)': {
      maxWidth: '350px',
    },
    '@media only screen and (min-width:750px)': {
      maxWidth: '390px',
    },
    '@media only screen and (min-width:900px)': {
      maxWidth: '500px',
    },
    '@media only screen and (min-width:1024px)': {
      maxWidth: '550px',
    },
    overflowX: 'auto',
    paddingBottom: '3px',
  },
  paginationMainGrid: {
    display: 'flex',
    width: 'max-content',
    height: 'max-content',
    color: 'grey',
    alignItems: 'center',
    paddingTop: '16px',
    marginTop: 'auto',
    justifyContent: 'center',
  },
  ArrowBackIosIcon: {
    cursor: 'pointer',
    width: '30px',
    padding: '5px',
    paddingBottom: '5px',
    paddingRight: '0px',
    color: (props) => (props.resultsPageOffset === 0 ? 'grey' : '#262A4C'),
  },
  ArrowForwardIosIcon: {
    cursor: 'pointer',
    width: '30px',
    padding: '5px',
    paddingBottom: '5px',
    color: (props) =>
      props.resultPagesArray.length - 1 ===
      props.resultsPageOffset / props.resultPageLimit
        ? 'grey'
        : '#262A4C',
  },
  paginationStyle: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' &:hover': {
      color: '#00B7A4',
    },
  },
}))
export { styles }
