import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '16px',
    padding: '8px 24px 8px 24px',
  },
  button: {
    color: theme.palette.primarySelection.main,
    borderColor: theme.palette.primarySelection.main,
    backgroundColor: 'white',
    width: 'max-content',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  collapseArrow: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '8px',
  },
  quizTitle: {
    color: theme.palette.primarySelection.main,
    fontSize: 20,
    fontWeight: 500,
    paddingTop: '4px',
    cursor: 'pointer',
  },

  quizCardContainer: {
    maxWidth: '99%',
    padding: 0,
  },
  quizDate: {
    color: theme.palette.primary.main,
  },
  quizDetailsContainer: {
    paddingTop: '15px',
  },
  avatar: {
    backgroundColor: theme.palette.darkBackground.main,
    color: theme.palette.darkBackground.contrastText,
    height: '36px',
    width: '36px',
  },
  quizDetailsTitle: {
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  button1: {
    pointerEvents: 'none',
  },
  link: {
    textDecoration: 'none',
  },
  CopyLinkIcon: {
    height: '1.5rem',
    width: '1.5rem',
  },
  copyUrlButton: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.secondary.main,
    height: '34px',
    cursor: 'copy',
    padding: '2px',
    fontWeight: 500,
    fontSize: '14px',
    marginRight: '12px',
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  quizNotPublishedContainer: {
    width: 'fit-content',
    height: '34px',
    padding: '0px 15px',
    borderRadius: '17px',
    backgroundColor: theme.palette.quizNotPublished.backgroundColor,
  },
  quizNotPublishedText: {
    color: theme.palette.quizNotPublished.text,
    fontSize: '12px',
    fontWeight: '500',
    '@media (min-width:620px)': {
      fontSize: '14px',
    },
  },
}))

export { useStyles }
