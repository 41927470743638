import React, { useState, useRef, useEffect, useMemo } from 'react'
import ReactQuill from 'react-quill'
import axios from 'axios'
import EditorToolbar, { formats } from './TextEditorToolbar'
import 'react-quill/dist/quill.snow.css'
import './style.css'
import Loader from '../Loader/Loader'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'

const QuillTextEditor = ({
  textEditorQuestion,
  setTextEditorQuestion,
  className,
  isQuizStarted,
  setLocalQuestion,
  localQuestion,
  setEditViewQuestion,
}) => {
  const [isImgLoading, setIsImgLoading] = useState(false)
  const [errorSnackBar, setErrorSnackBar] = useState(false)
  const [errorStatus, setErrorStatus] = useState(null)
  const toolbarRef = useRef(null)
  const quillRef = useRef(null)

  const getMediaServiceErrorMessage = new Map([
    [400, 'File format is not correct.'],
    [401, 'User is not authorized.'],
    [403, 'This content is not allowed.'],
    [404, 'Upload Path is Incorrect.'],
    [500, 'Internal Server Error.'],
    [504, 'The upstream server is timing out.'],
  ])

  useEffect(() => {
    setTextEditorQuestion((prevQuestion) => {
      const updatePrevQuestion = { ...prevQuestion }
      updatePrevQuestion.question = localQuestion?.question
      return updatePrevQuestion
    })
    setEditViewQuestion(localQuestion)
  }, [localQuestion])

  const handleChange = (value) => {
    if (value !== '<p><br></p>') {
      setTextEditorQuestion((prevQuestion) => {
        const updatePrevQuestion = { ...prevQuestion }
        updatePrevQuestion.question = value
        return updatePrevQuestion
      })
      const data = JSON.parse(JSON.stringify(localQuestion))
      data.question = value
      setLocalQuestion(data)
    }
  }

  useEffect(() => {
    if (toolbarRef.current) {
      const toolbarContainer = toolbarRef.current.querySelector(
        `#toolbar_${className}`
      )
      toolbarContainer.classList.add('ql-snow')
    }
  }, [className])

  const handleImageInsert = (imageUrl) => {
    const editor = quillRef.current.getEditor()
    const range = editor.getSelection(true)
    editor.insertEmbed(range.index, 'image', imageUrl)
  }

  const handleImageUpload = async (file) => {
    try {
      setIsImgLoading(true)
      setErrorSnackBar(false)
      const bodyFormData = new FormData()
      bodyFormData.append('imageUpload', file)
      const response = await axios({
        method: 'post',
        url: 'https://staging.zopping.com/api/media-service/image-upload',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Developer-Token': 'a0c0e34e-09b0-4433-bb25-25d81e521383',
        },
      })
      setIsImgLoading(false)
      return response?.data?.data?.imageUpload?.url
    } catch (error) {
      setIsImgLoading(false)
      setErrorSnackBar(true)
      console.error('Error during image upload:', error)
      setErrorStatus(error?.response?.status)
      throw error
    }
  }

  const modules = useMemo(() => {
    try {
      return {
        toolbar: {
          container: `#toolbar_${className}`,
          handlers: {
            image: () => {
              const editor = quillRef.current.getEditor()
              const range = editor.getSelection(true)
              editor.setSelection(range)
              editor.focus()

              const input = document.createElement('input')
              input.setAttribute('type', 'file')
              input.setAttribute('accept', 'image/*')
              input.click()

              input.onchange = async () => {
                const file = input.files[0]
                const imageUrl = await handleImageUpload(file)
                handleImageInsert(imageUrl)
              }
            },
          },
        },
      }
    } catch (error) {
      setErrorSnackBar(true)
      console.error('Error creating modules:', error)
      setErrorStatus(error?.response?.status)
      throw error
    }
  }, [className])

  const handleCloseSnackBar = () => {
    setErrorSnackBar(false)
    setErrorStatus(null)
  }

  function onPaste(event) {
    const brRegex = /\r?\n/g
    const paste = (event.clipboardData || window.clipboardData).getData(
      'text/plain'
    )
    const divText = paste.split(brRegex)

    const selection = window.getSelection()
    if (!selection.rangeCount) return false

    const div = document.createElement('div')
    divText.forEach((text) => {
      div.appendChild(document.createTextNode(text))
      div.appendChild(document.createElement('br'))
    })

    selection.deleteFromDocument()
    selection.getRangeAt(0).insertNode(div)

    event.preventDefault()
    return null
  }

  const render = () => {
    if (isImgLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader size="2rem" />
        </div>
      )
    }

    if (className !== undefined && className !== null) {
      return (
        <div id={`toolbar_${className}`} data-testid="text-editor">
          <>
            <EditorToolbar className={className} ref={toolbarRef} />
            <div onPaste={(event) => onPaste(event)}>
              <ReactQuill
                readOnly={isQuizStarted}
                className="react-quill-div"
                theme="snow"
                ref={quillRef}
                value={textEditorQuestion?.question}
                onChange={handleChange}
                placeholder="Enter Question"
                modules={modules}
                formats={formats}
                data-testid="react-quill"
              />
            </div>
          </>
        </div>
      )
    }

    return null
  }

  return (
    <>
      {render()}
      <CommonSnackbar
        open={errorSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        severity="error"
        message={getMediaServiceErrorMessage.get(errorStatus)}
      />
    </>
  )
}

export default QuillTextEditor
