import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, Paper, Checkbox } from '@material-ui/core'
import { useStyle } from './UpdateQuestionInQuizesModalStyles'
import Loader from '../Loader/Loader'

const UpdateQuestionsInQuizesModal = ({
  isLoading,
  showQuizesListModal,
  setShowQuizesListModal,
  affectedQuizes,
  handleSave,
  setSelectedQuizzes,
  selectedQuizzes,
  quizId,
}) => {
  const classes = useStyle()
  const handleClose = () => {
    return setShowQuizesListModal(false)
  }

  const handleQuizSelectToggle = (data) => {
    if (selectedQuizzes?.find((e) => e.quizID === data.quizID)) {
      const temp = selectedQuizzes?.filter((e) => e.quizID !== data.quizID)
      setSelectedQuizzes(temp)
    } else {
      setSelectedQuizzes((preValue) => [...preValue, data])
    }
  }

  const toggleSelectAll = () => {
    if (selectedQuizzes.length !== affectedQuizes.length) {
      setSelectedQuizzes(affectedQuizes)
    } else {
      setSelectedQuizzes([])
    }
  }

  const [currentQuizIndex, setCurrentQuizIndex] = useState(null)

  useEffect(() => {
    setSelectedQuizzes(affectedQuizes)
    setCurrentQuizIndex(affectedQuizes?.findIndex((e) => e.quizID === quizId))
  }, [affectedQuizes])

  const renderData = () => {
    if (isLoading) {
      return (
        <div
          style={{
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'scroll',
            backgroundColor: affectedQuizes?.length === 0 ? 'F3F7F7' : '',
          }}
        >
          <Loader size="3rem" />
        </div>
      )
    }
    return (
      <div
        style={{
          height: '300px',
          overflowY: 'scroll',
          backgroundColor: affectedQuizes?.length === 0 ? 'F3F7F7' : '',
        }}
      >
        <div
          key={currentQuizIndex}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 14px ',
            borderBottom: '1px solid #dadce0',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Typography style={{ marginRight: 8 }}>Select</Typography>
                  <Typography style={{ paddingLeft: '8px' }}>
                    Quiz Title
                  </Typography>
                </div>
                <Typography style={{ marginRight: 4 }}>Status</Typography>
              </div>
            </div>
          </div>
        </div>
        {currentQuizIndex >= 0 && (
          <div
            key={currentQuizIndex}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 14px ',
              borderBottom: '1px solid #dadce0',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  opacity: 0.7,
                  pointerEvents: 'none',
                }}
              >
                <Checkbox style={{ marginRight: 8 }} checked />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Typography style={{ paddingLeft: '8px' }}>
                      {affectedQuizes?.[currentQuizIndex]?.title}
                    </Typography>
                    <Typography style={{ paddingLeft: '8px' }}>
                      {affectedQuizes?.[currentQuizIndex]?.quizID === quizId &&
                        '(CURRENT)'}
                    </Typography>
                  </div>
                  <Typography style={{ paddingLeft: '8px' }}>
                    {affectedQuizes?.[currentQuizIndex]?.status}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
        {affectedQuizes?.map(
          (data, index) =>
            index !== currentQuizIndex && (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '8px 14px ',
                  borderBottom: '1px solid #dadce0',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Checkbox
                      onClick={() => handleQuizSelectToggle(data)}
                      style={{ marginRight: 8 }}
                      checked={selectedQuizzes?.find(
                        (e) => e.quizID === data.quizID
                      )}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Typography style={{ paddingLeft: '8px' }}>
                        {data.title}
                      </Typography>
                      <Typography style={{ paddingLeft: '8px' }}>
                        {data.status}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    )
  }
  return (
    <div>
      <Dialog
        open={showQuizesListModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography
            className={classes.dialogTitle}
            style={{ maxWidth: '450px' }}
          >
            Update Question
          </Typography>
          <Typography
            style={{ fontSize: '16px', paddingTop: '8px', maxWidth: '450px' }}
          >
            Below is the list of Quizes having the same question. Please choose
            if you want to update this question in these quizzes as well or not
          </Typography>
        </DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2px 14px ',
            alignItems: 'center',
          }}
        >
          <div>
            <Checkbox
              onClick={() => toggleSelectAll()}
              checked={affectedQuizes?.length === selectedQuizzes?.length}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
            }}
          >
            <div style={{ display: 'flex' }}>
              <Typography>Select All</Typography>
            </div>
          </div>
        </div>
        <DialogContent>
          <Paper
            variant="outlined"
            style={{ border: '2px solid #00B7A4', width: '450px' }}
          >
            {renderData()}
          </Paper>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24 24px' }}>
          <Button className={classes.dialogCancelButton} onClick={handleClose}>
            Close
          </Button>{' '}
          <Button onClick={() => handleSave('duplicateEdit')}>{`Update ${
            affectedQuizes?.length === selectedQuizzes?.length
              ? 'All'
              : 'Selected'
          } Quizes`}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UpdateQuestionsInQuizesModal
