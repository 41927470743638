import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { v4 as uuidv4 } from 'uuid'
import { useQueryClient } from 'react-query'
import {
  useQuestionPutQuery,
  useQuestionPostQuery,
} from '../../Common/Queries/QuestionQuery'
import { htmlToString, isQuizStarted } from '../../utils'
import { useStyles } from './AddTrueFalseQuestionStyles'
import EditTrueFalseTile from './EditTrueFalseTile'
import TrueFalseTile from './TrueFalseTile'
import ConfirmBox from '../common/ConfirmBox'
import {
  useGetAllQuizzesForAQuestion,
  useUpdateQuestionForQuizzes,
} from '../../Common/Queries/QuizQuery'

function AddTrueFalseQuestion({
  questionTypeFromParent,
  handleQuestionTypeChange,
  questions,
  quizType,
  codingQuestionSwitch,
  question,
  questionIndex,
  addQuestion,
  setAddQuestion,
  reload,
  setReload,
  quizId,
  quizStartTime,
  isPublished,
  isClosed,
  save,
  setSave,
  setCancelAddQuestion,
  setErrorMessage,
  setTruthValue,
  postQuestionMapping,
  deleteMappingQuestion,
  refetchGetQueryById,
  refetchGetQuizQuestionQueryById,
  deleteQuestion,
  isWriteAllowed,
  setLocalQuestion,
  localQuestion,
  setTextEditorQuestion,
  textEditorQuestion,
  setEditViewQuestion,
  editViewQuestion,
  deleteQuestionId,
  setDeleteQuestionId,
  editOngoing,
  setEditOngoing,
  editQuestion,
  setEditQuestion,
}) {
  const queryClient = useQueryClient()
  const classes = useStyles()
  const questionMcqType = 'mcq'
  // const [editQuestion, setEditQuestion] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [, setCorrectChoice] = useState('')
  const [, setMobile] = useState(window.innerWidth < 576)

  const [errors, setErrors] = useState({
    emptyMarks: false,
    marksNotValid: false,
    marksLessThanZero: false,
    emptyQuestion: false,
  })

  const [affectedQuizes, setAffectedQuizes] = useState([])
  const [selectedQuizzes, setSelectedQuizzes] = useState([])
  const [showQuizesListModal, setShowQuizesListModal] = useState(false)

  const updateMedia = () => {
    setMobile(window?.innerWidth < 576)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  const editQuestionQuery = useQuestionPutQuery(
    quizId,
    localQuestion?.id,
    questionMcqType,
    {
      onSuccess: () => {
        setEditQuestion(NaN)
        setErrorMessage('Question Saved Successfully!')
        setTruthValue(true)
        setReload(reload + 1)
        refetchGetQueryById()
        refetchGetQuizQuestionQueryById()
        setLocalQuestion({})
        setEditViewQuestion({})
        setTextEditorQuestion({ question: '<p></p>' })
      },
      onError: (err) => {
        setErrorMessage('Failed')
        if (
          typeof err?.response?.data === 'string' &&
          (err?.response?.data?.toLowerCase() === 'non existing token' ||
            err?.response?.data?.toLowerCase() === 'expired token')
        ) {
          localStorage.clear()
        }
      },
    }
  )
  const updateQuestionsInQuizzes = useUpdateQuestionForQuizzes({
    onSuccess: () => {
      setEditQuestion(NaN)
      setShowQuizesListModal(false)
      setErrorMessage('Question Saved Successfully!')
      setTruthValue(true)
      setAddQuestion(false)
      setReload(reload + 1)
      refetchGetQueryById()
      refetchGetQuizQuestionQueryById()
      queryClient.invalidateQueries('quizGetQueryById')
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const postNewQuestion = useQuestionPostQuery({
    staleTime: 0,
    retry: false,
    onSuccess: (successResponse) => {
      const id = successResponse?.data?.data?.question?.id
      const newQuestionId = id
      updateQuestionsInQuizzes.mutate({
        oldQuestionId: localQuestion?.id,
        newQuestionId,
        type: 'mcq',
        marks: localQuestion?.marks,
        quizIDs: selectedQuizzes.map((quiz) => quiz?.quizID),
      })
    },

    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const postQuestion = useQuestionPostQuery({
    staleTime: 0,
    onSuccess: async (successResponse) => {
      const id = successResponse?.data?.data?.question?.id
      const questionDetailsObj = {
        questionId: id,
        type: localQuestion?.type,
      }
      postQuestionMapping.mutate(questionDetailsObj)
      setErrorMessage('Question Saved Successfully!')
      setTruthValue(true)
      setAddQuestion(false)
      setReload(reload + 1)
      setSave(false)
      await refetchGetQueryById()
      await refetchGetQuizQuestionQueryById()
    },
    onError: (err) => {
      setErrorMessage('Failed')
      setSave(false)
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const { refetch: refetchQuizzesForQuestion } = useGetAllQuizzesForAQuestion(
    localQuestion?.id,
    {
      enabled: false,
      onSuccess: (response) => {
        const filteredQuizzes = response?.filter((quiz) => {
          return quiz?.status === 'DRAFT' || quiz?.status === 'UPCOMING'
        })
        setAffectedQuizes(filteredQuizzes || [])
      },
      onError: () => {},
    }
  )
  const RemoveId = (dummyQuestion) => {
    const modified = dummyQuestion?.choices?.map(
      ({ id, ...remainingProperties }) => {
        let returnObj
        if (typeof id === 'string' && id.includes('Added')) {
          returnObj = { ...remainingProperties }
        } else {
          returnObj = { id, ...remainingProperties }
        }
        return returnObj
      }
    )
    const temp = dummyQuestion
    temp.choices = modified
    return temp
  }
  useEffect(() => {
    const localCorrectChoice = localQuestion?.choices?.filter((choice) =>
      choice.isCorrect ? choice.value : null
    )
    if (localCorrectChoice !== undefined)
      setCorrectChoice(localCorrectChoice[0]?.value)

    if (Number.isNaN(editQuestion)) {
      setReload(reload + 1)
    } else {
      refetchQuizzesForQuestion()
    }
  }, [editQuestion])

  useEffect(() => {
    if (question && !addQuestion) {
      // setLocalQuestion(question)
      // if (textEditorQuestion.question !== '<p></p>') {
      // setTextEditorQuestion((prevQuestion) => {
      //   const updatedQuestion = JSON.parse(JSON.stringify(prevQuestion))
      //   updatedQuestion.question = question.question
      //   return updatedQuestion
      // })
      // }
    } else {
      const value = {
        id:
          editViewQuestion?.id !== undefined && editViewQuestion?.type === 't/f'
            ? editViewQuestion?.id
            : uuidv4(),
        question:
          editViewQuestion?.id !== undefined && editViewQuestion?.type === 't/f'
            ? editViewQuestion?.question
            : '<p></p>',
        choices:
          editViewQuestion?.id !== undefined && editViewQuestion?.type === 't/f'
            ? editViewQuestion?.choices
            : [
                {
                  value: 'True',
                  isCorrect: true,
                },
                { value: 'False', isCorrect: false },
              ],
        marks:
          editViewQuestion?.id !== undefined && editViewQuestion?.type === 't/f'
            ? editViewQuestion?.marks
            : 2,
        type:
          editViewQuestion?.id !== undefined && editViewQuestion?.type === 't/f'
            ? editViewQuestion?.type
            : 't/f',
        isRandomize:
          editViewQuestion?.id !== undefined && editViewQuestion?.type === 't/f'
            ? editViewQuestion?.isRandomize
            : false,
      }
      setLocalQuestion(value)
      setTextEditorQuestion((prevQuestion) => {
        const updatedQuestion = JSON.parse(JSON.stringify(prevQuestion))
        updatedQuestion.question = value?.question
        return updatedQuestion
      })
      setEditViewQuestion(value)
    }
  }, [question, questionTypeFromParent])

  const marksValidation = (marksToBeValidated) => {
    if (!marksToBeValidated) {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyMarks: true }
      })
      setErrors((errorsPrev) => {
        return { ...errorsPrev, marksLessThanZero: false }
      })
      setErrors((errorsPrev) => {
        return { ...errorsPrev, marksNotValid: false }
      })
    } else {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyMarks: false }
      })
      if (/^-?\d+\.?\d*$/.test(marksToBeValidated)) {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksNotValid: false }
        })
        if (marksToBeValidated <= 0) {
          setErrors((errorsPrev) => {
            return { ...errorsPrev, marksLessThanZero: true }
          })
        } else {
          setErrors((errorsPrev) => {
            return { ...errorsPrev, marksLessThanZero: false }
          })
        }
      } else {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksLessThanZero: false }
        })
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksNotValid: true }
        })
      }
    }
  }

  const handleEdit = (event, setting, choiceIndex) => {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    if (setting === 'question') {
      if (event.target.value === '') {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyQuestion: true }
        })
        dummyQuestion.question = ''
      } else {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyQuestion: false }
        })
        dummyQuestion.question = event?.target?.value
      }
    }

    if (setting === 'choice') {
      dummyQuestion.choices[choiceIndex].value = event.target.value
    }
    if (setting === 'marks') {
      marksValidation(event.target.value)
      dummyQuestion.marks = event.target.value
    }
    setLocalQuestion(dummyQuestion) // instead of setting local Question call API
  }
  const updateQuestion = () => {
    const dummyLocalQuestion = localQuestion
    dummyLocalQuestion.question = textEditorQuestion.question
    // setBackUpLocalQuestion(dummyLocalQuestion)
    setLocalQuestion(dummyLocalQuestion)
  }
  const handleChange = () => {
    if (editOngoing) {
      setErrorMessage('Please finish the previous edit ')
    } else {
      setLocalQuestion(question)
      setEditQuestion(questionIndex)
      setEditOngoing(true)
    }
  }
  const handleSave = async (value = '') => {
    updateQuestion()
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    dummyLocalQuestion.marks = parseInt(dummyLocalQuestion.marks, 10)
    if (htmlToString(dummyLocalQuestion?.question).trim() === '') {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyQuestion: true }
      })
      if (addQuestion) setSave(false)
      return
    }
    setErrors((errorsPrev) => {
      return { ...errorsPrev, emptyQuestion: false }
    })
    dummyLocalQuestion.quizID = quizId
    dummyLocalQuestion.type = 'mcq'
    if (value === 'duplicateEdit') {
      await postNewQuestion.mutateAsync(RemoveId(dummyLocalQuestion))
      setEditOngoing(false)
      return
    }
    if (!addQuestion) {
      if (dummyLocalQuestion.isEditable === false) {
        deleteMappingQuestion.mutate({
          quizId,
          questionId: dummyLocalQuestion?.id,
        })
        delete dummyLocalQuestion.id
        postQuestion.mutate(dummyLocalQuestion)
      } else {
        editQuestionQuery.mutate(dummyLocalQuestion)
      }
    }
    if (addQuestion) {
      delete dummyLocalQuestion.id
      postQuestion.mutate(dummyLocalQuestion)
    }
    setEditOngoing(false)
  }

  useEffect(() => {
    if (save && addQuestion) {
      handleSave()
    }
  }, [save, addQuestion])

  const handleDelete = () => {
    setOpenDialog(true)
    setDeleteQuestionId(question?.id)
  }

  const handleClick = () => {
    deleteQuestion.mutate({
      quizId,
      questionId: deleteQuestionId,
      questionType: questionMcqType,
    })
    setOpenDialog(false)
    setDeleteQuestionId(undefined)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }
  const handleCorrectChoice = (selectedChoice) => {
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    const modifiedChoices = dummyLocalQuestion.choices.map((choice) => {
      // eslint-disable-next-line no-param-reassign
      choice.isCorrect = choice.value === selectedChoice
      return choice
    })
    dummyLocalQuestion.choices = modifiedChoices
    setLocalQuestion(dummyLocalQuestion)
  }

  const handleCancel = () => {
    setEditOngoing(false)
    setEditQuestion(NaN)
    setLocalQuestion(question)
    setAddQuestion(false)
  }

  const handleCancelAddTf = () => {
    setEditOngoing(false)
    setCancelAddQuestion(false)
    setAddQuestion(false)
  }

  const updateQuestionInSelectedQuizzes = async () => {
    updateQuestion()
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    dummyLocalQuestion.type = 'mcq'
    delete dummyLocalQuestion.id
    dummyLocalQuestion.quizID = quizId
    await postNewQuestion.mutateAsync(RemoveId(dummyLocalQuestion))
  }

  return (
    <Grid container xs={12}>
      {editQuestion === questionIndex || addQuestion ? (
        <EditTrueFalseTile
          question={question}
          addQuestion={addQuestion}
          questions={questions}
          questionIndex={questionIndex}
          localQuestion={localQuestion}
          errors={errors}
          handleEdit={handleEdit}
          isQuizStarted={isQuizStarted}
          quizStartTime={quizStartTime}
          isPublished={isPublished}
          questionTypeFromParent={questionTypeFromParent}
          handleQuestionTypeChange={handleQuestionTypeChange}
          quizType={quizType}
          codingQuestionSwitch={codingQuestionSwitch}
          textEditorQuestion={textEditorQuestion}
          setTextEditorQuestion={setTextEditorQuestion}
          handleCorrectChoice={handleCorrectChoice}
          editQuestionQuery={editQuestionQuery}
          postQuestion={postQuestion}
          editQuestion={editQuestion}
          handleCancel={handleCancel}
          handleCancelAddTf={handleCancelAddTf}
          handleSave={handleSave}
          showQuizesListModal={showQuizesListModal}
          setShowQuizesListModal={setShowQuizesListModal}
          affectedQuizes={affectedQuizes}
          updateQuestionInSelectedQuizzes={updateQuestionInSelectedQuizzes}
          setSelectedQuizzes={setSelectedQuizzes}
          selectedQuizzes={selectedQuizzes}
          quizId={quizId}
          setLocalQuestion={setLocalQuestion}
          setEditViewQuestion={setEditViewQuestion}
          editViewQuestion={editViewQuestion}
        />
      ) : (
        <TrueFalseTile
          questionIndex={questionIndex}
          editQuestion={editQuestion}
          addQuestion={addQuestion}
          question={question}
          setEditQuestion={setEditQuestion}
          isPublished={isPublished}
          isQuizStarted={isQuizStarted}
          quizStartTime={quizStartTime}
          deleteQuestion={deleteQuestion}
          isClosed={isClosed}
          handleDelete={handleDelete}
          isWriteAllowed={isWriteAllowed}
          handleChange={handleChange}
        />
      )}
      <ConfirmBox
        isOpenDialog={openDialog}
        handleClose={handleClose}
        handleClick={handleClick}
        boxTitle="Delete Question"
        boxDescription="Are you sure you want to delete this question?"
        styles={classes}
        buttonName="Delete"
      />
    </Grid>
  )
}

export default AddTrueFalseQuestion
