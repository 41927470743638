import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(() => ({
  dialogTitle: {
    paddingBottom: '0px',
  },
  dialogTitleText: {
    fontSize: '20px',
  },
  dialogContentWrapper: {
    paddingTop: '0px',
  },
  redundantCandidatesText: {
    wordBreak: 'break-word',
    paddingLeft: '2px',
    paddingTop: '4px',
    fontSize: '12px',
    color: 'grey',
  },
  candidatesListWrapper: {
    paddingTop: '10px',
  },
  dialogActions: {
    padding: '30px 25px 20px 0px',
  },
  candidateText: { fontSize: '17px', wordBreak: 'break-word' },
  cancelIcon: { fontSize: '18px' },
  candidateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  cancelButton: {
    color: '#00B8A4',
    borderRadius: '4px',
    border: '1px solid #00B8A4 ',
    outline: 'none',
    background: '#FFFFFF',
  },
  candidatesNotFoundText: {
    wordBreak: 'break-word',
    fontSize: '12px',
    fontFamily: 'bold',
    color: 'red',
    padding: '4px 0px 3px 3px',
  },
}))
export { styles }
