import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '30px',
    '@media only screen and (max-width:815px)': {
      paddingTop: (props) => (props?.publish ? '40px' : ''),
    },
    '@media only screen and (max-width:665px)': {
      paddingTop: (props) => (props?.publish ? '100px' : '50px'),
    },
  },
  paper: { marginTop: '16px', padding: '24px' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media only screen and (max-width:480px)': {
      flexDirection: 'column',
    },
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: '24px',
    '@media only screen and (max-width:480px)': {
      marginBottom: '8px !important',
    },
  },
  buttons: {
    '@media only screen and (max-width:480px)': {
      marginBottom: '24px !important',
    },
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  editButton: {
    backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.primaryButton?.main}`,
    color: theme?.palette?.primaryButton?.main,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#00B7A4',
    },
  },
  loader: {
    padding: '2px 32px',
  },
  publishLoader: {
    padding: '2px 12px',
  },

  question: {
    color: '#000000',
    wordBreak: 'break-word',
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
    marginBlockStart: '0em',
    paddingBottom: '4px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
}))

export { useStyles }
