import React from 'react'
import { Typography, Button, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import QuestionsDrawerTile from './QuestionsDrawerTile'

const QuestionsDrawer = ({
  questions,
  executeScroll,
  // scrollToAddQuestion,
  handleDrawerAdd,
  currentQuestion,
  quizStartTime,
  isPublished,
  isWriteAllowed,
  isClosed,
}) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        height: 'calc(100vh - 210px)',
        position: 'fixed',
        width: '25%',
      }}
    >
      <div
        style={{
          // position: 'fixed',
          backgroundColor: 'white',
          display: 'flex',
          // width: '25%',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #dadce0',
          padding: '16px',
        }}
      >
        <Typography style={{ fontWeight: 500 }}>
          Total questions: {questions?.length}
        </Typography>
        {isWriteAllowed && (
          <Button
            startIcon={<AddIcon />}
            handleDrawerAdd
            onClick={handleDrawerAdd}
            disabled={(new Date() > quizStartTime && isPublished) || isClosed}
          >
            Add
          </Button>
        )}
      </div>
      <div
        style={{
          padding: '16px 16px 16px 16px',
          height: 'calc(100vh - 280px)',
          overflowY: 'scroll',
        }}
      >
        {questions?.length === 0 && (
          <Paper
            variant="outlined"
            style={{
              height: '100%',
              backgroundColor: '#F3F7F7',
              // marginTop: '8px',
              alignContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                margin: '18%',
                textAlign: 'center',
                fontSize: '14px',
                fontStyle: 'italic',
              }}
            >
              Add and save questions for the tiles to appear here
            </Typography>
          </Paper>
        )}
        {questions?.length > 0 &&
          questions?.map((question, index) => (
            <QuestionsDrawerTile
              key={index}
              index={index}
              question={question}
              executeScroll={executeScroll}
              currentQuestion={currentQuestion}
            />
          ))}
      </div>
    </div>
  )
}

export default QuestionsDrawer
