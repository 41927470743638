import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Logo from '../Logo/Logo'
import PageNotFoundIllustration from '../../Assets/Illustrations/PageNotFoundIllustration'
import useWindowSize from '../../hooks/useWindowSize'
import { useStyles } from './PageNotFoundStyles'

function PageNotFound() {
  const windowSize = useWindowSize()
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      style={{ height: `${windowSize?.height}px` }}
      data-testid="render-page-not-found"
    >
      <Grid container alignItems="center" className={classes.header}>
        <Logo />
      </Grid>
      <Grid container alignItems="center" className={classes.body}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.illustrationAndTextWrapper}
        >
          <Grid item className={classes.illustrationWrapper}>
            <PageNotFoundIllustration />
          </Grid>
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.text}>
              <span className={classes.message}>Sorry,</span> the page
              doesn&#39;t exist
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PageNotFound
