/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'
import {
  Typography,
  Paper,
  Grid,
  Button,
  Box,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { v4 as uuidv4 } from 'uuid'
import { useQueryClient } from 'react-query'
import AceEditor from 'react-ace'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { faro } from '@grafana/faro-web-sdk'
import {
  isFloat,
  htmlToString,
  aceModesForLanguages,
  isQuizStarted,
} from '../../utils'
import {
  useQuestionPutQuery,
  useQuestionPostQuery,
} from '../../Common/Queries/QuestionQuery'
import {
  useGetAllQuizzesForAQuestion,
  useUpdateQuestionForQuizzes,
} from '../../Common/Queries/QuizQuery'

import Loader from '../Loader/Loader'
import EditTestCase from '../EditTestCase/EditTestCase'
import 'ace-builds'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-golang'
import QuillTextEditor from '../TextEditorToolbar/TextEditor'
import { useStyles } from './EditCodingQuestion2Styles'
import ConfirmBox from '../common/ConfirmBox'
import UpdateQuestionsInQuizesModal from '../UpdateQuestionInQuizesModal/UpdateQuestionInQuizesModal'
// import { includes } from 'lodash'

const AddCodingQuestion = ({
  question,
  questionIndex,
  addQuestion,
  setAddQuestion,
  setCancelAddQuestion,
  reload,
  setReload,
  quizId,
  quizStartTime,
  isPublished,
  save,
  setSave,
  setTruthValue,
  setErrorMessage,
  setErrors,
  errors,
  questionTypeFromParent,
  handleQuestionTypeChange,
  questions,
  refetchGetQueryById,
  refetchGetQuizQuestionQueryById,
  postQuestionMapping,
  setQuestionType,
  deleteMappingQuestion,
  deleteQuestion,
  invalidTestCaseInput,
  invalidTestCaseArguments,
  setInvalidTestCaseArguments,
  setInvalidTestCaseInput,
  isWriteAllowed,
  setLocalQuestion,
  localQuestion,
  setTextEditorQuestion,
  textEditorQuestion,
  setEditViewQuestion,
  editViewQuestion,
  deleteQuestionId,
  setDeleteQuestionId,
  editOngoing,
  setEditOngoing,
  editQuestion,
  setEditQuestion,
}) => {
  const questionCodingType = 'coding'
  const queryClient = useQueryClient()
  const [language, setLanguage] = React.useState([])
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [deleteLanguage, setDeleteLanguage] = useState(false)
  // const [editQuestion, setEditQuestion] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState(
    question?.stubs?.[0]?.language
  )
  const [invalidInputs] = useState()
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const languageList = ['golang', 'java', 'python', 'c', 'cpp', 'javascript']
  const allowedInput = [
    'integer',
    'double',
    'string',
    'integer array',
    'double array',
    'string array',
  ]

  const returnTypeList = [
    {
      value: 'input1',
      label: 'integer',
    },
    {
      value: 'input2',
      label: 'double',
    },
    {
      value: 'input3',
      label: 'string',
    },
  ]

  const [affectedQuizes, setAffectedQuizes] = useState([])
  const [selectedQuizzes, setSelectedQuizzes] = useState([])
  const [showQuizesListModal, setShowQuizesListModal] = useState(false)

  const { refetch: refetchQuizzesForQuestion } = useGetAllQuizzesForAQuestion(
    localQuestion?.id,
    {
      enabled: false,
      onSuccess: (response) => {
        const filteredQuizzes = response?.filter((quiz) => {
          return quiz?.status === 'DRAFT' || quiz?.status === 'UPCOMING'
        })
        setAffectedQuizes(filteredQuizzes || [])
      },
      onError: () => {},
    }
  )
  useEffect(() => {
    if (!Number.isNaN(editQuestion)) {
      refetchQuizzesForQuestion()
    }
  }, [editQuestion])

  const classes = useStyles(currentSelectedLanguage)

  const handleCodingMarks = () => {
    if (
      Object.keys(localQuestion || {}).length > 0 ||
      Object.keys(question || {}).length > 0
    ) {
      const testCaseMarksArray = (
        Object.keys(localQuestion || {}).length > 0 ? localQuestion : question
      )?.testcases
        ?.map((a) => a?.marks ?? 0)
        ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      return testCaseMarksArray
    }
    return 0
  }

  const editQuestionQuery = useQuestionPutQuery(
    quizId,
    localQuestion?.id,
    questionCodingType,
    {
      staleTime: 0,
      onSuccess: () => {
        setEditQuestion(NaN)
        refetchGetQueryById()
        refetchGetQuizQuestionQueryById()
        // setSaveQuestionSnackbar(true)
        setErrorMessage('Question Saved Successfully!')
        setTruthValue(true)
        setReload(reload + 1)
        setLocalQuestion({})
        setEditViewQuestion({})
        setTextEditorQuestion({ question: '<p></p>' })
      },
      onError: (err) => {
        // setOpenErrorSnackbarCoding(true)
        setErrorMessage('Failed !')
        if (
          typeof err?.response?.data === 'string' &&
          (err?.response?.data?.toLowerCase() === 'non existing token' ||
            err?.response?.data?.toLowerCase() === 'expired token')
        ) {
          localStorage.clear()
        }
      },
    }
  )
  const postQuestion = useQuestionPostQuery({
    staleTime: 0,
    onSuccess: (successResponse) => {
      setQuestionType('coding')
      const id = successResponse?.data?.data?.question?.id
      const questionDetailsObj = {
        questionId: id,
        type: localQuestion?.type,
      }
      postQuestionMapping.mutate(questionDetailsObj)
      queryClient.invalidateQueries('quizGetQueryById')
      setLocalQuestion({})
    },
    onError: (err) => {
      // setOpenErrorSnackbarCoding(true)
      setErrorMessage('Failed')
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  const handleChangeLanguage = (event, newValue) => {
    setLanguage(() => newValue)
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    dummyQuestion.codeStubConfigs.languages = newValue
    setLocalQuestion(() => dummyQuestion)
  }
  const handleChangeReturnType = (event) => {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    dummyQuestion.codeStubConfigs.returnType = event?.target?.value
    setLocalQuestion(() => dummyQuestion)
  }
  useEffect(() => {
    if (postQuestion?.isSuccess) {
      // setSaveQuestionSnackbar(true)
      setErrorMessage('Question Saved Successfully!')
      setTruthValue(true)
      setAddQuestion(false)
    }
  }, [postQuestion?.isSuccess, setAddQuestion])

  useEffect(() => {
    if (question && !addQuestion) {
      // setLocalQuestion(question)
      setSelectedLanguages(
        question?.codeStubs?.map((languageOption) => {
          return languageOption
        })
      )
      // setCurrentSelectedLanguage(question?.stubs?.[0]?.language)
      // if (textEditorQuestion.question !== '<p></p>') {
      //   setTextEditorQuestion((prevQuestion) => {
      //     const updatedQuestion = JSON.parse(JSON.stringify(prevQuestion))
      //     updatedQuestion.value = question.question

      //     return updatedQuestion
      //   })
      // }
    } else {
      const value = {
        id:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === 'coding'
            ? editViewQuestion?.id
            : uuidv4(),
        question:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === 'coding'
            ? editViewQuestion?.question
            : '<p></p>',
        type:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === 'coding'
            ? editViewQuestion?.type
            : 'coding',
        testcases:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === 'coding'
            ? editViewQuestion?.testcases
            : [
                {
                  id: `Added${uuidv4()}`,
                  type: 'sample',
                  input: '',
                  output: '',
                  marks: 0,
                },
                {
                  id: `Added${uuidv4()}`,
                  type: 'hidden',
                  input: '',
                  output: '',
                  marks: 5,
                },
              ],
        // have to verify this change
        codeStubConfigs:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === 'coding'
            ? editViewQuestion?.codeStubConfigs
            : {},
        marks:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === 'coding'
            ? editViewQuestion?.marks
            : handleCodingMarks(),
      }

      setLocalQuestion(value)
      setTextEditorQuestion((prevQuestion) => {
        const updatedQuestion = JSON.parse(JSON.stringify(prevQuestion))
        updatedQuestion.question = value?.question
        return updatedQuestion
      })
      setEditViewQuestion(value)
    }
  }, [question])

  const handleFunctionName = (event) => {
    if (event?.target?.value?.trim() === '') {
      setErrors({ ...errors, emptyFunction: true })
    } else {
      setErrors({
        ...errors,
        emptyFunction: false,
      })
    }
    if (
      event?.target?.value?.indexOf(' ') >= 0 ||
      localQuestion?.codeStubConfigs?.functionName?.indexOf(' ') >= 0
    ) {
      setErrors({ ...errors, invalidFunctionName: true })
    } else {
      setErrors({
        ...errors,
        invalidFunctionName: false,
      })
    }
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    dummyQuestion.codeStubConfigs.functionName = event.target.value
    setLocalQuestion(() => dummyQuestion)
  }

  const handleChange = (event, testcaseId) => {
    const copyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    const copyTestCases = copyLocalQuestion?.testcases.map((item) => {
      const modifiedTestCase = item
      if (item?.id === testcaseId) {
        modifiedTestCase.type = event.target.checked ? 'hidden' : 'sample'
        if (item?.type === 'hidden') {
          modifiedTestCase.marks = 5
        } else modifiedTestCase.marks = 0
      }
      return modifiedTestCase
    })
    copyLocalQuestion.testcases = copyTestCases

    setLocalQuestion(copyLocalQuestion)
  }
  const RemoveId = (dummyQuestion) => {
    const modified = dummyQuestion?.testcases?.map(({ id, ...rest }) => {
      let returnObj
      if (typeof id === 'string' && id.includes('Added')) {
        returnObj = { ...rest }
      } else {
        returnObj = { id, ...rest }
      }
      return returnObj
    })
    const temp = dummyQuestion
    temp.testcases = modified
    return temp
  }
  const updateQuestion = () => {
    const dummyLocalQuestion = localQuestion
    dummyLocalQuestion.question = textEditorQuestion.question
    setLocalQuestion(() => dummyLocalQuestion)
  }
  const validateEmptyInputTestCase = (dummyLocalQuestion) => {
    return (
      dummyLocalQuestion?.testcases?.filter(
        (testcase) => testcase?.input === ''
      ).length > 0
    )
  }
  const validateEmptyOutputTestCase = (dummyLocalQuestion) => {
    return (
      dummyLocalQuestion?.testcases?.filter(
        (testcase) => testcase?.output === ''
      ).length > 0
    )
  }
  const validateSingle = (currentType, inputValue) => {
    if (currentType === 'integer') {
      return (
        /^\d+$/.test(inputValue) &&
        !isNaN(parseInt(inputValue, 10)) &&
        Number.isInteger(parseFloat(inputValue))
      )
    }
    if (currentType === 'integer array') {
      if (inputValue[0] !== '[') return false
      try {
        const parsedArray = JSON.parse(inputValue)
        return parsedArray.every(
          (value) =>
            !isNaN(parseInt(value, 10)) && Number.isInteger(parseFloat(value))
        )
      } catch (error) {
        if (faro && faro.api && faro.api.pushError) {
          faro.api.pushError(error)
        }
        return false
      }
    } else if (currentType === 'string') {
      return typeof inputValue === 'string' && inputValue.indexOf(',') === -1
    } else if (currentType === 'string array') {
      try {
        if (inputValue[0] !== '[') return false
        const parsedArray = inputValue
          .replace(/^\[|\]$/g, '')
          .split(',')
          .map((item) => item.trim())
        return parsedArray.every((value) => value.indexOf(',') === -1)
      } catch (error) {
        if (faro && faro.api && faro.api.pushError) {
          faro.api.pushError(error)
        }
        return false
      }
    } else if (currentType === 'double') {
      return isFloat(inputValue)
    } else if (currentType === 'double array') {
      if (inputValue[0] !== '[') return false
      try {
        const parsedArray = JSON.parse(inputValue)
        return parsedArray.every((value) => isFloat(value))
      } catch (error) {
        if (faro && faro.api && faro.api.pushError) {
          faro.api.pushError(error)
        }
        return false
      }
    }
    return true
  }
  const updateQuestionsInQuizzes = useUpdateQuestionForQuizzes({
    onSuccess: () => {
      setEditQuestion(NaN)
      setShowQuizesListModal(false)
      setErrorMessage('Question Saved Successfully!')
      setTruthValue(true)
      setAddQuestion(false)
      setReload(reload + 1)
      refetchGetQueryById()
      refetchGetQuizQuestionQueryById()
      queryClient.invalidateQueries('quizGetQueryById')
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const postNewQuestion = useQuestionPostQuery({
    staleTime: 0,
    retry: false,
    onSuccess: (successResponse) => {
      const id = successResponse?.data?.data?.question?.id
      const newQuestionId = id
      updateQuestionsInQuizzes.mutate({
        oldQuestionId: localQuestion?.id,
        newQuestionId,
        type: localQuestion?.type,
        marks: localQuestion?.marks,
        quizIDs: selectedQuizzes.map((quiz) => quiz?.quizID),
      })
    },

    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const handleSave = async (value = '') => {
    updateQuestion()
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    if (htmlToString(dummyLocalQuestion?.question).trim() === '') {
      setErrorMessage('Question cannot be empty')
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyQuestion: true }
      })
      if (addQuestion) setSave(false)
      return
    }
    setErrors((errorsPrev) => {
      return { ...errorsPrev, emptyQuestion: false }
    })
    if (localQuestion?.codeStubConfigs?.languages === undefined) {
      // setInvalidLanguageNameSnackbar(() => true)
      setErrorMessage('Languages cannot be empty')
      return
    }
    if (localQuestion?.codeStubConfigs?.languages.length === 0) {
      setErrorMessage('Languages cannot be empty')
      return
    }
    if (localQuestion?.codeStubConfigs?.inputTypes === undefined) {
      // setInvalidInputTypesSnackbar(() => true)
      setErrorMessage('Input Types cannot be empty')
      return
    }
    if (localQuestion?.codeStubConfigs?.functionName === undefined) {
      // setInvalidFunctionNameSnackbar(() => true)
      setErrorMessage('Function cannot be empty')
      return
    }
    if (localQuestion?.codeStubConfigs?.functionName.trim() === '') {
      setErrorMessage('Function cannot be empty')

      setErrors((errorsPrev) => {
        return { ...errorsPrev, spaceFunctionName: true }
      })
      return
    }
    setErrors((errorsPrev) => {
      return { ...errorsPrev, spaceFunctionName: false }
    })
    if (localQuestion?.codeStubConfigs?.returnType === undefined) {
      setErrorMessage('Return type cannot be empty')
      // setInvalidReturnTypesSnackbar(() => true)
      return
    }
    if (dummyLocalQuestion?.testcases?.length === 0) {
      setSave(false)
      setErrorMessage('Please add test cases')

      return
    }
    if (
      dummyLocalQuestion?.testcases?.filter(
        (testcase) => testcase?.type?.toLowerCase() === 'sample'
      ).length === 0
    ) {
      setErrorMessage('Please add sample test cases')
      return
    }
    if (
      dummyLocalQuestion?.testcases?.filter(
        (testcase) => testcase?.type?.toLowerCase() === 'hidden'
      ).length === 0
    ) {
      setErrorMessage('Please add hidden test cases')
      return
    }
    if (invalidInputs) {
      setErrorMessage('Please enter valid input types')
      return
    }
    if (
      validateEmptyInputTestCase(dummyLocalQuestion) &&
      validateEmptyOutputTestCase(dummyLocalQuestion)
    ) {
      setErrorMessage('Please add input and output!')
      return
    }
    if (localQuestion?.codeStubConfigs?.returnType === undefined) {
      setErrorMessage('Please select return type')
    }
    if (validateEmptyInputTestCase(dummyLocalQuestion)) {
      setSave(() => false)
      setErrorMessage('Please add input!')
      return
    }
    if (validateEmptyOutputTestCase(dummyLocalQuestion)) {
      setSave(() => false)
      setErrorMessage('Please add output!')

      return
    }
    const modifiedTestCases = dummyLocalQuestion?.testcases?.map((testcase) => {
      const arrayOfVariables = []
      let i = 0
      const test = testcase.input
      while (i < test.length) {
        if (test[i] === '[') {
          const arrayEnding = test.indexOf(']', i)
          if (arrayEnding === -1) {
            return false
          }
          const arrayFound = test.slice(i, arrayEnding + 1)
          if (arrayFound) arrayOfVariables.push(arrayFound)
          i += arrayFound.length + 1
        } else {
          const nextComma = test.indexOf(',', i)
          const substr = test.slice(
            i,
            nextComma !== -1 ? nextComma : test.length
          )
          if (substr) arrayOfVariables.push(substr)
          i += substr.length + 1
        }
      }
      testcase.input = Array.isArray(arrayOfVariables[0])
        ? arrayOfVariables[0]
        : arrayOfVariables
      testcase.marks = parseInt(testcase.marks, 10)
      return testcase
    })
    dummyLocalQuestion.testcases = modifiedTestCases
    dummyLocalQuestion.marks = parseInt(handleCodingMarks(), 10)
    dummyLocalQuestion.quizID = quizId
    if (value === 'duplicateEdit') {
      await postNewQuestion.mutateAsync(RemoveId(dummyLocalQuestion))
      setEditOngoing(false)
      return
    }
    if (!addQuestion) {
      if (dummyLocalQuestion.isEditable === false) {
        await deleteMappingQuestion.mutate({
          quizId,
          questionId: dummyLocalQuestion?.id,
        })
        delete dummyLocalQuestion.id
        postQuestion?.mutate(RemoveId(dummyLocalQuestion))
      } else {
        editQuestionQuery.mutate(RemoveId(dummyLocalQuestion))
      }
    } else {
      delete dummyLocalQuestion.id
      postQuestion?.mutate(RemoveId(dummyLocalQuestion))
    }
    setEditOngoing(false)
  }
  useEffect(() => {
    if (save && addQuestion) {
      handleSave()
    }
  }, [save, addQuestion])
  const handleClick = () => {
    deleteQuestion.mutate({
      quizId,
      questionId: deleteQuestionId,
      questionType: questionCodingType,
    })
    setOpenDialog(false)
    setDeleteQuestionId(undefined)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleDelete = () => {
    setOpenDialog(true)
    setDeleteQuestionId(question?.id)
    // deleteQuestion.mutate()
  }

  const handleChoice = (setting) => {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    const index = dummyQuestion?.testcases?.length
    if (setting === 'Add')
      dummyQuestion.testcases
        .splice(index + 1, 0, {
          id: `Added${uuidv4()}`,
          type: 'sample',
          input: '',
          output: '',
          marks: 0,
        })
        .join()
    setLocalQuestion(dummyQuestion)
  }

  const weightageValidation = (weightageToBeValidated) => {
    if (!weightageToBeValidated) {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyWeightage: true }
      })
      setErrors((errorsPrev) => {
        return { ...errorsPrev, weightageLessThanZero: false }
      })
      setErrors((errorsPrev) => {
        return { ...errorsPrev, weightageNotValid: false }
      })
    } else {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyWeightage: false }
      })
      if (/^-?\d+\.?\d*$/.test(weightageToBeValidated)) {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, weightageNotValid: false }
        })
        if (weightageToBeValidated <= 0) {
          setErrors((errorsPrev) => {
            return { ...errorsPrev, weightageLessThanZero: true }
          })
        } else {
          setErrors((errorsPrev) => {
            return { ...errorsPrev, weightageLessThanZero: false }
          })
        }
      } else {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, weightageLessThanZero: false }
        })
        setErrors((errorsPrev) => {
          return { ...errorsPrev, weightageNotValid: true }
        })
      }
    }
  }

  const validate = (input, test) => {
    let i = 0
    for (let index = 0; index < input?.length; index++) {
      if (!allowedInput?.includes(input[index])) return false
    }
    const arrayOfVariables = []
    while (i < test.length) {
      if (test[i] === '[') {
        const arrayEnding = test.indexOf(']', i)
        if (arrayEnding === -1) {
          return false
        }
        const arrayFound = test.slice(i, arrayEnding + 1)
        if (arrayFound) arrayOfVariables.push(arrayFound)
        i += arrayFound.length + 1
      } else {
        const nextComma = test.indexOf(',', i)
        const substr = test.slice(i, nextComma !== -1 ? nextComma : test.length)
        if (substr) arrayOfVariables.push(substr)
        i += substr.length + 1
      }
    }

    const inputArr = input
    let isValid = true
    if (inputArr.length !== arrayOfVariables.length) {
      return false
    }
    for (let j = 0; j < inputArr.length; j++) {
      const signleIsValid = validateSingle(inputArr[j], arrayOfVariables[j])
      isValid = isValid && signleIsValid
    }
    return isValid
  }
  const checkError = (testCaseInputArray, inputTypeArray, choiceIndex) => {
    if (
      !testCaseInputArray ||
      !inputTypeArray ||
      testCaseInputArray?.length <= 0 ||
      inputTypeArray?.length <= 0 ||
      !validate(inputTypeArray, testCaseInputArray)
    ) {
      return true
    }

    setInvalidTestCaseArguments((preValue) => {
      if (preValue?.includes(choiceIndex)) return [...preValue]
      return [choiceIndex]
    })

    return false
  }

  function handleInputTypes(event) {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    const splittedArr = event.target.value.split(',')
    let isValid = true
    splittedArr.forEach((ele) => {
      isValid = isValid && allowedInput.includes(ele)
      if (isValid) {
        dummyQuestion.testcases.forEach(({ input }, index) => {
          if (
            checkError(
              input ?? null,
              event.target.value?.split(',') ?? null,
              index
            )
          ) {
            setInvalidTestCaseInput([...invalidTestCaseInput, index])
          } else {
            const newInvalidTestCaseInputArr = invalidTestCaseInput.filter(
              (i) => i !== index
            )
            setInvalidTestCaseInput(newInvalidTestCaseInputArr)
          }
        })
      }
    })
    if (!allowedInput?.includes(event.target.value)) {
      setErrors({
        ...errors,
        emptyInputTypesType: true,
      })
    }
    if (event?.target?.value?.trim() === '') {
      setErrors({ ...errors, emptyInputTypes: true })
    } else {
      setErrors({
        ...errors,
        emptyInputTypesType: false,
        emptyInputTypes: false,
      })
    }
    dummyQuestion.codeStubConfigs.inputTypes = event?.target?.value?.split(',')
    setLocalQuestion(() => dummyQuestion)
  }

  const handleEdit = (event, setting, choiceIndex) => {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    const inputs = event?.target?.value
    if (setting === 'question') {
      if (event.target.value === '') {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyQuestion: true }
        })
        dummyQuestion.question = ''
      } else {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyQuestion: false }
        })
        dummyQuestion.question = event?.target?.value
      }
    } else if (setting === 'marks') {
      dummyQuestion.testcases[choiceIndex].marks = parseInt(
        event.target.value,
        10
      )
    } else if (setting === 'questionWeightage') {
      weightageValidation(event.target.value)
      dummyQuestion.marks = event.target.value
    } else if (setting === 'input') {
      const splittedArr = dummyQuestion?.codeStubConfigs?.inputTypes
      let isValid = true
      splittedArr?.forEach((ele) => {
        isValid = isValid && allowedInput.includes(ele)
        if (isValid) {
          if (
            checkError(
              inputs,
              dummyQuestion?.codeStubConfigs?.inputTypes,
              choiceIndex
            )
          ) {
            setInvalidTestCaseInput([...invalidTestCaseInput, choiceIndex])
          } else {
            const newInvalidTestCaseInputArr = invalidTestCaseInput?.filter(
              (i) => i !== choiceIndex
            )
            setInvalidTestCaseInput(newInvalidTestCaseInputArr)
          }
        }
      })
      dummyQuestion.testcases[choiceIndex].input = inputs
    } else if (setting === 'output')
      dummyQuestion.testcases[choiceIndex].output = event.target.value
    else if (setting === 'codeStubBody')
      dummyQuestion.codeStubs[choiceIndex].body = event.target.value
    setLocalQuestion(dummyQuestion) // instead of setting local Question call API
  }
  const handleRemoveChoice = (selectedIndex) => {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    const filterTestCases = dummyQuestion?.testcases?.filter(
      (x) => x.id !== selectedIndex
    )
    dummyQuestion.testcases = filterTestCases
    setLocalQuestion(dummyQuestion)
  }

  const handleCancel = () => {
    setEditOngoing(false)
    setEditQuestion(NaN)
  }
  const handleCancelCode = () => {
    setEditOngoing(false)
    setAddQuestion(false)
    setCancelAddQuestion(true)
  }

  useEffect(() => {
    if (localQuestion && deleteLanguage) {
      const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
      setLocalQuestion(dummyQuestion)
      setDeleteLanguage(false)
      setCurrentSelectedLanguage(dummyQuestion?.stubs?.[0]?.language)
    }
    if (localQuestion) {
      setCurrentSelectedLanguage(question?.stubs?.[0]?.language)
    }
  }, [selectedLanguages, deleteLanguage])

  const updateQuestionInSelectedQuizzes = async () => {
    updateQuestion()
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    delete dummyLocalQuestion.id
    dummyLocalQuestion.quizID = quizId
    await postNewQuestion.mutateAsync(RemoveId(dummyLocalQuestion))
  }

  const handleSaveButtonClick = async () => {
    if (affectedQuizes?.length > 1) setShowQuizesListModal(true)
    else handleSave()
  }

  const handleQuestionEdit = () => {
    if (editOngoing) {
      setErrorMessage('Please finish the previous edit')
    } else {
      setEditOngoing(true)
      setLocalQuestion(question)
      setEditQuestion(questionIndex)
    }
  }

  return (
    <Grid style={{ display: 'flex' }}>
      <Grid item xs={10} sm={11} style={{ paddingRight: '16px' }}>
        <Grid className={classes.root}>
          <Paper className={classes.editPaper} variant="outlined">
            {addQuestion || editQuestion === questionIndex ? (
              <Grid container xs={12} style={{ paddingBottom: '10px' }}>
                <UpdateQuestionsInQuizesModal
                  isLoading={false}
                  showQuizesListModal={showQuizesListModal}
                  setShowQuizesListModal={setShowQuizesListModal}
                  affectedQuizes={affectedQuizes}
                  handleSave={handleSave}
                  currentQuestion={localQuestion}
                  updateQuestionInSelectedQuizzes={
                    updateQuestionInSelectedQuizzes
                  }
                  setSelectedQuizzes={setSelectedQuizzes}
                  selectedQuizzes={selectedQuizzes}
                  quizId={quizId}
                />
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ alignItems: 'end', display: 'flex' }}
                >
                  <Typography className={classes.questionIndex}>
                    Question{' '}
                    {addQuestion ? questions?.length + 1 : questionIndex + 1}.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  className={classes.marksType}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {
                    <Tooltip
                      placement="top"
                      arrow
                      classes={{
                        arrow: classes.arrow,
                        tooltip: classes.tooltip,
                      }}
                      title="This is an aggregate of testcases marks given below"
                    >
                      <TextField
                        style={{ pointerEvent: 'none', cursor: 'none' }}
                        disabled
                        id="outlined-basic"
                        value={handleCodingMarks()}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        error={
                          errors?.emptyMarks ||
                          errors?.marksNotValid ||
                          errors?.marksLessThanZero
                        }
                        className={classes.mcqMarksContainer}
                        inputProps={{
                          style: {
                            fontSize: 16,
                            color: '#262B4B',
                            textAlign: 'center',
                            height: '32px',
                            width: '40px',
                            pointerEvents: 'none',
                          },
                        }}
                        onChange={(event) =>
                          handleEdit(event, 'questionWeightage', 0)
                        }
                        // disabled={isQuizStarted(quizStartTime) && isPublished}
                        label="Marks"
                        // type="number"
                      />
                    </Tooltip>
                  }
                  {addQuestion && (
                    <FormControl
                      variant="outlined"
                      style={{ marginLeft: '8px' }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Question Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={questionTypeFromParent}
                        onChange={handleQuestionTypeChange}
                        label="Question Type"
                      >
                        <MenuItem value="mcq">
                          Multiple Choice Question
                        </MenuItem>
                        <MenuItem
                          value="coding"
                          // disabled={quizType === 'public' || !codingQuestionSwitch}
                        >
                          Coding Question
                        </MenuItem>
                        <MenuItem value="t/f">True/False Question</MenuItem>
                        <MenuItem value="multi-choice">
                          Multiple Answer Question
                        </MenuItem>
                        <MenuItem value="essay">Essay Question</MenuItem>
                        <MenuItem value="fillInBlanks">
                          Fill In The Blanks
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container className={classes.questionHeader}>
                <Grid item xs={4}>
                  <Typography className={classes.questionIndex}>
                    Question{' '}
                    {questionIndex === undefined ? 1 : questionIndex + 1}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={8}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ paddingTop: '22px' }}
                  // className={classes.questionHeader}
                >
                  {editQuestion || addQuestion ? (
                    <></>
                  ) : (
                    <Typography
                      variant="h4"
                      style={{ color: '#00B7A4' }}
                      className={classes.tileMarks}
                    >
                      {handleCodingMarks()} marks
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={8}
                  container
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  className={classes.questionHeader}
                >
                  {!Number.isNaN(editQuestion) || addQuestion ? (
                    <>
                      {editQuestionQuery?.isLoading ||
                      postQuestion?.isLoading ? (
                        <Loader size={30} />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            )}

            {editQuestion === questionIndex || addQuestion ? (
              <Grid container className={classes.editQuestion}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <QuillTextEditor
                            textEditorQuestion={textEditorQuestion}
                            setTextEditorQuestion={setTextEditorQuestion}
                            className={localQuestion?.id?.toString()}
                            isQuizStarted={
                              isQuizStarted(quizStartTime) && isPublished
                            }
                            setLocalQuestion={setLocalQuestion}
                            localQuestion={localQuestion}
                            question={question}
                            setEditViewQuestion={setEditViewQuestion}
                            editViewQuestion={editViewQuestion}
                            addQuestion={addQuestion}
                          />
                        </Box>
                      </Box>
                      <Grid
                        container
                        direction="row"
                        className={classes.marksQuestionValidationContainer}
                      >
                        <Grid item sm={8} xs={12}>
                          {errors?.emptyQuestion && (
                            <Grid>
                              <Typography className={classes.validationText}>
                                Question should not be empty
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          sm={4}
                          xs={12}
                          className={classes.marksValidationContainer}
                        >
                          {errors?.emptyWeightage && (
                            <Grid>
                              <Typography className={classes.validationText}>
                                Marks should not be empty
                              </Typography>
                            </Grid>
                          )}
                          {errors?.weightageLessThanZero && (
                            <Grid>
                              <Typography className={classes.validationText}>
                                Marks should be more than 0
                              </Typography>
                            </Grid>
                          )}
                          {errors?.weightageNotValid && (
                            <Grid>
                              <Typography className={classes.validationText}>
                                Only numeric values are allowed
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12} className={classes.codeSnippetContainer}>
                    <div className={classes.paper} style={{ padding: '0px' }}>
                      <div className={classes.codeDetails}>
                        <Grid item xs={12}>
                          <Typography className={classes.codeSnippetTitle}>
                            Configurations:
                          </Typography>
                        </Grid>

                        <div className={classes.codeSnippetLanguagesMenu}>
                          <div className={classes.languageWrapper}>
                            <Typography className={classes.codeLanguageMessage}>
                              Languages
                            </Typography>
                            <FormControl
                              className={classes.languageForm}
                              variant="outlined"
                              fullWidth
                              style={{ marginTop: '-20px' }}
                            >
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={languageList}
                                disableCloseOnSelect
                                onChange={(e, value) =>
                                  handleChangeLanguage(e, value)
                                }
                                value={
                                  localQuestion?.codeStubConfigs?.languages
                                    ? localQuestion?.codeStubConfigs?.languages
                                    : language || []
                                }
                                getOptionLabel={(option) => option}
                                renderOption={(option, { selected }) => (
                                  <Box>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option}
                                  </Box>
                                )}
                                size="small"
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            </FormControl>
                            {localQuestion?.codeStubConfigs?.languages
                              ?.length === 0 && (
                              <Typography className={classes.validationText}>
                                Select atleast one language
                              </Typography>
                            )}
                          </div>

                          <div style={{ flex: 1 }} />
                          <div className={classes.inputWrapper}>
                            <div className={classes.inputTypesWrapper}>
                              <Typography className={classes.inputTypesHeader}>
                                Input types
                              </Typography>
                            </div>

                            <form
                              className={classes.root}
                              noValidate
                              autoComplete="off"
                            >
                              <div className={classes.inputTextFieldWrapper}>
                                <TextField
                                  label="Enter value"
                                  defaultValue={
                                    localQuestion?.codeStubConfigs?.inputTypes
                                  }
                                  id="outlined-size-normal"
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  className={classes.inputTextFied}
                                  onChange={handleInputTypes}
                                  autoComplete
                                />
                              </div>
                              <Typography className={classes.addingInfo}>
                                *Only integer,double,string,integer array,double
                                array and string array values are allowed
                              </Typography>
                              <Typography className={classes.addingInfo}>
                                Please give comma seperated values incase of
                                more than one value
                              </Typography>
                              {errors?.emptyInputTypesType && (
                                <p>Input tuype type</p>
                              )}
                              {invalidTestCaseInput?.length > 0 && (
                                <Grid>
                                  <Typography
                                    style={{
                                      fontSize: '12px',
                                      color: 'red',
                                    }}
                                  >
                                    Input types and test cases inputs to have
                                    similar type
                                  </Typography>
                                </Grid>
                              )}
                            </form>
                          </div>
                        </div>

                        <div className={classes.funcAndReturnType}>
                          <div className={classes.functionWrapper}>
                            <Typography className={classes.codeLanguageMessage}>
                              Function name
                            </Typography>

                            <TextField
                              fullWidth
                              label="Enter name"
                              defaultValue={
                                localQuestion?.codeStubConfigs?.functionName
                              }
                              variant="outlined"
                              onChange={handleFunctionName}
                              className={classes.functionTextField}
                              size="small"
                            />
                            {errors?.emptyFunction && (
                              <Typography className={classes.validationText}>
                                Function name cannot be empty
                              </Typography>
                            )}
                            {errors?.invalidFunctionName && (
                              <Typography className={classes.validationText}>
                                Function name cannot have whitespace.
                              </Typography>
                            )}
                          </div>

                          <div style={{ flex: 1 }} />
                          <div className={classes.returnTypeWrapper}>
                            <Typography className={classes.returnTypeHeader}>
                              Return type
                            </Typography>
                            <form
                              className={classes.root}
                              noValidate
                              autoComplete="off"
                            >
                              <div className={classes.returnTypeFieldWrapper}>
                                <TextField
                                  id="outlined-select-input"
                                  select
                                  label="Select"
                                  defaultValue=""
                                  value={
                                    localQuestion?.codeStubConfigs
                                      ?.returnType ?? ''
                                  }
                                  onChange={handleChangeReturnType}
                                  variant="outlined"
                                  fullWidth
                                  className={classes.returnType}
                                  size="small"
                                >
                                  {returnTypeList.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      className={classes.paper}
                      style={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                      <div className={classes.testCaseHeadingForMobile}>
                        <Typography variant="h4">Test Cases</Typography>
                      </div>
                      <div className={classes.textBoxContainer}>
                        <div className={classes.testCaseNumbers}>SN</div>
                        <div className={classes.testCaseInputTitle}>Input</div>
                        <div className={classes.testCaseOutputTitle}>
                          Output
                        </div>
                        <div className={classes.testCaseMarks}>Marks</div>
                        <div className={classes.hiddenCaseTitle}>Hidden</div>
                      </div>

                      {localQuestion?.testcases?.map((testcase, index) => {
                        return (
                          <>
                            <EditTestCase
                              testcase={testcase}
                              index={index}
                              handleChange={handleChange}
                              handleRemoveChoice={handleRemoveChoice}
                              handleEdit={handleEdit}
                              isQuizStarted={
                                isQuizStarted(quizStartTime) && isPublished
                              }
                              editQuestion={editQuestion}
                              addQuestion={addQuestion}
                              codingQuestionError={errors}
                              invalidTestCaseArguments={
                                invalidTestCaseArguments
                              }
                              questionIndex={questionIndex}
                            />
                          </>
                        )
                      })}
                      {(!isQuizStarted(quizStartTime) || !isPublished) && (
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={() => handleChoice('Add')}
                          className={classes.addTestCaseButton}
                        >
                          Add Testcase
                        </Button>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      {editQuestionQuery?.isLoading ||
                      postQuestion?.isLoading ? (
                        <div
                          className={classes.loader}
                          data-testid="render-loader"
                        >
                          <Loader size={30} />
                        </div>
                      ) : (
                        <>
                          {' '}
                          <Button
                            onClick={
                              !Number.isNaN(editQuestion)
                                ? handleCancel
                                : handleCancelCode
                            }
                            variant="filled"
                            className={classes.cancelButton}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={
                              !Number.isNaN(editQuestion)
                                ? handleSaveButtonClick
                                : () => handleSave()
                            }
                            variant="filled"
                            disabled={
                              (isQuizStarted(quizStartTime) && isPublished) ||
                              errors?.invalidInputType ||
                              errors?.invalidInputArgLength ||
                              invalidTestCaseInput?.length
                            }
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} className={classes.editQuestion}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography className={classes.question}>
                              {parse(question?.question)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.codeSnippetContainer}>
                  <div className={classes.paper}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={classes.codeSnippetTitle}>
                          Code Snippet:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid className={classes.codeSnippetLanguagesMenu}>
                          {question?.stubs?.map((languag) => {
                            return (
                              <Grid
                                className={classes.languageBox}
                                onClick={() =>
                                  setCurrentSelectedLanguage(languag?.language)
                                }
                                key={languag?.language}
                                style={{
                                  cursor: 'pointer',
                                  borderBottom:
                                    currentSelectedLanguage?.toLowerCase() ===
                                      languag?.language && '4px solid #00B7A4',
                                }}
                              >
                                <Typography
                                  className={classes.selectedLanguageDisplay}
                                >
                                  {languag?.language}
                                </Typography>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className={classes.codeStubWrapper}>
                        {question?.stubs?.map((codeStub) => {
                          return (
                            <Grid container key={codeStub?.language}>
                              {codeStub?.language?.toLowerCase() ===
                                currentSelectedLanguage && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.codeStubLanguageBody}
                                  >
                                    <Typography>Body:</Typography>
                                    <AceEditor
                                      readOnly
                                      mode={aceModesForLanguages(
                                        codeStub.language
                                      )}
                                      theme="dracula"
                                      defaultValue={codeStub?.functionSignature}
                                      highlightActiveLine
                                      enableBasicAutocompletion
                                      width="100%"
                                      height="150px"
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                {question?.testcases?.map((testcase, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={index?.toString()}
                      className={classes.codeSnippetContainer}
                    >
                      <EditTestCase
                        testcase={testcase}
                        index={index}
                        isQuizStarted={
                          isQuizStarted(quizStartTime) && isPublished
                        }
                        editQuestion={editQuestion}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )}
            <ConfirmBox
              isOpenDialog={openDialog}
              handleClose={handleClose}
              handleClick={handleClick}
              boxDescription="Are you sure you want to delete this question?"
              styles={classes}
              buttonName="Delete"
            />
          </Paper>
        </Grid>
      </Grid>
      {isWriteAllowed && (
        <Grid item xs={2} sm={1} md={1}>
          {editQuestion === questionIndex || addQuestion ? (
            <></>
          ) : (
            <>
              <Button
                className={classes.editButton}
                variant="outlined"
                onClick={handleQuestionEdit}
                disabled={isQuizStarted(quizStartTime)} // disabled in closed quiz
              >
                <EditOutlinedIcon />
              </Button>
              {(!isQuizStarted(quizStartTime) || !isPublished) && (
                <>
                  {deleteQuestion?.isLoading ? (
                    <Loader size={30} />
                  ) : (
                    <Button
                      disabled={isQuizStarted(quizStartTime)} // disabled in closed quiz
                      className={classes.deleteButton}
                      variant="outlined"
                      onClick={() => handleDelete()}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  )
}
export default AddCodingQuestion
