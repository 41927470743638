import { useMutation } from 'react-query'
import { createCandidateStream } from '../../Repository/LiveCandidates/CandidateStream'

const candidateStreamRequest = createCandidateStream()

export function SendAdminAnswer(reqId, reqUser, options) {
  return useMutation(
    (args) => candidateStreamRequest.postAdminAnswer(reqId, reqUser, args),
    {
      ...options,
    }
  )
}
