import React, { useState, useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MCQResultRowItem from '../MCQResultRowItem/MCQResultRowItem'
import MCQResultRowItemMobile from '../MCQResultRowItemMobile/MCQResultRowItemMobile'
import CodingQuestionResultRowItem from '../CodingQuestionResultRowItem/CodingQuestionResultRowItem'
import CodingQuestionResultRowItemTab from '../CodingQuestionResultRowItemTab/CodingQuestionResultRowItemTab'
import CodingQuestionResultRowItemMobile from '../CodingQuestionResultRowItemMobile/CodingQuestionResultRowItemMobile'
import EssayResultRowItem from '../EssayResultRowItem/EssayResultRowItem'
import EssayResultRowItemMobile from '../EssayResultRowItemMobile/EssayResultRowItemMobile'
import FillInTheBlankResultRowItem from '../FillInTheBlankResultRowItem/FillInTheBlankResultRowItem'
import { useStyles } from './ResultPageStyles'
import FillInTheBlankResultRowItemMobile from '../FillInTheBlankResultRowItemMobile/FillInTheBlankResultRowItemMobile'

function ResultPage({ result }) {
  const areMCQPresent = result?.evaluatedResult?.mcq?.length > 0
  const areCodingQuestionsPresent = result?.evaluatedResult?.coding?.length > 0
  const areEssayQuestionsPresent = result?.evaluatedResult?.essay?.length > 0
  const areFillInBlanksPresent =
    result.evaluatedResult?.fillInBlanks?.length > 0
  const [currentTab, setCurrentTab] = useState('')

  useEffect(() => {
    if (areMCQPresent) {
      setCurrentTab('mcq')
    } else if (areCodingQuestionsPresent) {
      setCurrentTab('coding')
    } else if (areEssayQuestionsPresent) {
      setCurrentTab('essay')
    } else if (areFillInBlanksPresent) {
      setCurrentTab('fillInTheBlanks')
    }
  }, [])

  const isWidthAboveMediumMobileRange = useMediaQuery(
    'only screen and (min-width:365px)'
  )

  const isWidthAboveSmallTabRange = useMediaQuery(
    'only screen and (min-width:650px)'
  )

  const isWidthAboveSmallLaptopRange = useMediaQuery(
    'only screen and (min-width:950px)'
  )

  const classes = useStyles({
    currentTab,
    areCodingQuestionsPresent,
    areEssayQuestionsPresent,
    areFillInBlanksPresent,
  })
  const returnTable = () => {
    if (currentTab === 'mcq') {
      return (
        <>
          {result?.evaluatedResult?.mcq?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              {isWidthAboveSmallTabRange ? (
                <MCQResultRowItem question={question} />
              ) : (
                <MCQResultRowItemMobile question={question} />
              )}
            </Grid>
          ))}
        </>
      )
    }
    if (currentTab === 'essay') {
      return (
        <>
          {result?.evaluatedResult?.essay?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              {isWidthAboveSmallTabRange ? (
                <EssayResultRowItem question={question} />
              ) : (
                <EssayResultRowItemMobile question={question} />
              )}
            </Grid>
          ))}
        </>
      )
    }
    if (currentTab === 'fillInTheBlanks') {
      return (
        <>
          {result?.evaluatedResult?.fillInBlanks?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              {isWidthAboveSmallTabRange ? (
                <FillInTheBlankResultRowItem question={question} />
              ) : (
                <FillInTheBlankResultRowItemMobile question={question} />
              )}
            </Grid>
          ))}
        </>
      )
    }
    if (isWidthAboveSmallLaptopRange) {
      return (
        <>
          {result?.evaluatedResult?.coding?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              <CodingQuestionResultRowItem question={question} />
            </Grid>
          ))}
        </>
      )
    }
    if (isWidthAboveSmallTabRange) {
      return (
        <>
          {result?.evaluatedResult?.coding?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              <CodingQuestionResultRowItemTab question={question} />
            </Grid>
          ))}
        </>
      )
    }

    return (
      <>
        {result?.evaluatedResult?.coding?.map((question, index) => (
          <Grid item key={index.toString(2)} className={classes.quizResults}>
            <CodingQuestionResultRowItemMobile question={question} />
          </Grid>
        ))}
      </>
    )
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column" className={classes.tabsContainer}>
        <>
          <Grid container className={classes.tabsWrapper}>
            {areMCQPresent ? (
              <Grid
                item
                component="button"
                onClick={() => setCurrentTab('mcq')}
                className={classes.mcqTab}
              >
                {isWidthAboveMediumMobileRange ? (
                  <Typography className={classes.mcqTabText}>
                    Multiple Choice Questions
                  </Typography>
                ) : (
                  <Typography className={classes.mcqTabText}>MCQ</Typography>
                )}

                {areMCQPresent ? (
                  <Grid item className={classes.mcqTabHighlight} />
                ) : null}
              </Grid>
            ) : null}

            {areCodingQuestionsPresent ? (
              <Grid
                item
                component="button"
                onClick={() => setCurrentTab('coding')}
                className={(classes.codingTab, classes.titleTabPadding)}
              >
                <Typography className={classes.codingTabText}>
                  Coding Questions
                </Typography>

                {areCodingQuestionsPresent ? (
                  <Grid item className={classes.codingTabHighlight} />
                ) : null}
              </Grid>
            ) : null}

            {areEssayQuestionsPresent ? (
              <Grid
                item
                component="button"
                onClick={() => setCurrentTab('essay')}
                className={classes.mcqTab}
              >
                {isWidthAboveMediumMobileRange ? (
                  <Typography className={classes.mcqTabText}>
                    Essay Questions
                  </Typography>
                ) : (
                  <Typography className={classes.mcqTabText}>Essay</Typography>
                )}

                {areEssayQuestionsPresent ? (
                  <Grid item className={classes.essayTabHighlight} />
                ) : null}
              </Grid>
            ) : null}

            {areFillInBlanksPresent ? (
              <Grid
                item
                component="button"
                onClick={() => setCurrentTab('fillInTheBlanks')}
                className={classes.mcqTab}
              >
                {isWidthAboveMediumMobileRange ? (
                  <Typography className={classes.mcqTabText}>
                    Fill In The Blanks
                  </Typography>
                ) : (
                  <Typography className={classes.mcqTabText}>
                    Fill In The Blank
                  </Typography>
                )}
                {areFillInBlanksPresent ? (
                  <Grid item className={classes.fillInTheBlanksTabHighlight} />
                ) : null}
              </Grid>
            ) : null}
          </Grid>
          <Grid item className={classes.tabsSeperator} />
        </>
      </Grid>
      <Grid item className={classes.tableWrapper}>
        <Grid container direction="column">
          {returnTable()}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResultPage
