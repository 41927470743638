import React, { useState, useEffect } from 'react'
import { Button, Typography, Tooltip, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { useQueryClient } from 'react-query'
import { useQuestionDeleteQueryById } from '../../Common/Queries/QuestionQuery'
import {
  isQuizStarted,
  validateEmail,
  validateEmailDomain,
} from '../../utils/index'
import './style.css'
import { useStyle } from './EditQuizConfigurationsStyles'
import QuizType from './QuizType'
import QuizConfig from './QuizConfig'
import AddInviteesModal from './AddInviteesModal'
import SendInviteModal from './SendInviteModal'
import AddedInviteesModal from './AddedInviteesModal'
import {
  useQuizGetInviteesById,
  useQuizPostInvitees,
  useDomainNamePostById,
  useDomainNameGetById,
  useQuizPostPermissions,
  useQuizDeletePermisisons,
  useGetAllPermissions,
  useQuizUpdatePermissionByUserID,
} from '../../Common/Queries/QuizQuery'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'
import ConfirmBox from '../common/ConfirmBox'
import PermissionsModal from './PermissionsModal'
import AddedPermissionsModal from './AddedPermissionsModal'

function EditQuizConfigurations({
  quizId,
  questionCount,
  questions,
  quizInfo,
  quizObject,
  updateQuizInfo,
  isPublished,
  isClosed,
  quizSettings,
  updateQuizSettings,
  invalidEmail,
  setInvalidEmail,
  invalidEmails,
  setInvalidEmails,
  privateWithInvitees,
  setPrivateWithInvitees,
  codingQuestionSwitch,
  setCodingQuestionSwitch,
  errors,
  setErrors,
  isWriteAllowed,
}) {
  const queryClient = useQueryClient()
  const classes = useStyle({
    publish: isPublished,
    startTime: quizInfo?.startTime,
    endTime: quizInfo?.endTime,
    isClosed,
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [truthValues, setTruthValues] = useState('')
  const [domainName, setDomainName] = useState()
  const [validEmails, setValidEmails] = useState([])
  const [addInviteesText, setAddInviteesText] = useState('')
  const [inviteesType, setInviteesType] = useState('Email')
  const [open, setOpen] = useState(false)
  const [openSendInvitesModal, setOpenSendInvitesModal] = useState(false)
  const [showAddedInviteesModal, setShowAddedInviteesModal] = useState(false)
  const [isOngoing, setIsOngoing] = useState()
  const [isPermissionsOpenDialog, setIsPermissionsOpenDialog] = useState(false)
  const [showAddedPermissionsModal, setShowPermissionsModal] = useState(false)
  const [validPermissions, setValidPermissions] = useState([])
  const [role, setRole] = useState('Viewer')
  const [roleForEmail, setRoleForEmail] = useState('')
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [
    openDeleteCodingQuestionDialogBox,
    setOpenDeleteCodingQuestionDialogBox,
  ] = useState(false)
  const [quizDuration, updateQuizDuration] = useState(
    quizObject?.time?.duration
  )
  const [postEmailOrDomain, setPostEmailOrDomain] = useState([])
  const [updateRole, setUpdateRole] = useState({})
  const [tempUpdateRole, setTempUpdateRole] = useState({}) // temporary state to hold updates
  const [selectedUserId, setSelectedUserId] = useState('')

  const {
    data: getInviteesData,
    isLoading: getInviteesIsLoading,
    isFetching: getInviteesIsFetching,
    refetch: getInviteesRefetch,
  } = useQuizGetInviteesById(quizId, inviteesType, {
    retry: false,
    onSuccess: (response) => {
      if (response?.data && postEmailOrDomain?.length === 0) {
        updateQuizSettings((prevQuizSettings) => {
          const updatedPrevQuizSetttings = { ...prevQuizSettings }
          updatedPrevQuizSetttings.invitees = response?.data
          return updatedPrevQuizSetttings
        })
        setValidEmails(response?.data)
      } else if (response?.data === null && postEmailOrDomain?.length === 0) {
        updateQuizSettings((prevQuizSettings) => {
          const updatedPrevQuizSetttings = { ...prevQuizSettings }
          updatedPrevQuizSetttings.invitees = null
          return updatedPrevQuizSetttings
        })
        setValidEmails([])
      }
    },
    onError: () => {},
  })

  const createInvitee = useQuizPostInvitees(quizId, {
    onSuccess: async () => {
      setPostEmailOrDomain(() => [])
      // setInviteesSuccess(true)
      setErrorMessage('Success !')
      setTruthValues(true)

      queryClient.invalidateQueries('useQuizGetInviteesById')
    },
    onError: (err) => {
      const errorCode = err?.message * 1
      if (
        errorCode === 400 ||
        errorCode === 401 ||
        errorCode === 403 ||
        errorCode === 500
      ) {
        updateQuizSettings((prevQuizSettings) => {
          const updatePrevQuizSettings = prevQuizSettings
          updatePrevQuizSettings.domain = []
          return updatePrevQuizSettings
        })
        setErrorMessage('Please try again !')
      }
    },
  })

  const deleteQuestion = useQuestionDeleteQueryById({
    staleTime: 0,
    onSuccess: () => {
      setErrorMessage('Success !')
      setTruthValues(true)
      queryClient.invalidateQueries('quizGetQueryById')
      queryClient.invalidateQueries('quizGetQuizQuestionQueryById')
      setCodingQuestionSwitch(() => false)
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      } else if (
        err?.response?.data?.errors?.[0]?.reason === 'Quiz Id is invalid' ||
        'Question ID is invalid'
      ) {
        setCodingQuestionSwitch(() => true)
        setErrorMessage('Please try again !')
      } else if (err?.response?.data?.errors?.[0]?.code === 'Invalid Route') {
        setCodingQuestionSwitch(() => true)
        setErrorMessage('Please try again !')
      }
    },
  })

  const addDomainName = useDomainNamePostById(quizId, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('getDomainNameById')
      setErrorMessage('Success !')
      setTruthValues(true)
    },
    onError: (err) => {
      // converting string to number
      const errorCode = err?.message * 1
      if (
        errorCode === 400 ||
        errorCode === 401 ||
        errorCode === 403 ||
        errorCode === 500
      ) {
        updateQuizSettings((prevQuizSettings) => {
          const updatePrevQuizSettings = prevQuizSettings
          updatePrevQuizSettings.domain = []
          return updatePrevQuizSettings
        })
        setErrorMessage('Please try again !')
      }
    },
  })

  const {
    data: domainObject,
    isLoading: isDomainLoading,
    isFetching: isDomainFetching,
  } = useDomainNameGetById(quizId, inviteesType, {
    retry: false,
    onSuccess: (successData) => {
      const domains = successData?.data?.data
      if (domains !== null && postEmailOrDomain?.length === 0) {
        updateQuizSettings((prevQuizSettings) => {
          const updatePrevQuizSettings = prevQuizSettings
          updatePrevQuizSettings.domain = domains
          return updatePrevQuizSettings
        })
        setValidEmails(domains)
      }
      if (domains === null && postEmailOrDomain?.length === 0) {
        updateQuizSettings((prevQuizSettings) => {
          const updatePrevQuizSettings = prevQuizSettings
          updatePrevQuizSettings.domain = null
          return updatePrevQuizSettings
        })
        setValidEmails([])
      }
    },
    onError: (err) => {
      if (
        (err?.response?.status === 404 &&
          typeof err?.response?.data?.errors?.[0]?.code === 'string' &&
          err?.response?.data?.errors?.[0]?.code === 'ENTITY_NOT_FOUND' &&
          typeof err?.response?.data?.errors?.[0]?.reason === 'string' &&
          err?.response?.data?.errors?.[0]?.reason?.toLowerCase() ===
            'quiz not found') ||
        (err?.response?.status === 400 &&
          typeof err?.response?.data?.errors?.[0]?.code === 'string' &&
          err?.response?.data?.errors?.[0]?.code === 'BAD_REQUEST' &&
          typeof err?.response?.data?.errors?.[0]?.reason === 'string' &&
          err?.response?.data?.errors?.[0]?.reason?.toLowerCase() ===
            'quiz id is invalid')
      ) {
        setErrorMessage('Please try again !')
      }
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  const {
    isLoading: getPermissionsIsLoading,
    isFetching: getPermissionsIsFetching,
    refetch: getPermissionsRefetch,
  } = useGetAllPermissions(quizId, {
    enabled: true,
    onSuccess: (response) => {
      updateQuizSettings((previousSettings) => {
        const settings = { ...previousSettings }
        settings.permissions = response?.data?.data?.userPermissions
        return settings
      })
      setValidPermissions(response?.data?.data)
    },
    onError: () => {},
  })

  useEffect(() => {
    if (domainObject?.data?.data?.length > 0) {
      setDomainName(domainObject?.data?.data)
      setInviteesType('Domain')
    }
  }, [domainObject])

  useEffect(() => {
    setIsOngoing(
      isPublished &&
        quizInfo?.startTime &&
        (quizInfo?.startTime < new Date() ? quizInfo?.startTime : '')
    )
  }, [isPublished, quizInfo])

  useEffect(() => {
    if (
      createInvitee?.data?.errors?.length > 0 ||
      getInviteesData?.errors?.length > 0
    ) {
      setErrorMessage('Please try again !')
    }
  }, [createInvitee.isLoading, getInviteesIsFetching])

  useEffect(() => {
    if (
      !isClosed &&
      !isPublished &&
      quizInfo?.startTime < new Date() &&
      quizInfo?.startTime !== null
    ) {
      return setErrors({
        ...errors,
        startTimeError: 'Start time should be greater than current time',
      })
    }
    return setErrors({ ...errors, startTimeError: '' })
  }, [])

  const handleStartTimeChange = (value) => {
    updateQuizInfo((prevQuizInfo) => {
      const updatePrevQuiz = { ...prevQuizInfo }
      updatePrevQuiz.startTime = value
      return updatePrevQuiz
    })
    if (value < new Date() && value !== null) {
      return setErrors({
        ...errors,
        startTimeError: 'Start time should be greater than current time',
      })
    }
    return setErrors({ ...errors, startTimeError: '' })
  }

  const handleStartTime = (time) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)
    return currentDate.getTime() < selectedDate.getTime()
  }

  const handleEndTime = (time) => {
    const currentDate =
      quizInfo.startTime < new Date() ? new Date() : quizInfo?.startTime
    const selectedDate = new Date(time)
    return currentDate < selectedDate.getTime()
  }

  const handleEndTimeChange = (value) => {
    updateQuizInfo((prevQuizInfo) => {
      const updatePrevQuiz = { ...prevQuizInfo }
      updatePrevQuiz.endTime = value
      return updatePrevQuiz
    })

    if (value <= quizInfo?.startTime && value !== null) {
      return setErrors({
        ...errors,
        endTimeError: 'End time should be greater than start time',
      })
    }
    if (value < new Date() && value !== null) {
      return setErrors({
        ...errors,
        endTimeError: 'End time should be greater than current time',
      })
    }

    return setErrors({ ...errors, endTimeError: '' })
  }

  const handleDurationChange = (event) => {
    const newDuration = event.target.value
    updateQuizDuration(newDuration)
    if (Number.isNaN(Number(newDuration)) || Number(newDuration) <= 0) {
      updateQuizInfo((prevQuizInfo) => {
        const updatePrevQuiz = { ...prevQuizInfo }
        updatePrevQuiz.duration = newDuration
        return updatePrevQuiz
      })
      return Number.isNaN(Number(newDuration))
        ? setErrors({
            ...errors,
            durationError: 'Duration should be a number',
          })
        : setErrors({
            ...errors,
            durationError: 'Duration should be a positive number',
          })
    }

    setErrors({
      ...errors,
      durationError: '',
    })

    updateQuizInfo((prevQuizInfo) => {
      const updatePrevQuiz = { ...prevQuizInfo }
      updatePrevQuiz.duration = newDuration
      return updatePrevQuiz
    })
    return ''
  }

  const handleChangeType = (value) => {
    updateQuizSettings((prevQuizSettings) => {
      const updatedPrevQuizSetttings = { ...prevQuizSettings }
      if (value === 'public') {
        updatedPrevQuizSetttings.proctored = false
        setCodingQuestionSwitch(() => false)
        setPrivateWithInvitees(() => false)
      }
      updatedPrevQuizSetttings.type = value
      return updatedPrevQuizSetttings
    })
  }

  const handleClearInvitees = () => {
    updateQuizSettings((prevQuizSettings) => {
      const updatedPrevQuizSetttings = { ...prevQuizSettings }
      updatedPrevQuizSetttings.invitees = []

      return updatedPrevQuizSetttings
    })
    setInvalidEmail(() => false)
  }

  const handleAddInviteesTextArea = (event) => {
    setAddInviteesText(event.target.value.toLowerCase())
  }

  const handleBackbutton = () => {
    setOpen(true)
    setOpenSendInvitesModal(false)
  }

  const handleClose = () => {
    setOpenSendInvitesModal(false)
  }

  const handleInvitees = () => {
    const trimmedTextValue = addInviteesText
      .replace(/^[, ]+|[, ] + $|[, ]+/g, '\n')
      .trim()
    let myArray = []
    const validArray = []
    const invalidArray = []

    myArray = trimmedTextValue.trim().split(/\r?\n/)
    myArray = Array.from(new Set(myArray))
    myArray.map((typeValue) => {
      if (inviteesType === 'Domain') {
        if (
          !validateEmailDomain(typeValue) &&
          typeValue !== '' &&
          !invalidEmails?.includes(typeValue)
        ) {
          invalidArray.push(typeValue)
          setInvalidEmails((typeValuesPrev) => {
            return [...typeValuesPrev, typeValue]
          })
          setInvalidEmail(true)
        }
        if (
          validateEmailDomain(typeValue) &&
          !validEmails?.includes(typeValue)
        ) {
          setPostEmailOrDomain((emailsPrev) => {
            return [...emailsPrev, typeValue]
          })
          validArray.push(typeValue)
          setValidEmails((typeValuesPrev) => {
            return [...typeValuesPrev, typeValue]
          })
        }
      } else {
        if (
          !validateEmail(typeValue) &&
          typeValue !== '' &&
          !invalidEmails?.includes(typeValue)
        ) {
          invalidArray.push(typeValue)
          setInvalidEmails((typeValuesPrev) => {
            return [...typeValuesPrev, typeValue]
          })
          setInvalidEmail(true)
        }
        if (validateEmail(typeValue) && !validEmails?.includes(typeValue)) {
          setPostEmailOrDomain((emailsPrev) => {
            return [...emailsPrev, typeValue]
          })
          validArray.push(typeValue)
          setValidEmails((typeValuesPrev) => {
            return [...typeValuesPrev, typeValue]
          })
        }
      }
      return undefined
    })
    quizSettings?.invitees?.forEach((email) => {
      if (validateEmail(email) === false) {
        setInvalidEmail(true)
      }
    })
    validArray.sort()
    invalidArray.sort()
  }
  const handleValidateButton = () => {
    handleInvitees()
    setOpen(false)
    setOpenSendInvitesModal(true)
  }

  const handleSubmitEmailDomain = () => {
    const truthValue = quizSettings?.domain
    const finalDomain = postEmailOrDomain?.filter(
      (item) => !truthValue?.includes(item)
    )
    if (finalDomain?.length > 0) {
      addDomainName.mutate({
        action: 'ADD',
        emailDomain: finalDomain,
      })
    }
    if (finalDomain?.length < 1) {
      setErrorMessage('Success !')
      setTruthValues(true)
    }
    setPostEmailOrDomain([])
    updateQuizSettings((prevQuizSettings) => {
      const updatedPrevQuizSetttings = { ...prevQuizSettings }
      updatedPrevQuizSetttings.domain = [...validEmails]
      return updatedPrevQuizSetttings
    })
    setOpenSendInvitesModal(false)
  }

  const handleConfirmButton = () => {
    const truthValue = quizSettings?.invitees
    const finalInvitees = postEmailOrDomain?.filter(
      (item) => !truthValue?.includes(item)
    )
    updateQuizSettings((prevQuizSettings) => {
      const updatedPrevQuizSetttings = { ...prevQuizSettings }
      updatedPrevQuizSetttings.invitees = [...validEmails]
      return updatedPrevQuizSetttings
    })
    if (finalInvitees?.length > 0) {
      const object = {
        action: 'ADD',
        inviteesEmail: finalInvitees,
      }
      createInvitee.mutate(object)
    }
    if (finalInvitees?.length < 1) {
      setErrorMessage('Success !')
      setTruthValues(true)
    }
    handleClose()
    setPostEmailOrDomain([])
  }

  const handleRemoveDialogEmail = (emailtype, index, email) => {
    if (emailtype === 'valid') {
      setValidEmails((prevValidEmails) => {
        let updatedValidEmails = { ...prevValidEmails }
        updatedValidEmails = prevValidEmails?.filter((e, i) => i !== index)
        return updatedValidEmails
      })
      setPostEmailOrDomain((prevValidEmails) => {
        let updatedValidEmails = { ...prevValidEmails }
        updatedValidEmails = prevValidEmails?.filter((e, i) => i !== index)
        return updatedValidEmails
      })
    } else if (emailtype === 'invalid') {
      setInvalidEmails((prevInvalidEmails) => {
        let updatedInvalidEmails = { ...prevInvalidEmails }
        updatedInvalidEmails = prevInvalidEmails?.filter((e, i) => i !== index)
        return updatedInvalidEmails
      })
    }
    setAddInviteesText(addInviteesText.replace(email, '').trim())
  }

  const handleDeleteInvitee = (value) => {
    const { invitees } = quizSettings
    const index = invitees.indexOf(value)
    if (index > -1) {
      invitees.splice(index, 1)
    }
    if (invitees.length === 0) {
      setInvalidEmail(false)
    } else {
      setInvalidEmail(false)
      for (let i = 0; i < invitees.length; i++) {
        if (validateEmail(invitees[i]) === false) {
          setInvalidEmail(true)
        }
      }
    }
    updateQuizSettings((prevQuizSettings) => {
      const updatedPrevQuizSetttings = { ...prevQuizSettings }
      updatedPrevQuizSetttings.invitees = invitees

      return updatedPrevQuizSetttings
    })
    setInvalidEmails((invalidEmailsPrev) => {
      const invalidEmailsPrevCopy = invalidEmailsPrev
      invalidEmailsPrev?.forEach((invalidEmailPrev, invalidEmailPrevIndex) => {
        if (invalidEmailPrev === value) {
          invalidEmailsPrevCopy.splice(invalidEmailPrevIndex, 1)
        }
      })
      return invalidEmailsPrevCopy
    })
  }

  const handleChangeProctoring = () => {
    updateQuizSettings((prevQuizSettings) => {
      const updatedPrevQuizSetttings = { ...prevQuizSettings }
      if (updatedPrevQuizSetttings.type !== 'public') {
        updatedPrevQuizSetttings.proctored = !prevQuizSettings?.proctored
      }
      return updatedPrevQuizSetttings
    })
  }

  const handleChangeResults = () => {
    updateQuizSettings((prevQuizSettings) => {
      const updatedPrevQuizSetttings = { ...prevQuizSettings }
      updatedPrevQuizSetttings.result = !prevQuizSettings?.result
      return updatedPrevQuizSetttings
    })
  }

  const handleChangeRandomize = () => {
    updateQuizSettings((prevQuizSettings) => {
      const updatedPrevQuizSetttings = { ...prevQuizSettings }
      updatedPrevQuizSetttings.randomize = !prevQuizSettings.randomize
      return updatedPrevQuizSetttings
    })
  }

  const handleChangeCodingQuestion = (event) => {
    if (
      codingQuestionSwitch &&
      questionCount &&
      questionCount[2]?.type === 'coding' &&
      questionCount[2]?.count
    ) {
      return setOpenDeleteCodingQuestionDialogBox(() => true)
    }
    return setCodingQuestionSwitch(() => event)
  }
  const toolTipRendering = () => {
    if (!isWriteAllowed) {
      return 'This setting cannot be modified for a viewer permission'
    }
    if (isClosed) {
      return 'This setting cannot be modified for a closed quiz'
    }
    if (isOngoing) {
      return 'This setting cannot be modified for ongoing quiz'
    }
    return ''
  }

  const toolTipRender = (x) => {
    if (!isWriteAllowed) {
      return 'This setting cannot be modified for a viewer permission'
    }
    if (x === 'startTime') {
      if (isClosed) {
        return 'Start time cannot be modified for a closed quiz'
      }
      if (isOngoing) {
        return 'Start time cannot be mobified for ongoing quiz'
      }
    }
    if (x === 'Duration') {
      if (isOngoing) {
        return 'Duration cannot be modified for ongoing quiz'
      }
      if (isClosed) {
        return 'Duration cannot be modified for a closed quiz'
      }
    }
    if (x === 'endTime') {
      if (isClosed) {
        return 'End time cannot be modified for a closed quiz'
      }
    }
    if (x === 'invitees') {
      if (isClosed) {
        return 'Invitees cannot be added for closed quiz'
      }
      if (quizSettings?.type === 'public') {
        return 'Select private quiz type to add invitees'
      }
      if (isOngoing) {
        return 'Invitees cannot be added for a ongoing quiz'
      }
    }
    if (x === 'public') {
      if (isClosed) {
        return 'Quiz type cannot be modified for a closed quiz'
      }
      if (isOngoing) {
        return 'Quiz type cannot be modified for a ongoing quiz'
      }
      if (codingQuestionSwitch && quizSettings?.proctored) {
        return 'Please disable proctoring and coding questions to switch to public'
      }
      if (quizSettings?.proctored) {
        return 'Please disable proctoring to switch to public'
      }
      if (quizSettings?.invitees && !quizSettings?.domain) {
        return 'Please remove invitees to switch to public'
      }
      if (quizSettings?.permissions?.length > 1) {
        return 'Please remove permissions to switch to public'
      }
      if (quizSettings?.domain && !quizSettings?.invitees) {
        return 'Please remove domains to switch to public'
      }
      if (
        ((questionCount && questionCount[2]?.coding) || codingQuestionSwitch) &&
        !isClosed &&
        !isOngoing
      ) {
        return 'Please delete codingQuestions to switch to public'
      }
      toolTipRendering()
    }
    if (x === 'permissions' && !privateWithInvitees) {
      return 'Select private quiz type to add permissions'
    }
    return ''
  }

  const renderEmailDomainButtonTitle = () => {
    if (quizSettings?.invitees !== null && quizSettings?.invitees?.length > 0) {
      return 'Edit Invitees'
    }
    if (quizSettings?.domain !== null && quizSettings?.domain?.length > 0) {
      return 'Edit Domain'
    }
    return 'Add Invitees/Domain'
  }

  const handleDisableCodingQuestionSwitch = () => {
    if (
      quizSettings?.type === 'public' ||
      (new Date() > quizInfo?.startTime && isPublished) ||
      isClosed
    ) {
      return true
    }
    return false
  }

  const handleDeleteCodingQuestion = () => {
    questions?.map((question) => {
      if (question?.type === 'coding') {
        return deleteQuestion.mutate({
          quizId,
          questionId: question?.id,
          questionType: question?.type,
        })
      }
      return ''
    })
    return setOpenDeleteCodingQuestionDialogBox(() => false)
  }

  const handleCloseDialog = () => {
    setOpenDeleteCodingQuestionDialogBox(false)
  }

  const handleSnackBar = () => {
    setErrorMessage('')
    setTimeout(() => setTruthValues(false), 1000)
  }

  const handleClickOpen = () => {
    if (quizSettings?.invitees?.length > 0) {
      setAddInviteesText(quizSettings?.invitees.join('\n'))
    }
    if (quizSettings?.domain?.length > 0) {
      setAddInviteesText(quizSettings?.domain.join('\n'))
    }
    setOpen(true)
  }

  const renderPermissionsButtonTitle = () => {
    return 'Add Permissions'
  }

  const handleClickPermissionsOpen = () => {
    setIsPermissionsOpenDialog(true)
  }

  const createRole = useQuizPostPermissions(quizId, {
    onSuccess: async () => {
      setErrorMessage('Permission added successfully !')
      setTruthValues(true)
      getPermissionsRefetch()
    },
    onError: (err) => {
      const errorCode = err?.message * 1
      if (errorCode === 401) {
        setErrorMessage('User is not authorized')
      } else if (errorCode === 400) {
        setErrorMessage('Invalid Email')
      } else if (errorCode === 404) {
        setErrorMessage('Quiz not found')
      } else if (errorCode === 409) {
        setErrorMessage('Permissions for this email already exists')
      } else if (errorCode === 403) {
        setErrorMessage('Access Denied')
      }
    },
  })

  const handleAddPermissionButton = () => {
    const object = {
      userID: roleForEmail,
      role: role.toUpperCase(),
    }
    createRole.mutate(object)
    setRoleForEmail('')
    setIsPermissionsOpenDialog(false)
  }

  const deleteRole = useQuizDeletePermisisons(quizId, {
    onSuccess: async () => {
      setErrorMessage('Permission deleted successfully !')
      setTruthValues(true)
      getPermissionsRefetch()
    },
    onError: () => {
      setErrorMessage('Something went wrong , please try again')
    },
  })

  const handleDeleteQuizPermissions = async (email) => {
    await deleteRole.mutateAsync(email)
  }

  const updateRoles = useQuizUpdatePermissionByUserID(quizId, {
    onSuccess: async () => {
      setErrorMessage('Permission updated successfully !')
      setTruthValues(true)
      getPermissionsRefetch()

      // If update is successful, update the actual state
      setUpdateRole(tempUpdateRole)
      setOpenConfirmDialog(false)
      setShowPermissionsModal(true)
    },
    onError: (err) => {
      setErrorMessage(err?.message)

      // If there's an error, revert the temporary state
      setTempUpdateRole((prevRoles) => ({
        ...prevRoles,
        [selectedUserId]: updateRole[selectedUserId], // Revert to the previous role for this user
      }))
      setOpenConfirmDialog(false)
      setShowPermissionsModal(true)
    },
  })

  const handleUpdateUserPermissions = (email, roleToUpdate) => {
    const object = {
      userID: email,
      role: roleToUpdate.toUpperCase(),
    }
    updateRoles.mutate(object)
  }

  const handleAddedInviteesModal = () => {
    getInviteesRefetch()
    setShowAddedInviteesModal(true)
  }
  const handleAddedPermissionsModal = () => {
    getPermissionsRefetch()
    setShowPermissionsModal(true)
    setOpenConfirmDialog(false)
  }

  const addInvitesModal = () => {
    return quizSettings?.type === 'private' ? (
      <Typography
        onClick={handleAddedInviteesModal}
        style={{
          paddingLeft: '16px',
          fontSize: '14px',
          color: '#00B7A4',
          fontWeight: 500,
          cursor: 'pointer',
        }}
      >
        {quizSettings?.invitees?.length} invitee
        {quizSettings?.invitees?.length > 1 && 's'} added
      </Typography>
    ) : (
      <Typography
        style={{
          paddingLeft: '16px',
          fontSize: '14px',
          color: '#gray',
          fontWeight: 500,
        }}
      >
        {quizSettings?.invitees?.length} invitee
        {quizSettings?.invitees?.length > 1 && 's'} added
      </Typography>
    )
  }

  const addPermissionsModal = () => {
    return (
      <Typography
        onClick={handleAddedPermissionsModal}
        style={{
          paddingLeft: '16px',
          fontSize: '14px',
          color: '#00B7A4',
          fontWeight: 500,
          cursor: 'pointer',
        }}
      >
        view permissions
      </Typography>
    )
  }

  const addDomainModal = () => {
    return quizSettings?.type === 'private' ? (
      <>
        <Typography
          onClick={handleAddedInviteesModal}
          style={{
            paddingLeft: '16px',
            fontSize: '14px',
            color: '#00B7A4',
            fontWeight: 500,
            cursor: 'pointer',
          }}
        >
          {quizSettings?.domain?.length} domain
          {quizSettings?.domain?.length > 1 && 's'} added
        </Typography>
      </>
    ) : (
      <Typography
        style={{
          paddingLeft: '16px',
          fontSize: '14px',
          color: '#gray',
          fontWeight: 500,
        }}
      >
        {quizSettings?.domain?.length} domain
        {quizSettings?.domain?.length > 1 && 's'} added
      </Typography>
    )
  }

  useEffect(() => {
    if (questionCount && questionCount[2]?.coding) {
      setCodingQuestionSwitch(() => true)
    }
  }, [questionCount])

  useEffect(() => {
    setPrivateWithInvitees(quizSettings?.type === 'private')
  }, [quizSettings?.type, setPrivateWithInvitees])

  useEffect(() => {
    setAddInviteesText(() => '')
  }, [inviteesType])

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item lg={8} xs={12}>
            <span className={classes.inputTitle}>Test Window</span>
            <Grid container spacing={2} className={classes.datePicker}>
              <Grid item xs={12} lg={6}>
                <Typography>Start Time</Typography>
                <Tooltip
                  arrow
                  placement="bottom-start"
                  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  title={toolTipRender('startTime')}
                >
                  <span>
                    <DatePicker
                      disabled={
                        ((quizInfo?.startTime
                          ? new Date() > quizInfo?.startTime
                          : '') &&
                          isPublished) ||
                        isClosed
                      }
                      selected={quizInfo.startTime}
                      onChange={handleStartTimeChange}
                      showTimeSelect
                      timeIntervals={10}
                      value={quizInfo.startTime}
                      filterTime={handleStartTime}
                      minDate={
                        isQuizStarted(quizInfo.startTime) && isPublished
                          ? new Date(quizInfo.StartTime)
                          : new Date()
                      }
                      isClearable={
                        !(
                          ((quizInfo?.startTime
                            ? new Date() > quizInfo?.startTime
                            : '') &&
                            isPublished) ||
                          isClosed
                        )
                      }
                      dateFormat="dd-MM-y hh:mm a"
                      placeholderText="dd-mm-yyyy hh:mm a"
                      className={classes.validityInputStart}
                    />
                  </span>
                </Tooltip>
                {errors.startTimeError.length > 0 && quizInfo?.startTime && (
                  <span
                    className={`${classes.durationError} ${classes.validationText}`}
                  >
                    {errors.startTimeError}
                  </span>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <Typography>End Time</Typography>
                <Tooltip
                  arrow
                  placement="bottom-start"
                  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  title={toolTipRender('endTime')}
                >
                  <span>
                    <DatePicker
                      disabled={isClosed}
                      selected={quizInfo?.endTime}
                      timeIntervals={10}
                      onChange={handleEndTimeChange}
                      value={quizInfo.endTime}
                      showTimeSelect
                      minDate={
                        quizInfo?.startTime < new Date()
                          ? new Date()
                          : quizInfo?.startTime
                      }
                      filterTime={handleEndTime}
                      dateFormat="dd-MM-y hh:mm a"
                      placeholderText="dd-mm-yyyy hh:mm a"
                      className={classes.validityInputEnd}
                      isClearable={!(new Date() > quizInfo?.startTime)}
                    />
                  </span>
                </Tooltip>
                {errors.endTimeError.length > 0 && quizInfo?.endTime && (
                  <span
                    className={`${classes.durationError} ${classes.validationText}`}
                  >
                    {errors.endTimeError}
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} xs={12}>
            <span className={classes.inputTitle}>Duration</span>
            <div>
              <Tooltip
                arrow
                placement="bottom-start"
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                title={toolTipRender('Duration')}
              >
                <span>
                  <OutlinedInput
                    fullWidth
                    variant="outlined"
                    placeholder="0 Minutes"
                    value={quizDuration}
                    inputProps={{
                      style: { height: '3px' },
                    }}
                    onChange={handleDurationChange}
                    endAdornment={<Typography>minutes</Typography>}
                    className={classes.input}
                    disabled={
                      (new Date() > quizInfo?.startTime && isPublished) ||
                      isClosed
                    }
                  />
                </span>
              </Tooltip>
            </div>
            {/* </div> */}
            {errors.durationError.length > 0 && (
              <span
                className={`${classes.durationError} ${classes.validationText}`}
              >
                {errors.durationError}
              </span>
            )}
          </Grid>
        </Grid>
        {/* Quiz Type */}
        <Grid container xs={12}>
          <Grid
            item
            xs={12}
            className={classes.quizTypeWrapper}
            style={{ marginTop: '32px' }}
          >
            <Grid container className={classes.quizTypeIconAndHeadingWrapper}>
              <Typography className={classes.settingQuizTypeTitle}>
                Quiz Type
              </Typography>
            </Grid>
            <QuizType
              publish={isPublished}
              startTime={quizInfo?.startTime}
              endTime={quizInfo?.endTime}
              isClosed={isClosed}
              handleChangeType={handleChangeType}
              quizSettings={quizSettings}
              toolTipRender={toolTipRender}
              isOngoing={isOngoing}
              codingQuestionSwitch={codingQuestionSwitch}
              isPublished={isPublished}
              isWriteAllowed={isWriteAllowed}
            />
          </Grid>
          {isWriteAllowed && (
            <Grid item xs={12}>
              <div
                // className={classes.inviteesSwitchWrapper}
                style={{ marginTop: '32px' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip
                    arrow
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                    title={toolTipRender('invitees')}
                  >
                    {/* <InputLabel>Add Invitees</InputLabel> */}
                    <span>
                      <Button
                        startIcon={<AddIcon />}
                        disabled={quizSettings?.type === 'public' || isOngoing}
                        onClick={handleClickOpen}
                      >
                        {renderEmailDomainButtonTitle()}
                      </Button>
                    </span>
                  </Tooltip>
                  {quizSettings?.invitees?.length > 0
                    ? addInvitesModal()
                    : null}
                  {quizSettings?.domain?.length > 0 ? addDomainModal() : null}
                  <AddedInviteesModal
                    setOpenSendInvitesModal={setOpenSendInvitesModal}
                    isPublished={isPublished}
                    quizInfo={quizInfo}
                    isClosed={isClosed}
                    quizSettings={quizSettings}
                    domainName={domainName}
                    showAddedInviteesModal={showAddedInviteesModal}
                    setShowAddedInviteesModal={setShowAddedInviteesModal}
                    inviteesType={inviteesType}
                    setInviteesType={setInviteesType}
                    getInviteesRefetch={getInviteesRefetch}
                    createInvitee={createInvitee}
                    updateQuizSettings={updateQuizSettings}
                    addDomainName={addDomainName}
                    isLoading={addDomainName?.isLoading}
                    setValidEmails={setValidEmails}
                    getInviteesIsLoading={getInviteesIsLoading}
                    getInviteesIsFetching={getInviteesIsFetching}
                    isDomainFetching={isDomainFetching}
                    isDomainLoading={isDomainLoading}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '14px',
                    marginTop: '16px',
                    maxWidth: '400px',
                  }}
                >
                  Add new members to take the test by entering their email
                  addresses and sending them an invitation.
                </Typography>

                <AddInviteesModal
                  testAreaText={addInviteesText}
                  handleAddInviteesTextArea={handleAddInviteesTextArea}
                  handleValidateButton={handleValidateButton}
                  isPublished={isPublished}
                  quizInfo={quizInfo}
                  isClosed={isClosed}
                  open={open}
                  inviteesType={inviteesType}
                  setInviteesType={setInviteesType}
                  setOpen={setOpen}
                  privateWithInvitees={privateWithInvitees}
                  toolTipRender={toolTipRender}
                  handleClearInvitees={handleClearInvitees}
                  quizSettings={quizSettings}
                  handleDeleteInvitee={handleDeleteInvitee}
                  invalidEmail={invalidEmail}
                  invalidEmails={invalidEmails}
                  domainName={domainName}
                  addDomainName={addDomainName}
                  setValidEmails={setValidEmails}
                  setInvalidEmails={setInvalidEmails}
                  setAddInviteesText={setAddInviteesText}
                />
                <SendInviteModal
                  handleClose={handleClose}
                  handleRemoveDialogEmail={handleRemoveDialogEmail}
                  handleConfirmButton={handleConfirmButton}
                  validEmails={validEmails}
                  invalidEmails={invalidEmails}
                  handleBackbutton={handleBackbutton}
                  openSendInvitesModal={openSendInvitesModal}
                  setOpenSendInvitesModal={setOpenSendInvitesModal}
                  isPublished={isPublished}
                  quizInfo={quizInfo}
                  isClosed={isClosed}
                  inviteesType={inviteesType}
                  setInviteesType={setInviteesType}
                  handleSubmitEmailDomain={handleSubmitEmailDomain}
                />
              </div>
            </Grid>
          )}

          {isWriteAllowed && (
            <Grid item xs={12}>
              <div style={{ marginTop: '32px' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className={classes.addPermissionsButton}
                >
                  <Tooltip
                    arrow
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                    title={toolTipRender('permissions')}
                  >
                    <span>
                      <Button
                        startIcon={<AddIcon />}
                        disabled={quizSettings?.type === 'public' || isOngoing}
                        onClick={handleClickPermissionsOpen}
                      >
                        {renderPermissionsButtonTitle()}
                      </Button>
                    </span>
                  </Tooltip>
                  {addPermissionsModal()}
                  <AddedPermissionsModal
                    isPublished={isPublished}
                    quizId={quizId}
                    quizInfo={quizInfo}
                    isClosed={isClosed}
                    quizObject={quizObject}
                    showAddedPermissionsModal={showAddedPermissionsModal}
                    setShowPermissionsModal={setShowPermissionsModal}
                    validPermissions={validPermissions}
                    getPermissionsIsLoading={getPermissionsIsLoading}
                    getPermissionsIsFetching={getPermissionsIsFetching}
                    handleDeleteQuizPermissions={handleDeleteQuizPermissions}
                    openConfirmDialog={openConfirmDialog}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                    handleUpdateUserPermissions={handleUpdateUserPermissions}
                    updateRole={updateRole}
                    setUpdateRole={setUpdateRole}
                    tempUpdateRole={tempUpdateRole}
                    setTempUpdateRole={setTempUpdateRole}
                    selectedUserId={selectedUserId}
                    setSelectedUserId={setSelectedUserId}
                    setErrorMessage={setErrorMessage}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '14px',
                    marginTop: '16px',
                    maxWidth: '400px',
                  }}
                >
                  Add permissions to members by entering their email addresses
                  and role.
                </Typography>
                <PermissionsModal
                  isClosed={isClosed}
                  isPermissionsOpenDialog={isPermissionsOpenDialog}
                  setIsPermissionsOpenDialog={setIsPermissionsOpenDialog}
                  isPublished={isPublished}
                  quizInfo={quizInfo}
                  role={role}
                  setRole={setRole}
                  roleForEmail={roleForEmail}
                  setRoleForEmail={setRoleForEmail}
                  handleAddPermissionButton={handleAddPermissionButton}
                />
              </div>
            </Grid>
          )}
        </Grid>

        {/* Quiz Config */}
        <QuizConfig
          isPublished={isPublished}
          startTime={quizInfo?.startTime}
          endTime={quizInfo?.endTime}
          isClosed={isClosed}
          handleDisableCodingQuestionSwitch={handleDisableCodingQuestionSwitch}
          codingQuestionSwitch={codingQuestionSwitch}
          handleChangeCodingQuestion={handleChangeCodingQuestion}
          toolTipRendering={toolTipRendering}
          handleChangeResults={handleChangeResults}
          quizSettings={quizSettings}
          handleChangeRandomize={handleChangeRandomize}
          handleChangeProctoring={handleChangeProctoring}
          isOngoing={isOngoing}
          isWriteAllowed={isWriteAllowed}
        />
      </div>
      <CommonSnackbar
        open={errorMessage !== ''}
        autoHideDuration={6000}
        onClose={handleSnackBar}
        severity={truthValues ? 'success' : 'error'}
        message={errorMessage}
      />
      <ConfirmBox
        isOpenDialog={openDeleteCodingQuestionDialogBox}
        handleClose={handleCloseDialog}
        handleClick={handleDeleteCodingQuestion}
        boxTitle="Delete Coding Question"
        boxDescription="Present Coding questions will be deleted"
        styles={classes}
        buttonName="Delete"
      />
    </div>
  )
}

export default EditQuizConfigurations
