import { createFetcher } from '../../Fetcher/Fetcher'
import {
  MOCK_BASE_URL,
  PAYMENT_ENDPOINT,
  BILLING_ENDPOINT,
  ADMIN_ENDPOINT,
} from '../../constant'

/**
 * For payments.
 * @Applications
 */
export default class Payment {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Payment(
        MOCK_BASE_URL,
        PAYMENT_ENDPOINT,
        BILLING_ENDPOINT,
        ADMIN_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(
    baseUrl,
    paymentEndPoint,
    billingEndPoint,
    adminEndPoint,
    fetcherObj
  ) {
    this.base_url = baseUrl
    this.paymentEndPoint = paymentEndPoint
    this.billingEndPoint = billingEndPoint
    this.adminEndPoint = adminEndPoint
    this.fetcher = fetcherObj
  }
  /**
   * this function is to get all applications
   * @function postPayment
   ** @param {reqParam} default parameter
   */

  async postPayment(reqData, resourceId) {
    const url = `${this.base_url + this.adminEndPoint}/${
      resourceId + this.paymentEndPoint
    }`
    const response = await this.fetcher.postData(url, reqData)
    return response?.data?.data
  }

  async getBillingLog(resourceId, transactionType, billingPageLimit, offset) {
    const url = `${this.base_url + this.adminEndPoint}/${
      resourceId + this.billingEndPoint
    }?transactionType=${transactionType}&limit=${billingPageLimit}&offset=${offset}`
    const response = await this.fetcher.getData(url)
    return response
  }
}
