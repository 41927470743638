import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

import { useStyles } from './EditMcqQuestion2Styles'

const Choice = ({ choice }) => {
  const classes = useStyles()

  return (
    <Grid
      item
      xs={12}
      key={choice?.id}
      className={classes.mcqChoice}
      style={{ marginBottom: '8px', wordBreak: 'break-word' }}
    >
      <Paper
        elevation={2}
        variant="outlined"
        className={choice.isCorrect ? classes.isCorrect : ''}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '12px 66px 12px 12px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div>
            {choice.isCorrect ? (
              <>
                <RadioButtonCheckedIcon
                  className={classes.selectedMcqChoice}
                  data-testid="radio-button-icon"
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {choice?.isCorrect ? (
              <Typography className={classes.selectedChoiceValue}>
                {choice?.value}
              </Typography>
            ) : (
              <Typography className={classes.choiceValue}>
                {choice?.value}
              </Typography>
            )}
          </div>
        </div>
      </Paper>
    </Grid>
  )
}

export default Choice
