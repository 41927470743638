import _ from 'lodash'
import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { faro } from '@grafana/faro-web-sdk'
import moment from 'moment'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export function getRequestHeaders() {
  const token = JSON.parse(localStorage.getItem('tokenStorage'))
  if (token?.accessToken) {
    return {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token?.accessToken}`,
    }
  }
  return { 'Content-type': 'application/json' }
}

export function isLogin() {
  const result = JSON?.parse(localStorage.getItem('tokenStorage'))
  if (result?.accessToken !== '' && result !== null) {
    return true
  }

  return false
}

export function identifyQuestionType(question) {
  if (question?.type === 'multi-choice') {
    return 'multi-choice'
  }
  if (question?.type === 'essay') {
    return 'essay'
  }
  if (question?.type === 'fill-in-blanks') {
    return 'fillInBlanks'
  }
  if (question?.choices?.length !== 2) return 'mcq'
  const choicesDetails = {
    containsTrueChoice: false,
    containsFalseChoice: false,
  }
  question.choices.forEach((currentChoice) => {
    if (currentChoice.value.toLowerCase() === 'true') {
      choicesDetails.containsTrueChoice = true
    } else if (currentChoice.value.toLowerCase() === 'false') {
      choicesDetails.containsFalseChoice = true
    }
  })
  if (choicesDetails.containsTrueChoice && choicesDetails.containsFalseChoice) {
    return 't/f'
  }
  return 'mcq'
}

export function capiatilizeChoices(question) {
  const modifiedQuestion = question
  modifiedQuestion.choices.forEach((currentChoice) => {
    // eslint-disable-next-line no-param-reassign
    currentChoice.value = _.capitalize(currentChoice.value)
  })
  return modifiedQuestion
}

export const aceModesForLanguages = (language) => {
  if (language === 'c' || language === 'cpp') return 'c_cpp'
  return language
}
export const unixTimeStamp = (unixTime, billingUnixTime) => {
  const dateString = new Date(unixTime * 1000)
    .toISOString()
    .slice(0, 16)
    .replace('T', ' ')
  const timeZoneFormattedTime = dayjs(dateString).utc(true).local().format()
  if (billingUnixTime) {
    const timeZoneFormattedBillingTime = timeZoneFormattedTime.substring(0, 10)
    return timeZoneFormattedBillingTime
  }
  return timeZoneFormattedTime
}
export const manipulateDateStringToDateObject = (dateString) => {
  const y = dateString?.toString().substring(0, 4)
  const m = dateString?.toString().substring(5, 7) - 1
  const d = dateString?.toString().substring(8, 10)
  const h = dateString?.toString().substring(11, 13)
  const min = dateString?.toString().substring(14, 16)
  const dateObject = new Date(y, m, d, h, min)
  return dateObject
}
export const isQuizExpired = (endDateTime) => {
  if (endDateTime === null) return false
  return new Date() > manipulateDateStringToDateObject(endDateTime)
}

export const isQuizStarted = (startDateTime) => {
  if (startDateTime === null) return false
  const currentTime = moment()
  const dateToCompare = moment(startDateTime)
  return currentTime > dateToCompare
}
export const htmlToString = (html) => {
  const divContainer = document.createElement('div')
  divContainer.innerHTML = html
  return divContainer.textContent || divContainer.innerText || ''
}
export const isQuizUpcoming = (startDateTime) => {
  if (startDateTime === null) return false
  return new Date() < new Date(startDateTime)
}

export const modifyDateTimeFormat = (dateTime) => {
  return dayjs(dateTime, 'YYYY-MM-DD HH:mm')
    .local()
    .format('Do MMMM YYYY,h:mm A')
}

export const modifyQuizIntervalFormat = (startDateTime, endDateTime) => {
  const splitStartDateTime = startDateTime?.split('T')
  const splitEndDateTime = endDateTime?.split('T')
  if (endDateTime === null && startDateTime === null) return 'No Test Window'
  if (startDateTime === null) {
    return ` Open till ${dayjs(endDateTime, 'YYYY-MM-DD HH:mm')
      .local()
      .format('DD MMMM YYYY,h:mm A')}`
  }
  if (endDateTime === null) {
    return ` Open from ${dayjs(startDateTime)
      .local()
      .format('Do MMMM YYYY, h:mm A')}`
  }

  if (splitEndDateTime?.[0] === splitStartDateTime?.[0]) {
    return ` Open from ${dayjs(startDateTime, 'YYYY-MM-DD HH:mm')
      .local()
      .format('Do MMMM YYYY, h:mm A')} to ${dayjs(endDateTime, 'hh:mm')
      .local()
      .format('h:mm A')}`
  }
  return ` Open from ${dayjs(startDateTime, 'YYYY-MM-DD HH:mm')
    .local()
    .format('Do MMMM YYYY, h:mm A')} to ${dayjs(endDateTime, 'YYYY-MM-DD HH:mm')
    .local()
    .format('Do MMMM YYYY, h:mm A')}`
}

export const validateEmail = (value) => {
  const regex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(value)
}

export const validateEmailDomain = (value) => {
  const regex = /^[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
  return regex.test(value)
}

export const includes = (jsonArray, value) => {
  let hasMatch = false

  for (let index = 0; index < jsonArray.length; ++index) {
    const jsonObject = jsonArray[index]

    if (jsonObject?.language === value) {
      hasMatch = true
      break
    }
  }
  return hasMatch
}

export const secondsToMinutes = (secs) => {
  const minutes = Math.floor(secs / 60)
  let finalTimeFormat = ''
  if (minutes >= 1) {
    finalTimeFormat = `${minutes}:${`0${Math.floor(secs % 60)}`.slice(-2)} min`
  } else {
    finalTimeFormat += `${secs < 0 ? '00:00' : secs} sec`
  }

  return finalTimeFormat
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms))

export const questionsCount = (QuestionArr) => {
  const QuestionArrResult = QuestionArr?.map((queCnt) => {
    if (QuestionArr?.length !== 0) {
      return queCnt.count
    }
    return 0
  })
  return QuestionArrResult?.reduce(
    (total, currentValue) => total + currentValue,
    0
  )
}

export const decodeJwt = (jwtToken) => {
  try {
    const decodedInformation = jwtDecode(jwtToken)
    return decodedInformation
  } catch (error) {
    if (faro && faro.api && faro.api.pushError) {
      faro.api.pushError(error)
    }
    return null
  }
}

export function isFloat(s) {
  // Regex to check for decimal part
  const floatRegex = /^\d*\.\d+$/
  // Check if conversion is successful and the result is not an integer
  return !Number.isNaN(parseFloat(s)) && floatRegex.test(s)
}
