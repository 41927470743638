import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import { Typography } from '@material-ui/core'

const ErrorTag = ({ errorMessage }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid red',
        backgroundColor: '#F8D7D9',
        borderRadius: '4px',
        padding: '4px',
        width: 'max-content',
      }}
      data-testid="ErrorTag"
    >
      <CancelIcon style={{ color: 'red' }} fontSize="small" />
      <Typography style={{ color: 'red', paddingLeft: '4px', fontWeight: 500 }}>
        {errorMessage}
      </Typography>
    </div>
  )
}

export default ErrorTag
