import React from 'react'
import parse from 'html-react-parser'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import GreenTickCircleOutlinedIcon from '../../icons/GreenTickCircleOutlinedIcon'
import RedWrongCircleOutlinedIcon from '../../icons/RedWrongCircleOutlinedIcon'
import GrayThreeDotsCircleOutlinedIcon from '../../icons/GrayThreeDotsCircleOutlinedIcon'
import { useStyles } from './MCQResultRowItemStyles'

function MCQResultRowItem({ question }) {
  const classes = useStyles()
  const isNotAttempted = !question?.result?.selectedChoice
  const isCorrect = question?.result?.isCorrect
  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.iconWrapper}>
        <Grid item className={classes.icon}>
          {isNotAttempted ? <GrayThreeDotsCircleOutlinedIcon /> : null}
          {isCorrect ? <GreenTickCircleOutlinedIcon /> : null}
          {!isCorrect && !isNotAttempted ? (
            <RedWrongCircleOutlinedIcon />
          ) : null}
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.contentWrapper}>
        <Grid item className={classes.questionWrapper}>
          <Grid item className={classes.question}>
            {parse(question?.question)}
          </Grid>
        </Grid>
        <Grid container className={classes.resultWrapper}>
          <Grid
            container
            direction="column"
            className={classes.selectedChoiceWrapper}
          >
            <Typography className={classes.selectedChoiceHeading}>
              Selected Choice
            </Typography>
            <Typography
              className={classes.selectedChoice}
              data-testid="selectedChoice-testId"
            >
              {isNotAttempted ? (
                <>NA</>
              ) : (
                <>{question?.result?.selectedChoice?.join(', ')}</>
              )}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.correctChoiceWrapper}
          >
            <Typography className={classes.correctChoiceHeading}>
              Correct Choice
            </Typography>
            <Typography
              className={classes.correctChoice}
              data-testid="correctChoice-testId"
            >
              {question?.result?.correctChoice?.join(', ')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.marksContainer}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.marksWrapper}
        >
          <Typography className={classes.marks}>
            {question?.result?.marks}/{question?.maximumMarks}
          </Typography>
          <Typography className={classes.marksHeading}>Marks</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MCQResultRowItem
