import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import InputBase from '@material-ui/core/InputBase'
import Box from '@material-ui/core/Box'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { useStyles } from './QuestionStyles'

const Question = () => {
  const classes = useStyles()
  return (
    <Container className={classes.root} data-testid="render">
      <Grid container spacing={2}>
        <Grid item xs={11} className={classes.questionTitle}>
          <Typography variant="h5">Question 1</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.outerColumn}
          container
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <CloseIcon />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex">
            <Box p={1} flexGrow={1}>
              <TextField
                id="outlined-basic"
                value="What is your name?"
                variant="outlined"
                autoComplete="off"
                size="small"
                fullWidth
                className={classes.questionField}
                inputProps={{
                  style: { fontSize: 14, color: 'grey' },
                }}
              />
            </Box>
            <Box p={1} maxWidth="5em">
              <TextField
                id="outlined-basic"
                value="12"
                variant="outlined"
                autoComplete="off"
                size="small"
                fullWidth
                className={classes.questionContainer}
                inputProps={{
                  style: { fontSize: 14, color: 'grey', textAlign: 'center' },
                }}
                onChange
              />
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={1}>
          <TextField
            id="outlined-basic"
            value="1"
            variant="outlined"
            autoComplete="off"
            size="small"
            fullWidth
            style={{ backgroundColor: 'white' }}
            inputProps={{
              style: { fontSize: 14, color: 'grey' },
            }}
            // onChange={(event) => handleEdit(event, 'question')}
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          className={classes.choice}
          style={{
            border: '1px solid lightgrey',
            borderRadius: '5px',
            margin: '1%',
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <Box display="flex">
                <Box p={1} paddingRight="1.5%">
                  <Typography variant="h6" className={classes.options}>
                    A:
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              className={classes.outerColumn}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box display="flex">
                <Box p={2}>
                  <DeleteIcon className={classes.options} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex">
            <Box p={0.5} flexGrow={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display="flex">
                    <Box p={0.5} paddingRight="1.5%">
                      <Typography
                        variant="subtitle1"
                        className={classes.options}
                      >
                        Input:
                      </Typography>
                    </Box>
                    <Box
                      p={0.5}
                      flexGrow={1}
                      className={classes.questionDisplay}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <InputBase
                            defaultValue="Something is happening"
                            autoComplete="off"
                            inputProps={{
                              'aria-label': 'naked',
                              style: {
                                fontSize: 14,
                                color: 'grey',
                                paddingLeft: '1%',
                              },
                            }}
                            size="small"
                            fullWidth
                            // onChange={(event) => handleEdit(event, 'question')}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex">
                    <Box p={0.5} paddingRight="1%">
                      <Typography
                        variant="subtitle"
                        className={classes.options}
                      >
                        Output:
                      </Typography>
                    </Box>
                    <Box
                      p={0.5}
                      flexGrow={1}
                      className={classes.questionDisplay}
                    >
                      <Grid container>
                        <Grid item xs={11}>
                          <InputBase
                            defaultValue="Something is happening"
                            autoComplete="off"
                            inputProps={{
                              'aria-label': 'naked',
                              style: {
                                fontSize: 14,
                                color: 'grey',
                                paddingLeft: '1%',
                              },
                            }}
                            size="small"
                            fullWidth
                            // onChange={(event) => handleEdit(event, 'question')}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* </Grid> */}
            {/* </Grid> */}
          </Box>
          <Grid
            item
            xs={12}
            className={classes.outerColumn}
            container
            direction="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Box display="flex">
              <Box p={1}>
                <Typography className={classes.options}>Marks:</Typography>
              </Box>
              <Box p={1} maxWidth="5em">
                <TextField
                  id="outlined-basic"
                  value="12"
                  // label="Marks:"
                  // variant="outlined"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  className={classes.questMarks}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      color: 'grey',
                      textAlign: 'center',
                    },
                  }}
                  onChange
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.outerColumn}
          container
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button endIcon={<AddIcon />} color="primary">
            Add Choice
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Question
