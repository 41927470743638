import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: '30px',
    '@media only screen and (max-width:815px)': {
      paddingTop: (props) => (props?.publish ? '40px' : ''),
    },
    '@media only screen and (max-width:665px)': {
      paddingTop: (props) => (props?.publish ? '100px' : '50px'),
    },
  },
  paper: {
    marginTop: '16px',
    padding: '24px',
    minHeight: '135px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
  },
  inputTitle: {
    fontSize: '20px',
    fontWeight: 500,
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  descriptionHeader: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  descriptionLength: {
    color: '#7a7878',
  },
}))
export { useStyle }
