import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    paddingBottom: '4px',
  },
  dialogCancelButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },
  },
}))
export { useStyle }
