/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import { QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { FaroErrorBoundary, withFaroProfiler } from '@grafana/faro-react'
import { initializeFaro } from './initialize'
import theme from './theme'
import Router from './router'
import { queryClient } from './Client'
import { BalanceProvider } from './stateManagement/Provider'

function App() {
  useEffect(() => {
    initializeFaro()
  }, [])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <FaroErrorBoundary>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BalanceProvider>
              <Router />
            </BalanceProvider>
          </ThemeProvider>
        </FaroErrorBoundary>
      </QueryClientProvider>
    </>
  )
}

export default withFaroProfiler(App)
