import { createFetcher } from '../../Fetcher/Fetcher'
import {
  MOCK_BASE_URL,
  RESULT_ENDPOINT,
  QUIZ_ENDPOINT,
  ADMIN_ENDPOINT,
} from '../../constant'
import { unixTimeStamp } from '../../utils'

export class Result {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Result(
        MOCK_BASE_URL,
        RESULT_ENDPOINT,
        QUIZ_ENDPOINT,
        ADMIN_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(baseUrl, endpoint, quizendPoint, adminEndPoint, fetcherObj) {
    this.base_url = baseUrl
    this.endpoint = endpoint
    this.quizendPoint = quizendPoint
    this.adminEndPoint = adminEndPoint
    this.fetcher = fetcherObj
  }

  async getResults(quizId, resultPageLimit, resultsPageOffset) {
    const url = `${
      this.base_url + this.adminEndPoint
    }/quiz/${quizId}/responses?limit=${resultPageLimit}&offset=${resultsPageOffset}`
    const response = await this.fetcher.getData(url)
    const resultsCopy = response?.data?.data?.data?.userResponseData
    response.data.data.userResponseData = resultsCopy?.sort(
      (a, b) => b?.evaluatedMarks - a?.evaluatedMarks
    )
    return response
  }

  async getAllResults(quizId) {
    const url = `${this.base_url + this.adminEndPoint}/quiz/${quizId}/responses`
    const response = await this.fetcher.getData(url)
    const resultsCopy = response?.data?.data?.data?.userResponseData
    response.data.data.userResponseData = resultsCopy?.sort(
      (a, b) => b?.evaluatedMarks - a?.evaluatedMarks
    )
    return response
  }

  async getResultSheet(candidateId, quizId) {
    const url = `${this.base_url + this.adminEndPoint}/quiz/${quizId}${
      this.endpoint
    }/${candidateId}`
    const response = await this.fetcher.getData(url)
    const submissionTime = response?.data?.data?.submissionTime
    response.data.data.submissionTime = unixTimeStamp(submissionTime)
    return response
  }

  async geIndividualResult(quizId, reqDataArg) {
    const url = `${this.base_url + this.adminEndPoint}/quiz/${quizId}${
      this.endpoint
    }/${reqDataArg}`
    const response = await this.fetcher.getData(url)
    const submissionTime = response?.data?.data?.submissionTime
    response.data.data.submissionTime = unixTimeStamp(submissionTime)
    return response
  }
}

export const createResult = () => Result.getInstance()
