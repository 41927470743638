import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import {
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { useStyle } from './EditQuizConfigurationsStyles'
import Loader from '../Loader/Loader'
import ConfirmBox from '../common/ConfirmBox'

const AddedPermissionsModal = ({
  isPublished,
  showAddedPermissionsModal,
  quizInfo,
  quizObject,
  isClosed,
  setShowPermissionsModal,
  validPermissions,
  getPermissionsIsLoading,
  getPermissionsIsFetching,
  handleDeleteQuizPermissions,
  openConfirmDialog,
  setOpenConfirmDialog,
  handleUpdateUserPermissions,
  updateRole,
  setUpdateRole,
  tempUpdateRole,
  setTempUpdateRole,
  selectedUserId,
  setSelectedUserId,
  setErrorMessage,
}) => {
  const [permissions, setPermissions] = useState([])
  const classes = useStyle({
    publish: isPublished,
    startTime: quizInfo?.startTime,
    endTime: quizInfo?.endTime,
    isClosed,
  })
  const handleClose = () => {
    return setShowPermissionsModal(false)
  }
  const handleDeleteOne = async (userID, role) => {
    if (role === 'OWNER' && quizObject?.createdBy === userID) {
      setErrorMessage('Access Denied')
      return
    }
    try {
      await handleDeleteQuizPermissions(userID)

      // Update permissions state after deletion
      setPermissions((prevPermissions) =>
        prevPermissions.filter((permission) => permission.userID !== userID)
      )
    } catch (error) {
      console.error('Error in deleting permissions', error)
    }
  }

  const handleRoleChange = (event, userId) => {
    const newRole = event.target.value
    if (newRole === 'OWNER' && quizObject?.createdBy === userId) {
      return
    }
    // Update the temporary state
    setTempUpdateRole((prevRoles) => ({
      ...prevRoles,
      [userId]: newRole,
    }))
    setSelectedUserId(userId)
    setOpenConfirmDialog(true)
    setShowPermissionsModal(false)
  }

  const convertRole = (role) => {
    switch (role) {
      case 'VIEWER':
        return 'Viewer'
      case 'OWNER':
        return 'Owner'
      case 'EDITOR':
        return 'Editor'
      default:
        return role
    }
  }

  useEffect(() => {
    if (validPermissions && validPermissions.userPermissions) {
      const initialRole = {}
      setPermissions(validPermissions.userPermissions)
      validPermissions.userPermissions.forEach((permission) => {
        const convertedRole = convertRole(permission.role)
        initialRole[permission.userID] = convertedRole
      })
      setUpdateRole(initialRole)
    }
  }, [validPermissions])

  const handleUpdatePermissions = (userId) => {
    const email = userId
    const role = tempUpdateRole[userId] // Use temporary state for updates
    handleUpdateUserPermissions(email, role)
  }

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false)
    setShowPermissionsModal(true)
  }

  const renderData = () => {
    if (getPermissionsIsFetching || getPermissionsIsLoading) {
      return (
        <div
          style={{
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'scroll',
            backgroundColor: 'F3F7F7',
          }}
        >
          <Loader size="3rem" />
        </div>
      )
    }
    return (
      <div
        style={{
          height: '300px',
          overflowY: 'scroll',
          backgroundColor: 'F3F7F7',
        }}
      >
        {permissions.length > 0
          ? permissions.map((data, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '8px 14px ',
                  borderBottom: '1px solid #dadce0',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex', width: '16rem' }}>
                    <PersonIcon />

                    <Typography
                      style={{
                        paddingLeft: '8px',
                        flexWrap: 'wrap',
                        wordBreak: 'break-all',
                      }}
                    >
                      {data.userID}
                    </Typography>
                  </div>

                  <Typography style={{ paddingLeft: '4px' }}>
                    <FormControl
                      variant="filled"
                      className={classes.dropdownControl}
                      size="small"
                    >
                      <Select
                        id={data.userID}
                        onChange={(e) => handleRoleChange(e, data?.userID)}
                        value={updateRole[data?.userID] || ''}
                        label="role"
                        classes={{ root: classes.selectValue }}
                      >
                        <MenuItem value="Viewer">Viewer</MenuItem>
                        <MenuItem value="Owner">Owner</MenuItem>
                        <MenuItem value="Editor">Editor</MenuItem>
                      </Select>
                    </FormControl>
                  </Typography>
                  <IconButton
                    color="primary"
                    style={{ marginLeft: '7px', paddingTop: '10px' }}
                    onClick={() => handleDeleteOne(data?.userID, data.role)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))
          : null}
      </div>
    )
  }
  return (
    <div>
      <Dialog
        open={showAddedPermissionsModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            Added Permissions
          </Typography>
          <Typography
            style={{ fontSize: '16px', paddingTop: '8px', maxWidth: '450px' }}
          >
            View the Added permissions for this quiz.
          </Typography>
        </DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2px 14px ',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
            }}
          />
        </div>
        <DialogContent>
          <Paper
            variant="outlined"
            style={{ border: '2px solid #00B7A4', width: '450px' }}
          >
            {renderData()}
          </Paper>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24 24px' }}>
          <Button className={classes.dialogCancelButton} onClick={handleClose}>
            Close
          </Button>{' '}
        </DialogActions>
      </Dialog>

      {!showAddedPermissionsModal && (
        <ConfirmBox
          isOpenDialog={openConfirmDialog}
          handleClose={handleCloseDialog}
          handleClick={() => handleUpdatePermissions(selectedUserId)}
          boxTitle="Update Permission"
          boxDescription="Are you sure you want to update this permission?"
          styles={classes}
          buttonName="Update"
        />
      )}
    </div>
  )
}

export default AddedPermissionsModal
