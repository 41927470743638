/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { Grid, Button } from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { Helmet } from 'react-helmet'
import Loader from '../Loader/Loader'
import {
  useQuizIdGetAll,
  useMyViewCandidatesGetAll,
  useQuizIdGetSortFilter,
} from '../../Common/Queries/LiveCandidatesQuery'
import MultipleCandidatesStream from './MultipleCandidatesStream'
import { modifyQuizIntervalFormat, delay } from '../../utils'
import { styles } from './LiveCandidatesStyles'

function LiveCandidates() {
  const [currentSelectedTab, setCurrentSelectedTab] = useState('defaultView')
  const [totalLiveCandidates, setTotalLiveCandidates] = useState([])
  const [filterStatus, setFilterStatus] = useState('')
  const [pagesArray, setPagesArray] = useState([])
  const [myViewPagesArray, setMyViewPagesArray] = useState([])
  const [sortBy, setsortBy] = useState('')
  const { id } = useParams()
  const [limit] = useState(9)
  const [offset, setOffset] = useState(0)
  const [myViewOffset, setMyViewOffset] = useState(0)
  const [userInputArray, setUserInputArray] = useState([])
  const [currentMyViewCandidates, setCurrentMyViewCandidates] = useState([])
  const [activeUsers, updateActiveUsers] = useState([])
  const [totalLiveCount, updateTotalLiveCount] = useState(0)
  const [timerObject, setTimerObject] = useState({})
  const [watchMultipleStream, updateWatchMultipleStream] = useState(false)
  const classes = styles()
  const location = useLocation()
  const { quizTitle, quizStartTime, quizEndTime } = location.state
  const {
    // data: totalCandidatesData,
    isLoading: isTotalCandidateListLoading,
    isFetching: isTotalCandidateListFetching,
    refetch: totalCandidatesRefetch,
  } = useQuizIdGetAll(id, {
    staleTime: 0,
    enabled: false,
    retry: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    onSuccess: (successData) => {
      setTotalLiveCandidates(successData || [])
      const waitAndRefetch = async () => {
        await delay(5000)
        await totalCandidatesRefetch()
      }
      waitAndRefetch()
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
      if (
        typeof err?.response?.data?.errors[0]?.code === 'string' &&
        err?.response?.status === 404 &&
        err?.response?.data?.errors[0]?.code === 'ENTITY_NOT_FOUND'
      ) {
        setTotalLiveCandidates(() => [])
      }
    },
  })

  const {
    data: candidatesList,
    isLoading: isCandidateListLoading,
    isFetching: isCandidateListFetching,
    refetch: sortFilterRefetch,
  } = useQuizIdGetSortFilter(id, limit, offset, sortBy, filterStatus, {
    staleTime: 0,
    enabled: false,
    retry: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    onSuccess: (successData) => {
      if (successData?.data?.data?.totalCount > 0) {
        setPagesArray(() =>
          new Array(
            Math.ceil(successData?.data?.data?.totalCount / limit)
          ).fill(0)
        )
      } else {
        updateActiveUsers(() => [])
      }
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
      if (
        typeof err?.response?.data?.errors[0]?.code === 'string' &&
        err?.response?.status === 404 &&
        err?.response?.data?.errors[0]?.code === 'ENTITY_NOT_FOUND'
      ) {
        updateActiveUsers(() => [])
      }
    },
  })
  const {
    data: myViewCandidatesList,
    isLoading: isMyViewCandidateListLoading,
    isFetching: isMyViewCandidateListFetching,
    refetch: myViewCandidatesRefetch,
  } = useMyViewCandidatesGetAll(
    id,
    limit,
    myViewOffset,
    filterStatus,
    sortBy,
    userInputArray,
    {
      staleTime: 0,
      enabled: false,
      retry: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (
          typeof err?.response?.data === 'string' &&
          (err?.response?.data?.toLowerCase() === 'non existing token' ||
            err?.response?.data?.toLowerCase() === 'expired token')
        ) {
          localStorage.clear()
        }
        if (
          typeof err?.response?.data?.errors[0]?.code === 'string' &&
          err?.response?.status === 404 &&
          err?.response?.data?.errors[0]?.code === 'ENTITY_NOT_FOUND'
        ) {
          setCurrentMyViewCandidates(() => [])
        }
      },
    }
  )

  const handleChangeFilter = (filterValue) => {
    setFilterStatus(filterValue)
  }
  const handleChangeSort = (sortValue) => {
    setsortBy(sortValue)
  }
  useEffect(() => {
    if (currentSelectedTab !== 'myView') {
      sortFilterRefetch()
    }
  }, [sortBy, filterStatus])
  useEffect(() => {
    if (currentSelectedTab !== 'myView' && userInputArray.length !== 0) {
      totalCandidatesRefetch()
    }
  }, [currentSelectedTab])
  useEffect(() => {
    if (myViewCandidatesList === null) {
      return
    }
    setCurrentMyViewCandidates(myViewCandidatesList?.data?.data?.data || [])
  }, [myViewCandidatesList])
  useEffect(() => {
    sortFilterRefetch()
    totalCandidatesRefetch()
  }, [offset, sortBy, filterStatus])

  useEffect(() => {
    if (candidatesList === null) {
      return
    }
    if (candidatesList) {
      const liveCandidatesObject = {}
      const data = candidatesList?.data?.data?.users
      data?.forEach((candidateItem) => {
        liveCandidatesObject[`${candidateItem?.email}`] = {
          streamCredentials: candidateItem?.streamCredentials,
          tabChangeCount: candidateItem?.tabChangeCount,
          name: candidateItem?.name,
          timer: timerObject?.[candidateItem?.email],
        }
      })
      localStorage.setItem(
        'live_candidates',
        JSON.stringify(liveCandidatesObject)
      )
    }
    updateTotalLiveCount(candidatesList?.data?.data?.totalCount)
    updateActiveUsers(candidatesList?.data?.data?.users || [])
  }, [candidatesList, timerObject])
  const handlePrev = () => {
    setOffset((prevOffset) => {
      return prevOffset - limit >= 0 ? prevOffset - limit : 0
    })
  }
  const handleNext = () => {
    setOffset((prevOffset) => {
      return prevOffset + limit <= totalLiveCount
        ? prevOffset + limit
        : totalLiveCount
    })
  }
  const myViewHandlePrev = () => {
    setMyViewOffset((prevOffset) => {
      return prevOffset - limit >= 0 ? prevOffset - limit : 0
    })
  }
  const myViewHandleNext = () => {
    setMyViewOffset((prevOffset) => {
      return prevOffset + limit <= userInputArray.length
        ? prevOffset + limit
        : userInputArray.length
    })
  }

  useEffect(() => {
    if (totalLiveCandidates?.length) {
      setTimerObject(() => {
        const temp = {}
        totalLiveCandidates?.forEach((item) => {
          temp[item.email] = item?.remainingDuration
        })
        return temp
      })
    }
  }, [totalLiveCandidates, currentSelectedTab])
  useEffect(() => {
    const intervalId = setInterval(() => {
      for (const email in timerObject) {
        if (timerObject[email] > 0) {
          setTimerObject((prev) => ({
            ...prev,
            [email]: prev[email] - 1,
          }))
        } else clearInterval(intervalId)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [timerObject, totalLiveCandidates, currentSelectedTab])
  useEffect(() => {
    setMyViewPagesArray(() =>
      new Array(Math.ceil(userInputArray.length / limit)).fill(0)
    )
  }, [userInputArray])
  useEffect(() => {
    if (
      watchMultipleStream &&
      currentSelectedTab === 'myView' &&
      userInputArray.length > 0
    ) {
      myViewCandidatesRefetch()
      totalCandidatesRefetch()
    }
  }, [userInputArray, filterStatus, sortBy, myViewOffset])
  if (isCandidateListLoading) {
    return (
      <div className={classes.loader}>
        <Loader size="8rem" />
      </div>
    )
  }
  if (watchMultipleStream) {
    return (
      <MultipleCandidatesStream
        totalLiveCandidates={totalLiveCandidates}
        candidatesList={activeUsers}
        isCandidateListFetching={isCandidateListFetching}
        isCandidateListLoading={isCandidateListLoading}
        handleNext={handleNext}
        handlePrevious={handlePrev}
        totalLiveCount={totalLiveCount}
        limit={limit}
        timerObject={timerObject}
        currentMyViewCandidates={currentMyViewCandidates}
        userInputArray={userInputArray}
        setUserInputArray={setUserInputArray}
        handleFilter={handleChangeFilter}
        handleSort={handleChangeSort}
        pagesArray={pagesArray}
        setOffset={setOffset}
        offset={offset}
        myViewHandleNext={myViewHandleNext}
        myViewHandlePrevious={myViewHandlePrev}
        myViewOffset={myViewOffset}
        myViewPagesArray={myViewPagesArray}
        setMyViewOffset={setMyViewOffset}
        isMyViewCandidateListLoading={isMyViewCandidateListLoading}
        isMyViewCandidateListFetching={isMyViewCandidateListFetching}
        currentSelectedTab={currentSelectedTab}
        setCurrentSelectedTab={setCurrentSelectedTab}
        isTotalCandidateListLoading={isTotalCandidateListLoading}
        isTotalCandidateListFetching={isTotalCandidateListFetching}
        totalCandidatesRefetch={totalCandidatesRefetch}
      />
    )
  }
  return (
    <div className={classes.liveCandidatesContainer}>
      <Helmet>
        <title>List Candidates</title>
      </Helmet>
      <div className={classes.title}>
        <p>
          <span>Quiz</span> / Watch Live
        </p>
      </div>
      <div className={classes.liveCandidatesBody}>
        <div className={classes.quizDetail}>
          <p className={classes.quizTitle}>{quizTitle}</p>
          <p className={classes.quizDuration}>
            {modifyQuizIntervalFormat(quizStartTime, quizEndTime)}
          </p>
        </div>
        <div className={classes.candidateCountWrapper}>
          <div className={classes.candidatesCount}>
            <div className={classes.styledPersonIcon}>
              <PersonOutlineIcon className={classes.personIcon} />
            </div>
            <span>{`Total ${
              totalLiveCount || '0'
            } candidates attending the quiz`}</span>
          </div>
          {pagesArray.length > 0 && (
            <Button
              className={classes.watchBtn}
              onClick={() => {
                updateWatchMultipleStream(true)
              }}
            >
              Watch All
            </Button>
          )}
        </div>
        <Grid>
          {totalLiveCandidates?.length > limit && (
            <Button
              className={offset === 0 ? classes.disabledBtn : classes.watchBtn}
              disabled={offset === 0}
              onClick={handlePrev}
            >
              Previous
            </Button>
          )}
          {totalLiveCandidates?.length > limit && (
            <Button
              className={
                pagesArray.length - 1 === offset / limit
                  ? classes.disabledBtn
                  : classes.watchBtn
              }
              disabled={pagesArray.length - 1 === offset / limit}
              onClick={handleNext}
              style={{ float: 'right' }}
            >
              Next
            </Button>
          )}
        </Grid>
        <Grid container className={classes.liveCandidatesList}>
          {activeUsers?.length > 0 && (
            <Grid item container>
              <Grid item xs={9} className={classes.candidateNameHeader}>
                Name
              </Grid>
            </Grid>
          )}
          {activeUsers?.map((candidate) => {
            const candidateStreamCredentials =
              totalLiveCandidates?.length > 0
                ? (totalLiveCandidates?.filter(
                    (item) => item?.email === candidate?.email
                  ))[0]?.streamCredentials
                : ''
            return (
              <Grid
                key={candidate.email}
                item
                container
                alignItems="center"
                className={classes.candidateCard}
              >
                <Grid item xs={9} className={classes.personIconWrapper}>
                  <div className={classes.styledPersonIcon}>
                    <PersonOutlineIcon className={classes.personIcon} />
                  </div>
                  <Typography className={classes.candidateNames}>
                    {candidate.name}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.watchBtnWrapper}>
                  <NavLink
                    to={{
                      pathname: `./${id}/${candidate.email}`,
                      state: {
                        name: candidate?.name,
                        tabChangeCount: candidate?.tabChangeCount,
                        quizTitle,
                        quizStartTime,
                        quizEndTime,
                        streamCredentials: candidateStreamCredentials,
                      },
                    }}
                    className={classes.link}
                  >
                    <Button className={classes.watchBtn}>Watch</Button>
                  </NavLink>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}
export default LiveCandidates
