import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'
import { useStyles } from './HeaderStyles'
import { Refresh } from '../../Common/Queries/RefreshConfigQieries'

const CustomDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle disableTypography {...other}>
      <Typography variant="h3">{children}</Typography>
      {onClose && (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  )
}

const OrgModal = ({ modalState, handleCloseOrgModal }) => {
  const classes = useStyles()
  let tenantStore
  const email =
    JSON.parse(localStorage.getItem('currentOrg'))?.name ??
    JSON.parse(localStorage.getItem('tokenStorage'))?.tenants[0]?.name
  const [storage, setStorage] = useState([])
  const [refreshErrorMessage, setRefreshErrorMessage] = useState('')
  const refreshCall = Refresh({
    onSuccess: (data) => {
      localStorage.setItem('currentOrg', JSON.stringify(tenantStore))
      storage.accessToken = data.data.data.accessToken
      const tenantsArray = storage.tenants?.filter((t) => {
        return t.id !== tenantStore.id
      })
      tenantsArray.unshift(tenantStore)
      storage.tenants = tenantsArray
      localStorage.setItem('tokenStorage', JSON.stringify(storage))
      window.location.href = '/all-quiz'
    },
    onError: () => {
      handleCloseOrgModal()
      setRefreshErrorMessage('Something Went Wrong!!!')
    },
  })
  useEffect(() => {
    let data = localStorage.getItem('tokenStorage')
    data = JSON.parse(data)
    setStorage(data)
  }, [modalState])

  const setOrganisation = async (tenant) => {
    try {
      let data = localStorage.getItem('tokenStorage')
      data = JSON.parse(data)
      const requestBody = {
        tenantID: tenant.id,
        refreshToken: data.refreshToken,
      }
      tenantStore = tenant
      await refreshCall.mutateAsync(requestBody)
    } catch (error) {
      console.error('Error in setting organisation', error)
    }
  }
  return (
    <div>
      <CommonSnackbar
        open={refreshErrorMessage !== ''}
        autoHideDuration={6000}
        severity="error"
        onClose={() => {
          setRefreshErrorMessage('')
        }}
        message={refreshErrorMessage}
      />
      <Dialog
        onClose={handleCloseOrgModal}
        aria-labelledby="customized-dialog-title"
        open={modalState}
      >
        <div className={classes.dialog}>
          <CustomDialogTitle
            dividers
            id="customized-dialog-title"
            onClose={handleCloseOrgModal}
            className={classes.dialogTitle}
          >
            Choose Organisation
          </CustomDialogTitle>
          <DialogContent className={classes.dialogContent}>
            {storage?.tenants?.map((tenant, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: email === tenant.name ? '#E0E0E0' : '',
                  }}
                >
                  <ListItem
                    className={classes.dialogData}
                    button
                    onClick={() => setOrganisation(tenant)}
                  >
                    <ListItemText
                      primary={`${tenant.name}  ${
                        email === tenant.name ? '(Current)' : ''
                      }`}
                    />
                  </ListItem>
                  <Divider />
                </div>
              )
            })}
          </DialogContent>
        </div>
      </Dialog>
    </div>
  )
}

export default OrgModal
