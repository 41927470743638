import React, { Suspense } from 'react'
import { LoginPage } from '@zopsmart/zs-components'
import { Helmet } from 'react-helmet'
import LoginLogo from '../../icons/LoginLogo'
import Loader from '../Loader/Loader'
import useWindowSize from '../../hooks/useWindowSize'
import { useStyles } from './LoginStyles'

const LoginButton = React.lazy(() => import('./loginButton'))

export default function SignInSide({ setReload }) {
  const windowSize = useWindowSize()
  const zoomOut =
    (windowSize?.height < 550 && windowSize?.width > 600) ||
    windowSize?.height < 320
  const classes = useStyles({ zoomOut })
  const handleLoginButton = () => {
    return (
      <>
        <Suspense
          fallback={
            <div className={classes.centerLoader}>
              <Loader size={30} />
            </div>
          }
        >
          <LoginButton setReload={setReload} />
        </Suspense>
      </>
    )
  }
  const handleBackgroundImage = () => {
    return <LoginLogo />
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <LoginPage
        imageAlignment="center"
        backgroundImage={handleBackgroundImage()}
        renderComponent={handleLoginButton()}
        title="Sign In"
        subTitle="Create and conduct quizzes in no time to hire the best tech talent remotely."
      />
    </>
  )
}
