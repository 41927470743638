import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import {
  Grid,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core'
import { validateEmail } from '../../utils/index'
import { useStyle } from './EditQuizConfigurationsStyles'

const PermissionsModal = ({
  isPermissionsOpenDialog,
  setIsPermissionsOpenDialog,
  isPublished,
  quizInfo,
  isClosed,
  role,
  setRole,
  roleForEmail,
  setRoleForEmail,
  handleAddPermissionButton,
}) => {
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const classes = useStyle({
    publish: isPublished,
    startTime: quizInfo?.startTime,
    endTime: quizInfo?.endTime,
    isClosed,
  })

  const handleClose = () => {
    setIsPermissionsOpenDialog(false)
  }

  const handleRoleChange = (event) => {
    setRole(event.target.value)
  }

  const handleAddEmailTextArea = (event) => {
    const email = event.target.value.toLowerCase()
    if (!validateEmail(email)) {
      setInvalidEmail(true)
      setIsButtonDisabled(true)
    } else {
      setInvalidEmail(false)
      setIsButtonDisabled(false)
    }
    setRoleForEmail(email)
  }

  return (
    <div>
      <Dialog
        open={isPermissionsOpenDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            Add Permissions
          </Typography>
          <Typography
            style={{ fontSize: '16px', paddingTop: '8px', maxWidth: '450px' }}
          >
            Please provide the email address and their role to add permissions.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Roles
            </InputLabel>
            <Select
              onChange={(e) => handleRoleChange(e)}
              value={role}
              label="role"
            >
              <MenuItem value="Viewer">Viewer</MenuItem>
              <MenuItem value="Owner">Owner</MenuItem>
              <MenuItem value="Editor">Editor</MenuItem>
            </Select>
          </FormControl>
          <Grid item>
            <Grid item xs={12}>
              <TextField
                placeholder="Enter mail address"
                disabled={
                  (new Date() > quizInfo?.startTime && isPublished) || isClosed
                }
                fullWidth
                multiline
                variant="outlined"
                maxRows={8}
                value={roleForEmail}
                onChange={(e) => handleAddEmailTextArea(e)}
              />
              {invalidEmail && (
                <p style={{ color: 'red' }}>Please enter a valid email</p>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px 24px' }}>
          <Button onClick={handleClose} className={classes.dialogCancelButton}>
            Cancel
          </Button>
          <Button
            onClick={handleAddPermissionButton}
            color="tertiary"
            autoFocus
            disabled={isButtonDisabled}
          >
            Add Permission
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PermissionsModal
