import React, { useEffect, useState, useRef, useCallback } from 'react'
import { CSVLink } from 'react-csv'
import { Grid, Typography, Button } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocation, useParams, NavLink } from 'react-router-dom'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { Helmet } from 'react-helmet'
import GetAppIcon from '@material-ui/icons/GetApp'
import CircularProgress from '@material-ui/core/CircularProgress'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'
import {
  useResultGetQuery,
  useAllResultGetQuery,
  useResultIdGetQueryMutate,
} from '../../Common/Queries/ResultQuery'
import Loader from '../Loader/Loader'
import {
  modifyQuizIntervalFormat,
  modifyDateTimeFormat,
  isQuizExpired,
  unixTimeStamp,
  htmlToString,
} from '../../utils'
import QuizNotFound from '../QuizNotFound/QuizNotFound'
import { useStyles } from './DisplayResultsStyles'
import ResultsPagination from './ResultsPagination'
import { useQuizQuestionGetQueryById } from '../../Common/Queries/QuizQuery'

export default function DisplayResults() {
  const { id } = useParams()
  const [finalHeaders, setFinalHeaders] = useState([])
  const [candidateResultArray, setCandidateResultArray] = useState()
  const [allResults, setAllResults] = useState([])
  const [totalResultsCount, updateTotalResultsCount] = useState(0)
  const [resultsPageOffset, setResultPageOffset] = useState(0)
  const [resultPagesArray, setResultPagesArray] = useState([])
  const [resultPageLimit] = useState(10)
  const [userResults, setUserResults] = useState([])
  const [message, setMessage] = useState('')
  const [truthValue, setTruthValue] = useState(false)

  const [quizDetails, setQuizDetails] = useState({
    quizTitle: '',
    quizStartTime: '',
    quizEndTime: '',
    quizType: '',
    quizId: '',
    isProctored: '',
  })
  const resultsCSVRef = useRef(null)
  const submissionsCSVRef = useRef(null)
  const aboveTabScreenSize = useMediaQuery('(min-width:960px)')
  const classes = useStyles({
    aboveTabScreenSize,
  })
  const location = useLocation()
  const [inviteeCount, setInviteeCount] = useState(0)
  const [, setIsCurrentQuizExpired] = useState(
    isQuizExpired(quizDetails?.quizEndTime)
  )
  const [quizNotFound, setQuizNotFound] = useState(false)
  const [selectedButton, setSelectedButton] = useState('')

  useEffect(() => {
    const jsonParsedQuizList = JSON.parse(localStorage.getItem('quizList'))
    if (location?.state) {
      setQuizDetails((quizDetailsPrev) => {
        return {
          ...quizDetailsPrev,
          quizTitle: location?.state?.quizTitle,
          quizStartTime: location?.state?.quizStartTime,
          quizEndTime: location?.state?.quizEndTime,
          quizType: location?.state?.quizType,
          quizId: location?.state?.quizId,
          isProctored: location?.state?.isProctored,
          isClosed: location?.state?.isClosed,
        }
      })
    } else if (jsonParsedQuizList) {
      const filteredQuiz = jsonParsedQuizList?.filter((quizItem) => {
        return `${quizItem?.id}` === id
      })?.[0]
      if (filteredQuiz) {
        setQuizDetails((quizDetailsPrev) => {
          return {
            ...quizDetailsPrev,
            quizTitle: filteredQuiz?.info?.title,
            quizStartTime: filteredQuiz?.time?.start,
            quizEndTime: filteredQuiz?.time?.end,
            quizType: filteredQuiz?.quizConfig?.type,
            quizId: filteredQuiz?.id,
            isProctored: filteredQuiz?.quizConfig?.isProctored,
            isClosed: filteredQuiz?.info?.isClosed,
          }
        })
      }
    }
  }, [location])

  const emails = []

  useEffect(() => {
    const interval = setInterval(
      () => setIsCurrentQuizExpired(isQuizExpired(quizDetails?.quizEndTime)),
      1000
    )
    return () => {
      clearInterval(interval)
    }
  }, [])

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email ID', key: 'emailID' },
    { label: 'Start time', key: 'startTime' },
    { label: 'End time', key: 'endTime' },
    { label: 'Submission time', key: 'submissionTime' },
    { label: 'Marks', key: 'marks' },
    { label: 'Tab Change Count', key: 'tabChangeCount' },
  ]

  const resultOne = useResultIdGetQueryMutate(id, {
    enabled: true,
    retry: false,
    staleTime: 0,
  })
  const {
    data: resultsData,
    isSuccess: isCandidateListSuccess,
    isLoading: isCandidateListLoading,
    isFetching: isCandidateListFetching,
  } = useResultGetQuery(id, resultPageLimit, resultsPageOffset, {
    stale: 0,
    refetchOnWindowFocus: false,
    refetchOnREconnect: false,
    onSuccess: (successResponse) => {
      updateTotalResultsCount(() => successResponse?.data?.data?.totalCount)
      if (successResponse?.data?.data?.totalCount) {
        setResultPagesArray(() =>
          new Array(
            Math.ceil(successResponse?.data?.data?.totalCount / resultPageLimit)
          ).fill(0)
        )
      }
      setCandidateResultArray(successResponse?.data?.data?.responses || [])
      setInviteeCount(() => successResponse?.data?.data?.inviteeCount)
    },

    onError: (err) => {
      if (
        (err?.response?.status === 404 &&
          typeof err?.response?.data?.errors?.[0]?.code === 'string' &&
          err?.response?.data?.errors?.[0]?.code === 'ENTITY_NOT_FOUND' &&
          typeof err?.response?.data?.errors?.[0]?.reason === 'string' &&
          err?.response?.data?.errors?.[0]?.reason?.toLowerCase() ===
            'quiz not found') ||
        (err?.response?.status === 400 &&
          typeof err?.response?.data?.errors?.[0]?.code === 'string' &&
          err?.response?.data?.errors?.[0]?.code === 'BAD_REQUEST' &&
          typeof err?.response?.data?.errors?.[0]?.reason === 'string' &&
          err?.response?.data?.errors?.[0]?.reason?.toLowerCase() ===
            'quiz id is invalid')
      ) {
        setQuizNotFound(true)
      }
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  const calculateSubmissionTime = (startTime, submissionTime) => {
    if (startTime) {
      if (!submissionTime) return 'Not Submitted'
      const duration = new Date(submissionTime) - new Date(startTime)
      const hours = Math.floor(duration / 3600) % 24
      const minutes = Math.floor(duration / 60) % 60
      const seconds = duration % 60
      let durationInHMSformat = ''
      if (hours) {
        durationInHMSformat += `${hours} Hrs `
      }
      if (minutes) {
        durationInHMSformat += `${minutes} Mins `
      }
      if (seconds) {
        durationInHMSformat += `${seconds} Secs `
      }
      return durationInHMSformat
    }
    return 'Not Started'
  }

  const { refetch: refetchQuizQuestionById } = useQuizQuestionGetQueryById(id, {
    staleTime: 0,
    enabled: false,
    retry: false,
    onSuccess: (successData) => {
      let result = []
      const questionHeaders = []
      successData.mcq?.map((question, index) => {
        const questionObj = {
          label: `${htmlToString(question?.question)} (${question?.type})`,
          key: `mcqQuestion_${index}`,
        }
        const marksObj = {
          label: 'score',
          key: `mcqScore_${index}`,
        }
        questionHeaders.push(questionObj)
        questionHeaders.push(marksObj)
        return ''
      })
      successData.coding?.map((question, index) => {
        const questionObj = {
          label: `${htmlToString(question?.question)} (${question?.type})`,
          key: `codingQuestion_${index}`,
        }
        const marksObj = {
          label: 'score',
          key: `codingScore_${index}`,
        }
        questionHeaders.push(questionObj)
        questionHeaders.push(marksObj)
        return ''
      })
      successData.essay?.map((question, index) => {
        const questionObj = {
          label: `${htmlToString(question?.question)} (${question?.type})`,
          key: `essayQuestion_${index}`,
        }
        const marksObj = {
          label: 'score',
          key: `essayScore_${index}`,
        }
        questionHeaders.push(questionObj)
        questionHeaders.push(marksObj)
        return ''
      })
      successData.fillInBlanks?.map((question, index) => {
        const questionObj = {
          label: `${htmlToString(question?.question)} (${question?.type})`,
          key: `fillInBlanksQuestion_${index}`,
        }
        const marksObj = {
          label: 'score',
          key: `fillInBlanksScore_${index}`,
        }
        questionHeaders.push(questionObj)
        questionHeaders.push(marksObj)
        return ''
      })
      result = [...headers, ...questionHeaders]
      setFinalHeaders(result)
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  useEffect(() => {
    refetchQuizQuestionById()
  }, [])

  const replaceDoubleQuotes = (str) => {
    if (typeof str === 'string') {
      // eslint-disable-next-line quotes
      return str.replace(/"/g, "'")
    }
    return str
  }
  const convertArrayToOBject = (userIdentifier) => {
    const submissions = {}
    const userSubmissions = userResults.find(
      (csvData) => csvData.userIdentifier === userIdentifier
    )
    userSubmissions?.evaluatedResult.mcq?.map((question, index) => {
      submissions[`mcqQuestion_${index}`] = question.result.selectedChoice
      submissions[`mcqScore_${index}`] = question.result.marks
      return ''
    })
    userSubmissions?.evaluatedResult.coding?.map((question, index) => {
      submissions[`codingQuestion_${index}`] = replaceDoubleQuotes(
        question.code
      )
      submissions[`codingScore_${index}`] = question.result.marks
      return ''
    })
    userSubmissions?.evaluatedResult.essay?.map((question, index) => {
      submissions[`essayQuestion_${index}`] = question.result.userResponse
      submissions[`essayScore_${index}`] = question.result.marks
      return ''
    })
    userSubmissions?.evaluatedResult.fillInBlanks?.map((question, index) => {
      submissions[`fillInBlanksQuestion_${index}`] =
        question.result.userResponse
      submissions[`fillInBlanksScore_${index}`] = question.result.marks
      return ''
    })
    return submissions
  }

  const getSubmissionsCsvData = () => {
    const copyDataCsv = (allResults || [])?.map((candidateResultInfo) => {
      const copyCandidateResultInfo = JSON.parse(
        JSON.stringify(candidateResultInfo)
      )
      const userSubmissions = convertArrayToOBject(
        candidateResultInfo?.userIdentifier
      )
      const data = {
        name: copyCandidateResultInfo?.username,
        emailID:
          quizDetails?.quizType?.toLowerCase() === 'public'
            ? 'N/A'
            : copyCandidateResultInfo?.userIdentifier,
        startTime: modifyDateTimeFormat(
          unixTimeStamp(copyCandidateResultInfo?.activeUserTime?.startTime)
        ),
        endTime: copyCandidateResultInfo?.activeUserTime?.submissionTime
          ? modifyDateTimeFormat(
              unixTimeStamp(
                copyCandidateResultInfo?.activeUserTime?.submissionTime
              )
            )
          : 'Not Submitted',
        submissionTime:
          copyCandidateResultInfo?.activeUserTime?.submissionTime === null
            ? 'Not Submitted'
            : calculateSubmissionTime(
                copyCandidateResultInfo?.activeUserTime?.startTime,
                copyCandidateResultInfo?.activeUserTime?.submissionTime
              ),
        marks: copyCandidateResultInfo?.evaluatedMarks,
        tabChangeCount: copyCandidateResultInfo?.tabChangeCount,
        ...userSubmissions,
      }
      return data
    })
    return copyDataCsv
  }

  const getResultsCsvData = () => {
    const copyDataCsv = (allResults || [])?.map((candidateResultInfo) => {
      const copyCandidateResultInfo = JSON.parse(
        JSON.stringify(candidateResultInfo)
      )
      return {
        name: copyCandidateResultInfo?.username,
        emailID:
          quizDetails?.quizType?.toLowerCase() === 'public'
            ? 'N/A'
            : copyCandidateResultInfo?.userIdentifier,
        startTime: modifyDateTimeFormat(
          unixTimeStamp(copyCandidateResultInfo?.activeUserTime?.startTime)
        ),
        endTime: copyCandidateResultInfo?.activeUserTime?.submissionTime
          ? modifyDateTimeFormat(
              unixTimeStamp(
                copyCandidateResultInfo?.activeUserTime?.submissionTime
              )
            )
          : 'Not Submitted',
        submissionTime:
          copyCandidateResultInfo?.activeUserTime?.submissionTime === null
            ? 'Not Submitted'
            : calculateSubmissionTime(
                copyCandidateResultInfo?.activeUserTime?.startTime,
                copyCandidateResultInfo?.activeUserTime?.submissionTime
              ),
        marks: copyCandidateResultInfo?.evaluatedMarks,
        tabChangeCount: copyCandidateResultInfo?.tabChangeCount,
      }
    })
    return copyDataCsv
  }
  useEffect(() => {
    if (userResults?.length > 0) {
      const copyDataCsv = getSubmissionsCsvData()
      if (
        copyDataCsv.length > 0 &&
        copyDataCsv[0]?.mcqQuestion !== 'mcqQuestion'
      ) {
        submissionsCSVRef.current.link.click()
        setTruthValue(true)
        setMessage('File Downloaded Succesfully !!')
      }
    }
  }, [userResults])

  const handleAllData = async (emailsData) => {
    try {
      const resultPromise = await Promise.allSettled(
        emailsData.map(async (userIdentifier) =>
          resultOne.mutateAsync(userIdentifier)
        )
      )
      const userObject = resultPromise.map((promise) => {
        if (promise.status === 'rejected')
          throw new Error('Something unexpected happened !!')
        return promise?.value?.data?.data
      })
      setUserResults(userObject)
    } catch (error) {
      setMessage(error?.message || 'Something went wrong !!')
    }
  }
  const {
    isLoading: isAllResultsLoading,
    isSuccess: isAllResultsSuccess,
    isFetching: isAllResultsFetching,
    refetch: allResultsRefetch,
    data: allResultsData,
  } = useAllResultGetQuery(id, {
    stale: 0,
    refetchOnWindowFocus: false,
    refetchOnREconnect: false,
    enabled: false,
    onSuccess: (successResponse) => {
      setAllResults(() => successResponse?.data?.data?.responses)
      if (selectedButton === 'submissions') {
        successResponse?.data?.data?.responses?.forEach((element) => {
          emails.push(element?.userIdentifier)
        })
        handleAllData(emails)
      }
    },
    onError: () => {
      setMessage('something went wrong !!')
    },
  })

  const handleDownloads = useCallback(async () => {
    await allResultsRefetch()
  }, [allResultsRefetch])

  const handlePrev = () => {
    setResultPageOffset((prevOffset) => {
      return prevOffset - resultPageLimit >= 0
        ? prevOffset - resultPageLimit
        : 0
    })
  }

  const handleNext = () => {
    setResultPageOffset((prevOffset) => {
      return prevOffset + resultPageLimit <= totalResultsCount
        ? prevOffset + resultPageLimit
        : totalResultsCount
    })
  }

  useEffect(() => {
    if (
      selectedButton === 'results' &&
      isAllResultsSuccess &&
      allResultsData?.data?.data?.responses?.length > 0
    ) {
      const copyDataCsv = getResultsCsvData()
      if (copyDataCsv.length > 0 && copyDataCsv[0]?.name !== 'name') {
        resultsCSVRef.current.link.click()
        setTruthValue(true)
        setMessage('File Downloaded Succesfully !!')
      }
    }
  }, [isAllResultsSuccess, allResultsData])

  if (quizNotFound) {
    return <QuizNotFound reason="Quiz not found" />
  }

  const handleTotalAttempteeMessage = () => {
    if (inviteeCount) {
      return `Total ${totalResultsCount}/${inviteeCount} candidates attended the quiz`
    }
    return `Total ${totalResultsCount || 0} candidates attended the quiz`
  }

  return (
    <div className={classes.resultListContainer}>
      <Helmet>
        <title>Result List</title>
      </Helmet>
      <CommonSnackbar
        open={message !== ''}
        autoHideDuration={6000}
        onClose={() => {
          setMessage('')
          setTimeout(() => setTruthValue(false), 100)
        }}
        severity={truthValue ? 'success' : 'error'}
        message={message}
      />

      <div className={classes.title}>
        <p>
          <span className={classes.quizResultsTitle}>
            {quizDetails?.quizTitle !== undefined ? quizDetails?.quizTitle : ''}{' '}
            Results
          </span>
        </p>
      </div>
      <div className={classes.resultListBody}>
        <div className={classes.quizDetail}>
          <div className={classes.quizDurationWrapper}>
            <p className={classes.quizDuration}>
              {modifyQuizIntervalFormat(
                quizDetails?.quizStartTime,
                quizDetails?.quizEndTime
              )}
            </p>
          </div>
          <div className={classes.downloadBtnWrapper}>
            <div className={classes.ctaWrapper}>
              {resultsData?.data?.data?.totalCount ? (
                <Button
                  className={classes.downloadResultBtn}
                  onClick={() => {
                    setSelectedButton('results')
                    handleDownloads()
                  }}
                  startIcon={
                    (resultOne?.isLoading ||
                      isAllResultsFetching ||
                      isAllResultsLoading ||
                      isCandidateListFetching ||
                      isCandidateListLoading) &&
                    selectedButton === 'results' ? (
                      <></>
                    ) : (
                      <GetAppIcon />
                    )
                  }
                  data-testid="downloadResult-testId"
                  disabled={
                    resultOne?.isLoading ||
                    isAllResultsFetching ||
                    isAllResultsLoading ||
                    isCandidateListFetching ||
                    isCandidateListLoading
                  }
                >
                  {(resultOne?.isLoading ||
                    isAllResultsFetching ||
                    isAllResultsLoading ||
                    isCandidateListFetching ||
                    isCandidateListLoading) &&
                  selectedButton === 'results' ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    'Download Results'
                  )}
                </Button>
              ) : (
                <></>
              )}
              <CSVLink
                ref={resultsCSVRef}
                data={getResultsCsvData()}
                headers={headers}
                filename={`${quizDetails?.quizTitle}-Results.csv`}
                className={classes.csvLink}
              />
            </div>
            <div className={classes.ctaWrapper}>
              {resultsData?.data?.data?.totalCount ? (
                <Button
                  className={classes.downloadSubmissionBtn}
                  onClick={() => {
                    setSelectedButton('submissions')
                    handleDownloads()
                  }}
                  startIcon={
                    (resultOne?.isLoading ||
                      isAllResultsFetching ||
                      isAllResultsLoading ||
                      isCandidateListFetching ||
                      isCandidateListLoading) &&
                    selectedButton === 'submissions' ? (
                      <></>
                    ) : (
                      <GetAppIcon />
                    )
                  }
                  data-testid="downloadResult-testId"
                  disabled={
                    resultOne?.isLoading ||
                    isAllResultsFetching ||
                    isAllResultsLoading ||
                    isCandidateListFetching ||
                    isCandidateListLoading
                  }
                >
                  {(resultOne?.isLoading ||
                    isAllResultsFetching ||
                    isAllResultsLoading ||
                    isCandidateListFetching ||
                    isCandidateListLoading) &&
                  selectedButton === 'submissions' ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    ' Download Submissions'
                  )}
                </Button>
              ) : (
                <></>
              )}
              <CSVLink
                ref={submissionsCSVRef}
                data={getSubmissionsCsvData()}
                headers={finalHeaders}
                filename={`${quizDetails?.quizTitle}-Submissions.csv`}
                className={classes.csvLink}
              />
            </div>
          </div>
        </div>
        <div className={classes.candidateCountWrapper}>
          <div className={classes.candidatesCount}>
            <div className={classes.styledPersonIcon}>
              <PersonOutlineIcon className={classes.personIcon} />
            </div>
            <span>{handleTotalAttempteeMessage()}</span>
          </div>
        </div>
        {(isCandidateListLoading || isCandidateListFetching) && (
          <div className={classes.resultListLoader}>
            <Loader size="6rem" />
          </div>
        )}
        {isCandidateListSuccess && resultsData?.data?.data?.totalCount ? (
          <Grid container>
            {aboveTabScreenSize && candidateResultArray?.length > 0 && (
              <Grid item container>
                <Grid item md={3}>
                  Name
                </Grid>
                <Grid item md={3}>
                  Submission Time
                </Grid>
                <Grid item md={2}>
                  Marks
                </Grid>
                <Grid item md={2}>
                  Tab Change
                </Grid>
                <div className={classes.resultRowHeader}>Results</div>
              </Grid>
            )}
            {candidateResultArray?.map((candidate) => {
              return (
                <Grid
                  key={candidate?.userIdentifier}
                  item
                  container
                  alignItems="center"
                  className={classes.candidateCard}
                >
                  <Grid item xs={12} md={3} container alignItems="center">
                    <div className={classes.name}>
                      <Grid className={classes.styledPersonIcon}>
                        <PersonOutlineIcon className={classes.personIcon} />
                      </Grid>

                      <Typography className={classes.candidateName}>
                        {candidate?.username}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} container direction="column">
                    {!aboveTabScreenSize && (
                      <Typography className={classes.boldText}>
                        Submission Time
                      </Typography>
                    )}

                    {candidate?.activeUserTime?.submissionTime === null
                      ? 'Not Submitted'
                      : calculateSubmissionTime(
                          candidate?.activeUserTime?.startTime,
                          candidate?.activeUserTime?.submissionTime
                        )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={2}
                    container
                    direction="column"
                    style={{
                      color: candidate?.evaluatedMarks >= 0 ? '' : 'red',
                    }}
                  >
                    {!aboveTabScreenSize && (
                      <Typography
                        className={classes.boldText}
                        style={{
                          color: candidate?.evaluatedMarks ? '' : 'black',
                        }}
                      >
                        Marks
                      </Typography>
                    )}
                    {candidate?.evaluatedMarks >= 0
                      ? candidate?.evaluatedMarks
                      : 'Evaluating'}
                  </Grid>
                  <Grid item xs={3} md={1}>
                    {!aboveTabScreenSize && (
                      <Typography className={classes.boldText}>
                        Tab Count
                      </Typography>
                    )}
                    {candidate?.tabChangeCount ? candidate.tabChangeCount : 0}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={3}
                    justifyContent={!aboveTabScreenSize ? 'flex-end' : 'center'}
                    className={classes.resultBtnContainer}
                  >
                    <NavLink
                      to={`/quiz/${id}/response/${candidate?.userIdentifier}`}
                      className={classes.link}
                    >
                      <Button className={classes.resultBtn}>Results</Button>
                    </NavLink>
                  </Grid>
                </Grid>
              )
            })}
            <div className={classes.resultsPaginationDiv}>
              <ResultsPagination
                className={classes.pagination}
                totalResultsCount={totalResultsCount}
                handleNext={handleNext}
                handlePrevious={handlePrev}
                resultsPageOffset={resultsPageOffset}
                resultPagesArray={resultPagesArray}
                resultPageLimit={resultPageLimit}
                setResultPageOffset={setResultPageOffset}
              />
            </div>
          </Grid>
        ) : (
          (!isCandidateListLoading || !isCandidateListFetching) && (
            <div>
              <Typography className={classes.resultListsMsg}>
                No results found
              </Typography>{' '}
            </div>
          )
        )}
      </div>
    </div>
  )
}
