import { createFetcher } from '../../Fetcher/Fetcher'
import {
  MOCK_BASE_URL,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  ADMIN_ENDPOINT,
  REFRESH_ENDPOINT,
} from '../../constant'
// import { unixTimeStamp } from '../../utils'

/**
 * Login to account.
 * @Applications
 */
export default class Login {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Login(
        MOCK_BASE_URL,
        LOGIN_ENDPOINT,
        LOGOUT_ENDPOINT,
        ADMIN_ENDPOINT,
        createFetcher(),
        REFRESH_ENDPOINT
      )
    }
    return this.instance
  }

  constructor(
    baseUrl,
    LoginEndpoint,
    LogoutEndpoint,
    adminEndPoint,
    fetcherObj,
    RefreshEndpoint
  ) {
    this.base_url = baseUrl
    this.LoginEndpoint = LoginEndpoint
    this.LogoutEndpoint = LogoutEndpoint
    this.adminEndPoint = adminEndPoint
    this.fetcher = fetcherObj
    this.RefreshEndpoint = RefreshEndpoint
  }
  /**
   * this function is to get all applications
   * @function postQuizById
   ** @param {reqParam} default parameter
   */

  async postLogin(reqData) {
    const url = `${this.base_url + this.adminEndPoint + this.LoginEndpoint}`
    const response = await this.fetcher.postData(url, reqData)
    // response.data.data.createdAt = unixTimeStamp(
    //   response?.data?.data?.createdAt
    // )
    // response.data.data.loggedAt = unixTimeStamp(response?.data?.data?.loggedAt)
    return response
  }

  async postLogout(reqData) {
    const url = `${this.base_url + this.adminEndPoint + this.LogoutEndpoint}`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }

  async postRefresh(reqData) {
    const url = `${this.base_url + this.RefreshEndpoint}`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }
}
