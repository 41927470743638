import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  logo: {
    fontWeight: 600,
  },
  quizzing: {
    color: theme?.palette?.primarySelection?.main,
  },
  pro: {
    color: theme?.palette?.lightTextColor?.main,
  },
}))
export { useStyles }
