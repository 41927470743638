import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, withRouter, Redirect } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce'
import { InputAdornment, TextField, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import { Helmet } from 'react-helmet'
import { useQueryClient } from 'react-query'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { isLogin, questionsCount } from '../../utils'
import { ReactComponent as SearchQuiz } from '../../icons/SearchQuiz.svg'
import InfoCard from './InfoCard'
import QuizCard from './QuizCard'
import {
  useQuizDeleteQueryById,
  useQuizGetQueryById,
  useQuizQuestionGetQueryById,
  useQuizPostQuery,
  useQuizPatchById,
  useQuizIdGetQuizPagination,
  useGetUserDetails,
} from '../../Common/Queries/QuizQuery'
import { useQuestionMappingPostQueryById } from '../../Common/Queries/QuestionQuery'
import TotalQuizSvg from '../../images/create.svg'
import { DialogActions, DialogContent, DialogTitle } from './CustomDialog'
import Loader from '../Loader/Loader'
import { useStyles } from './DashboardStyles'
import QuizPagination from './QuizPagination'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'
import ConfirmBox from '../common/ConfirmBox'

const Dashboard = () => {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()
  const userEmail = localStorage.getItem('email')
  const [quizList, setQuizList] = useState(null)
  const [quizId, setQuizId] = useState(-1)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [loaderId, setLoaderId] = useState(-2)
  const [endQuizLoaderId, setEndQuizLoaderId] = useState(-2)
  const [openCreateQuizDialog, setOpenCreateQuizDialog] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [duplicateQuizId, setDuplicateQuizId] = useState('')
  const [createDuplicateQuiz, setCreateDuplicateQuiz] = useState(false)
  const [quizPagesArray, setQuizPagesArray] = useState([])
  const [quizPageLimit] = useState(8)
  const [quizPageOffset, setQuizPageOffset] = useState(0)
  const [quizCount, updateQuizCount] = useState(0)
  const [quizType, setQuizType] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [message, setMessage] = useState('')
  const [truthValue, setTruthValue] = useState(false)

  const postQuiz = useQuizPostQuery({
    staleTime: 0,
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  const handleTopFilters = (value) => {
    switch (value) {
      case '':
      case 'ongoing':
      case 'upcoming':
      case 'completed':
        setFilter(value)
        break
      default:
        setFilter('')
        break
    }
  }

  const deleteQuiz = useQuizDeleteQueryById(quizId, userEmail, {
    staleTime: 0,
    onSuccess: () => {
      queryClient.invalidateQueries('quizPagination')
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const getUserDetails = useGetUserDetails({
    enabled: false,
    onSuccess: (successData) => {
      localStorage.setItem('id', successData?.id)
      localStorage.setItem('creditBalance', successData?.creditBalance)
    },
    onError: () => {
      // setOpenSnackbar(() => true)
    },
  })
  useEffect(() => {
    if (localStorage.getItem('isLoggedIn')) {
      getUserDetails.refetch()
    }
  }, [localStorage.getItem('isLoggedIn')])

  const { refetch: refetchQuizById } = useQuizGetQueryById(
    duplicateQuizId,
    userEmail,
    {
      staleTime: 0,
      enabled: false,
      retry: false,
      onSuccess: (response) => {
        setCreateDuplicateQuiz(true)
        const quizData = {
          info: {
            title: response?.info?.title ? response?.info?.title : '',
            slug: response?.info?.slug ? response?.info?.slug : '',
            description: response?.info?.description
              ? response?.info?.description
              : '',
            showResult: response?.showResult ? response?.showResult : '',
            emailDomainCount: response?.emailDomainCount
              ? response?.emailDomainCount
              : '',
            instructions: response?.info?.instructions
              ? response?.info?.instructions
              : '',
          },
          time: {
            start: null,
            end: null,
            duration: response?.time?.duration ? response?.time?.duration : 60,
          },
          quizConfig: {
            showResult: response?.quizConfig?.showResult
              ? response?.quizConfig?.showResult
              : false,
            isProctored: response?.quizConfig?.isProctored
              ? response?.quizConfig?.isProctored
              : false,
            isRandomize: response?.quizConfig?.isRandomize
              ? response?.quizConfig.isRandomize
              : false,
            type: response?.quizConfig?.type ? response?.quizConfig?.type : '',
          },
        }

        postQuiz.mutate(quizData)
      },
      onError: (err) => {
        if (
          typeof err?.response?.data === 'string' &&
          (err?.response?.data?.toLowerCase() === 'non existing token' ||
            err?.response?.data?.toLowerCase() === 'expired token')
        ) {
          localStorage.clear()
        }
      },
    }
  )
  const { data: quizQuestionById, refetch: refetchQuizQuestionById } =
    useQuizQuestionGetQueryById(duplicateQuizId, {
      staleTime: 0,
      enabled: false,
      retry: false,
      onSuccess: () => {
        setCreateDuplicateQuiz(true)
      },
      onError: (err) => {
        if (
          typeof err?.response?.data === 'string' &&
          (err?.response?.data?.toLowerCase() === 'non existing token' ||
            err?.response?.data?.toLowerCase() === 'expired token')
        ) {
          localStorage.clear()
        }
      },
    })

  const postQuestionMapping = useQuestionMappingPostQueryById(quizId, {
    staleTime: 0,
    retry: false,
    onSuccess: async () => {
      // await refetchQuizQuestionById()
      queryClient.invalidateQueries('quizGetQuizQuestionQueryById')
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const updateQuiz = useQuizPatchById(quizId, {
    retry: false,
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
    onSuccess: () => {
      if (endQuizLoaderId !== -2) {
        setMessage('Quiz has been ended !!')
        setTruthValue(true)
        setEndQuizLoaderId(null)
        setQuizList((quizListPrev) => {
          const copyQuizListPrev = JSON.parse(JSON.stringify(quizListPrev))
          return copyQuizListPrev?.map((quizItem) => {
            if (quizItem?.id === quizId && !quizItem?.isClosed) {
              const copyQuizItem = JSON.parse(JSON.stringify(quizItem))
              copyQuizItem.info.isClosed = true
              copyQuizItem.time.end = new Date().toISOString()
              return copyQuizItem
            }
            return quizItem
          })
        })
      }
    },
  })

  const {
    // data: quizById,
    refetch: refetchQuizByIdEndQuiz,
    // isSuccess: isQuizGetByIdSuccess,
  } = useQuizGetQueryById(quizId, userEmail, {
    staleTime: 0,
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      const obj = {
        info: {
          title: response?.info?.title,
          description: response?.info?.description,
          instructions: response?.info?.instructions,
          status: 'CLOSED',
          inviteesCount: response?.info?.inviteesCount,
          emailDomainCount: response?.info?.emailDomainCount,
        },
        id: response?.id,
        time: {
          start: response?.time?.start
            ? Math.floor(new Date(response?.time?.start).getTime() / 1000)
            : response?.time?.start,
          end: response?.time?.end
            ? Math.floor(new Date(response?.time?.end).getTime() / 1000)
            : null,
          duration: response?.time?.duration,
        },
        quizConfig: {
          isProctored: response?.quizConfig?.isProctored
            ? response?.quizConfig?.isProctored
            : false,
          isRandomize: response?.quizConfig?.isRandomize
            ? response?.quizConfig?.isRandomize
            : false,
          showResult: response?.quizConfig?.showResult
            ? response?.quizConfig?.showResult
            : false,
          type: response?.quizConfig?.type,
        },
      }
      updateQuiz.mutate(obj)
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  const {
    data: candidatesList,
    isError: isPaginatedQuizzError,
    refetch: paginationRefetch,
    isLoading: isQuizListLoading,
    isSuccess: isQuizListSuccess,
    isFetching: isQuizListFetching,
  } = useQuizIdGetQuizPagination(
    quizType,
    filter,
    quizPageLimit,
    quizPageOffset,
    searchValue,
    {
      staleTime: 0,
      enabled: false,
      retry: 1,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      onSuccess: (successData) => {
        updateQuizCount(() => successData?.data?.data?.currentQuizCount)
        if (successData?.data?.data?.currentQuizCount) {
          setQuizPagesArray(() =>
            new Array(
              Math.ceil(
                successData?.data?.data?.currentQuizCount / quizPageLimit
              )
            ).fill(0)
          )
        }
        setQuizList(successData?.data?.data?.quizzes)
      },
    }
  )

  useEffect(() => {
    if (deleteQuiz.isSuccess) {
      setMessage('Quiz deleted successfully')
      setTruthValue(true)
      const newItem = quizList?.filter((row) => row.id !== quizId)
      setQuizList(newItem)
      paginationRefetch()
    }
  }, [deleteQuiz.isSuccess])

  useEffect(() => {
    if (isPaginatedQuizzError) {
      setLoading(false)
    }
  }, [isPaginatedQuizzError])

  const handleDuplicateQuiz = (id) => {
    if (createDuplicateQuiz && id > 0) {
      const questionCount =
        (quizQuestionById?.questionCount?.[0]?.count || 0) +
        (quizQuestionById?.questionCount?.[1]?.count || 0) +
        (quizQuestionById?.questionCount?.[2]?.count || 0) +
        (quizQuestionById?.questionCount?.[3]?.count || 0)
      for (let i = 0; i < questionCount; i++) {
        postQuestionMapping.mutate({
          quizId: id,
          questionId: quizQuestionById?.questions[i]?.id,
          type:
            quizQuestionById?.questions[i]?.type === 'fillInBlanks'
              ? 'fill-in-blanks'
              : quizQuestionById?.questions[i]?.type,
        })
      }
      history.push(`/edit/${id}`)
      setCreateDuplicateQuiz(false)
    }
  }

  useEffect(() => {
    if (postQuiz.isSuccess) {
      const id = postQuiz?.data?.data?.data?.quiz?.id
      if (createDuplicateQuiz) {
        setQuizId(id)
        handleDuplicateQuiz(id)
      } else {
        history.push(`./edit/${id}`)
      }
    }
  }, [postQuiz.isSuccess])

  useEffect(() => {
    if (postQuiz.isError) {
      setCreateDuplicateQuiz(false)
    }
  }, [postQuiz.isError])

  useEffect(() => {
    if (deleteQuiz.isLoading) {
      setLoaderId(quizId)
    }
  }, [deleteQuiz.isLoading, quizId])

  const handleClick = () => {
    setOpenDeleteDialog(false)
    deleteQuiz.mutate()
    paginationRefetch()
  }
  const handleClose = () => {
    setOpenDeleteDialog(false)
  }
  const handleDelete = (id) => {
    setQuizId(id)
    setOpenDeleteDialog(true)
  }

  useEffect(() => {
    if (deleteQuiz.isError) {
      setLoaderId('-2')
      setMessage('Quiz deletion failed')
    }
  }, [deleteQuiz.isError])

  useEffect(() => {
    if (quizList) {
      const jsonStringifiedQuizList = JSON.stringify(quizList)
      localStorage?.setItem('quizList', jsonStringifiedQuizList)
    }
  }, [quizList])

  useEffect(() => {
    if (duplicateQuizId !== '' && createDuplicateQuiz) {
      refetchQuizById()
      refetchQuizQuestionById()
    }
  }, [duplicateQuizId, createDuplicateQuiz])

  useEffect(() => {
    if (postQuiz.isLoading) {
      setLoading(() => false)
    }
  }, [postQuiz.isLoading])

  const handleEdit = (id, isClosed) => {
    history.push(`./edit/${id}`, { isClosed })
  }

  const handleDuplicate = (id) => {
    setDuplicateQuizId(id)
    setCreateDuplicateQuiz(true)
  }

  const handleClickEnd = () => {
    setEndQuizLoaderId(quizId)
    refetchQuizByIdEndQuiz()
    setOpenDialog(false)
  }

  const handleEndQuiz = (id) => {
    setQuizId(id)
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCreateQuiz = () => {
    setOpenCreateQuizDialog(() => true)
  }

  const handleCreateQuizDialogClose = () => {
    setOpenCreateQuizDialog(() => false)
  }

  const handleSubmitTitle = () => {
    if (title.trim() === '') {
      setMessage('Title cannot be empty! Please enter the title')
      // setOpenSnackbar(() => true)
    } else if (title.length > 100) {
      setMessage('Title limit exceeded!!')
      // setOpenSnackbarTitle(() => true)
    } else {
      const data = {
        info: {
          title,
        },
        quizConfig: {
          type: 'public',
        },
      }
      postQuiz.mutate(data)
    }
  }

  const handlePrev = () => {
    setQuizPageOffset((prevOffset) => {
      return prevOffset - quizPageLimit >= 0 ? prevOffset - quizPageLimit : 0
    })
  }

  const handleNext = () => {
    setQuizPageOffset((prevOffset) => {
      return prevOffset + quizPageLimit <= quizCount
        ? prevOffset + quizPageLimit
        : quizCount
    })
  }

  const debouncedFilter = useCallback(
    debounce(() => paginationRefetch(), 500),
    []
  )
  const handleSearch = (searchInputValue) => {
    setSearchValue(searchInputValue)
    debouncedFilter()
  }

  const handleSearchInput = (value) => {
    if (value === null) {
      handleSearch('')
    } else handleSearch(value)
  }

  useEffect(() => {
    if (isLogin()) {
      paginationRefetch(quizPageLimit, quizPageOffset)
    }
  }, [quizPageOffset, filter, quizType])

  useEffect(() => {
    setQuizPageOffset(() => 0)
  }, [filter, quizType])

  const handleChangeFilter = (event) => {
    const filterValue = event?.target?.value
    setQuizType(filterValue)
  }
  const handleTitle = (event) => {
    if (event.target.value === '') {
      setMessage('Title cannot be empty! Please enter the title')
    }

    if (event.target.value.length > 100) {
      setMessage('Title limit exceeded!!')
      return
    }
    setTitle(event?.target.value)
  }

  if (!isLogin()) {
    return <Redirect to="/login" />
  }

  const render = () => {
    if (!isQuizListLoading && !isQuizListFetching)
      return (
        <div>
          <Typography className={classes.quizListsMsg}>
            No quizzes found
          </Typography>
        </div>
      )
    return null
  }
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <CommonSnackbar
        open={message !== ''}
        autoHideDuration={6000}
        onClose={() => {
          setMessage('')
          setTimeout(() => setTruthValue(false), 1000)
        }}
        severity={truthValue ? 'success' : 'error'}
        message={message}
      />

      {loading ? (
        <div className={classes.loader}>
          <Loader size="8rem" />
        </div>
      ) : (
        <div className={classes.root}>
          <div className={classes.fixQuizHeader}>
            <Container className={classes.container}>
              <Grid container className={classes.quizHeader}>
                <Grid
                  item
                  container
                  xs={6}
                  sm={9}
                  className={classes.outerColumn}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography className={classes.heading}>Quizzes</Typography>
                </Grid>

                <Grid
                  item
                  className={classes.outerColumn}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  xs={6}
                  sm={3}
                >
                  <Button
                    className={classes.addQuizButton}
                    onClick={handleCreateQuiz}
                  >
                    <AddIcon className={classes.addIcon} fontSize="small" />
                    Add Quiz
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </div>
          {candidatesList?.status === 'Failed' ||
          candidatesList?.data?.data?.quizList === null ? (
            <>
              <Container className={classes.emptyQuizContainer} />
              <Box display="flex" justifyContent="center">
                <Typography className={classes.emptyQuizContent}>
                  There are no quiz created
                </Typography>
              </Box>
            </>
          ) : (
            <div className={classes.quizBody}>
              <Container className={classes.container}>
                <Grid container spacing={3} className={classes.cardContainer}>
                  <Grid
                    component="button"
                    onClick={() => handleTopFilters('')}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.infoCardButtonWrapper}
                  >
                    <Paper
                      className={`${classes.paper} ${
                        filter === '' ? classes.quizTopFilters : ''
                      }`}
                    >
                      {isQuizListLoading || isQuizListFetching ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '24px',
                          }}
                        >
                          <Loader size={30} />
                        </div>
                      ) : (
                        <InfoCard
                          quizTitleType="All"
                          quizCount={
                            candidatesList?.data?.data?.totalCount?.totalCount
                          }
                          avatar={TotalQuizSvg}
                        />
                      )}
                    </Paper>
                  </Grid>
                  <Grid
                    component="button"
                    handleTopFilters
                    onClick={() => handleTopFilters('ongoing')}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.infoCardButtonWrapper}
                  >
                    <Paper
                      className={`${classes.paper} ${
                        filter === 'ongoing' ? classes.quizTopFilters : ''
                      }`}
                    >
                      {isQuizListLoading || isQuizListFetching ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '24px',
                          }}
                        >
                          <Loader size={30} />
                        </div>
                      ) : (
                        <InfoCard
                          quizTitleType="Ongoing"
                          quizCount={
                            candidatesList?.data?.data?.totalCount?.activeCount
                          }
                          avatar={TotalQuizSvg}
                        />
                      )}
                    </Paper>
                  </Grid>
                  <Grid
                    component="button"
                    onClick={() => handleTopFilters('upcoming')}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.infoCardButtonWrapper}
                  >
                    <Paper
                      className={`${classes.paper} ${
                        filter === 'upcoming' ? classes.quizTopFilters : ''
                      }`}
                    >
                      {isQuizListLoading || isQuizListFetching ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '24px',
                          }}
                        >
                          <Loader size={30} />
                        </div>
                      ) : (
                        <InfoCard
                          quizTitleType="Upcoming/Draft"
                          quizCount={
                            candidatesList?.data?.data?.totalCount
                              ?.upcomingCount
                          }
                          avatar={TotalQuizSvg}
                        />
                      )}
                    </Paper>
                  </Grid>
                  <Grid
                    component="button"
                    onClick={() => handleTopFilters('completed')}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.infoCardButtonWrapper}
                  >
                    <Paper
                      className={`${classes.paper} ${
                        filter === 'completed' ? classes.quizTopFilters : ''
                      }`}
                    >
                      {isQuizListLoading || isQuizListFetching ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '24px',
                          }}
                        >
                          <Loader size={30} />
                        </div>
                      ) : (
                        <InfoCard
                          quizTitleType="Completed"
                          quizCount={
                            candidatesList?.data?.data?.totalCount
                              ?.completedCount
                          }
                          avatar={TotalQuizSvg}
                        />
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
              <Container className={classes.container}>
                <Grid container className={classes.searchAndFilter} spacing={2}>
                  <Grid
                    item
                    sm={6}
                    md={3}
                    xs={12}
                    className={classes.searchBarGridItem}
                  >
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      // disabled={candidatesList?.data?.data === undefined}
                      options={quizList?.map((option) => {
                        return option?.info?.title
                      })}
                      onInputChange={(event, value) => {
                        handleSearchInput(value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search by name"
                          InputProps={{
                            ...params.InputProps,
                            className: classes.underline,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchQuiz />
                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: 18,
                              backgroundColor: '#E2E5E8',
                              paddingLeft: 10,
                              paddingRight: 20,
                              height: 36,
                              width: '100%',
                              maxWidth: 480,
                              boxShadow:
                                'inset 0px -2px 3px 0px rgba(0, 0, 0, 0.05)',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sm={3}
                    xs={4}
                    className={classes.outerColumn}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  />
                  <Grid
                    item
                    sm={3}
                    md={2}
                    xs={8}
                    className={classes.outerColumn}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ paddingLeft: 0 }}
                  >
                    <FormControl
                      className={classes.formControl}
                      style={{ width: '100%' }}
                    >
                      <Select
                        native
                        fullWidth
                        variant="outlined"
                        defaultValue=""
                        id="grouped-native-select"
                        onChange={handleChangeFilter}
                        inputProps={{
                          name: 'quizes',
                          id: 'outlined-age-native-simple',
                          className: classes.selectFilterInputProp,
                        }}
                      >
                        <option aria-label="all-quiz" value="">
                          All Quizzes
                        </option>
                        <optgroup label="Quiz Type">
                          <option value="public">Public Quiz</option>
                          <option value="private">Private Quiz</option>
                        </optgroup>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>
              {(isQuizListLoading || isQuizListFetching) && (
                <div className={classes.quizListLoader}>
                  <Loader size="6rem" />
                </div>
              )}
              {isQuizListSuccess &&
              !isQuizListLoading &&
              !isQuizListFetching &&
              candidatesList?.data?.data?.currentQuizCount > 0 ? (
                <Container className={classes.container}>
                  <Grid container>
                    {quizList?.map((quiz) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          key={quiz?.id}
                          style={{ paddingTop: 0 }}
                        >
                          <QuizCard
                            quizId={quiz?.id}
                            LoaderId={loaderId}
                            endQuizLoaderId={endQuizLoaderId}
                            handleEdit={() =>
                              handleEdit(
                                quiz?.id,
                                quiz?.info?.status === 'CLOSED'
                              )
                            }
                            handleDuplicate={() => handleDuplicate(quiz?.id)}
                            QuizTitle={quiz?.info?.title}
                            StartDate={quiz?.time?.start}
                            EndDate={quiz?.time?.end}
                            Duration={quiz?.time?.duration}
                            QuestionCount={questionsCount(quiz?.questionCount)}
                            isPublished={quiz?.info?.status === 'PUBLISHED'}
                            slug={quiz?.info?.slug}
                            isProctored={quiz?.quizConfig?.isProctored}
                            quizType={quiz?.info?.type}
                            isClosed={
                              quiz?.info?.status === 'CLOSED' ||
                              quiz?.info?.status === 'ENDED' ||
                              quiz?.info?.isClosed
                            }
                            setQuizList={setQuizList}
                            handleDelete={() => handleDelete(quiz?.id)}
                            handleEndQuiz={() => handleEndQuiz(quiz?.id)}
                            openEndQuizDialog={openDialog}
                            isLoading={deleteQuiz.isLoading}
                            isWriteAllowed={quiz.isWriteAllowed}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                  {candidatesList?.data?.data?.currentQuizCount > 8 &&
                    !isQuizListLoading &&
                    !isQuizListFetching && (
                      <div className={classes.quizPaginationGrid}>
                        <QuizPagination
                          className={classes.pagination}
                          quizCount={quizCount}
                          handleNext={handleNext}
                          handlePrevious={handlePrev}
                          offset={quizPageOffset}
                          quizPagesArray={quizPagesArray}
                          quizPageLimit={quizPageLimit}
                          setOffset={setQuizPageOffset}
                        />
                      </div>
                    )}
                </Container>
              ) : (
                render()
              )}
            </div>
          )}
          <div>
            <ConfirmBox
              isOpenDialog={openDialog}
              handleClose={handleCloseDialog}
              handleClick={handleClickEnd}
              boxDescription="Are you sure you want to end the quiz?"
              styles={classes}
              buttonName="End Quiz"
            />

            <ConfirmBox
              isOpenDialog={openDeleteDialog}
              handleClose={handleClose}
              handleClick={handleClick}
              boxTitle="Delete Quiz"
              boxDescription="Are you sure you want to delete this quiz?"
              styles={classes}
              buttonName="Delete"
            />
          </div>
          <Dialog
            onClose={handleCreateQuizDialogClose}
            open={openCreateQuizDialog}
            className={classes.createQuizDialog}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle
              onClose={handleCreateQuizDialogClose}
              customClasses={{ root: classes.addQuizContainer }}
            />
            <DialogContent className={classes.createQuizDialogContentContainer}>
              <Typography className={classes.dialogTitle}>
                Create a new Quiz
              </Typography>
              <div className={classes.titleHeader}>
                <Typography>Enter a suitable title</Typography>
                <span className={classes.titleLength}>
                  {title?.length ? title?.length : 0}
                  /100
                </span>
              </div>
              <br />

              <Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Quiz name"
                  value={title}
                  onChange={handleTitle}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter' && !postQuiz.isLoading) {
                      handleSubmitTitle()
                      setLoading(false)
                    }
                  }}
                  inputProps={{
                    style: {
                      fontSize: '1rem',
                    },
                  }}
                />
              </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {postQuiz.isLoading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress size="1.2rem" />
                </div>
              ) : (
                <Button
                  variant="filled"
                  className={classes.createQuizDialogButton}
                  onClick={handleSubmitTitle}
                >
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  )
}

export default withRouter(Dashboard)
