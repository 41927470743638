import React from 'react'

function LoginLogo() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 500 500"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-1"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-3"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-5"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-7"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-9"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-11"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-13"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-15"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-17"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-19"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-21"
        />
        <path
          d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
          id="path-23"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-25"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-27"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-29"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-31"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-33"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-35"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-37"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-39"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-41"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-43"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-45"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-47"
        />
        <path
          d="M79.0825854,0 C80.256291,0 81.2703022,0.820395357 81.5153346,1.96823852 L83.1476555,9.61477417 L83.1509121,9.77072968 L290.215589,9.77096501 L290.215589,178.27529 C290.215589,179.649133 289.101869,180.762852 287.728027,180.762852 L2.48756219,180.762852 C1.11371953,180.762852 0,179.649133 0,178.27529 L0,9.77096501 L30.9651741,9.77072968 L30.9669117,9.70698578 L32.3034402,2.05931911 C32.5115091,0.868740614 33.5452402,0 34.7538633,0 L79.0825854,0 Z"
          id="path-49"
        />
      </defs>
      <g
        id="New-svg"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-56-Copy">
          <rect id="Rectangle" x="0" y="0" width="500" height="500" />
          <g id="Group-45-Copy" transform="translate(15.497553, 88.499184)">
            <g id="Group-33-Copy">
              <path
                d="M23.60719,322.185971 C18.6446238,300.496998 26.6921668,277.156565 47.7498193,252.164672 C79.3362977,214.676832 89.1499005,206.143265 74.8116652,167.852717 C60.4734299,129.56217 26.8088127,49.1762704 98.8130914,13.5378822 C170.81737,-22.1005062 207.927645,22.5472422 245.74547,34.4297746 C283.563294,46.3123071 391.21824,75.4323389 432.682781,141.120856 C474.147329,206.809374 440.209515,205.95248 432.682781,255.709625 C425.156052,305.46677 449.908006,322.185971 419.421986,322.185971 C399.097977,322.185971 267.159711,322.185971 23.60719,322.185971 Z"
                id="Path-2"
                fill="#DFF1F1"
                fillRule="nonzero"
              />
              <path
                d="M97.0636215,248.776509 L126.019576,250.014914 C126.019576,260.971006 121.193584,264.797845 111.541599,261.495433 C101.889614,258.19302 97.0636215,253.953378 97.0636215,248.776509 Z"
                id="Path-25-Copy"
                fill="#CADFDF"
                fillRule="nonzero"
                opacity="0.481944949"
                transform="translate(111.541599, 255.709625) scale(-1, 1) translate(-111.541599, -255.709625) "
              />
              <path
                d="M107.356557,214.210125 C107.67005,207.890596 105.340629,204.730832 100.368293,204.730832 C92.9097897,204.730832 91.4034895,210.99826 91.4034895,214.94491 C91.4034895,216.714807 90.9270919,221.229379 92.9303574,227.871329 C93.5801963,230.025909 95.2847819,233.731659 98.0441147,238.988581 C110.734406,235.185745 118.568509,229.47612 121.546426,221.859706 C126.013301,210.435086 116.934769,208.442625 113.460399,210.056011 C111.144151,211.131601 109.109538,212.516305 107.356557,214.210125 Z"
                id="Path-24-Copy-2"
                fill="#CADFDF"
                fillRule="nonzero"
                opacity="0.481944949"
                transform="translate(107.055465, 221.859706) scale(-1, 1) translate(-107.055465, -221.859706) "
              />
              <g id="Group-44" transform="translate(96.247961, 34.665579)">
                <path
                  d="M2.44698206,0 L283.034258,0 C284.385688,0 285.48124,1.09555118 285.48124,2.44698206 L285.48124,177.814029 C285.48124,179.16546 284.385688,180.261011 283.034258,180.261011 L2.44698206,180.261011 C1.09555118,180.261011 0,179.16546 0,177.814029 L0,2.44698206 C0,1.09555118 1.09555118,0 2.44698206,0 Z"
                  id="Rectangle"
                  fill="#B2CECE"
                  fillRule="nonzero"
                />
                <path
                  d="M85.7092202,2.44698206 L129.349836,2.44698206 C130.508778,2.44698206 131.508775,3.25999624 131.745311,4.39454421 L133.360522,12.1419297 L133.360522,12.1419297 L81.9738989,12.2349103 L83.2971203,4.48226684 C83.4977667,3.30669597 84.5166493,2.44698206 85.7092202,2.44698206 Z"
                  id="Rectangle-Copy-29"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  opacity="0.251408067"
                />
                <path
                  d="M137.095843,2.44698206 L180.736459,2.44698206 C181.895401,2.44698206 182.895398,3.25999624 183.131934,4.39454421 L184.747145,12.1419297 L184.747145,12.1419297 L133.360522,12.2349103 L134.683743,4.48226684 C134.88439,3.30669597 135.903272,2.44698206 137.095843,2.44698206 Z"
                  id="Rectangle-Copy-30"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  opacity="0.251408067"
                />
                <g
                  id="Mask"
                  transform="translate(0.000000, 2.446982)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <path
                    d="M77.7924943,0 C78.947053,0 79.9445224,0.807012072 80.1855575,1.93613022 L81.79125,9.45792631 L81.7944535,9.61133768 L285.48124,9.61156917 L285.48124,175.367047 C285.48124,176.718478 284.385688,177.814029 283.034258,177.814029 L2.44698206,177.814029 C1.09555118,177.814029 0,176.718478 0,175.367047 L0,9.61156917 L30.4600326,9.61133768 L30.4617418,9.54863365 L31.7764673,2.025725 C31.9811419,0.854568662 32.9980095,0 34.1869161,0 L77.7924943,0 Z"
                    id="path-1"
                  />
                </g>
                <g
                  id="Rectangle-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle"
                    fill="#F2F8F8"
                    fillRule="nonzero"
                    mask="url(#mask-2)"
                    x="35.0734095"
                    y="4.07830343"
                    width="18.3523654"
                    height="2.8548124"
                    rx="1.4274062"
                  />
                </g>
                <g
                  id="Rectangle-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-4" fill="white">
                    <use xlinkHref="#path-3" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle"
                    fill="#F2F8F8"
                    fillRule="nonzero"
                    mask="url(#mask-4)"
                    x="8.97226754"
                    y="17.5367047"
                    width="269.168026"
                    height="8.56443719"
                    rx="4.2822186"
                  />
                </g>
                <g id="Oval-Clipped" transform="translate(0.000000, 2.446982)">
                  <mask id="mask-6" fill="white">
                    <use xlinkHref="#path-5" />
                  </mask>
                  <g id="path-1" />
                  <circle
                    id="Oval"
                    fill="#00B8A5"
                    fillRule="nonzero"
                    mask="url(#mask-6)"
                    cx="10.6035889"
                    cy="4.07830343"
                    r="1.63132137"
                  />
                </g>
                <g
                  id="Oval-Copy-5-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-8" fill="white">
                    <use xlinkHref="#path-7" />
                  </mask>
                  <g id="path-1" />
                  <circle
                    id="Oval-Copy-5"
                    fill="#00B8A5"
                    fillRule="nonzero"
                    mask="url(#mask-8)"
                    cx="15.9053834"
                    cy="4.07830343"
                    r="1.63132137"
                  />
                </g>
                <g
                  id="Oval-Copy-6-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-10" fill="white">
                    <use xlinkHref="#path-9" />
                  </mask>
                  <g id="path-1" />
                  <circle
                    id="Oval-Copy-6"
                    fill="#00B8A5"
                    fillRule="nonzero"
                    mask="url(#mask-10)"
                    cx="21.2071778"
                    cy="4.07830343"
                    r="1.63132137"
                  />
                </g>
                <g
                  id="Path-36-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-12" fill="white">
                    <use xlinkHref="#path-11" />
                  </mask>
                  <g id="path-1" />
                  <line
                    x1="74.6329527"
                    y1="4.07830343"
                    x2="76.2642741"
                    y2="5.7096248"
                    id="Path-36"
                    stroke="#EEEDED"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    mask="url(#mask-12)"
                  />
                </g>
                <g
                  id="Path-37-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-14" fill="white">
                    <use xlinkHref="#path-13" />
                  </mask>
                  <g id="path-1" />
                  <line
                    x1="76.2642741"
                    y1="4.07830343"
                    x2="74.6329527"
                    y2="5.7096248"
                    id="Path-37"
                    stroke="#EEEDED"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    mask="url(#mask-14)"
                  />
                </g>
                <g
                  id="Path-36-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-16" fill="white">
                    <use xlinkHref="#path-15" />
                  </mask>
                  <g id="path-1" />
                  <line
                    x1="126.835237"
                    y1="4.07830343"
                    x2="128.466558"
                    y2="5.7096248"
                    id="Path-36"
                    stroke="#BED3D3"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    mask="url(#mask-16)"
                  />
                </g>
                <g
                  id="Path-37-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-18" fill="white">
                    <use xlinkHref="#path-17" />
                  </mask>
                  <g id="path-1" />
                  <line
                    x1="128.466558"
                    y1="4.07830343"
                    x2="126.835237"
                    y2="5.7096248"
                    id="Path-37"
                    stroke="#BED3D3"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    mask="url(#mask-18)"
                  />
                </g>
                <g
                  id="Path-36-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-20" fill="white">
                    <use xlinkHref="#path-19" />
                  </mask>
                  <g id="path-1" />
                  <line
                    x1="178.62969"
                    y1="4.07830343"
                    x2="180.261011"
                    y2="5.7096248"
                    id="Path-36"
                    stroke="#BED3D3"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    mask="url(#mask-20)"
                  />
                </g>
                <g
                  id="Path-37-Clipped"
                  transform="translate(0.000000, 2.446982)"
                >
                  <mask id="mask-22" fill="white">
                    <use xlinkHref="#path-21" />
                  </mask>
                  <g id="path-1" />
                  <line
                    x1="180.261011"
                    y1="4.07830343"
                    x2="178.62969"
                    y2="5.7096248"
                    id="Path-37"
                    stroke="#BED3D3"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    mask="url(#mask-22)"
                  />
                </g>
                <g id="Quiz-Clipped" transform="translate(0.000000, 2.446982)">
                  <mask id="mask-24" fill="white">
                    <use xlinkHref="#path-23" />
                  </mask>
                  <g id="path-1" />
                  <text
                    id="Quiz"
                    fill="#DDEBEB"
                    fillRule="nonzero"
                    mask="url(#mask-24)"
                    fontFamily="OpenSans-Bold, Open Sans"
                    fontSize="15"
                    fontWeight="bold"
                  >
                    <tspan x="23.1921646" y="47.1140599">
                      Quiz
                    </tspan>
                  </text>
                </g>
                <g
                  id="Rectangle-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-26" fill="white">
                    <use xlinkHref="#path-25" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle"
                    fill="#00B7A4"
                    fillRule="nonzero"
                    mask="url(#mask-26)"
                    x="228.441128"
                    y="37.7280265"
                    width="36.8988391"
                    height="12.8524046"
                    rx="4"
                  />
                </g>
                <g
                  id="Rectangle-Copy-39-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-28" fill="white">
                    <use xlinkHref="#path-27" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle-Copy-39"
                    fill="#DDEBEB"
                    fillRule="nonzero"
                    mask="url(#mask-28)"
                    x="228.441128"
                    y="70.8955224"
                    width="36.8988391"
                    height="12.8524046"
                    rx="4"
                  />
                </g>
                <g
                  id="Rectangle-Copy-40-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-30" fill="white">
                    <use xlinkHref="#path-29" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle-Copy-40"
                    stroke="#252B4C"
                    mask="url(#mask-30)"
                    x="228.441128"
                    y="103.648425"
                    width="36.8988391"
                    height="12.8524046"
                    rx="4"
                  />
                </g>
                <g
                  id="Rectangle-Copy-41-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-32" fill="white">
                    <use xlinkHref="#path-31" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle-Copy-41"
                    fill="#DDEBEB"
                    fillRule="nonzero"
                    mask="url(#mask-32)"
                    x="228.441128"
                    y="136.401327"
                    width="36.8988391"
                    height="12.8524046"
                    rx="4"
                  />
                </g>
                <g
                  id="Rectangle-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-34" fill="white">
                    <use xlinkHref="#path-33" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    mask="url(#mask-34)"
                    x="235.074627"
                    y="42.2885572"
                    width="1"
                    height="3.73134328"
                  />
                </g>
                <g
                  id="Rectangle-Copy-7-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-36" fill="white">
                    <use xlinkHref="#path-35" />
                  </mask>
                  <g id="path-1" />
                  <polygon
                    id="Rectangle-Copy-7"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    mask="url(#mask-36)"
                    transform="translate(235.281924, 44.154229) rotate(-90.000000) translate(-235.281924, -44.154229) "
                    points="235.084626 42.2885572 235.479222 42.2885572 235.479222 46.0199005 235.084626 46.0199005"
                  />
                </g>
                <g
                  id="Add-Quiz-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-38" fill="white">
                    <use xlinkHref="#path-37" />
                  </mask>
                  <g id="path-1" />
                  <text
                    id="Add-Quiz"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    mask="url(#mask-38)"
                    fontFamily="OpenSans-SemiBold, Open Sans"
                    fontSize="5.5"
                    fontWeight="500"
                  >
                    <tspan x="238.336592" y="46.3916865">
                      Add Quiz
                    </tspan>
                  </text>
                </g>
                <g
                  id="Rectangle-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-40" fill="white">
                    <use xlinkHref="#path-39" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle"
                    fill="#DDEBEB"
                    fillRule="nonzero"
                    mask="url(#mask-40)"
                    x="26.9485904"
                    y="66.7495854"
                    width="158.374793"
                    height="6.21890547"
                    rx="3.10945274"
                  />
                </g>
                <g
                  id="Rectangle-Copy-35-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-42" fill="white">
                    <use xlinkHref="#path-41" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle-Copy-35"
                    fill="#F2F8F8"
                    fillRule="nonzero"
                    mask="url(#mask-42)"
                    x="26.9485904"
                    y="99.5024876"
                    width="107.379768"
                    height="6.21890547"
                    rx="3.10945274"
                  />
                </g>
                <g
                  id="Rectangle-Copy-9-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-44" fill="white">
                    <use xlinkHref="#path-43" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle-Copy-9"
                    stroke="#252B4C"
                    mask="url(#mask-44)"
                    x="26.9485904"
                    y="77.5290216"
                    width="87.893864"
                    height="6.21890547"
                    rx="3.10945274"
                  />
                </g>
                <g
                  id="Rectangle-Copy-36-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-46" fill="white">
                    <use xlinkHref="#path-45" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle-Copy-36"
                    fill="#F2F8F8"
                    fillRule="nonzero"
                    mask="url(#mask-46)"
                    x="26.9485904"
                    y="110.281924"
                    width="142.620232"
                    height="6.21890547"
                    rx="3.10945274"
                  />
                </g>
                <g
                  id="Rectangle-Copy-38-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-48" fill="white">
                    <use xlinkHref="#path-47" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle-Copy-38"
                    stroke="#252B4C"
                    mask="url(#mask-48)"
                    x="26.9485904"
                    y="132.25539"
                    width="123.134328"
                    height="6.21890547"
                    rx="3.10945274"
                  />
                </g>
                <g
                  id="Rectangle-Copy-37-Clipped"
                  transform="translate(0.000000, 2.487562)"
                >
                  <mask id="mask-50" fill="white">
                    <use xlinkHref="#path-49" />
                  </mask>
                  <g id="path-1" />
                  <rect
                    id="Rectangle-Copy-37"
                    fill="#F2F8F8"
                    fillRule="nonzero"
                    mask="url(#mask-50)"
                    x="26.9485904"
                    y="143.034826"
                    width="155.472637"
                    height="6.21890547"
                    rx="3.10945274"
                  />
                </g>
              </g>
              <g id="Group-43" transform="translate(0.000000, 182.006633)">
                <path
                  d="M355.474415,138.059701 C369.231363,138.059701 386.30454,109.390493 376.403375,89.7952629 C366.502211,70.2000319 347.251524,53.4825871 320.12148,53.4825871 C292.991436,53.4825871 288.180213,55.8001455 272.010041,64.3135439 C255.839869,72.8269424 242.537313,92.5421389 242.537313,104.380349 C242.537313,116.218558 248.372117,138.059701 273.648565,138.059701 C298.925013,138.059701 341.717466,138.059701 355.474415,138.059701 Z"
                  id="Path-58"
                  stroke="#252B4C"
                  fill="#00B8A5"
                  fillRule="nonzero"
                />
                <circle
                  id="Oval"
                  stroke="#252B4C"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  cx="290.008292"
                  cy="28.3996683"
                  r="7.66998342"
                />
                <circle
                  id="Oval-Copy-8"
                  stroke="#252B4C"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  cx="332.296849"
                  cy="28.3996683"
                  r="7.66998342"
                />
                <rect
                  id="Rectangle"
                  stroke="#252B4C"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  x="300.165837"
                  y="39.800995"
                  width="23.6318408"
                  height="19.9004975"
                  rx="9.5"
                />
                <ellipse
                  id="Oval"
                  stroke="#252B4C"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  cx="311.359867"
                  cy="24.8756219"
                  rx="21.1442786"
                  ry="24.8756219"
                />
                <rect
                  id="Rectangle"
                  fill="#B2CECE"
                  fillRule="nonzero"
                  x="0"
                  y="138.059701"
                  width="476.368159"
                  height="8.29187396"
                  rx="4.14593698"
                />
                <g id="Group-23" transform="translate(0.000000, 1.345103)">
                  <path
                    d="M20.7296849,53.3812649 C41.332924,53.3812649 52.9415477,65.5426803 55.5555556,89.8655104"
                    id="Path-14"
                    stroke="#252B4C"
                  />
                  <path
                    d="M0,51.3082964 L29.4361526,52.5672388 C29.4361526,63.705024 24.5301272,67.5953271 14.7180763,64.2381476 C4.90602541,60.8809681 0,56.5710178 0,51.3082964 Z"
                    id="Path-25"
                    fill="#00B8A5"
                    fillRule="nonzero"
                  />
                  <line
                    x1="65.2115543"
                    y1="21.872144"
                    x2="64.5562732"
                    y2="89.8655104"
                    id="Path-21"
                    stroke="#252B4C"
                  />
                  <path
                    d="M77.3919374,89.4509167 C75.6328794,65.7412027 75.9707948,49.6767272 78.4056841,41.2574899 C78.9881032,39.243629 79.734726,37.3813001 80.6455514,35.6705032 C84.9995883,27.4923204 95.1581103,24.3909912 103.337479,28.7427995 C107.981226,31.2134905 109.742835,36.9808816 107.272144,41.6246281 C107.167917,41.8205253 107.056877,42.0127201 106.939225,42.2008607"
                    id="Path-22"
                    stroke="#252B4C"
                  />
                  <path
                    d="M87.5147716,89.036323 C86.9366173,76.0457206 93.420085,69.5504192 106.965174,69.5504192"
                    id="Path-23"
                    stroke="#252B4C"
                  />
                  <path
                    d="M98.6733002,67.4774507 L128.109453,68.736393 C128.109453,79.8741783 123.203427,83.7644813 113.391376,80.4073018 C103.579325,77.0501223 98.6733002,72.7401721 98.6733002,67.4774507 Z"
                    id="Path-25-Copy"
                    fill="#00B8A5"
                    fillRule="nonzero"
                    transform="translate(113.391376, 74.525544) scale(-1, 1) translate(-113.391376, -74.525544) "
                  />
                  <path
                    d="M44.3615257,89.4509167 L96.185738,89.4509167 L90.6647193,133.08905 C90.4025717,135.161064 88.6401028,136.714598 86.5515709,136.714598 L54.6095386,136.714598 C52.5427898,136.714598 50.7916754,135.192431 50.5039693,133.145805 L44.3615257,89.4509167 L44.3615257,89.4509167 Z"
                    id="Rectangle"
                    stroke="#252B4C"
                    fill="#F2F8F8"
                    fillRule="nonzero"
                  />
                  <path
                    d="M109.136931,31.923233 C109.455623,25.4989028 107.087571,22.2867377 102.032775,22.2867377 C94.4505822,22.2867377 92.9193019,28.6581029 92.9193019,32.6702037 C92.9193019,34.4694516 92.4350038,39.0588926 94.471491,45.8109909 C95.1321067,48.0013014 96.8649607,51.768507 99.6700536,57.1126082 C112.570797,53.2467073 120.53482,47.4423955 123.562122,39.699673 C128.103074,28.0855891 118.873986,26.0600864 115.341997,27.7002276 C112.987338,28.7936551 110.918983,30.2013236 109.136931,31.923233 Z"
                    id="Path-24"
                    fill="#00B8A5"
                    fillRule="nonzero"
                    transform="translate(108.830846, 39.699673) scale(-1, 1) translate(-108.830846, -39.699673) "
                  />
                  <path
                    d="M62.8177513,14.0704479 C63.1367882,7.6319777 60.7661746,4.41274262 55.7059109,4.41274262 C48.115515,4.41274262 46.5825784,10.7981312 46.5825784,14.8190627 C46.5825784,16.6222708 46.0977567,21.2218131 48.136447,27.9887728 C48.7977766,30.1839042 50.5325054,33.9594017 53.3406327,39.3152653 C66.2553326,35.4408554 74.2279706,29.6237683 77.2585469,21.8640039 C81.8044117,10.2243574 72.5653396,8.19439644 69.0295303,9.83814768 C66.6723239,10.9339818 64.601731,12.3447486 62.8177513,14.0704479 Z"
                    id="Path-24-Copy"
                    fill="#00B8A5"
                    fillRule="nonzero"
                    transform="translate(62.511335, 21.864004) rotate(20.000000) translate(-62.511335, -21.864004) "
                  />
                </g>
                <path
                  d="M367.808186,89.4944254 C363.503868,91.2198321 361.351709,94.3741911 361.351709,98.9575025"
                  id="Path-62"
                  stroke="#252B4C"
                  strokeLinecap="round"
                />
                <path
                  d="M251.962139,92.7046853 C256.687201,95.7711443 260.184951,103.751881 260.184951,103.751881"
                  id="Path-63"
                  stroke="#252B4C"
                  strokeLinecap="round"
                />
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  x="258.706468"
                  y="65.920398"
                  width="102.819237"
                  height="72.1393035"
                  rx="3"
                />
                <g
                  id="Group-39"
                  transform="translate(303.897181, 91.210173)"
                  fill="#DDEBEB"
                  fillRule="nonzero"
                >
                  <ellipse
                    id="Oval"
                    cx="5.83022388"
                    cy="12.5417073"
                    rx="5.83022388"
                    ry="5.70085593"
                  />
                  <path
                    d="M12.2201732,-2.31526609e-13 C12.3619618,0.475217011 12.4378109,0.976505216 12.4378109,1.49451239 C12.4378109,4.08322474 10.5435202,6.25438993 7.99174921,6.84077143 C7.849501,6.36552094 7.77363184,5.86416882 7.77363184,5.34609333 C7.77363184,2.77154305 9.64725307,0.609983856 12.1778729,0.00960014142 L12.2201732,-2.31526609e-13 Z"
                    id="Combined-Shape"
                  />
                </g>
                <g
                  id="Group-41"
                  transform="translate(210.002794, 123.963516)"
                  fill="#F2F8F8"
                  fillRule="nonzero"
                  stroke="#252B4C"
                >
                  <ellipse
                    id="Oval"
                    cx="3.56173677"
                    cy="7.11719183"
                    rx="3.56173677"
                    ry="3.52404643"
                  />
                  <path
                    d="M4.32995452,0 L25.0718333,0 L25.0718333,3.31065262 C25.0718333,9.0383643 20.4286056,13.681592 14.7008939,13.681592 C8.97318226,13.681592 4.32995452,9.0383643 4.32995452,3.31065262 L4.32995452,0 L4.32995452,0 Z"
                    id="Rectangle"
                  />
                </g>
              </g>
            </g>
            <path
              d="M320.066335,171.641791 C323.67843,171.641791 326.655887,174.358996 327.066189,177.860888 L327.114428,177.860697 C331.006982,177.860697 334.162521,181.016235 334.162521,184.908789 C334.162521,185.203829 334.144392,185.494635 334.109188,185.780153 C337.636469,186.166201 340.381426,189.155576 340.381426,192.78607 C340.381426,193.584046 340.248814,194.351048 340.004425,195.066242 C340.248763,195.781326 340.381426,196.548471 340.381426,197.3466 C340.381426,201.239155 337.225888,204.394693 333.333333,204.394693 C329.799581,204.394693 326.873235,201.794076 326.363779,198.402326 C323.23376,196.956136 320.305431,197.037015 317.578773,198.644478 C314.24233,200.611432 312.874931,198.629799 311.388952,196.618257 L311.234721,196.410221 L311.234721,196.410221 L311.079131,196.202934 C310.088337,194.894536 308.984842,193.689306 307.181534,193.689306 C302.435989,193.689306 304.674544,195.899786 300.165837,199.626986 C295.657131,203.354186 291.899467,197.390911 290.854749,204.198954 C290.027223,209.591632 287.397814,200.8705 287.203487,196.200633 C285.777304,195.326224 284.825871,193.752663 284.825871,191.956882 C284.825871,189.443419 286.689747,187.365287 289.110728,187.029257 C288.242021,185.857778 287.728027,184.406825 287.728027,182.835821 C287.728027,178.943267 290.883565,175.787728 294.776119,175.787728 C297.360272,175.787728 299.619604,177.178451 300.846495,179.252275 C301.684359,176.295432 304.403484,174.129353 307.628524,174.129353 C309.92006,174.129353 311.956173,175.222951 313.243432,176.916716 C314.029329,173.88258 316.78615,171.641791 320.066335,171.641791 Z"
              id="Combined-Shape"
              fill="#252B4C"
              fillRule="nonzero"
            />
            <path
              d="M303.81999,206.404715 C305.661795,205.770531 306.928666,204.599468 306.649625,203.789074 C306.370584,202.978679 304.651298,202.835833 302.809493,203.470017 C300.967687,204.104202 299.700816,205.275265 299.979857,206.085659 C300.258898,206.896053 301.978184,207.0389 303.81999,206.404715 Z"
              id="Oval"
              fill="#252B4C"
              fillRule="nonzero"
            />
            <path
              d="M316.917661,207.251205 C315.075856,206.617021 313.808985,205.445958 314.088026,204.635564 C314.367067,203.825169 316.086353,203.682323 317.928159,204.316507 C319.769964,204.950692 321.036835,206.121755 320.757794,206.932149 C320.478752,207.742544 318.759466,207.88539 316.917661,207.251205 Z"
              id="Oval-Copy-17"
              fill="#252B4C"
              fillRule="nonzero"
            />
            <circle
              id="Oval"
              fill="#252B4C"
              fillRule="nonzero"
              cx="303.812415"
              cy="210.237197"
              r="1"
            />
            <circle
              id="Oval-Copy-18"
              fill="#252B4C"
              fillRule="nonzero"
              cx="315.945441"
              cy="210.237197"
              r="1"
            />
            <path
              d="M309.737051,211.083687 C308.130176,216.524172 308.130176,219.244414 309.737051,219.244414"
              id="Path-60"
              fill="#252B4C"
              fillRule="nonzero"
            />
            <path
              d="M307.969035,222.485152 C309.737051,224.345366 314.055354,224.755983 317.42291,221.13252"
              id="Path-61"
              fill="#252B4C"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LoginLogo
