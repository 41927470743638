import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { v4 as uuidv4 } from 'uuid'
import { useQueryClient } from 'react-query'
import {
  useQuestionPutQuery,
  useQuestionPostQuery,
} from '../../Common/Queries/QuestionQuery'
import 'react-quill/dist/quill.snow.css'
import { htmlToString, isQuizStarted } from '../../utils'
import { useStyles } from './EditMcqQuestion2Styles'
import McqQuestionTile from './McqQuestionTile'
import EditMcqQuestionTile from './EditMcqQuestionTile'
import ConfirmBox from '../common/ConfirmBox'
import {
  useGetAllQuizzesForAQuestion,
  useUpdateQuestionForQuizzes,
} from '../../Common/Queries/QuizQuery'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'

function EditQuestionContent({
  questionTypeFromParent,
  quizType,
  codingQuestionSwitch,
  handleQuestionTypeChange,
  questions,
  question,
  questionIndex,
  addQuestion,
  setAddQuestion,
  reload,
  setReload,
  quizId,
  quizStartTime,
  isPublished,
  save,
  setSave,
  isClosed,
  setCancelAddQuestion,
  setErrorMessages,
  setTruthValue,
  refetchGetQueryById,
  postQuestionMapping,
  deleteMappingQuestion,
  refetchGetQuizQuestionQueryById,
  deleteQuestion,
  isWriteAllowed,
  setLocalQuestion,
  localQuestion,
  setTextEditorQuestion,
  textEditorQuestion,
  setAddEditQuestion,
  setEditViewQuestion,
  editViewQuestion,
  deleteQuestionId,
  setDeleteQuestionId,
  editOngoing,
  setEditOngoing,
  editQuestion,
  setEditQuestion,
}) {
  const queryClient = useQueryClient()
  const [errorMessage, setErrorMessage] = useState('')
  const [questionMcqType] = useState('mcq')
  const [backUpLocalQuestion, setBackUpLocalQuestion] = useState()
  // const [editQuestion, setEditQuestion] = useState(false) //
  const [, setCorrectChoice] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [adminEvaluate, setAdminEvaluate] = useState(true)
  const [isMultipleAnswers, SetisMultipleAnswers] = useState(false)

  const [errors, setErrors] = useState({
    emptyQuestion: false,
    emptyMarks: false,
    marksNotValid: false,
    marksLessThanZero: false,
    emptyChoicesPresent: false,
    correctChoiceNotSelected: false,
    similarChoicesPresent: false,
    onlyOneChoicePresent: false,
  })

  const [affectedQuizes, setAffectedQuizes] = useState([])
  const [selectedQuizzes, setSelectedQuizzes] = useState([])
  const [showQuizesListModal, setShowQuizesListModal] = useState(false)

  const editQuestionQuery = useQuestionPutQuery(
    quizId,
    localQuestion?.id,
    questionMcqType,
    {
      retry: false,
      onSuccess: () => {
        setEditQuestion(NaN)
        setErrorMessages('Question Saved Successfully!')
        setTruthValue(true)
        setReload(reload + 1)
        refetchGetQueryById()
        refetchGetQuizQuestionQueryById()
      },
      onError: (err) => {
        setErrorMessages('Failed')
        if (
          typeof err?.response?.data === 'string' &&
          (err?.response?.data?.toLowerCase() === 'non existing token' ||
            err?.response?.data?.toLowerCase() === 'expired token')
        ) {
          localStorage.clear()
        }
      },
    }
  )

  const postQuestion = useQuestionPostQuery({
    staleTime: 0,
    retry: false,
    onSuccess: (successResponse) => {
      const id = successResponse?.data?.data?.question?.id
      const questionDetailsObj = {
        questionId: id,
        type: localQuestion?.type,
      }
      postQuestionMapping.mutate(questionDetailsObj)
      setTruthValue(true)
      setErrorMessages('Question Saved Successfully!')
      setAddQuestion(false)
      setReload(reload + 1)
      refetchGetQueryById()
      refetchGetQuizQuestionQueryById()
      queryClient.invalidateQueries('quizGetQueryById')
      setSave(false)
      setLocalQuestion({})
      setEditViewQuestion({})
      setTextEditorQuestion({ question: '<p></p>' })
    },

    onError: (err) => {
      setErrorMessages('Failed')
      setSave(false)
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const { refetch: refetchQuizzesForQuestion } = useGetAllQuizzesForAQuestion(
    localQuestion?.id,
    {
      enabled: false,
      onSuccess: (response) => {
        const filteredQuizzes = response?.filter((quiz) => {
          return quiz?.status === 'DRAFT' || quiz?.status === 'UPCOMING'
        })
        setAffectedQuizes(filteredQuizzes || [])
      },
      onError: () => {},
    }
  )

  const updateQuestionsInQuizzes = useUpdateQuestionForQuizzes({
    onSuccess: async () => {
      setEditQuestion(NaN)
      setShowQuizesListModal(false)
      setErrorMessages('Question Saved Successfully!')
      setTruthValue(true)
      setAddQuestion(false)
      setReload(reload + 1)
      await refetchGetQueryById()
      await refetchGetQuizQuestionQueryById()
    },
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  const postNewQuestion = useQuestionPostQuery({
    staleTime: 0,
    retry: false,
    onSuccess: (successResponse) => {
      const id = successResponse?.data?.data?.question?.id
      const newQuestionId = id
      updateQuestionsInQuizzes.mutate({
        oldQuestionId: localQuestion?.id,
        newQuestionId,
        type: localQuestion?.type,
        marks: localQuestion?.marks,
        quizIDs: selectedQuizzes.map((quiz) => quiz?.quizID),
      })
      queryClient.invalidateQueries('getAllQuizzesForAQuestion')
    },

    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  useEffect(() => {
    if (localQuestion?.type === 'multi-choice') {
      SetisMultipleAnswers(() => true)
    }
    const localCorrectChoice = localQuestion?.choices?.filter((choice) =>
      choice.isCorrect ? choice.value : null
    )
    if (localCorrectChoice !== undefined)
      setCorrectChoice(localCorrectChoice[0]?.value)
    if (Number.isNaN(editQuestion)) {
      setReload(reload + 1)
    } else {
      refetchQuizzesForQuestion()
    }
  }, [editQuestion])

  useEffect(() => {
    if (question && !addQuestion) {
      // setLocalQuestion(question)
      if (question?.type === 'multi-choice') {
        SetisMultipleAnswers(() => true)
      }
      // if (textEditorQuestion.question !== '<p></p>') {
      // setTextEditorQuestion((prevQuestion) => {
      //   const updatedQuestion = JSON.parse(JSON.stringify(prevQuestion))
      //   updatedQuestion.question = question.question
      //   return updatedQuestion
      // })
      // }
    } else {
      const value = {
        id:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === questionTypeFromParent
            ? editViewQuestion?.id
            : `Added${uuidv4()}`,
        question:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === questionTypeFromParent
            ? editViewQuestion?.question
            : '<p></p>',
        choices:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === questionTypeFromParent
            ? editViewQuestion?.choices
            : [
                {
                  id: `Added${uuidv4()}`,
                  value: '',
                  isCorrect: false,
                },
                {
                  id: `Added${uuidv4()}`,
                  value: '',
                  isCorrect: false,
                },
              ],
        marks:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === questionTypeFromParent
            ? editViewQuestion?.marks
            : 2,
        type:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === questionTypeFromParent
            ? editViewQuestion?.type
            : questionTypeFromParent,
        isRandomize:
          editViewQuestion?.id !== undefined &&
          editViewQuestion?.type === questionTypeFromParent
            ? editViewQuestion?.isRandomize
            : false,
      }
      setLocalQuestion(value)
      setTextEditorQuestion((prevQuestion) => {
        const updatedQuestion = JSON.parse(JSON.stringify(prevQuestion))
        updatedQuestion.question = value?.question
        return updatedQuestion
      })
      setEditViewQuestion(value)
    }
  }, [question, questionTypeFromParent])

  useEffect(() => {
    if (Number.isNaN(editQuestion)) {
      setBackUpLocalQuestion(localQuestion)
    }
  }, [localQuestion])

  const classes = useStyles()

  const marksValidation = (marksToBeValidated) => {
    if (!marksToBeValidated) {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyMarks: true }
      })
      setErrors((errorsPrev) => {
        return { ...errorsPrev, marksLessThanZero: false }
      })
      setErrors((errorsPrev) => {
        return { ...errorsPrev, marksNotValid: false }
      })
    } else {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyMarks: false }
      })
      if (/^-?\d+\.?\d*$/.test(marksToBeValidated)) {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksNotValid: false }
        })
        if (marksToBeValidated <= 0) {
          setErrors((errorsPrev) => {
            return { ...errorsPrev, marksLessThanZero: true }
          })
        } else {
          setErrors((errorsPrev) => {
            return { ...errorsPrev, marksLessThanZero: false }
          })
        }
      } else {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksLessThanZero: false }
        })
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksNotValid: true }
        })
      }
    }
  }

  const handleEdit = (event, setting, choiceIndex) => {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    if (setting === 'question') {
      if (htmlToString(event) === '') {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyQuestion: true }
        })
        dummyQuestion.question = '<p></p>'
      } else {
        dummyQuestion.question = event
      }
    }

    if (setting === 'choice') {
      dummyQuestion.choices[choiceIndex].value = event.target.value
    }
    if (setting === 'marks') {
      marksValidation(event.target.value)
      dummyQuestion.marks = event.target.value
    }
    setLocalQuestion(dummyQuestion)
  }

  const RemoveId = (dummyQuestion) => {
    const modified = dummyQuestion?.choices?.map(({ id, ...rest }) => {
      let returnObj
      if (typeof id === 'string' && id.includes('Added')) {
        returnObj = { ...rest }
      } else {
        returnObj = { id, ...rest }
      }
      return returnObj
    })
    const temp = dummyQuestion
    temp.choices = modified
    return temp
  }

  const updateQuestion = () => {
    const dummyLocalQuestion = localQuestion
    dummyLocalQuestion.question = textEditorQuestion.question
    setBackUpLocalQuestion(dummyLocalQuestion)
    setLocalQuestion(() => dummyLocalQuestion)
  }
  const findSimilarElements = (choicesArray) => {
    const choiceCounts = {}

    choicesArray.forEach((element) => {
      choiceCounts[element] = (choiceCounts[element] || 0) + 1
    })

    const similarElements = Object.keys(choiceCounts || {}).filter(
      (element) => choiceCounts[element] > 1
    )
    return similarElements
  }

  const handleSave = async (value = '') => {
    updateQuestion()
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    const isOnlyOneChoicePresent =
      dummyLocalQuestion?.choices?.filter((choice) => {
        return choice?.value
      })?.length === 1
    const areEmptyChoicesPresent =
      dummyLocalQuestion?.choices?.filter((choice) => {
        return !choice?.value
      })?.length > 0
    const isCorrectChoicePresent =
      dummyLocalQuestion?.choices?.filter((choice) => {
        return !!choice?.isCorrect
      })?.length > 0
    const similarChoices = findSimilarElements(
      dummyLocalQuestion?.choices?.map((item) => item?.value)
    )
    if (htmlToString(dummyLocalQuestion?.question).trim() === '') {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyQuestion: true }
      })
      if (addQuestion) setSave(false)
      return
    }
    setErrors((errorsPrev) => {
      return { ...errorsPrev, emptyQuestion: false }
    })

    if (isOnlyOneChoicePresent) {
      setErrors((errorsPrev) => {
        setErrorMessage('Please add more choices')
        return { ...errorsPrev, onlyOneChoicePresent: true }
      })
      if (addQuestion) setSave(false)
      return
    }

    if (areEmptyChoicesPresent) {
      setErrors((errorsPrev) => {
        setErrorMessage('Empty choices are not allowed')
        return { ...errorsPrev, emptyChoicesPresent: true }
      })
      if (addQuestion) setSave(false)
      return
    }
    if (!isCorrectChoicePresent) {
      setErrors((errorsPrev) => {
        setErrorMessage('Select a correct choice')
        return { ...errorsPrev, correctChoiceNotSelected: true }
      })
      if (addQuestion) setSave(false)
      return
    }
    if (similarChoices?.length !== 0) {
      setErrors((errorsPrev) => {
        setErrorMessage('Similar Choices are present')
        return { ...errorsPrev, similarChoicesPresent: true }
      })
      if (addQuestion) setSave(false)
      return
    }
    dummyLocalQuestion.marks = parseInt(dummyLocalQuestion.marks, 10)
    setBackUpLocalQuestion(dummyLocalQuestion)
    // setLocalQuestion({})
    dummyLocalQuestion.quizID = quizId
    if (value === 'duplicateEdit') {
      await postNewQuestion.mutateAsync(RemoveId(dummyLocalQuestion))
      setEditOngoing(false)
      return
    }
    if (!addQuestion) {
      if (dummyLocalQuestion.isEditable === false) {
        deleteMappingQuestion.mutate({
          quizId,
          questionId: dummyLocalQuestion?.id,
        })
        delete dummyLocalQuestion.id
        postQuestion.mutate(RemoveId(dummyLocalQuestion))
      } else {
        editQuestionQuery.mutate(RemoveId(dummyLocalQuestion))
      }
    }
    if (addQuestion) {
      delete dummyLocalQuestion.id
      postQuestion.mutate(RemoveId(dummyLocalQuestion))
    }
    setEditOngoing(false)
  }
  useEffect(() => {
    if (save && addQuestion) {
      handleSave()
      // setLocalQuestion(question)
    }
  }, [save, addQuestion])

  const handleClick = () => {
    deleteQuestion.mutate({
      quizId,
      questionId: deleteQuestionId,
      questionType:
        questionMcqType === 'multi-choice' ? 'mcq' : questionMcqType,
    })
    setOpenDialog(false)
    setDeleteQuestionId(undefined)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleDelete = () => {
    setDeleteQuestionId(question?.id)
    setOpenDialog(true)
  }

  const handleChoice = (setting) => {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    const index = dummyQuestion?.choices?.length
    if (setting === 'Add')
      dummyQuestion.choices
        .splice(index + 1, 0, {
          id: `Added${uuidv4()}`,
          value: '',
          isCorrect: false,
        })
        .join()

    setLocalQuestion(dummyQuestion)
  }

  const handleCorrectChoice = (selectedChoiceId) => {
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    const modifiedChoices = dummyLocalQuestion.choices.map((choice) => {
      const copyChoice = JSON.parse(JSON.stringify(choice))
      copyChoice.isCorrect = choice?.id === selectedChoiceId
      return copyChoice
    })
    dummyLocalQuestion.choices = modifiedChoices
    setLocalQuestion(dummyLocalQuestion)
  }

  const handleMultiChoice = (selectedChoiceId) => {
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    const modifiedChoices = dummyLocalQuestion.choices.map((choice) => {
      if (choice?.id === selectedChoiceId)
        return { ...choice, isCorrect: !choice.isCorrect }
      return choice
    })
    dummyLocalQuestion.choices = modifiedChoices
    setLocalQuestion(dummyLocalQuestion)
  }

  const handleRemoveChoice = (choiceIndex) => {
    const dummyQuestion = JSON.parse(JSON.stringify(localQuestion))
    const filterChoices = dummyQuestion?.choices?.filter(
      (x) => x.id !== choiceIndex
    )
    dummyQuestion.choices = filterChoices
    setLocalQuestion(dummyQuestion)
  }
  const handleCancel = () => {
    setEditOngoing(false)
    setEditQuestion(NaN)
    setLocalQuestion(backUpLocalQuestion)
    setAddQuestion(false)
    setTextEditorQuestion((prevQuestion) => {
      const updatePrevQuestion = { ...prevQuestion }
      updatePrevQuestion.value = backUpLocalQuestion?.question
      return updatePrevQuestion
    })
    if (isMultipleAnswers && localQuestion?.type === 'mcq') {
      SetisMultipleAnswers(() => false)
    }
  }
  const handleCancelAddMcq = () => {
    setEditOngoing(false)
    setAddQuestion(false)
    setCancelAddQuestion(true)
    setEditViewQuestion({})
    setLocalQuestion({})
  }

  const handleChange = () => {
    if (editOngoing) {
      setErrorMessage('Please finish the previous edit ')
    } else {
      setLocalQuestion(question)
      setEditQuestion(questionIndex)
      setEditOngoing(true)
    }
  }

  const updateQuestionInSelectedQuizzes = async () => {
    updateQuestion()
    const dummyLocalQuestion = JSON.parse(JSON.stringify(localQuestion))
    delete dummyLocalQuestion.id
    dummyLocalQuestion.quizId = quizId
    await postNewQuestion.mutateAsync(RemoveId(dummyLocalQuestion))
  }

  return (
    <Grid container className={classes.root}>
      <CommonSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorMessage !== ''}
        autoHideDuration={1000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setErrorMessage('')}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        key={errorMessage}
        severity="error"
      />
      {editQuestion === questionIndex || addQuestion ? (
        <EditMcqQuestionTile
          question={question}
          addQuestion={addQuestion}
          questions={questions}
          questionIndex={questionIndex}
          localQuestion={localQuestion}
          setLocalQuestion={setLocalQuestion}
          errors={errors}
          questionTypeFromParent={questionTypeFromParent}
          isQuizStarted={isQuizStarted}
          quizStartTime={quizStartTime}
          handleEdit={handleEdit}
          isPublished={isPublished}
          handleQuestionTypeChange={handleQuestionTypeChange}
          quizType={quizType}
          codingQuestionSwitch={codingQuestionSwitch}
          textEditorQuestion={textEditorQuestion}
          setTextEditorQuestion={setTextEditorQuestion}
          handleCorrectChoice={handleCorrectChoice}
          handleRemoveChoice={handleRemoveChoice}
          handleChoice={handleChoice}
          editQuestionQuery={editQuestionQuery}
          postQuestion={postQuestion}
          editQuestion={editQuestion}
          adminEvaluate={adminEvaluate}
          setAdminEvaluate={setAdminEvaluate}
          handleCancel={handleCancel}
          handleCancelAddMcq={handleCancelAddMcq}
          handleSave={handleSave}
          handleMultiChoice={handleMultiChoice}
          showQuizesListModal={showQuizesListModal}
          setShowQuizesListModal={setShowQuizesListModal}
          affectedQuizes={affectedQuizes}
          updateQuestionInSelectedQuizzes={updateQuestionInSelectedQuizzes}
          setSelectedQuizzes={setSelectedQuizzes}
          selectedQuizzes={selectedQuizzes}
          quizId={quizId}
          setAddEditQuestion={setAddEditQuestion}
          setEditViewQuestion={setEditViewQuestion}
          editViewQuestion={editViewQuestion}
        />
      ) : (
        <>
          <McqQuestionTile
            questionIndex={questionIndex}
            editQuestion={editQuestion}
            addQuestion={addQuestion}
            question={question}
            setEditQuestion={setEditQuestion}
            handleChange={handleChange}
            isPublished={isPublished}
            isQuizStarted={isQuizStarted}
            quizStartTime={quizStartTime}
            deleteQuestion={deleteQuestion}
            isClosed={isClosed}
            handleDelete={handleDelete}
            questionTypeFromParent={questionTypeFromParent}
            isWriteAllowed={isWriteAllowed}
          />
        </>
      )}
      <ConfirmBox
        isOpenDialog={openDialog}
        handleClose={handleClose}
        handleClick={handleClick}
        boxTitle="Delete Question"
        boxDescription="Are you sure you want to delete this question?"
        styles={classes}
        buttonName="Delete"
      />
    </Grid>
  )
}

export default EditQuestionContent
