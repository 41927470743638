import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import HelveticaNeue from './Assets/Fonts/HelveticaNeue.ttf'

const helveticaNeue = {
  fontFamily: 'HelveticaNeue',
  fontDisplay: 'swap',

  src: `
    local('HelveticaNeue'),
    url(${HelveticaNeue}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      h3: {
        fontWeight: 700,
        fontSize: '30px',
      },
      h6: {
        fontWeight: 500,
        fontSize: '14px',
      },
      h4: {
        fontWeight: 500,
        fontSize: '20px',
      },
      h5: {
        fontWeight: 400,
        fontSize: '20px',
      },
      fontFamily: ['Helvetica Neue', 'HelveticaNeue', 'Helvetica'].join(','),
      button: {
        textTransform: 'none',
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [helveticaNeue],
          },
        },
      },
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },

    overrides: {
      MuiTabs: {
        root: {
          backgroundColor: 'white',
          fontSize: '14px',
        },
      },
      MuiTypography: {
        body1: {
          fontSize: '14px',
        },
        h3: {
          fontWeight: 700,
          fontSize: '30px',
        },
        h6: {
          fontWeight: 500,
          fontSize: '14px',
        },
        h4: {
          fontWeight: 500,
          fontSize: '20px',
        },
        h5: {
          fontWeight: 400,
          fontSize: '20px',
        },
      },
      MuiButton: {
        root: {
          fontSize: '14px',
          backgroundColor: '#00B7A4',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#009685',
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '0.9rem',
          color: '#FFFFFF',
          backgroundColor: '#000000',
          padding: '0.7rem 0.7rem',
          borderRadius: '3px',
        },
        arrow: {
          color: '#000000',
          '&::before': {
            backgroundColor: '#000000',
          },
        },
      },
      MuiRadio: {
        root: {
          color: '#231F20',
        },
        colorSecondary: {
          '&$checked': {
            color: '#00B7A4',
          },
        },
      },
      MuiChip: {
        root: {
          backgroundColor: '#F3F7F7',
        },
      },

      MuiInputBase: {
        input: {
          paddingLeft: '10px ',
          paddingRight: 0,
          paddingBottom: '5px',
        },
      },
      // MuiFilledInput: {
      //   root: {
      //     backgroundColor: ''
      //   },
      //   input: {
      //     padding: '12px 0 12px 12px',
      //   },
      // },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'white',
            borderColor: 'brown',
          },

          '&:before': {
            borderColor: 'orange',
          },
          '&:after': {
            borderColor: 'green',
          },
        },
      },
    },

    palette: {
      background: {
        default: '#F3F7F7',
        main: '#CCF1ED',
        white: '#FFFFFF',
        grey: '#E6E6E6',
        delete: '#F8D7D9',
      },
      border: {
        main: '#D7D8DE',
      },
      text: { primary: '#262B4B', secondary: '#262A4C' },
      primary: { main: '#212649' },
      secondary: { main: '#00B7A4', contrastText: '#FFFFFF' },
      delete: { main: '#F8D7D9', contrastText: 'red' },

      lightHighlight: { main: '#F0F0FD' },
      footer: { main: '#17173F', contrastText: 'whitesmoke' },
      quizHeader: { main: '#26225E', contrastText: 'whitesmoke' },
      errorColor: { main: 'crimson' },
      successColor: { main: 'forestgreen' },
      partialSuccessColor: { main: 'goldenrod' },

      primarySelection: { main: '#00B7A4', contrastText: '#FFFFFF' },

      loader: { main: '#00B7A4' },
      header: { main: '#262B4B', contrastText: '#FFFFFF' },

      darkTextColor: { main: '#262B4B' },
      lightTextColor: { main: '#FFFFFF' },
      grayTextColor: { main: '#8E8E8E' },

      lightBackground: { main: '#FFFFFF', contrastText: '#262B4B' },
      darkBackground: { main: '#F3F7F7', contrastText: '#262B4B' },

      borders: { main: '#D4D5DB' },
      dividerLines: { main: '#E6E6E6' },
      lightDividerLines: { main: '#EDEFF0' },
      headerDividerLines: { main: '#383D5A' },

      highlight: { main: 'rgba(0, 183, 164, 0.1)' },
      inputValidation: {
        main: 'crimson',
      },
      quizNotPublished: {
        text: '#FF0000',
        backgroundColor: '#ffeaea',
      },
      primaryButton: {
        main: '#00B7A4',
        contrastText: '#FFFFFF',
        fontWeight: 600,
      },
      secondaryButton: {
        main: '#FFFFFF',
        contrastText: '#00B7A4',
        fontWeight: 600,
        hover: 'e6fcfa',
      },
      tertiaryButton: {
        main: '#D4D5DB',
        contrastText: '#262B4B',
        fontWeight: 600,
      },
      navigateButton: {
        main: '#212649',
        text: '#FFFFFF',
        hover: '#323b78',
        disabled: '#F8F8F8',
      },
      cancelButton: {
        main: '#FFFFFF',
        hover: '#F6F6F8',
        text: '#595C61',
      },
      dangerPrimaryButton: {
        main: '#E73F32',
        hover: '#D10202',
        text: '#FFFFFF',
      },
      dangerSecondaryButton: {
        main: '#FF2D2D',
        hover: '#FDECE9',
      },
    },
  })
)
export default theme
