import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { useStyle } from './EditQuizInfoStyles'

function EditQuizInfo({
  quizInfo,
  updateQuizInfo,
  isPublished,
  isClosed,
  isWriteAllowed,
  setErrorMessage,
}) {
  const classes = useStyle({ publish: isPublished })
  const [errors, setErrors] = useState({
    emptyDescription: false,
  })
  function handleChangeDescription(event) {
    if (event?.target?.value === '') {
      setErrors({ ...errors, emptyDescription: true })
    } else {
      setErrors({
        ...errors,
        emptyDescription: false,
      })
    }
    if (event.target.value?.length > 300) {
      setErrorMessage('Description limit exceeded!!')
      return
    }
    updateQuizInfo((prevQuizInfo) => {
      const updatePrevQuiz = { ...prevQuizInfo }
      updatePrevQuiz.description = event.target.value
      return updatePrevQuiz
    })
  }
  const handleChangeInstructions = (event) => {
    updateQuizInfo((prevQuizInfo) => {
      const updatePrevQuiz = { ...prevQuizInfo }
      updatePrevQuiz.instructions = event.target.value
      return updatePrevQuiz
    })
  }

  const toolTipRender = (x) => {
    if (!isWriteAllowed) {
      return 'This setting cannot be modified for a viewer permission'
    }
    if (x === 'Description') {
      if (isClosed) {
        return ' Description cannot be modified for a closed quiz'
      }
      if (new Date() > quizInfo?.startTime && isPublished) {
        return ' Description cannot be modified for ongoing quiz'
      }
    }
    if (x === 'Instructions') {
      if (isClosed) {
        return 'Instructions cannot be modified for a closed quiz'
      }
      if (new Date() > quizInfo?.startTime && isPublished) {
        return 'Instructions cannot be modified for ongoing quiz'
      }
    }
    return ''
  }
  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <div>
          {/* // */}
          <div className={classes.descriptionHeader}>
            <span className={classes.inputTitle}>Description</span>
            <span className={classes.descriptionLength}>
              {quizInfo.description?.length ? quizInfo.description?.length : 0}
              /300
            </span>
          </div>
          <Tooltip
            arrow
            placement="bottom-start"
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            title={toolTipRender('Description')}
          >
            <TextField
              disabled={
                (new Date() > quizInfo?.startTime && isPublished) || isClosed
              }
              required
              variant="outlined"
              rows="5"
              margin="dense"
              placeholder="Enter Description"
              type="text"
              onChange={(event) => handleChangeDescription(event)}
              value={quizInfo?.description === null ? '' : quizInfo.description}
              error={errors?.emptyDescription}
              fullWidth
              multiline
              className={classes.input}
            />
          </Tooltip>
          {errors?.emptyDescription && (
            <span className={classes.validationText}>
              Please enter the description
            </span>
          )}
        </div>
        <div style={{ marginTop: '32px' }}>
          <span className={classes.inputTitle}>Instructions</span>
          <Tooltip
            arrow
            placement="bottom-start"
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            title={toolTipRender('Instructions')}
          >
            <TextField
              disabled={
                (new Date() > quizInfo?.startTime && isPublished) || isClosed
              }
              required
              variant="outlined"
              rows="5"
              margin="dense"
              placeholder="User can enter to take next line"
              type="text"
              value={quizInfo?.instructions}
              onChange={handleChangeInstructions}
              fullWidth
              multiline
              className={classes.input}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
export default EditQuizInfo
