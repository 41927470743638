import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 100,
  },
  appBar: {
    boxShadow: 'none',
  },
  headerWrapper: {
    display: 'flex',
  },
  creditIcon: {
    height: '20px',
    width: '20px',
  },
  avatar: {
    backgroundColor: theme.palette.tertiaryButton?.main,
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 400,
    width: 30,
    height: 30,
  },
  payment: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#E0E0E0',
    height: '150px',
    width: '120px',
  },
  logoutBotton: {
    backgroundColor: '#E0E0E0',
    color: 'black',
    ' &:hover': {
      backgroundColor: '#E0E0E0',
    },
  },
  paymentButton: {
    backgroundColor: '#E0E0E0',
    color: 'black',
    ' &:hover': {
      backgroundColor: '#E0E0E0',
    },
  },
  menuItem: {
    paddingBottom: '3px',
  },
  styleCredit: {
    display: 'flex',
    paddingTop: '18px',
    cursor: 'Default',
  },
  credits: {
    paddingTop: '14px',
    fontSize: 19,
    fontWeight: 'bold',
    paddingRight: 6,
    paddingLeft: '6px',
    cursor: 'Default',
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgba(0, 20, 40, .7)',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(0, 20, 40, .7)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    '@media only screen and (max-width:1200px)': {
      padding: '4px',
      margin: '0px',
      transform: 'translate3d(30px, 0px, 0px) !important',
    },
    '@media only screen and (min-width:1200px)': {
      padding: '5px',
      margin: '0px',
      transform: 'translate3d(20px, 0px, 0px) !important',
    },
  },
  toolbar: {
    minHeight: 40,
    maxHeight: 46,
    paddingLeft: theme.spacing(2),
    paddingRight: 10,
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
  },
  title: {
    flexGrow: 1,
  },
  dialog: {
    minWidth: '25rem',
    minHeight: '35rem',
    '@media screen and (max-width:425px)': {
      minWidth: '16rem',
      minHeight: '30rem',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    alignItems: 'center',
  },
  dialogContent: {
    padding: '0px !important',
  },
  dialogData: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}))
export { useStyles }
