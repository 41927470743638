import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useWindowSize from '../../hooks/useWindowSize'
import { usePaymentResponsePostQuery } from '../../Common/Queries/PaymentResponseQueries'
import Loader from '../Loader/Loader'
import PaymentSuccessIcon from '../../icons/PaymentSuccessIcon'
import PaymentFailedIcon from '../../icons/PaymentFailedIcon'
import { useStyles } from './PaymentResponseStyles'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}
function PaymentResponse() {
  const classes = useStyles()
  const query = useQuery()
  const history = useHistory()
  const windowSize = useWindowSize()
  const transactionStatus = query?.get('transaction_status')
  const transactionAmount = +query?.get('transaction_amount')
  const resourceId = localStorage.getItem('id')
  const transactionID = query?.get('transaction_id')
  const zopPayReference = query?.get('zoppay_reference')
  const gatewayTransactionID = query?.get('gateway_transaction_id')
  const bankTransactionID = query?.get('bank_transaction_id')
  const udf1 = query?.get('udf1')
  const udf2 = query?.get('udf2')
  const [paymentResponseTimer, setPaymentResponseTimer] = useState(5)
  const [paymentStatus, setPaymentStatus] = useState('')

  const paymentResponseMutation = usePaymentResponsePostQuery(
    transactionID,
    resourceId,
    {
      staleTime: 0,
      onSuccess: (successData) => {
        setPaymentStatus(() => successData?.status)
        setTimeout(() => {
          history.push('/')
        }, 5000)
        setInterval(() => {
          setPaymentResponseTimer((prev) => (prev ? prev - 1 : 0))
        }, 1000)
      },
      onError: () => {
        setTimeout(() => {
          history.push('/payment')
        }, 5000)
        setInterval(() => {
          setPaymentResponseTimer((prev) => (prev ? prev - 1 : 0))
        }, 1000)
      },
    }
  )
  useEffect(() => {
    paymentResponseMutation?.mutate({
      transaction_status: transactionStatus,
      transaction_amount: transactionAmount,
      zoppay_reference: zopPayReference,
      gateway_transaction_id: gatewayTransactionID,
      bank_transaction_id: bankTransactionID,
      udf1,
      udf2,
    })
  }, [
    transactionStatus,
    transactionAmount,
    zopPayReference,
    gatewayTransactionID,
    bankTransactionID,
    udf1,
    udf2,
  ])
  if (paymentResponseMutation?.isError) {
    return (
      <Grid
        container
        direction="column"
        className={classes.root}
        style={{ height: `${windowSize?.height}px` }}
      >
        <Grid container alignItems="center" className={classes.body}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.responseIconAndTextWrapper}
          >
            <Grid item className={classes.paymentResponseIconWrapper}>
              <PaymentFailedIcon />
            </Grid>
            <Grid item className={classes.textWrapper}>
              <Typography className={classes.paymentInfo}>
                <span className={classes.paymentVerificationMessage}>
                  Payment Verification failed!
                </span>
                <Typography className={classes.paymentRedirectMessage}>
                  Taking you back to payments in {paymentResponseTimer} sec
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  if (paymentStatus === 'FAILED') {
    return (
      <Grid
        container
        direction="column"
        className={classes.root}
        style={{ height: `${windowSize?.height}px` }}
      >
        <Grid container alignItems="center" className={classes.body}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.responseIconAndTextWrapper}
          >
            <Grid item className={classes.paymentResponseIconWrapper}>
              <PaymentFailedIcon />
            </Grid>
            <Grid item className={classes.textWrapper}>
              <Typography className={classes.paymentInfo}>
                <span className={classes.paymentVerificationMessage}>
                  Payment failed!
                </span>
                <Typography className={classes.paymentRedirectMessage}>
                  Taking you back to payments in {paymentResponseTimer} sec
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  if (paymentStatus === 'SUCCESS') {
    return (
      <Grid
        container
        direction="column"
        className={classes.root}
        style={{ height: `${windowSize?.height}px` }}
      >
        <Grid container alignItems="center" className={classes.body}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.responseIconAndTextWrapper}
          >
            <Grid item className={classes.paymentResponseIconWrapper}>
              <PaymentSuccessIcon />
            </Grid>
            <Grid item className={classes.textWrapper}>
              <Typography className={classes.paymentInfo}>
                <span className={classes.paymentMessage}>
                  Payment successful!
                </span>
                <Typography className={classes.paymentRedirectMessage}>
                  Taking you back to dashboard in {paymentResponseTimer} sec
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <div
      className={classes.paymentResponseMutationLoader}
      style={{
        height: `${windowSize?.height}px`,
      }}
      data-testid="default-payment"
    >
      <Loader size="150px" />
    </div>
  )
}

export default PaymentResponse
