import React from 'react'

export default function DecreaseVolumeIcon({ hoverStates }) {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Reduce-volume-default"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-30">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <polygon
            id="Path"
            fill={hoverStates.decreaseVolume ? '#00B8A4' : '#262A4C'}
            fillRule="nonzero"
            points="4.375 10.125 4.375 14.875 7.54166667 14.875 11.5 18.8333333 11.5 6.16666667 7.54166667 10.125"
          />
          <g
            id="Group-12"
            transform="translate(13.000000, 11.916667)"
            stroke={hoverStates.decreaseVolume ? '#00B8A4' : '#262A4C'}
            strokeWidth="2"
          >
            <line
              x1="6"
              y1="0.583333333"
              x2="-2.43138842e-14"
              y2="0.583333333"
              id="Path-13"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
