import React from 'react'

function YellowTickCircleOutlinedIcon() {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="yellowIcon-testId"
    >
      <g
        id="Yellow-icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="utility/messaging/failure" fillRule="nonzero">
          <path
            d="M12,2.8125 C17.0717859,2.81812136 21.1818786,6.92821413 21.1875,12 C21.1875,17.0741161 17.0741161,21.1875 12,21.1875 C6.92588386,21.1875 2.8125,17.0741161 2.8125,12 C2.8125,6.92588386 6.92588386,2.8125 12,2.8125 Z M12,3.9375 C7.5472042,3.9375 3.9375,7.5472042 3.9375,12 C3.9375,16.4527958 7.5472042,20.0625 12,20.0625 C16.4507397,20.05754 20.05754,16.4507397 20.0625,12 C20.0625,7.5472042 16.4527958,3.9375 12,3.9375 Z"
            id="Color"
            fill="#FD950D"
          />
          <rect
            id="failure"
            fillOpacity="0"
            fill="#FFFFFF"
            x="-1.70530257e-13"
            y="0"
            width="24"
            height="24"
          />
          <path
            d="M9.57129,15.2036113 C9.37683957,15.2040094 9.19286725,15.1155259 9.07179,14.96337 L7.46631,12.941895 C7.24866783,12.6659406 7.29516769,12.2659438 7.57030998,12.0472759 C7.84545227,11.828608 8.24561942,11.8736186 8.46531,12.147945 L9.6738,13.67139 L15.637185,8.93409 C15.8155528,8.79124653 16.0566933,8.7547003 16.2693765,8.83827771 C16.4820598,8.92185512 16.6338066,9.11279293 16.6672071,9.3388543 C16.7006077,9.56491568 16.6105613,9.79157841 16.431135,9.93309 L9.968265,15.065925 C9.85516774,15.155118 9.71532604,15.2036113 9.57129,15.2036113 Z"
            id="Path"
            fill="#FD950D"
          />
        </g>
      </g>
    </svg>
  )
}

export default YellowTickCircleOutlinedIcon
