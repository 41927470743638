import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  myViewStyling: {
    display: 'flex',
  },
  myViewButton: {
    margin: '5px 0px 10px 20px',
    borderRadius: '0px',
    fontSize: '14px',
    ' &:hover': {
      backgroundColor: 'black',
    },
    backgroundColor: 'black',
    paddingBottom: (props) => (props?.currentTab === 'myView' ? '5px' : '9px'),
    borderBottom: (props) =>
      props?.currentTab === 'myView'
        ? `solid 4px ${theme?.palette?.primarySelection?.main}`
        : 'none',
  },
  defaultViewButton: {
    margin: '5px 0px 10px 20px',
    fontSize: '14px',
    borderRadius: '0px',

    border: 'none',
    ' &:hover': {
      backgroundColor: 'black',
    },
    backgroundColor: 'black',
    paddingBottom: (props) =>
      props?.currentTab === 'defaultView' ? '5px' : '9px',
    borderBottom: (props) =>
      props?.currentTab === 'defaultView'
        ? `solid 4px ${theme?.palette?.primarySelection?.main}`
        : 'none',
  },
  multiStreamContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 46px)',
    overflow: 'hidden',
    marginTop: '46px',
    backgroundColor: '#202124',
  },
  multiStreamHeader: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    padding: '10px 0px',
    backgroundColor: 'black',
  },
  liveCount: {
    flexGrow: '0',
    color: 'white',
    fontSize: '14px',
    padding: '28px 10px',
  },
  selectOptions: {
    color: 'black',
  },
  filterIcon: {
    backgroundColor: 'black',
    marginLeft: -30,
    marginTop: -8,
    height: 0,
    background: 'black',
  },
  sortByGrid: { display: 'flex', alignItems: 'center' },

  sortIcon: {
    marginTop: -8,
    height: 0,
    backgroundColor: 'white',
    marginLeft: -30,
    background: 'black',
  },
  selectDropdown: {
    height: '20px',
    marginTop: 1,
    marginLeft: -14,
    color: 'white',
    '&.Mui-focused': {
      color: 'black',
    },
    transform: 'scale(0.8)',
    fontSize: 18,
    marginRight: 10,
    padding: '28px 0px ',
    backgroundColor: 'black',
  },
  styleFilter: {
    marginTop: -19,
    marginRight: -25,
    backgroundColor: 'black',
    transform: 'scale(0.3)',
    background: 'black',
  },
  stylesort: {
    transform: 'scale(0.3)',
    marginTop: -19,
    marginRight: -25,
    backgroundColor: 'black',
    background: 'black',
  },
  name: {
    color: 'white',
    marginLeft: 5,
    fontSize: 16,
    background: 'black',
  },
  dropDown2: {
    marginLeft: -25,
  },
  dropDown1: {
    marginLeft: -20,
  },
  streamContainer: {
    width: '250px',
    height: '250px',
    paddingBottom: '30px',
    paddingTop: '16px',
    backgroundColor: 'white',
  },
  loader: {
    display: 'flex',
    flex: 1,
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navigatorBtn: {
    width: 'fit-content',
    height: 'fit-content',
    fontSize: '8px',
    padding: '0px',
    color: 'grey',
    borderRadius: '4px',
    border: '1px solid grey ',
    outline: 'none',
    background: '#FFFFFF',
  },
  paginationGrid: {
    marginTop: 'auto',
    width: '100%',
    justifyContent: 'center',
    direction: 'column',
    alignItems: 'center',
  },
  addCandidateButton: {
    paddingTop: '15px',
    display: 'flex',
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

  textFieldInformationText: {
    paddingLeft: '2px',
    paddingTop: '4px',
    fontSize: '10px',
    color: 'grey',
  },
}))
export { styles }
