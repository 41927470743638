import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { styles } from './PaginationStyles'

function Pagination({
  totalLiveCount,
  handleNext,
  handlePrevious,
  offset,
  pagesArray,
  limit,
  setOffset,
}) {
  const classes = styles({ offset, pagesArray, limit })
  return (
    <Grid className={classes.paginationMainGrid}>
      {totalLiveCount > 0 && (
        <IconButton onClick={handlePrevious} disabled={offset === 0}>
          <ArrowBackIosIcon className={classes.ArrowBackIosIcon} />
        </IconButton>
      )}
      <Grid className={classes.paginationDottedButtons}>
        {pagesArray?.map((_, index) => {
          return (
            <Grid key={index} className={classes.paginationDottedButtonsChild}>
              <Grid
                key={index}
                className={classes.paginationStyle}
                style={{
                  background: index === offset / limit ? '#00B7A4' : 'none',
                }}
                onClick={() => setOffset(index * limit)}
              />
            </Grid>
          )
        })}
      </Grid>
      {totalLiveCount > 0 && (
        <IconButton
          onClick={handleNext}
          disabled={pagesArray.length - 1 === offset / limit}
        >
          <ArrowForwardIosIcon
            className={classes.ArrowForwardIosIcon}
            disabled={pagesArray.length - 1 < 0}
          />
        </IconButton>
      )}
    </Grid>
  )
}

export default Pagination
