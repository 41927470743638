import React from 'react'
import Grid from '@material-ui/core/Grid'
import './Loader.css'

function Loader({ size }) {
  return (
    <Grid
      className="sk-circle"
      style={{ height: size, width: size }}
      data-testid="loader"
    >
      <Grid className="sk-circle1 sk-child" />
      <Grid className="sk-circle2 sk-child" />
      <Grid className="sk-circle3 sk-child" />
      <Grid className="sk-circle4 sk-child" />
      <Grid className="sk-circle5 sk-child" />
      <Grid className="sk-circle6 sk-child" />
      <Grid className="sk-circle7 sk-child" />
      <Grid className="sk-circle8 sk-child" />
      <Grid className="sk-circle9 sk-child" />
      <Grid className="sk-circle10 sk-child" />
      <Grid className="sk-circle11 sk-child" />
      <Grid className="sk-circle12 sk-child" />
    </Grid>
  )
}

export default Loader
