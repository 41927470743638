import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {
  Box,
  Container,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import ClockIcon from '@material-ui/icons/AccessTime'
import QuestionIcon from '@material-ui/icons/HelpOutline'
import Button from '@material-ui/core/Button'
// import { useHistory } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import DuplicateButton from '@material-ui/icons/FileCopy'
import Tooltip from '@material-ui/core/Tooltip'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import useCollapse from 'react-collapsed'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import DeleteIcon from '../../icons/Delete.svg'
import EditIcon from '../../icons/Edit.svg'
import Loader from '../Loader/Loader'
import { QUIZ_URL } from '../../constant'
import { ReactComponent as CopyLinkIcon } from '../../icons/CopyLink.svg'
import {
  isQuizExpired,
  isQuizStarted,
  isQuizUpcoming,
  modifyQuizIntervalFormat,
} from '../../utils'
import { useStyles } from './QuizCardStyles'

dayjs.extend(utc)
dayjs.extend(timezone)

const QuizCard = ({
  QuizTitle,
  StartDate,
  EndDate,
  Duration,
  QuestionCount,
  handleEdit,
  handleDuplicate,
  handleDelete,
  LoaderId,
  endQuizLoaderId,
  quizId,
  isPublished,
  isProctored,
  isClosed,
  slug,
  quizType,
  handleEndQuiz,
  isLoading,
  isWriteAllowed,
}) => {
  const classes = useStyles()
  const [copied, setCopied] = useState(false)
  const [, setIsCurrentQuizExpired] = useState(isQuizExpired(EndDate))
  const [isCurrentQuizUpcoming, setIsCurrentQuizUpcoming] = useState(
    isQuizUpcoming(StartDate)
  )
  useEffect(() => {
    const interval = setInterval(
      () => setIsCurrentQuizExpired(isQuizExpired(EndDate)),
      1000
    )
    const quizNotStarted = setInterval(
      () => setIsCurrentQuizUpcoming(isQuizUpcoming(StartDate)),
      1000
    )
    return () => {
      clearInterval(interval)
      clearInterval(quizNotStarted)
    }
  }, [])

  const copy = () => {
    const el = document.createElement('input')
    el.value = `${QUIZ_URL}/quiz/${slug}`
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    setCopied(true)
  }

  function Collapsible() {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    return (
      <div>
        <div className={classes.collapseArrow} {...getToggleProps()}>
          <Typography className={classes.quizDate}>
            {modifyQuizIntervalFormat(StartDate, EndDate)}
          </Typography>
          <div style={{ flex: 1 }} />
          {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
        <div {...getCollapseProps()}>
          <Grid container className={classes.quizDetailsContainer} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box display="flex">
                <Box order={1} marginRight="10%">
                  <Box display="flex">
                    <Box order={1} marginRight={1.25}>
                      <Avatar variant="rounded" className={classes.avatar}>
                        <ClockIcon />
                      </Avatar>
                    </Box>
                    <Box disabled={isPublished} order={2}>
                      <Typography className={classes.quizDetailsTitle}>
                        Duration
                      </Typography>
                      <Typography>{Duration} Minutes</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box order={2}>
                  <Box display="flex">
                    <Box order={1} marginRight={1.25}>
                      <Avatar variant="rounded" className={classes.avatar}>
                        <QuestionIcon />
                      </Avatar>
                    </Box>
                    <Box order={2}>
                      <Typography className={classes.quizDetailsTitle}>
                        Questions
                      </Typography>
                      <Typography>{QuestionCount || 0} Number</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {(isPublished || isClosed) && !isCurrentQuizUpcoming && (
              <Grid
                item
                xs={12}
                sm={6}
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box display="flex">
                  <Box order={1} paddingRight={1.25}>
                    {!isClosed &&
                      isProctored &&
                      (EndDate
                        ? new Date().getTime() < new Date(EndDate).getTime()
                        : true) && (
                        <NavLink
                          to={{
                            pathname: `./live-candidates/${quizId}`,
                            state: {
                              quizTitle: QuizTitle,
                              quizStartTime: StartDate,
                              quizEndTime: EndDate,
                            },
                          }}
                          className={classes.link}
                        >
                          <Button variant="outlined" className={classes.button}>
                            View Live
                          </Button>
                        </NavLink>
                      )}
                  </Box>
                  <Box order={2} paddingRight={1.25}>
                    {(isPublished || isClosed) && (
                      <NavLink
                        to={{
                          pathname: `./quiz/${quizId}/responses`,
                          state: {
                            quizTitle: QuizTitle,
                            quizStartTime: StartDate,
                            quizEndTime: EndDate,
                            quizType,
                            quizId,
                            isProctored,
                            isClosed,
                          },
                        }}
                        className={classes.link}
                      >
                        <Button variant="outlined" className={classes.button}>
                          View Results
                        </Button>
                      </NavLink>
                    )}
                  </Box>
                  <Box order={2}>
                    {endQuizLoaderId === quizId ? (
                      <IconButton>
                        <Loader size={25} />
                      </IconButton>
                    ) : (
                      !isClosed &&
                      (EndDate
                        ? new Date().getTime() < new Date(EndDate).getTime()
                        : true) &&
                      isWriteAllowed && (
                        <Button
                          variant="outlined"
                          onClick={(e) => handleEndQuiz(e)}
                          className={classes.button}
                        >
                          End Quiz
                        </Button>
                      )
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    )
  }
  return (
    <Paper className={classes.root} cursor="pointer">
      <Container className={classes.quizCardContainer}>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Typography
              className={classes.quizTitle}
              onClick={(e) => handleEdit(e)}
            >
              {QuizTitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!isQuizExpired(EndDate) && (
              <>
                {isPublished ? (
                  <>
                    <Hidden xsDown>
                      <Chip
                        onClick={copy}
                        className={classes.copyUrlButton}
                        label={!copied ? 'Copy Quiz Link' : 'Copied!'}
                      />
                    </Hidden>
                    <Hidden smUp>
                      <Tooltip
                        arrow
                        title="Copy quiz link"
                        classes={{
                          arrow: classes.arrow,
                          tooltip: classes.tooltip,
                        }}
                      >
                        <IconButton onClick={copy}>
                          <CopyLinkIcon className={classes.CopyLinkIcon} />
                        </IconButton>
                      </Tooltip>
                    </Hidden>
                  </>
                ) : (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.quizNotPublishedContainer}
                  >
                    <Typography className={classes.quizNotPublishedText}>
                      Draft
                    </Typography>
                  </Grid>
                )}
              </>
            )}
            <Tooltip
              arrow
              title={isClosed ? 'Quiz cannot be edited for a closed quiz.' : ''}
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            >
              <span>
                <IconButton disabled={isClosed} onClick={(e) => handleEdit(e)}>
                  <img src={EditIcon} alt="edit" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title="Duplicate Quiz"
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            >
              <IconButton onClick={(e) => handleDuplicate(e)}>
                <DuplicateButton />
              </IconButton>
            </Tooltip>

            {LoaderId === quizId && isLoading ? (
              <IconButton>
                <Loader size={25} />
              </IconButton>
            ) : (
              !isQuizStarted(StartDate) && (
                <IconButton onClick={(e) => handleDelete(e)}>
                  <img src={DeleteIcon} alt="delete" />
                </IconButton>
              )
            )}
          </Grid>
        </Grid>
        <div> {Collapsible()}</div>
      </Container>
    </Paper>
  )
}

export default QuizCard
