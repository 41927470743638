import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useResultIdGetQuery } from '../../Common/Queries/ResultQuery'
import ResultPage from '../ResultPage/ResultPage'
import Loader from '../Loader/Loader'
import { styles } from './ResultSheetStyles'

function ResultSheet() {
  const [resultPage, updateResultPage] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const { candidateId, id } = useParams()
  const classes = styles()
  const {
    data: resultSheetResponse,
    isLoading: isResultSheetLoading,
    isSuccess: isResultSheetSuccess,
    isError: isResultSheetError,
  } = useResultIdGetQuery(candidateId, id, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetch: false,
    retry: false,
    onError: (err) => {
      setErrorMessage(err?.message)
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  useEffect(() => {
    if (isResultSheetSuccess) {
      updateResultPage(resultSheetResponse?.data?.data)
    }
  }, [isResultSheetSuccess])

  if (isResultSheetLoading) {
    return (
      <div className={classes.loader}>
        <Loader size="8rem" />
      </div>
    )
  }
  if (isResultSheetError) {
    return (
      <div className={classes.evaluationErrorMessage}>
        {/* <Typography variant="h5">Failed to load Results.</Typography> */}
        {/* <Typography variant="h5">Evaluation is in progress.</Typography> */}
        <Typography variant="h5">{errorMessage}</Typography>
      </div>
    )
  }
  return (
    <Grid className={classes.root}>
      <Helmet>
        <title>Result {candidateId}</title>
      </Helmet>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.marksWrapper}
      >
        <Typography
          className={classes.evaluatedMarks}
          data-testid="marks-testId"
        >
          Marks: {resultPage?.evaluatedMarks}/{resultPage?.maximumMarks}
        </Typography>
      </Grid>
      {resultPage && (
        <Grid item className={classes.resultPageWrapper}>
          <ResultPage result={resultPage} />
        </Grid>
      )}
    </Grid>
  )
}

export default ResultSheet
