import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    width: '90%',
    paddingBottom: '3%',
  },
  paper: {
    padding: '64px 72px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
    },
  },
  editPaper: {
    padding: '33px 72px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
    },
  },
  marksType: {
    '@media only screen and (max-width:599px)': {
      display: 'block',
      marginTop: '24px',
    },
  },
  isCorrect: {
    border: '1px solid #01B7A4',
    backgroundColor: '#ECFBF6',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  question: {
    fontSize: '20px',
    paddingBottom: '4px',
    color: theme?.palette?.text?.primary,
    marginBlockStart: '0em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  tileMarks: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  marks: {
    background: theme?.palette?.background?.grey,
    padding: '15px 20px',
    borderRadius: '4px',
    height: '62px',
    width: '100%',
  },
  marksCount: {
    display: 'table',
    margin: '0 auto',
    color: theme?.palette?.text?.primary,
    lineHeight: 1.1,
  },
  marksLabel: {
    display: 'table',
    margin: '0 auto',
    color: theme?.palette?.text?.secondary,
    lineHeight: 1.5,
    fontSize: '12px',
    opacity: '80%',
  },
  index: {
    display: 'flex',
    alignItems: 'center',
  },
  choices: {
    marginTop: '1%',
  },
  choice: {
    height: '3rem',
  },
  checkIcon: {
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '5%',
  },
  checkIconContainer: {
    padding: '1em',
  },
  editQuestion: {
    paddingBottom: '3%',
  },
  inputChoiceValue: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  selectedChoiceValue: {
    fontSize: 16,
    marginLeft: '12px',
    color: '#262B4B',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  choiceValue: {
    fontSize: 16,
    marginLeft: '36px',
    color: '#262B4B',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },

  choiceContainer: {
    borderRadius: '4px',
    marginLeft: '20px',
    backgroundColor: theme?.palette?.background?.grey,
  },
  radioChoiceContainer: {
    textAlign: 'center',
    width: '40',
    borderLeft: '1px solid lightgrey',
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    borderRadius: '5px 0 0 5px',
  },
  selectedChoiceText: {
    fontSize: '12px',
    color: '#01B78F',
    marginRight: '5%',
  },
  marksQuestionValidationContainer: {
    padding: '0px 8px 0px 8px',
  },
  marksValidationContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  validationText: {
    fontSize: '12px',
    color: theme?.palette?.inputValidation?.main,
  },
  marksHeading: {
    width: '100%',
    textAlign: 'center',
    color: '#262A4C',
    fontSize: '12px',
  },
  questionIndex: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '1.1',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },

    // padding: '3px 0px 16px 0px',
  },
  cancelAndSaveButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  loader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  questionHeader: {
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  deleteButton: {
    backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.dangerSecondaryButton?.main}`,
    color: theme?.palette?.dangerSecondaryButton?.main,
    lineHeight: '14px',
    padding: '10px 20px',
    marginRight: '10px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: theme?.palette?.dangerSecondaryButton?.hover,
    },
    [theme.breakpoints.down('xs')]: {
      width: '50px',
    },
  },
  editButton: {
    backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.primaryButton?.main}`,
    color: theme?.palette?.primaryButton?.main,
    lineHeight: '14px',
    padding: '10px 20px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
    },
  },
  cancelButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    // border: `1px solid ${theme?.palette?.cancelButton?.text}`,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },

    marginTop: '10px',
    marginRight: '10px',
    '@media only screen and (max-width:460px)': {
      marginRight: '4px',
    },
  },
  titleDialog: {
    padding: '24px',
    pointerEvents: 'none',
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    paddingBottom: '4px',
  },
  dialogActions: {
    padding: '0px 24px 24px',
  },
  dialogCancelButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    // border: `1px solid ${theme?.palette?.cancelButton?.text}`,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },
  },
  dialogDeleteButton: {
    backgroundColor: theme?.palette?.dangerPrimaryButton?.main,
    color: theme?.palette?.dangerPrimaryButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.dangerPrimaryButton?.hover,
    },
  },
}))
export { useStyles }
