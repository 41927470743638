import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme?.palette?.darkBackground?.main,
  },
  header: {
    height: '46px',
    width: '100%',
    background: theme?.palette?.header?.main,
    paddingLeft: '16px',
  },
  body: {
    height: 'calc(100% - 46px)',
    width: '100%',
    overflowY: 'auto',
  },
  illustrationAndTextWrapper: {
    width: '100%',
    paddingBottom: '25%',
    '@media only screen and (min-width:426px)': {
      paddingBottom: '15%',
    },
    '@media only screen and (min-width:769px)': {
      paddingBottom: '10%',
    },
  },
  illustrationWrapper: {
    width: '75%',

    '@media only screen and (min-width:426px)': {
      width: '55%',
    },
    '@media only screen and (min-width:769px)': {
      width: '43%',
    },
    '@media only screen and (min-width:1025px)': {
      width: '33%',
    },
  },
  textWrapper: {
    width: '75%',

    '@media only screen and (min-width:426px)': {
      width: '55%',
    },
    '@media only screen and (min-width:769px)': {
      width: '45%',
    },
    '@media only screen and (min-width:1025px)': {
      width: '35%',
    },
  },
  message: {
    fontWeight: 600,
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
    '@media only screen and (min-width:426px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '20px',
    },
  },
}))
export { useStyles }
