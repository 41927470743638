import React from 'react'

export default function FullScreenIcon({ hoverStates }) {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Full-screen-default"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-8">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <g
            id="Group-7"
            transform="translate(6.000000, 6.000000)"
            stroke={hoverStates.fullScreen ? '#00B8A4' : '#262A4C'}
            strokeLinecap="round"
            strokeWidth="2"
          >
            <polyline id="Path-11" points="0 3.17647088 0 0 3.17647067 0" />
            <polyline
              id="Path-11"
              transform="translate(10.411764, 1.588235) scale(-1, 1) translate(-10.411764, -1.588235) "
              points="8.8235289 3.17647088 8.8235289 4.27997462e-13 11.9999996 4.27997462e-13"
            />
            <polyline
              id="Path-11"
              transform="translate(1.588235, 10.411765) scale(1, -1) translate(-1.588235, -10.411765) "
              points="0 12 0 8.82352912 3.17647067 8.82352912"
            />
            <polyline
              id="Path-11"
              transform="translate(10.411764, 10.411765) scale(-1, -1) translate(-10.411764, -10.411765) "
              points="8.8235289 12 8.8235289 8.82352912 11.9999996 8.82352912"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
