import React, { useState, useEffect, useRef, useCallback } from 'react'
// import DOMPurify from 'dompurify'
import TextField from '@material-ui/core/TextField'
// import Input from '@material-ui/core/Input'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
  Hidden,
  // Tooltip,
  // Grid,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import useKeypress from '../../hooks/useKeyPress'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { styles } from './InlineEditStyles'

function InlineEdit({
  text = '',
  onSetText,
  quizStartTime,
  isPublished,
  // isClosed,
  isWriteAllowed,
  setErrorMessage,
}) {
  const wrapperRef = useRef(null)
  const inputRef = useRef(null)
  const [isInputActive, setIsInputActive] = useState(false)
  const [emptyTitle, setEmptyTitle] = useState(false)
  const [titleUpperLimit, setTitleUpperLimit] = useState(false)
  const [, setCurrentTitle] = useState(inputRef)
  const [openCreateQuizDialog, setOpenCreateQuizDialog] = useState(false)
  const classes = styles({
    // titleLength: text?.length,
  })

  const enter = useKeypress('Enter')
  const esc = useKeypress('Escape')

  // check to see if the user clicked outside of this component
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      onSetText(text)
      setIsInputActive(false)
    }
  })

  const onEnter = useCallback(() => {
    if (enter) {
      onSetText(text)
      setIsInputActive(false)
    }
  }, [enter, text, onSetText])

  const onEsc = useCallback(() => {
    if (esc) {
      onSetText(text)
      setIsInputActive(false)
    }
  }, [esc, text])

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isInputActive) {
      inputRef.current?.focus()
    } else {
      inputRef.current?.blur()
    }
  }, [isInputActive])

  useEffect(() => {
    if (isInputActive) {
      // if Enter is pressed, save the text and close the editor
      onEnter()
      // if Escape is pressed, revert the text and close the editor
      onEsc()
    }
  }, [onEnter, onEsc, isInputActive, text]) // watch the Enter and Escape key presses

  const titleValidations = (title) => {
    if (typeof title !== 'string') return false
    if (title.length === 0) {
      setEmptyTitle(true)
    } else {
      setEmptyTitle(false)
    }
    if (title.length > 100) {
      setErrorMessage('Title limit exceeded!!')
      setTitleUpperLimit(true)
      return false
    }
    setTitleUpperLimit(false)
    return true
  }
  const handleInputChange = useCallback(
    (event) => {
      // sanitize the input a little
      const value = event.target.value.trimLeft()
      // inputRef.current.size = Math.max(10, value.length)
      if (titleValidations(value)) onSetText(value)
    },
    [onSetText]
  )

  const handleSpanClick = useCallback(() => {
    setIsInputActive(true)
  }, [setIsInputActive])

  const handleEditQuizName = () => {
    setOpenCreateQuizDialog(() => true)
  }

  const handleCreateQuizDialogClose = () => {
    setOpenCreateQuizDialog(() => false)
    setCurrentTitle(text)
  }

  // useEffect(() => {
  //   const input = inputRef.current
  //   input.style.width = text?.length
  //   input.style.width = `${input.scrollWidth}%`
  // }, [text])

  // const toolTipRender = () => {
  //   return 'hello'
  //   // if (isClosed) {
  //   //   return 'Title cannot be modified for a closed quiz'
  //   // }
  //   // if ((quizStartTime ? new Date() > quizStartTime : '') && isPublished) {
  //   //   return 'Title cannot be modified for ongoing quiz'
  //   // }
  //   // return ''
  // }

  return (
    <>
      <div
        style={{
          marginTop: '16px',
          alignItems: 'center',
          paddingLeft: '10px',
          position: 'relative',
        }}
      >
        <Hidden
          only={['lg', 'xl']}
          style={{ position: 'relative', display: 'flex' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {text !== '' ? (
              <div style={{ minWidth: '0px' }}>
                <Typography
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    fontSize: '24px',
                    fontWeight: 600,
                  }}
                >
                  {text}
                </Typography>
              </div>
            ) : (
              <Typography
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  color: '#9295A5',
                  fontSize: '24px',
                  fontWeight: 600,
                }}
              >
                UntitledTest
              </Typography>
            )}

            {isWriteAllowed && (
              <Button
                // color="secondary"
                className={classes.editButton}
                variant="outlined"
                onClick={() => handleEditQuizName()}
                disabled={
                  (quizStartTime ? new Date() > quizStartTime : '') &&
                  isPublished
                }
              >
                <EditOutlinedIcon />
              </Button>
            )}
          </div>
        </Hidden>
      </div>

      <div
        style={{
          marginTop: '16px',
          alignItems: 'center',
          paddingLeft: '10px',
        }}
      >
        {/* <Grid item xs={9} sm={11} md={10}> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Hidden
            only={['xs', 'sm', 'md']}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {text !== '' ? (
              <Typography
                style={{
                  // color: 'white',
                  fontSize: '24px',
                  fontWeight: 600,
                  height: '28px',
                  lineHeight: '26px',
                  overflow: 'hidden',
                }}
              >
                {text}
              </Typography>
            ) : (
              <Typography
                style={{
                  color: '#9295A5',
                  fontSize: '24px',
                  fontWeight: 600,
                  height: '28px',
                  lineHeight: '26px',
                  overflow: 'hidden',
                }}
              >
                UntitledTest
              </Typography>
            )}
            {isWriteAllowed && (
              <Button
                color="secondary"
                className={classes.editButton}
                variant="outlined"
                onClick={() => handleEditQuizName()}
                disabled={
                  (quizStartTime ? new Date() > quizStartTime : '') &&
                  isPublished
                }
              >
                <EditOutlinedIcon />
              </Button>
            )}
          </Hidden>
        </div>

        <Dialog
          // onClose={handleCreateQuizDialogClose}
          open={openCreateQuizDialog}
          className={classes.createQuizDialog}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            onClose={handleCreateQuizDialogClose}
            customClasses={{ root: classes.addQuizContainer }}
          />
          <DialogContent className={classes.createQuizDialogContentContainer}>
            <div className={classes.titleHeader}>
              <Typography className={classes.dialogTitle}>
                Quiz Title
              </Typography>
              <span className={classes.titleLength}>
                {text?.length ? text?.length : 0}
                /100
              </span>
            </div>
            <br />
            <Snackbar
              // open={openSnackbarTitle}
              autoHideDuration={6000}
              // onClose={handleCloseSnackBarTitle}
            >
              <Alert severity="error">Title limit exceeded.</Alert>
            </Snackbar>
            <Typography>
              {/* // */}
              <TextField
                multiline
                fullWidth
                variant="outlined"
                placeholder="Enter quiz title"
                value={text}
                onChange={handleInputChange}
                onFocus={handleSpanClick}
                // onKeyPress={(ev) => {
                //   if (ev.key === 'Enter' && !postQuiz.isLoading) {
                //     handleSubmitTitle()
                //     setLoading(false)
                //   }
                // }}
                inputProps={{
                  style: {
                    lineHeight: 1.5,
                    fontSize: '20px',
                  },
                }}
              />
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="filled"
              style={{ margin: '0px 16px 16px 0px' }}
              // className={classes.createQuizDialogButton}
              onClick={handleCreateQuizDialogClose}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
        {emptyTitle && (
          <p className={classes.titleValidation}>Please enter a title</p>
        )}
        {titleUpperLimit && (
          <p className={classes.titleValidation}>
            Title should be below 100 characters
          </p>
        )}
        {/* </span> */}
      </div>
    </>
  )
}

export default InlineEdit
