import { createFetcher } from '../../Fetcher/Fetcher'
import { ADMIN_ENDPOINT, MOCK_BASE_URL, QUIZ_ENDPOINT } from '../../constant'

/**
 * Creates a new Quiz.
 * @Applications
 */
export default class Question {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Question(
        MOCK_BASE_URL,
        QUIZ_ENDPOINT,
        ADMIN_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(baseUrl, endpoint, adminEndPoint, fetcherObj) {
    this.base_url = baseUrl
    this.endpoint = endpoint
    this.adminEndPoint = adminEndPoint
    this.fetcher = fetcherObj
  }

  //   /**
  //    * this function is to get all quiz
  //    * @function getQuizTypeById
  //    ** @param {reqParam} default parameter
  //    */

  //   async getAllQuiz(reqHeaders) {
  //     const url = `${this.base_url + this.endpoint}?userEmail=${email}`
  //     const response = await Fetcher.getData(url, reqHeaders)
  //     return response
  //   }

  //   /**
  //    * this function is to get all applications
  //    * @function getQuizById
  //    ** @param {reqParam} default parameter
  //    */
  async postQuestion(reqData) {
    const url = `${this.base_url + this.adminEndPoint}/question`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }

  //   /**
  //    * this function is to get all applications
  //    * @function postQuizById
  //    ** @param {reqParam} default parameter
  //    */

  async putQuestion(reqQuizId, reqQuestionId, reqQuestionType, reqData) {
    const url = `${this.base_url + this.adminEndPoint}/question/${reqData?.id}`
    const response = await this.fetcher.putData(url, reqData)
    return response
  }

  async deleteQuestionById(reqData) {
    const url = `${this.base_url + this.adminEndPoint + this.endpoint}/${
      reqData?.quizId
    }/question/${reqData?.questionId}`
    const response = await this.fetcher.deleteData(url)
    return response
  }

  async deleteQuestionMappingById(reqData) {
    const url = `${this.base_url + this.adminEndPoint + this.endpoint}/${
      reqData?.quizId
    }/question/${reqData?.questionId}`
    const response = await this.fetcher.deleteData(url)
    return response
  }

  //   /**
  //    * this function is to get all applications
  //    * @function postQuizQuestionMapping
  //    ** @param {reqParam} default parameter
  //    */

  async postQuestionMapping(reqQuizId, reqData) {
    const quizId = reqData?.quizId ? reqData?.quizId : reqQuizId
    const questionId = reqData?.questionId ? reqData?.questionId : reqData
    let payload
    if (reqData !== undefined) {
      payload = JSON.parse(JSON.stringify(reqData))
      delete payload.type
    }
    const url = `${
      this.base_url + this.adminEndPoint + this.endpoint
    }/${quizId}/question/${questionId}?type=${
      reqData?.type === 't/f' ? 'mcq' : reqData?.type
    }`
    const response = await this.fetcher.postData(url, payload)
    return response
  }
}
