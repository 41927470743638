import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  resultListContainer: {
    width: '100%',
    color: '#262A4C',
    backgroundColor: '#f3f7f7',
    marginTop: '46px',
    margin: 'auto',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '96px',
    margin: 'auto',
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
    '& > p ': {
      width: '90%',
      fontSize: '30px',
      height: '36px',
      [theme.breakpoints.up('sm')]: {
        width: '80%',
      },
      color: '#262B4B',
      '& > span': {
        color: '#00B8A4',
      },
    },
  },
  resultListBody: {
    width: '90%',
    margin: 'auto',
    marginTop: '40px',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
  quizDetail: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: 'red',
    lineHeight: 'normal',
    '@media only screen and (min-width:426px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '@media only screen and (max-width:700px)': {
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },

  quizDurationWrapper: {
    paddingBottom: '10px',
    flex: 1,
    '@media only screen and (min-width:426px)': {
      paddingRight: '10px',
      paddingBottom: '0px',
    },
    '@media only screen and (max-width:700px)': {
      paddingLeft: '5px',
    },
  },

  quizDuration: {
    margin: '0px',
  },

  ctaWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '@media only screen and (min-width:345px)': {
      flexDirection: 'row',
    },
    padding: '5px',
  },

  viewLiveBtnWrapper: {
    paddingBottom: '10px',
    '@media only screen and (min-width:345px)': {
      paddingRight: '10px',
      paddingBottom: '0px',
    },
  },

  viewLiveBtn: {
    padding: '0px 15px',
    color: '#00B8A4',
    height: '36px',
    borderRadius: '4px',
    border: '1px solid #00B8A4 ',
    outline: 'none',
    background: 'none',
    width: '100%',
    '&:hover': {
      background: '#FFFFFF',
    },
  },

  csvLink: {
    textDecoration: 'none',
    color: 'inherit',
  },

  candidateCountWrapper: {
    borderTop: '2px solid #E6E6E6',
    borderBottom: '2px solid #E6E6E6',
    display: 'flex',
    margin: '12px 0px',
    width: '100%',
    flexDirection: 'column',
    lineHeight: 'normal',
    '@media only screen and (min-width:426px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  candidatesCount: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0px 4px 12px',
  },
  candidateCard: {
    backgroundColor: '#FFFFFF',
    padding: '5px 0px 5px 12px',
    borderRadius: '4px',
    marginTop: '0.5rem',
  },
  resultBtn: {
    padding: '5px 15px',
    color: '#00B8A4',
    height: '30px',
    borderRadius: '4px',
    border: '1px solid #00B8A4 ',
    outline: 'none',
    background: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  evaluateLoader: {
    width: '80px',
    height: '30px',
  },
  evaluateBtn: {
    padding: '5px 15px',
    color: '#00B8A4',
    height: '30px',
    borderRadius: '4px',
    border: '1px solid #00B8A4 ',
    outline: 'none',
    background: '#FFFFFF',
    width: '80px',
    marginLeft: '5px',
  },
  resultRowHeader: {
    paddingLeft: '40px',
    '@media only screen and (min-width:1100px) and (max-width:1200px) ': {
      paddingLeft: '46px',
    },
    '@media only screen and (min-width:1201px) and (max-width:1300px)': {
      paddingLeft: '50px',
    },
    '@media only screen and (min-width:1301px) and (max-width:1400px)': {
      paddingLeft: '64px',
    },
    '@media only screen and (min-width:1401px) and (max-width:1600px) ': {
      paddingLeft: '82px',
    },
    '@media only screen and (min-width:1601px) and (max-width:1800px) ': {
      paddingLeft: '108px',
    },
    '@media only screen and (min-width:1801px) and (max-width:2000px) ': {
      paddingLeft: '126px',
    },
    '@media only screen and (min-width:2001px) and (max-width:2300px) ': {
      paddingLeft: '148px',
    },
    '@media only screen and (min-width:2301px) and (max-width:2500px) ': {
      paddingLeft: '160px',
    },
    '@media only screen and (min-width:2501px) and (max-width:3000px) ': {
      paddingLeft: '168px',
    },
    '@media only screen and (min-width:3001px) and (max-width:3500px) ': {
      paddingLeft: '186px',
    },
    '@media only screen and (min-width:3501px) ': {
      paddingLeft: '206px',
    },
  },
  styledPersonIcon: {
    width: '24px',
    height: '24px',
    border: '1px solid #f3f7f7',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    padding: '2px',
    marginRight: '1rem',
  },
  loader: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boldText: {
    fontWeight: 'bold',
    paddingTop: (props) => (props.aboveTabScreenSize ? '' : '10px'),
  },
  resultBtnContainer: {
    paddingLeft: '80px',
    width: '100%',
    flexWrap: 'nowrap',
    margin: (props) => (props.aboveTabScreenSize ? '0px' : '4px 4px 0px 0px'),
    '@media only screen and (min-width:250px) and (max-width:355px)': {
      paddingLeft: '30px',
    },
    '@media only screen and (min-width:960px) and (max-width:1100px)': {
      paddingLeft: '86px',
    },
    '@media only screen and (min-width:1101px) and (max-width:1300px)': {
      paddingLeft: '96px',
    },
    '@media only screen and (min-width:1301px) and (max-width:1400px)': {
      paddingLeft: '125px',
    },
    '@media only screen and (min-width:1401px) and (max-width:1600px)': {
      paddingLeft: '144px',
    },
    '@media only screen and (min-width:1601px) and (max-width:1800px)': {
      paddingLeft: '186px',
    },
    '@media only screen and (min-width:1801px) and (max-width:2000px)': {
      paddingLeft: '210px',
    },
    '@media only screen and (min-width:2001px) ': {
      paddingLeft: '244px',
    },
  },
  quizResultsTitle: {
    '@media only screen and (max-width:450px)': {
      fontSize: '25px',
    },
    '@media only screen and (min-width:451px) and (max-width:681px)': {
      fontSize: '27px',
    },
  },
  name: {
    display: 'flex',
    height: 'max-content',
    width: '90%',
  },
  candidateName: {
    fontSize: '14px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    fontWeight: (props) => (props.aboveTabScreenSize ? '500' : 'bold'),
    paddingTop: (props) => (props.aboveTabScreenSize ? '' : '4px'),
  },
  personIcon: {
    height: (props) => (props.aboveTabScreenSize ? '16px' : '20px'),
    width: (props) => (props.aboveTabScreenSize ? '16px' : '20px'),
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    color: theme?.palette?.primarySelection?.main,
    border: '1px solid #00B7A4',
    backgroundColor: 'white',
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  resultsPaginationDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  resultListsMsg: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '200px',
  },
  resultListLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '200px',
  },
  downloadSubmissionBtn: {
    minWidth: '192px',
    '@media only screen and (max-width: 345px)': {
      width: '100%',
    },
  },
  downloadResultBtn: {
    minWidth: '142px',
    '@media only screen and (max-width: 345px)': {
      width: '100%',
    },
  },
  downloadBtnWrapper: {
    display: 'flex',
    '@media only screen and (max-width : 410px)': {
      flexDirection: 'column',
    },
    '@media only screen and (max-width: 345px)': {
      width: '100%',
    },
  },
}))

export { useStyles }
