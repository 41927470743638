import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  // titleContainer: {
  //   // outline: 'none',
  //   fontSize: '30px',
  //   color: 'inherit',
  //   fontWeight: '700',
  //   paddingTop: '20px',
  //   width: '100%',
  //   // '& > input': {
  //   //   minWidth: '100px',
  //   //   maxWidth: '70%',
  //   //   fontSize: '30px',
  //   //   color: 'inherit',
  //   //   fontWeight: '700',
  //   //   outline: 'none',
  //   //   border: 'none',
  //   //   borderBottom: ' 1px dotted #262A4C',
  //   // },
  // },
  titleValidation: {
    color: theme?.palette?.inputValidation?.main,
    marginTop: '4px',
    marginBottom: '0px',
    fontSize: '12px',
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
  },
  editButton: {
    background: 'rgba(0, 0, 0, 0)',

    // backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.primaryButton?.main}`,
    color: theme?.palette?.primaryButton?.main,
    lineHeight: '14px',
    marginLeft: '12px',
    padding: '4px 0px',
    // [theme.breakpoints.down('xs')]: {
    //   width: '50px',
    // },
    '&:hover': {
      color: '#FFFFFF',
      background: theme?.palette?.primaryButton?.main,
    },
  },
  titleHeader: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  titleLength: {
    color: '#7a7878',
  },
}))
export { styles }
