import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme?.palette?.darkBackground?.main,
    width: '100%',
    padding: '21.37px 20px 43px 20px',
    height: '100%',
    '@media only screen and (min-width:426px)': {
      padding: '30px 40px 53px 40px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '30px 80px 53px 80px',
    },
    '@media only screen and (min-width:1024px)': {
      padding: '30px 135px 63px 135px',
    },
    '@media only screen and (min-width:2000px)': {
      padding: '60px 175px 83px 175px',
    },
  },
  tabsContainer: {
    height: 'calc(24.5px + 4px + 1px)',
    width: '100%',
    '@media only screen and (min-width:426px)': {
      height: 'calc(26.5px + 4px + 1px)',
    },
  },
  tabsWrapper: {
    flexWrap: 'nowrap',
    marginBottom: '1px',
    gap: '1.5rem',
    '@media only screen and (max-width: 770px)': {
      overflowY: 'clip',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    height: '24.5px',
    width: '100%',
    '@media only screen and (min-width:426px)': {
      height: '26.5px',
    },
    'align-items': 'baseline',
  },
  mcqTab: {
    padding: 0,
    height: '100%',
    border: 'none',
    background: 'none',
    flex: 1,
    whiteSpace: 'nowrap',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
    },
  },
  mcqTabText: {
    fontSize: '12px',
    cursor: 'pointer',
    '@media only screen and (min-width:426px)': {
      fontSize: '14px',
    },
  },
  codingTab: {
    border: 'none',
    padding: 0,
    background: 'none',
    height: '100%',
    flex: 1,
    whiteSpace: 'nowrap',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
    },
  },
  codingTabText: {
    fontSize: '12px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '@media only screen and (min-width:426px)': {
      fontSize: '14px',
    },
  },
  tabsSeperator: {
    height: '1px',
    background: theme?.palette?.dividerLines?.main,
    '@media only screen and (max-width: 770px)': {
      marginTop: '-6px',
    },
  },
  mcqTabHighlight: {
    height: '3px',
    background: (props) =>
      props?.currentTab === 'mcq'
        ? theme?.palette?.primarySelection?.main
        : 'none',
    flex: 1,
    width: '100%',
    margin: 'auto',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
      width: '100%',
      marginTop: '7px',
    },
  },
  codingTabHighlight: {
    height: '3px',
    borderBottom: (props) =>
      props?.currentTab === 'coding'
        ? `3px solid ${theme?.palette?.primarySelection?.main}`
        : 'none',
    flex: 1,
    width: '100%',
    margin: 'auto',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
      width: '100%',
      marginTop: '7px',
    },
  },
  fillInTheBlanksTabHighlight: {
    height: '3px',
    borderBottom: (props) =>
      props?.currentTab === 'fillInTheBlanks'
        ? `3px solid ${theme?.palette?.primarySelection?.main}`
        : 'none',
    flex: 1,
    width: '100%',
    margin: 'auto',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
      width: '100%',
      marginTop: '7px',
    },
  },
  essayTabHighlight: {
    height: '3px',
    width: '100%',
    margin: 'auto',
    background: (props) =>
      props?.currentTab === 'essay'
        ? theme?.palette?.primarySelection?.main
        : 'none',
    flex: 1,

    '@media only screen and (min-width:769px)': {
      flex: 'none',
      width: '100%',
      marginTop: '7px',
    },
  },
  tableWrapper: {
    width: '100%',
    height: 'calc(100% - 24.5px - 4px - 1px - 16px)',
    overflowY: 'auto',
    marginTop: '1rem',
    paddingTop: '26.5px',
    '@media only screen and (min-width:426px)': {
      height: 'calc(100% - 26.5px - 4px - 1px - 29.5px)',
    },
  },
  quizResults: {
    marginBottom: '16px',
  },
  titleTabPadding: {
    padding: '0px 0px 10px 0px',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    background: 'none',
    marginBottom: '-4px',
  },
}))
export { useStyles }
