import { makeStyles } from '@material-ui/core/styles'
import EmptyQuizSvg from '../../images/EmptyQuiz.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '75px',
    paddingBottom: '30px',
  },
  quizPaginationGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    '@media only screen and (min-width:640px)': {
      maxWidth: '85%',
    },
  },
  fixQuizHeader: {
    position: 'fixed',
    top: 46,
    width: '100%',
    zIndex: 90,
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid #E6E6E6',
  },

  quizBody: {
    marginTop: '124px',
  },
  button: {
    color: theme.palette.primarySelection.main,
    borderColor: theme.palette.primarySelection.main,
    backgroundColor: 'white',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  emptyQuizContainer: {
    backgroundImage: `url(${EmptyQuizSvg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    height: '60vh',
  },
  quizHeader: {
    height: 75,
  },
  heading: {
    fontSize: '30px',
    fontWeight: 700,
  },
  addQuizButton: {
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButton.contrastText,
    padding: '10px 15px 10px 15px',
    fontSize: '14px',
    height: 36,
    maxWidth: '100%',
    borderRadius: 4,
    fontWeight: 500,
    ' &:hover': {
      backgroundColor: theme.palette.primaryButton.hover,
    },
  },
  addIcon: {
    paddingRight: 2,
  },
  paper: {
    height: 126,
    padding: 20,
    borderRadius: 8,
    color: theme.palette.text.secondary,
  },
  infoCardButtonWrapper: {
    background: 'none',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  cardContainer: {
    marginTop: '1%',
  },
  searchAndFilter: {
    marginTop: '1%',
  },
  collapsibleDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  emptyQuizContent: {
    fontSize: '20px',
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    paddingBottom: '4px',
  },
  createQuizDialogButton: {
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButton.contrastText,
    fontSize: '14px',
    ' &:hover': {
      backgroundColor: theme.palette.primaryButton.hover,
    },
  },
  createQuizDialogLoader: {
    // quick hacks for fixing broken design
    marginRight: '32px',
    marginBottom: '8px',
  },
  loader: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  rootFirstSelect: {
    paddingLeft: '4px 0px',
  },
  searchBarGridItem: {
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 2,
    },
  },
  filterInputProp: {
    width: 28,
    maxWidth: 30,
    height: '36px',
    padding: 0,
    paddingRight: 16,
    color: 'rgb(38, 43, 75)',
    fontSize: '12px',
    fontWeight: 400,
  },
  titleDialog: {
    padding: '24px',
    pointerEvents: 'none',
  },
  selectFilterInputProp: {
    width: '100%',
    height: 36,
    padding: '0px',
    color: 'black',
    backgroundColor: 'white',
    paddingLeft: '10px',
  },
  addQuizContainer: {
    padding: '10px 10px 0px 16px',
  },
  dialogActions: {
    padding: '0px 24px 24px',
  },
  circularProgress: {
    backgroundColor: '#00B7A4',
    padding: '8px 4px 0px 4px',
    borderRadius: '4px',
    height: '36px',
    width: '74px',
    textAlign: 'center',
  },
  createQuizDialogContentContainer: {
    padding: '0px 24px 16px',
  },
  quizListsMsg: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '200px',
  },
  quizListLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '200px',
  },
  quizTopFilters: {
    border: '2px solid #03b7a4',
    boxShadow: '0px 0px 3px 0px #03b7a4',
  },
  deleteButton: {
    backgroundColor: theme?.palette?.dangerPrimaryButton?.main,
    color: theme?.palette?.dangerPrimaryButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.dangerPrimaryButton?.hover,
    },
  },
  editButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    // border: `1px solid ${theme?.palette?.cancelButton?.text}`,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },
  },
  titleHeader: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  titleLength: {
    color: '#7a7878',
  },
}))

export { useStyles }
