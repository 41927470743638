import { useMutation, useQuery } from 'react-query'
import Payment from '../../Repository/Payment/Payment'

const PaymentReq = Payment.getInstance()

/**
 * this function is to Query get All application
 * @function useUserPaymentPostQuery
 ** @param { options} default parameter
 */
export function usePaymentPostQuery(resourceId, options) {
  return useMutation((args) => PaymentReq.postPayment(args, resourceId), {
    ...options,
  })
}

export function usePaymentGetBillingLog(
  resourceId,
  transactionType,
  billingPageLimit,
  offset,
  options
) {
  return useQuery(
    ['billingStatus', resourceId, transactionType, billingPageLimit, offset],
    () =>
      PaymentReq.getBillingLog(
        resourceId,
        transactionType,
        billingPageLimit,
        offset
      ),
    {
      ...options,
    }
  )
}
