import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    minHeight: '100%',
    position: 'relative',
  },
  container: {
    '@media only screen and (min-width:640px)': {
      maxWidth: '85%',
    },
  },
  editQuizHeader: {
    // backgroundColor: '#212649',
    backgroundColor: 'white',
    position: 'fixed',
    top: '46px',
    zIndex: 90,
    width: '100%',
    borderBottom: '1px solid #dadce0',
  },
  editQuizBody: {
    marginTop: '164px',
    paddingBottom: '160px',
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
    '@media only screen and (min-width:421px) and (max-width:481px)': {
      marginTop: '188px',
    },
  },
  editQuizFooter: {
    position: 'fixed',
    bottom: 0,
    height: '46px',
    width: '100%',
    borderTop: '1px solid #dadce0',
  },
  editQuizFooterButtons: {
    '@media only screen and (min-width:640px)': {
      maxWidth: '85%',
    },

    padding: '4px 0px',
    backgroundColor: 'white',
  },
  tabFlexContainer: {
    // backgroundColor: '#212649',
    // color: 'white',
  },
  tabs: {
    // paddingTop: '1%',
    '@media only screen and (min-width:250px) and (max-width:420px)': {
      paddingTop: '8px',
    },
  },
  tab: {
    minWidth: '60px',
  },
  cancelButton: {
    backgroundColor: '#EBEBEB',
    color: '#202020',
    height: '36px',
    marginRight: '1%',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#d2d2d2',
    },
  },
  loader: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  publishButton: {
    marginRight: '1%',
    height: '36px',
    padding: '10px 20px',
    color: theme.palette.navigateButton.text,
    backgroundColor: theme.palette.navigateButton.main,
    '&:hover': {
      backgroundColor: theme.palette.navigateButton.hover,
    },
    '&:disabled': {
      backgroundColor: theme.palette.navigateButton.disabled,
    },
  },

  copyUrlButton: {
    color: theme.palette.primaryButton.main,
    backgroundColor: theme.palette.primaryButton.contrastText,
    borderColor: theme.palette.primaryButton.main,
    cursor: 'copy',
    '&:hover': {
      color: theme.palette.primaryButton.hover,
      backgroundColor: theme.palette.primaryButton.contrastText,
      borderColor: theme.palette.primaryButton.hover,
    },
    '@media only screen and (min-width:666px) and (max-width:951px)': {
      width: 'max-content',
    },
  },
  alertCopyLinkButton: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.secondary.main,
    height: '34px',
    cursor: 'copy',
    padding: '2px',
    fontWeight: 500,
    fontSize: '14px',
  },
  quizConfigCostWrapper: {
    display: 'flex',
  },
  quizConfigCostDiv: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    '@media only screen and (max-width:480px)': {
      paddingLeft: '0px',
      paddingTop: '10px',
      flexDirection: 'column',
    },
  },
  creditsContainer: {
    paddingLeft: '10px',
    paddingTop: '10px',
    '@media only screen and (max-width:480px)': {
      paddingTop: '12px',
    },
  },
  credits: {
    fontSize: '20px',
    paddingLeft: '11px',
    paddingTop: '8px',
    height: '48px',
    width: '48px',
    border: 'solid 1px #00B7A4',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: '#00B7A4',
    '@media only screen and (max-width:480px)': {
      fontSize: '18px',
      height: '44px',
      width: '44px',
    },
  },
  creditMessage: {
    display: 'flex',
    // color: 'white',
    fontSize: '20px',
    width: 'max-content',
    fontWeight: 600,
    '@media only screen and (max-width:480px)': {
      fontSize: '18px',
    },
  },
  creditSubMessage: {
    // color: '#00B7A4',
    fontSize: '14px',
  },
  titleAndCreditsWrapper: {
    display: 'flex',
    '@media only screen and (max-width:665px)': {
      flexDirection: 'column',
    },

    '@media only screen and (max-width:570px)': {
      flexDirection: (props) => (props?.publish ? 'column' : ''),
    },
  },
  buttonWrapper: {
    paddingLeft: '25px',
    paddingTop: '10px',
    paddingRight: '15px',
    width: 'max-content',
    '@media only screen and (max-width:480px)': {
      width: 'max-content',
      paddingLeft: '0px',
      paddingTop: '10px',
    },
    '@media only screen and (max-width:815px)': {
      paddingLeft: (props) => (props?.publish ? '0px' : ''),
      paddingTop: (props) => (props?.publish ? '10px' : ''),
      width: (props) => (props?.publish ? 'max-content' : ''),
    },
    '@media only screen and (min-width:911px) and (max-width:959px)': {
      width: (props) => (props?.publish ? 'max-content' : ''),
    },
  },
  linkAndCreditsContainer: {
    display: 'flex',
    paddingLeft: '10px',
    paddingTop: '8px',

    '@media only screen and (max-width:420px)': {
      paddingTop: '5px',
      paddingLeft: '0px !important',
    },
    '@media only screen and (max-width:560px)': {
      flexDirection: (props) => (props?.publish ? 'column-reverse' : ''),
    },
    '@media only screen and (min-width:560px) and (max-width:815px)': {
      flexDirection: (props) => (props?.publish ? 'column-reverse' : ''),
    },
  },
  spaceBetweenTitleAndCredits: {
    display: 'flex',
    flex: 1,
    height: 'max-content',
    width: 'max-content',
  },
  quizTitle: {
    height: '70px',
    width: '100%',
    overflow: 'hidden',
  },
  labelContainer: {
    width: 'auto',
    padding: 0,
  },
  iconLabelWrapper: {
    flexDirection: 'row',
  },
  labelIcon: {
    minHeight: 'auto',
  },
  '& .MuiTab-labelIcon .MuiTab-wrapper > :nth-child(1)': {
    marginBottom: '0px',
  },
}))

export { useStyles }
