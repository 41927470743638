import Snackbar from '@material-ui/core/Snackbar'
import React from 'react'
import Alert from '@material-ui/lab/Alert'

const CommonSnackbar = (props) => {
  const { open, autoHideDuration, onClose, severity, action, message } = props
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      {...(action && { action })}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  )
}

export default CommonSnackbar
