/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useRef } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import {
  Grid,
  Avatar,
  IconButton,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useQuizIdGetAll } from '../../Common/Queries/LiveCandidatesQuery'
import { SendAdminAnswer } from '../../Common/Queries/CandidateStreamQuery'
import DecreaseVolumeIcon from '../../icons/DecreaseVolumeIcon'
import IncreaseVolumeIcon from '../../icons/IncreaseVolumeIcon'
import MuteVolumeIcon from '../../icons/MuteVolumeIcon'
import FullScreenIcon from '../../icons/FullScreenIcon'
import SettingsIcon from '../../icons/SettingsIcon'
import { secondsToMinutes } from '../../utils'
import { styles } from './CandidateStreamStyle'

function QuizTimeLeft(executableFunction, timeInterval) {
  this.timeInterval = timeInterval
  this.timerStarted = false
  this.start = () => {
    this.timerStarted = true
    this.expected = Date.now() + this.timeInterval
    this.timeout = setTimeout(this.round, this.timeInterval)
  }
  this.stop = () => {
    if (this.timerStarted) {
      this.timerStarted = false
      clearTimeout(this.timeout)
    }
  }
  this.round = () => {
    const drift = Date.now() - this.expected
    let reduceSeconds = 1
    let updatedTimeInterval = this.timeInterval - drift
    if (drift > 999) {
      reduceSeconds = Math.floor(drift / 1000)
      updatedTimeInterval = this.timeInterval - (drift % 1000)
    }
    executableFunction(reduceSeconds)
    this.expected += reduceSeconds * 1000
    this.timeout = setTimeout(this.round, updatedTimeInterval)
  }
}
export default function CandidateStream(props) {
  const flag = useRef(false)
  const { id } = useParams()
  let { user } = useParams()
  if (user === undefined) {
    user = props.username
  }

  const classes = styles({
    singleStream: !props.username,
    userInitial: user?.[0].toUpperCase(),
    userInitialBackground: {
      A: '#e52165',
      B: '#0d1137',
      C: '#d72631',
      D: '#a2d5c6',
      E: '#077b8a',
      F: '#5c3c92',
      G: '#e2d810',
      H: '#d9138a',
      I: '#322e2f',
      J: '#f3ca20',
      K: '#000000',
      L: '#cf1578',
      M: '#e8d21d',
      N: '#039fbe',
      O: '#b20238',
      P: '#fbcbc9',
      Q: '#be1558',
      R: '#322514',
      S: '#fe3d59',
      T: '#f5foef',
      U: '#ff6e40',
      V: '#ffcf3b',
      W: '#ecc19c',
      X: '#fe847f',
      Y: '#000000',
      Z: '#c4a35a',
    },
  })
  const location = useLocation()
  const history = useHistory()
  // const freshGetReq = useRef(false)
  const localStorageLiveCandidates = JSON.parse(
    localStorage.getItem('live_candidates')
  )
  const video = useRef(null)
  const [peerConn, setPeerConn] = useState(null)
  const refPeerConn = useRef()
  const [loader, setLoader] = useState(false)
  const [areSettingsOpen, setAreSettingsOpen] = useState(false)
  const [countDownTimer, setCountDownTimer] = useState(0)
  const [candidateDetails, setCandidateDetails] = useState('')
  const [timer, setTimer] = useState('')
  const [hoverStates, setHoverStates] = useState({
    increaseVolume: false,
    decreaseVolume: false,
    mute: false,
    fullScreen: false,
  })
  const { quizTitle, quizStartTime, quizEndTime } = location.state
  const [playerState, setPlayerState] = useState({
    isMuted: true,
    volume: 0.5,
  })

  // const [isVideoVisible, setIsVideoVisible] = useState(false)

  // const initialVolumeSet = useRef(false)
  const candidateData = useRef({
    offer: {},
    candidates: [],
  })
  const adminData = useRef({
    answer: {},
    candidates: [],
  })
  const timeLeftRef = React.useRef(
    new QuizTimeLeft((reduceSeconds) => {
      setCountDownTimer((sec) => {
        return sec - reduceSeconds
      })
    }, 1000)
  )
  const sendAdmin = SendAdminAnswer(id, user, {
    staleTime: 0,
  })

  const configuration = {
    iceServers: [
      {
        urls: [
          'stun:stun.l.google.com:19302',
          // 'stun:stun1.l.google.com:19302',
          'stun:stun2.l.google.com:19302',
        ],
      },
    ],
  }
  const {
    // isSuccess: fetchIsSucess,
    // data: totalCandidatesData,
    // isLoading: isTotalCandidateListLoading,
    // isFetching: isTotalCandidateListFetching,
    refetch: totalCandidatesRefetch,
  } = useQuizIdGetAll(id, {
    // staleTime: 0,
    enabled: false,
    // manual: true,
    onSuccess: (successData) => {
      if (flag?.current) {
        const ex1 = successData.filter((userData) => userData?.email === user)
        setCandidateDetails(ex1[0])
      }
      if (!flag?.current) {
        flag.current = true
      }
    },
  })

  const createAndSendAnswer = () => {
    peerConn.createAnswer(
      (answer) => {
        peerConn.setLocalDescription(answer)
        adminData.current.answer = answer
      },
      () => {
        console.log('could not create answer')
      }
    )
  }
  useEffect(() => {
    function redirectToLiveCandidates() {
      if (!props?.timer || !props?.candidateDetails) {
        history.push({
          pathname: `/live-candidates/${id}`,
          state: {
            quizTitle,
            quizStartTime,
            quizEndTime,
          },
        })
      }
    }
    window.addEventListener('load', redirectToLiveCandidates)
    return () => window.removeEventListener('load', redirectToLiveCandidates)
  }, [])

  useEffect(() => {
    if (!props?.timer && props?.timer !== 0) {
      setCountDownTimer(() => localStorageLiveCandidates[user]?.timer)
    }
  }, [])
  useEffect(() => {
    if (countDownTimer > 0 && !timeLeftRef.current.timerStarted) {
      timeLeftRef.current.start()
    } else if (countDownTimer <= 0) {
      timeLeftRef.current.stop()
    } else if (countDownTimer > 0) {
      localStorageLiveCandidates[user].timer = countDownTimer
      localStorage.setItem(
        'live_candidates',
        JSON.stringify(localStorageLiveCandidates)
      )
    }
  }, [countDownTimer])
  useEffect(() => {
    setTimer(() => {
      return props?.timer || localStorageLiveCandidates[user]?.timer
    })
  }, [props?.timer])
  useEffect(() => {
    setCandidateDetails(() => {
      return props?.location?.state || localStorageLiveCandidates[user]
    })
  }, [props?.candidateDetails])
  useEffect(() => {
    // setLoader(true)
    return function cleanup() {
      refPeerConn.current?.close()
    }
  }, [])

  useEffect(() => {
    // setPeerConn(new RTCPeerConnection(configuration))
    if (peerConn == null) {
      const rtc = new RTCPeerConnection(configuration)
      setPeerConn(rtc)
      return
    }

    if (!user) {
      peerConn.close()
    }

    if (peerConn.connectionState === 'connected') {
      console.log('peerConn is in connecting or connected state')
      return
    }
    setLoader(() => false)

    refPeerConn.current = peerConn
    peerConn.onconnectionstatechange = async () => {
      if (peerConn?.connectionState === 'connected') {
        setLoader(() => false)
      }
      if (
        peerConn?.connectionState === 'disconnected' ||
        peerConn?.connectionState === 'closed' ||
        peerConn?.connectionState === 'failed'
      ) {
        // await peerConn.close()
        const candidatesRefetchTomer = setTimeout(() => {
          totalCandidatesRefetch()
        }, 3000)
        return () => clearTimeout(candidatesRefetchTomer)
      }
      return null
    }
    peerConn.addEventListener('iceconnectionstatechange', () => {
      // console.log(` ${user} ice candidate state ${peerConn.iceConnectionState}`)
    })
    peerConn.ontrack = (e) => {
      if (video.current) {
        video.current.srcObject = e.streams[0]
      }
    }
    peerConn.onicecandidate = (e) => {
      if (e.candidate == null) {
        if (adminData.current.candidates.length > 0) {
          // console.log(`sending answer to server ${adminData.current}`)
          sendAdmin.mutate(JSON.stringify(adminData.current))
        }
      }
      adminData.current.candidates.push(e.candidate)
    }
    try {
      const candidateResponse = JSON.parse(candidateDetails?.streamCredentials)
      candidateData.current.offer = candidateResponse?.offer
      candidateData.current.candidates = candidateResponse?.candidates
      peerConn.setRemoteDescription(
        new RTCSessionDescription(candidateData.current.offer)
      )
      createAndSendAnswer()
      candidateData.current.candidates.forEach((candidate) => {
        peerConn.addIceCandidate(candidate)
      })
    } catch (err) {
      console.error(`some parsing error has occurened for ${user}`)
    }
  }, [peerConn, candidateDetails?.streamCredentials])

  useEffect(() => {
    if (flag?.current) {
      setTimeout(() => {
        if (
          peerConn?.connectionState &&
          peerConn.connectionState !== 'connected'
        ) {
          peerConn.close()
          const timerCandidatesRefetch = setTimeout(() => {
            totalCandidatesRefetch()
          }, 10000)
          return () => clearTimeout(timerCandidatesRefetch)
        }
        return null
      }, 10000)
    }
  }, [candidateDetails?.streamCredentials])

  const handleHoverStates = (setting) => {
    setHoverStates((hoverStatesPrev) => {
      return {
        ...hoverStatesPrev,
        decreaseVolume: setting === 'decreaseVolume',
        increaseVolume: setting === 'increaseVolume',
        mute: setting === 'mute',
        fullScreen: setting === 'fullScreen',
      }
    })
  }

  const clearHoverStates = () => {
    setHoverStates((hoverStatesPrev) => {
      return {
        ...hoverStatesPrev,
        decreaseVolume: false,
        increaseVolume: false,
        mute: false,
        fullScreen: false,
      }
    })
  }

  const requestFullscreen = () => {
    if (video?.current?.requestFullscreen) {
      video?.current?.requestFullscreen()
    } else if (video?.current?.webkitRequestFullscreen) {
      video?.current?.webkitRequestFullscreen()
    } else if (video?.current?.mozRequestFullScreen) {
      video?.current?.mozRequestFullScreen()
    } else if (video?.current?.msRequestFullscreen) {
      video?.current?.msRequestFullscreen()
    } else if (video?.current?.webkitEnterFullScreen) {
      video?.current?.webkitEnterFullScreen()
    }
    setAreSettingsOpen(() => false)
    clearHoverStates()
  }

  const toggleMute = () => {
    setPlayerState((playerStatePrev) => {
      return {
        ...playerStatePrev,
        isMuted: !playerStatePrev?.isMuted,
      }
    })
  }

  // useEffect(() => {
  //   if (playerState?.isMuted) video.current.muted = true
  //   else video.current.muted = false
  // }, [playerState?.isMuted, video])
  useEffect(() => {
    if (video.current) {
      // Check if video.current is not null
      if (playerState?.isMuted) video.current.muted = true
      else video.current.muted = false
    }
  }, [playerState?.isMuted, video])

  const handleVolume = (setting) => {
    setPlayerState((playerStatePrev) => {
      const approxVolumeState = Math.round(playerStatePrev?.volume * 10) / 10
      let volumeToBeSet
      if (setting === 'increase') {
        volumeToBeSet = approxVolumeState >= 0.9 ? 1 : approxVolumeState + 0.1
      } else if (setting === 'decrease') {
        volumeToBeSet = approxVolumeState >= 0.1 ? approxVolumeState - 0.1 : 0
      }
      let setToMute = playerStatePrev?.isMuted
      if (volumeToBeSet > 0 && playerStatePrev?.isMuted) {
        setToMute = false
      }
      if (volumeToBeSet <= 0 && !playerStatePrev?.isMuted) {
        setToMute = true
      }
      return { ...playerStatePrev, volume: volumeToBeSet, isMuted: setToMute }
    })
  }

  // useEffect(() => {
  //   video.current.volume = playerState?.volume
  // }, [playerState?.volume, video])

  useEffect(() => {
    if (video.current) {
      // Check if video.current is not null
      video.current.volume = playerState?.volume
    }
  }, [playerState?.volume, video])

  return (
    <Grid className={classes.streamContainer}>
      {loader ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.videoLoaderContainer}
          direction="column"
        >
          <Avatar className={classes.userAvatar}>
            {user?.[0].toUpperCase()}
          </Avatar>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              width: 'fit-content',
            }}
          >
            <p className={classes.loadingText}>Connecting...</p>
            <CircularProgress
              size="1.2rem"
              style={{
                color: 'white',
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          className={classes.videoContainer}
          style={{ position: 'relative' }}
        >
          {areSettingsOpen ? (
            <div className={classes.controlsContainer}>
              <div className={classes.controlsWrapper}>
                <div className={classes.settingsIconButtonWrapper}>
                  <IconButton
                    onClick={() => setAreSettingsOpen(() => false)}
                    className={classes.settingsIconButton}
                  >
                    <div className={classes.settingsIconWrapper}>
                      <SettingsIcon />
                    </div>
                  </IconButton>
                </div>
                <div className={classes.controlIconButtonsContainer}>
                  <ClickAwayListener
                    onClickAway={() => setAreSettingsOpen(() => false)}
                  >
                    <div className={classes.controlIconButtonsWrapper}>
                      <div
                        onMouseEnter={() => handleHoverStates('decreaseVolume')}
                        onMouseLeave={clearHoverStates}
                        className={classes.controlIconButtonWrapper}
                      >
                        <IconButton
                          onClick={() => handleVolume('decrease')}
                          className={classes.controlIconButton}
                        >
                          <div className={classes.controlIconWrapper}>
                            <DecreaseVolumeIcon hoverStates={hoverStates} />
                          </div>
                        </IconButton>
                      </div>
                      <div
                        onMouseEnter={() => handleHoverStates('increaseVolume')}
                        onMouseLeave={clearHoverStates}
                        className={classes.controlIconButtonWrapper}
                      >
                        <IconButton
                          onClick={() => handleVolume('increase')}
                          className={classes.controlIconButton}
                        >
                          <div className={classes.controlIconWrapper}>
                            <IncreaseVolumeIcon hoverStates={hoverStates} />
                          </div>
                        </IconButton>
                      </div>
                      <div
                        onMouseEnter={() => handleHoverStates('mute')}
                        onMouseLeave={clearHoverStates}
                        className={classes.controlIconButtonWrapper}
                      >
                        <IconButton
                          onClick={toggleMute}
                          className={classes.controlIconButton}
                        >
                          <div className={classes.controlIconWrapper}>
                            <MuteVolumeIcon playerState={playerState} />
                          </div>
                        </IconButton>
                      </div>
                      <div
                        onMouseEnter={() => handleHoverStates('fullScreen')}
                        onMouseLeave={clearHoverStates}
                        className={classes.controlIconButtonWrapper}
                        style={{
                          paddingRight: '0px',
                        }}
                      >
                        <IconButton
                          onClick={requestFullscreen}
                          className={classes.controlIconButton}
                        >
                          <div className={classes.controlIconWrapper}>
                            <FullScreenIcon hoverStates={hoverStates} />
                          </div>
                        </IconButton>
                      </div>
                    </div>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          ) : null}
          <div className={classes.infoAndSettingsContainer}>
            <div className={classes.infoAndSettingsWrapper}>
              {!areSettingsOpen ? (
                <div className={classes.settingsIconButtonWrapper}>
                  <IconButton
                    onClick={() => setAreSettingsOpen(() => true)}
                    className={classes.settingsIconButton}
                  >
                    <div className={classes.settingsIconWrapper}>
                      <SettingsIcon />
                    </div>
                  </IconButton>
                </div>
              ) : null}

              <div className={classes.infoWrapper}>
                <div className={classes.nameAndUsernameTextWrapper}>
                  <Typography className={classes.nameText}>
                    {candidateDetails?.name}
                  </Typography>
                  <Typography className={classes.usernameText}>
                    {user}
                  </Typography>
                </div>
                <div className={classes.tabCountTextWrapper}>
                  <Typography className={classes.timerText}>
                    {props?.timer
                      ? secondsToMinutes(timer)
                      : secondsToMinutes(countDownTimer)}{' '}
                    left
                  </Typography>
                  <Typography className={classes.tabCountText}>
                    Tab count: {candidateDetails?.tabChangeCount}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <video
            className={classes.streamVideo}
            style={{ position: 'relative', zIndex: 0 }}
            ref={video}
            muted
            autoPlay
          />
        </Grid>
      )}

      {/* <Container className={classes.videoBtn}>
          {micOpen ? (
            <Button
              color="secondary"
              startIcon={<MicIcon className={classes.micBtn} />}
              className={classes.micBtnContainer}
            />
          ) : (
            <Button
              color="secondary"
              startIcon={<MicOffIcon className={classes.micBtn} />}
              className={classes.micBtnContainer}
            />
          )}
        </Container> */}
    </Grid>
  )
}
