import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  avatar: {
    height: 82,
    width: 82,
  },
  quizDetailsContainer: {
    paddingLeft: 10,
  },
  quizTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    marginTop: 10,
    fontWeight: 500,
  },
  quizCount: {
    fontSize: '30px',
    marginTop: 5,
    marginBottom: 5,
    fontWeight: 500,
    padding: 0,
    color: theme.palette.primary.main,
  },
}))

export { useStyles }
