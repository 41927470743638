import { useMutation } from 'react-query'
import McqQuestion from '../../Repository/McqQuestion/McqQuestion'

const questionReq = McqQuestion.getInstance()

export function useQuestionPutQuery(
  reqQuizId,
  reqQuestionId,
  reqQuestionType,
  options
) {
  return useMutation(
    (args) =>
      questionReq.putQuestion(reqQuizId, reqQuestionId, reqQuestionType, args),
    {
      ...options,
    }
  )
}
export function useQuestionPostQuery(options) {
  // const queryClient = useQueryClient()
  return useMutation((args) => questionReq.postQuestion(args), {
    ...options,
  })
}

export function useQuestionDeleteQueryById(options) {
  return useMutation(
    ['QuizDeleteQueryById'],
    (args) => questionReq.deleteQuestionById(args),
    {
      ...options,
    }
  )
}

// QUESTION MAPPING POST CALL
export function useQuestionMappingPostQueryById(reqQuizId, options) {
  return useMutation(
    ['QuizMappingPostQueryById'],
    (args) => questionReq.postQuestionMapping(reqQuizId, args),
    {
      ...options,
    }
  )
}

// QUESTION MAPPING DELETE CALL

export function useQuestionMappingDeleteQueryById(options) {
  return useMutation(
    ['deleteQuestionMappingById'],
    (args) => questionReq.deleteQuestionMappingById(args),
    {
      ...options,
    }
  )
}
