import React, { useEffect } from 'react'
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormGroup,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputBase from '@material-ui/core/InputBase'
import Checkbox from '@material-ui/core/Checkbox'
import AddIcon from '@material-ui/icons/Add'
import Radio from '@material-ui/core/Radio'
import DeleteIcon from '../../icons/Delete.svg'
import Loader from '../Loader/Loader'

import { useStyles } from './EditMcqQuestion2Styles'
import QuillTextEditor from '../TextEditorToolbar/TextEditor'
import UpdateQuestionsInQuizesModal from '../UpdateQuestionInQuizesModal/UpdateQuestionInQuizesModal'

const EditMcqQuestionTile = ({
  addQuestion,
  questions,
  questionIndex,
  setLocalQuestion,
  errors,
  questionTypeFromParent,
  isQuizStarted,
  quizStartTime,
  handleEdit,
  isPublished,
  handleQuestionTypeChange,
  quizType,
  codingQuestionSwitch,
  textEditorQuestion,
  setTextEditorQuestion,
  handleCorrectChoice,
  handleRemoveChoice,
  handleChoice,
  editQuestionQuery,
  postQuestion,
  editQuestion,
  handleCancel,
  handleCancelAddMcq,
  handleSave,
  handleMultiChoice,
  question,
  showQuizesListModal,
  setShowQuizesListModal,
  affectedQuizes,
  updateQuestionInSelectedQuizzes,
  setSelectedQuizzes,
  selectedQuizzes,
  quizId,
  localQuestion,
  setEditViewQuestion,
  editViewQuestion,
}) => {
  const classes = useStyles()
  const handleSaveButtonClick = async () => {
    if (affectedQuizes?.length > 1) setShowQuizesListModal(true)
    else handleSave()
  }
  useEffect(() => {
    if (addQuestion && Object.keys(editViewQuestion || {})?.length > 0) {
      setLocalQuestion(editViewQuestion)
    } else if (!Number.isNaN(editQuestion)) {
      setLocalQuestion(localQuestion)
    }
  }, [])
  return (
    <>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} sm={12} md={11} className={classes.editQuestion}>
          <Paper className={classes.editPaper} variant="outlined">
            {/* Header */}

            <Grid container xs={12} style={{ marginBottom: '16px' }}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ alignItems: 'end', display: 'flex' }}
              >
                <Typography className={classes.questionIndex}>
                  Question{' '}
                  {addQuestion ? questions?.length + 1 : questionIndex + 1}.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                container
                className={classes.marksType}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <TextField
                  id="filled-number"
                  value={localQuestion?.marks}
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  error={
                    errors?.emptyMarks ||
                    errors?.marksNotValid ||
                    errors?.marksLessThanZero
                  }
                  className={classes.mcqMarksContainer}
                  inputProps={{
                    style: {
                      fontSize: 16,
                      color: '#262B4B',
                      textAlign: 'center',
                      height: '32px',
                      width: '40px',
                    },
                  }}
                  onChange={(event) => handleEdit(event, 'marks', 0)}
                  disabled={isQuizStarted(quizStartTime) && isPublished}
                  label="Marks"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {addQuestion && (
                  <FormControl variant="outlined" style={{ marginLeft: '8px' }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Question Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={questionTypeFromParent}
                      onChange={handleQuestionTypeChange}
                      label="Question Type"
                    >
                      <MenuItem value="mcq">Multiple Choice Question</MenuItem>
                      <MenuItem
                        value="coding"
                        disabled={
                          quizType === 'public' || !codingQuestionSwitch
                        }
                      >
                        Coding Question
                      </MenuItem>
                      <MenuItem value="t/f">True/False Question</MenuItem>
                      <MenuItem value="multi-choice">
                        Multiple Answer Question
                      </MenuItem>
                      <MenuItem value="essay">Essay Question</MenuItem>
                      <MenuItem value="fillInBlanks">
                        Fill In The Blanks
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* Editor */}
            <Grid xs={12} style={{ marginBottom: '16px' }}>
              <QuillTextEditor
                textEditorQuestion={textEditorQuestion}
                setTextEditorQuestion={setTextEditorQuestion}
                className={localQuestion?.id?.toString()}
                setLocalQuestion={setLocalQuestion}
                isQuizStarted={isQuizStarted(quizStartTime) && isPublished}
                localQuestion={localQuestion}
                question={question}
                setEditViewQuestion={setEditViewQuestion}
                editViewQuestion={editViewQuestion}
                addQuestion={addQuestion}
              />
              <Grid
                container
                direction="row"
                className={classes.marksQuestionValidationContainer}
              >
                <Grid item sm={8} xs={12}>
                  {errors?.emptyQuestion && (
                    <Grid>
                      <Typography className={classes.validationText}>
                        Question should not be empty
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={12}
                  className={classes.marksValidationContainer}
                >
                  {errors?.emptyMarks && (
                    <Grid>
                      <Typography className={classes.validationText}>
                        Marks should not be empty
                      </Typography>
                    </Grid>
                  )}
                  {errors?.marksLessThanZero && (
                    <Grid>
                      <Typography className={classes.validationText}>
                        Marks should be more than 0
                      </Typography>
                    </Grid>
                  )}
                  {errors?.marksNotValid && (
                    <Grid>
                      <Typography className={classes.validationText}>
                        Only numeric values are allowed
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                {localQuestion?.choices?.map((choice, index) => {
                  return (
                    <Grid item xs={12} key={choice?.id}>
                      <Paper
                        elevation={2}
                        variant="outlined"
                        className={choice?.isCorrect ? classes.isCorrect : ''}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '8px',
                          marginBottom: '8px',
                        }}
                      >
                        <Grid container xs={12} spacing={2}>
                          <Grid
                            item
                            xs={2}
                            sm={1}
                            md={1}
                            lg={1}
                            className={classes.outerColumn}
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {(questionTypeFromParent || question?.type) ===
                            'mcq' ? (
                              <RadioGroup
                                className={classes.mcqRadioChoice}
                                onChange={() => handleCorrectChoice(choice?.id)}
                              >
                                <FormControlLabel
                                  value={choice?.value}
                                  control={<Radio />}
                                  checked={choice?.isCorrect}
                                  disabled={
                                    isQuizStarted(quizStartTime) && isPublished
                                  }
                                />
                              </RadioGroup>
                            ) : null}
                            {(questionTypeFromParent || question?.type) ===
                            'multi-choice' ? (
                              <FormGroup className={classes.mcqRadioChoice}>
                                <FormControlLabel
                                  value={choice?.value}
                                  control={<Checkbox />}
                                  onChange={() => handleMultiChoice(choice?.id)}
                                  checked={choice?.isCorrect}
                                  disabled={
                                    isQuizStarted(quizStartTime) && isPublished
                                  }
                                />
                              </FormGroup>
                            ) : null}
                          </Grid>

                          <Grid
                            item
                            xs={8}
                            sm={10}
                            md={10}
                            lg={10}
                            direction="row"
                            style={{ display: 'flex' }}
                            justifyContent="flex-start"
                          >
                            <InputBase
                              defaultValue={choice?.value}
                              placeholder="Enter Choice"
                              multiline
                              autoComplete="off"
                              className={classes.inputChoiceValue}
                              inputProps={{
                                'aria-label': 'naked',
                              }}
                              size="small"
                              fullWidth
                              onChange={(event) =>
                                handleEdit(event, 'choice', index)
                              }
                              disabled={
                                isQuizStarted(quizStartTime) && isPublished
                              }
                            />
                          </Grid>

                          {(!isQuizStarted(quizStartTime) || !isPublished) && (
                            <Grid
                              item
                              xs={2}
                              sm={1}
                              md={1}
                              lg={1}
                              style={{ display: 'flex' }}
                              alignItems="center"
                              justifyContent="flex-end"
                              onClick={() => {
                                handleRemoveChoice(choice?.id, choice?.value)
                              }}
                            >
                              <img
                                src={DeleteIcon}
                                alt="delete"
                                className={classes.closeChoice}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {(!isQuizStarted(quizStartTime) || !isPublished) && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => handleChoice('Add')}
                    className={classes.addChoiceButton}
                    disabled={isQuizStarted(quizStartTime) && isPublished}
                  >
                    Add Choice
                  </Button>
                )}
              </Grid>
              <UpdateQuestionsInQuizesModal
                isLoading={false}
                showQuizesListModal={showQuizesListModal}
                setShowQuizesListModal={setShowQuizesListModal}
                affectedQuizes={affectedQuizes}
                handleSave={handleSave}
                currentQuestion={localQuestion}
                updateQuestionInSelectedQuizzes={
                  updateQuestionInSelectedQuizzes
                }
                setSelectedQuizzes={setSelectedQuizzes}
                selectedQuizzes={selectedQuizzes}
                quizId={quizId}
              />
            </Grid>
            <div style={{ flex: 1 }} />
            {editQuestionQuery?.isLoading || postQuestion?.isLoading ? (
              <div className={classes.cancelAndSaveButtonWrapper}>
                <Loader size={30} />
              </div>
            ) : (
              <div className={classes.cancelAndSaveButtonWrapper}>
                <Button
                  onClick={
                    !Number.isNaN(editQuestion)
                      ? handleCancel
                      : handleCancelAddMcq
                  }
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveButtonClick}
                  variant="filled"
                  disabled={isQuizStarted(quizStartTime) && isPublished}
                >
                  Save
                </Button>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default EditMcqQuestionTile
