import { useMutation } from 'react-query'
import Login from '../../Repository/Login/Login'

const loginReq = Login.getInstance()

export function Refresh(options) {
  return useMutation((args) => loginReq.postRefresh(args), {
    ...options,
  })
}
