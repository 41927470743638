import { useMutation, useQuery } from 'react-query'
import { createResult } from '../../Repository/Result/Results'

const resultReq = createResult()

export function useResultGetQuery(
  reqId,
  resultPageLimit,
  resultsPageOffset,
  options
) {
  return useQuery(
    ['getAllResults', reqId, resultPageLimit, resultsPageOffset],
    () => resultReq.getResults(reqId, resultPageLimit, resultsPageOffset),
    { ...options }
  )
}

export function useAllResultGetQuery(reqId, options) {
  return useQuery(['getResults', reqId], () => resultReq.getAllResults(reqId), {
    ...options,
  })
}

export function useResultIdGetQuery(candidateId, quizId, options) {
  return useQuery(
    ['ResultSheet', candidateId, quizId],
    () => resultReq.getResultSheet(candidateId, quizId),
    { ...options }
  )
}

export function useResultIdGetQueryMutate(quizId, options) {
  return useMutation((args) => resultReq.geIndividualResult(quizId, args), {
    ...options,
  })
}
