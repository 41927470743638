import React from 'react'
import { Grid, Typography, Tooltip, Hidden } from '@material-ui/core'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useStyle } from './EditQuizConfigurationsStyles'
import { ReactComponent as CodingQuestionIcon } from '../../icons/CodingQuestionIcon.svg'
import ProctoringIcon from '../../icons/Proctoring'
import ShowResultIcon from '../../icons/Showresult'
import RandomizeQuestionsIcon from '../../icons/RandomizeQuestions'

const QuizConfig = ({
  isOngoing,
  isPublished,
  startTime,
  endTime,
  isClosed,
  //   handleDisableCodingQuestionSwitch,
  codingQuestionSwitch,
  handleChangeCodingQuestion,
  toolTipRendering,
  handleChangeResults,
  quizSettings,
  handleChangeRandomize,
  //   questionCount,
  handleChangeProctoring,
  isWriteAllowed,
}) => {
  const classes = useStyle({
    publish: isPublished,
    startTime,
    endTime,
    isClosed,
  })

  const handleCodingQuestion = () => {
    // isClose condition handles the condition for isWriteAllowed in EditQuizMain.jsx
    if (!isOngoing && !isClosed) {
      handleChangeCodingQuestion(
        quizSettings?.type === 'private' ? !codingQuestionSwitch : false
      )
    }
  }
  const handleProctoring = () => {
    // isClose condition handles the condition for isWriteAllowed in EditQuizMain.jsx
    if (!isOngoing && !isClosed) {
      handleChangeProctoring()
    }
  }

  const handleResults = () => {
    if (!isOngoing && isWriteAllowed) handleChangeResults()
  }

  const handleRandomize = () => {
    if (!isOngoing && isWriteAllowed) handleChangeRandomize()
  }

  const getClassNameForPublicSettings = (condition = true) => {
    if (isOngoing || quizSettings?.type === 'public')
      return classes.disabledTile
    if (condition) return classes.selectedConfigTile
    return classes.configTile
  }

  const getTitleForPublicQuiz = (condition = true) => {
    if (isOngoing || isClosed || condition) return toolTipRendering()
    if (quizSettings?.type === 'public')
      return 'Select private quiz type to enable this setting'
    return ''
  }

  return (
    <Grid
      container
      className={classes.quizTypeWrapper}
      style={{ paddingTop: '24px' }}
    >
      <Grid item container className={classes.quizTypeIconAndHeadingWrapper}>
        <Typography className={classes.settingTitle}>Quiz Config</Typography>
        <Grid container spacing={2}>
          {/* Add Coding Questions */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Tooltip
              arrow
              classes={{
                arrow: classes.arrow,
                tooltip: classes.tooltip,
              }}
              title={getTitleForPublicQuiz(isPublished)}
            >
              <FormControl fullWidth>
                <FormGroup>
                  <div
                    role="presentation"
                    style={{
                      marginTop: '16px',
                      opacity:
                        isOngoing || isClosed || quizSettings?.type === 'public'
                          ? 0.5
                          : 1,
                      cursor:
                        isOngoing || isClosed || quizSettings?.type === 'public'
                          ? ''
                          : 'pointer',
                    }}
                    className={getClassNameForPublicSettings(
                      codingQuestionSwitch
                    )}
                    onClick={() => handleCodingQuestion()}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '16px',
                      }}
                    >
                      <CodingQuestionIcon />
                      {codingQuestionSwitch ? (
                        <CheckBoxRoundedIcon style={{ color: '#01B7A4' }} />
                      ) : (
                        <CheckBoxOutlineBlankOutlinedIcon />
                      )}
                      <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
                        <FormControlLabel
                          className={classes.hideCheckbox}
                          disabled={quizSettings?.type === 'public'}
                          control={
                            <Checkbox
                              checked={codingQuestionSwitch}
                              onChange={handleCodingQuestion}
                            />
                          }
                        />
                      </Hidden>
                    </div>
                    <Typography className={classes.settingTileTitle}>
                      Add Coding Questions
                    </Typography>
                    <Typography className={classes.settingConfigSubTitle}>
                      Enables Coding Questions
                    </Typography>

                    <Typography
                      className={
                        quizSettings?.type === 'private'
                          ? classes.credits
                          : classes.disabledCredits
                      }
                    >
                      2 credits
                    </Typography>
                  </div>
                </FormGroup>
              </FormControl>
            </Tooltip>
          </Grid>

          {/* Proctoring */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Tooltip
              arrow
              classes={{
                arrow: classes.arrow,
                tooltip: classes.tooltip,
              }}
              title={getTitleForPublicQuiz()}
            >
              <FormControl fullWidth>
                <FormGroup>
                  <div
                    role="presentation"
                    style={{
                      marginTop: '16px',
                      opacity:
                        isClosed || isOngoing || quizSettings?.type === 'public'
                          ? 0.5
                          : 1,
                      cursor:
                        isClosed || isOngoing || quizSettings?.type === 'public'
                          ? ''
                          : 'pointer',
                    }}
                    className={getClassNameForPublicSettings(
                      quizSettings?.proctored
                    )}
                    onClick={handleProctoring}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '16px',
                      }}
                    >
                      <ProctoringIcon />
                      {quizSettings?.proctored ? (
                        <CheckBoxRoundedIcon style={{ color: '#01B7A4' }} />
                      ) : (
                        <CheckBoxOutlineBlankOutlinedIcon />
                      )}
                      <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
                        <FormControlLabel
                          className={classes.hideCheckbox}
                          disabled={quizSettings?.type === 'public'}
                          control={
                            <Checkbox
                              checked={quizSettings?.proctored}
                              onChange={() => handleProctoring()}
                            />
                          }
                        />
                      </Hidden>
                    </div>
                    <Typography
                      className={classes.settingTileTitle}
                      data-testid="quizConfigProctoring-testId"
                    >
                      Proctoring
                    </Typography>
                    <Typography className={classes.settingConfigSubTitle}>
                      Proctoring enables to see live video of quiz takers.
                    </Typography>
                    <Typography
                      className={
                        quizSettings?.type === 'private'
                          ? classes.credits
                          : classes.disabledCredits
                      }
                    >
                      4 credits
                    </Typography>
                  </div>
                </FormGroup>
              </FormControl>
            </Tooltip>
          </Grid>

          {/* Show Result */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Tooltip
              arrow
              classes={{
                arrow: classes.arrow,
                tooltip: classes.tooltip,
              }}
              title={isOngoing || !isWriteAllowed ? toolTipRendering() : ''}
            >
              <FormControl fullWidth>
                <FormGroup>
                  <div
                    role="presentation"
                    onClick={handleResults}
                    style={{
                      marginTop: '16px',
                      opacity: isOngoing || !isWriteAllowed ? 0.5 : 1,
                      cursor: isOngoing || !isWriteAllowed ? '' : 'pointer',
                    }}
                    className={
                      quizSettings?.result
                        ? classes.selectedConfigTile
                        : classes.configTile
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '16px',
                      }}
                    >
                      <ShowResultIcon />
                      {quizSettings?.result ? (
                        <CheckBoxRoundedIcon style={{ color: '#01B7A4' }} />
                      ) : (
                        <CheckBoxOutlineBlankOutlinedIcon />
                      )}
                    </div>
                    <Typography className={classes.settingTileTitle}>
                      Show Result
                    </Typography>
                    <Typography className={classes.settingConfigSubTitle}>
                      Display the result to the test taker at the end of the
                      test
                    </Typography>
                  </div>
                </FormGroup>
              </FormControl>
            </Tooltip>
          </Grid>

          {/* Randomize Questions */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Tooltip
              arrow
              classes={{
                arrow: classes.arrow,
                tooltip: classes.tooltip,
              }}
              title={isOngoing || !isWriteAllowed ? toolTipRendering() : ''}
            >
              <FormControl fullWidth>
                <FormGroup>
                  <div
                    role="presentation"
                    style={{
                      marginTop: '16px',
                      opacity: isOngoing || !isWriteAllowed ? 0.5 : 1,
                      cursor: isOngoing || !isWriteAllowed ? '' : 'pointer',
                    }}
                    className={
                      quizSettings?.randomize
                        ? classes.selectedConfigTile
                        : classes.configTile
                    }
                    onClick={handleRandomize}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '16px',
                      }}
                    >
                      <RandomizeQuestionsIcon />
                      {quizSettings?.randomize ? (
                        <CheckBoxRoundedIcon style={{ color: '#01B7A4' }} />
                      ) : (
                        <CheckBoxOutlineBlankOutlinedIcon />
                      )}
                      <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
                        <FormControlLabel
                          className={classes.hideCheckbox}
                          control={
                            <Checkbox
                              checked={quizSettings?.randomize}
                              onChange={() => handleRandomize()}
                            />
                          }
                        />
                      </Hidden>
                    </div>
                    <Typography className={classes.settingTileTitle}>
                      Randomize Questions
                    </Typography>
                    <Typography className={classes.settingConfigSubTitle}>
                      Questions will not be shown in the order added.
                    </Typography>
                  </div>
                </FormGroup>
              </FormControl>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuizConfig
