import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { styles } from './CustomDialogStyles'

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, customClasses, ...other } = props
  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.defaultRoot} ${
        customClasses?.root ? customClasses.root : classes.additionalRoot
      }`}
      {...other}
    >
      <Typography variant="h6" className={classes.dialogTitleText}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export { styles, DialogActions, DialogTitle, DialogContent }
