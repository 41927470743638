import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Logo from '../Logo/Logo'
import QuizNotFoundIllustration from '../../Assets/Illustrations/QuizNotFoundIllustration'
import useWindowSize from '../../hooks/useWindowSize'
import { useStyles } from './QuizNotFoundStyles'

function QuizNotFound({ reason }) {
  const windowSize = useWindowSize()
  const classes = useStyles()

  useEffect(() => {
    window?.gtag('event', 'quiz_load_failed', {
      event_category: 'Quiz not found',
      event_label: 'Quiz not found',
    })
  }, [])

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      style={{ height: `${windowSize?.height}px` }}
    >
      <Helmet>
        <title>Quiz not found</title>
      </Helmet>
      <Grid container alignItems="center" className={classes.header}>
        <Logo />
      </Grid>
      <Grid container alignItems="center" className={classes.body}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.illustrationAndTextWrapper}
        >
          <Grid item className={classes.illustrationWrapper}>
            <QuizNotFoundIllustration />
          </Grid>
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.text}>{reason}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuizNotFound
