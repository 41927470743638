import React from 'react'

function PageNotFoundIllustration() {
  return (
    <svg
      viewBox="0 0 1232 1232"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#D0ECEB" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="71.2898835%"
          id="linearGradient-2"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#9EC9C8" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="71.2898835%"
          id="linearGradient-3"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#BFDEDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="56.7433801%"
          y1="55.5270963%"
          x2="38.7162612%"
          y2="50%"
          id="linearGradient-4"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#A1D2D0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="100%"
          x2="50%"
          y2="0%"
          id="linearGradient-5"
        >
          <stop stopColor="#979797" offset="0%" />
          <stop stopColor="#C8C8C8" stopOpacity="0.366641171" offset="100%" />
        </linearGradient>
        <filter
          x="-35.7%"
          y="-40.5%"
          width="171.4%"
          height="181.1%"
          filterUnits="objectBoundingBox"
          id="filter-6"
        >
          <feGaussianBlur stdDeviation="15" in="SourceGraphic" />
        </filter>
        <filter
          x="-43.3%"
          y="-49.5%"
          width="186.5%"
          height="198.9%"
          filterUnits="objectBoundingBox"
          id="filter-7"
        >
          <feGaussianBlur stdDeviation="15" in="SourceGraphic" />
        </filter>
        <filter
          x="-65.2%"
          y="-73.8%"
          width="230.4%"
          height="247.5%"
          filterUnits="objectBoundingBox"
          id="filter-8"
        >
          <feGaussianBlur stdDeviation="15" in="SourceGraphic" />
        </filter>
        <filter
          x="-91.8%"
          y="-104.7%"
          width="283.7%"
          height="309.3%"
          filterUnits="objectBoundingBox"
          id="filter-9"
        >
          <feGaussianBlur stdDeviation="15" in="SourceGraphic" />
        </filter>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-10"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-11"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-12"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-13"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-8.12081541%"
          y1="50.0035013%"
          x2="102.099013%"
          y2="50%"
          id="linearGradient-14"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-8.12081541%"
          y1="50.0092554%"
          x2="102.099013%"
          y2="50%"
          id="linearGradient-15"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-8.12081541%"
          y1="50.0041701%"
          x2="102.099013%"
          y2="50%"
          id="linearGradient-16"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="When-we-enter-correct-quiz-number-but-some-wrong-route-in-address-bar-(-page-doesn't-exists-or-bad-route)">
          <g id="Group-32" transform="translate(42.000000, 268.000000)">
            <path
              d="M167.3481,697 C157.89078,655.666638 173.227238,611.186037 213.357473,563.558196 C273.552824,492.116433 292.254917,475.853744 264.930089,402.882235 C237.60526,329.910728 173.449534,176.716775 310.670366,108.79957 C447.891198,40.882366 518.613424,125.968993 590.68405,148.613911 C662.754675,171.258829 867.916111,226.753795 946.9364,351.938482 C1025.9567,477.123169 961.280334,475.490158 946.9364,570.313924 C932.592474,665.13769 979.763055,697 921.664883,697 C882.932775,697 631.493846,697 167.3481,697 Z"
              id="Path-2"
              fill="#DFF1F1"
              fillRule="nonzero"
            />
            <rect
              id="Rectangle"
              fill="#B2CECE"
              fillRule="nonzero"
              x="0"
              y="677"
              width="1149"
              height="20"
              rx="10"
            />
            <polygon
              id="Path-33"
              stroke="#00B8A5"
              strokeWidth="6"
              opacity="0.133698219"
              points="479 274 631 269.131721 631 192 787 189 787.121425 414 879.665039 413.591502 879.665039 609 694 609 694 657.555329 479 657.555329 479 634 409.015581 634 409.015581 518 341 516 341 341.827026 479 341.827026"
            />
            <path
              d="M740.441753,312.670362 L561.079243,364.806829 L561.079243,364.806829 L387.575258,312.700141 C386.517365,312.382435 385.402222,312.982474 385.084516,314.040367 C385.028473,314.226979 385,314.420779 385,314.615625 L385,546.563759 C385,547.432421 385.560721,548.201761 386.387668,548.467715 L534.182354,596 L534.182354,596 L590.147629,596 L741.598963,548.461196 C742.432692,548.199498 743,547.426829 743,546.552992 L743,314.590873 C743,313.486303 742.104569,312.590873 741,312.590873 C740.811097,312.590873 740.623147,312.617635 740.441753,312.670362 Z"
              id="Path-24"
              stroke="#252B4C"
              strokeWidth="2"
              fill="#00B8A5"
              fillRule="nonzero"
            />
            <path
              d="M732.151515,323.037458 L672.515372,323.037458 C653.82898,323.037458 635.516412,328.273233 619.65421,338.151078 L563.576587,373.072208 L563.576587,373.072208 C535.447007,342.664965 511.647908,324.53861 492.17929,318.693144 C472.710672,312.847678 440.96202,314.295783 396.933333,323.037458 L396.933333,539.279263 L536.175037,585.157509 L590.517269,585.157509 L732.151515,539.279263 L732.151515,323.037458 Z"
              id="Path-25"
              fill="url(#linearGradient-1)"
            />
            <path
              d="M422.969697,306.08497 L422.969697,526.60164 C458.695429,529.846076 485.037126,534.577579 501.994789,540.796148 C518.952453,547.014717 540.455928,559.828587 566.505215,579.237758 C603.124741,558.98293 630.789843,546.16906 649.500522,540.796148 C668.2112,535.423235 691.422137,533.627667 719.133333,535.409443 L719.133333,317.333248 C682.246457,313.630777 654.883075,315.023163 637.043187,321.510408 C619.203299,327.997653 596.027991,344.983008 567.517263,372.466473 C546.656772,344.235468 526.945383,325.857726 508.383095,317.333248 C489.820808,308.80877 461.349675,305.059344 422.969697,306.08497 Z"
              id="Path-26-Copy"
              fill="url(#linearGradient-2)"
            />
            <path
              d="M426.224242,308.83195 L426.224242,519.655579 C460.379612,522.757402 485.563433,527.280927 501.775705,533.226153 C517.987976,539.171378 538.546243,551.422001 563.450507,569.978022 C598.460384,550.613516 624.909437,538.362893 642.797668,533.226153 C660.685899,528.089412 682.876576,526.37277 709.369697,528.076226 L709.369697,319.585798 C674.104222,316.046072 647.943625,317.377255 630.887908,323.579346 C613.832191,329.781438 591.675578,346.020184 564.41807,372.295585 C544.474523,345.305502 525.629569,327.735574 507.883206,319.585798 C490.136843,311.436022 462.917188,307.851406 426.224242,308.83195 Z"
              id="Path-26"
              fill="url(#linearGradient-3)"
            />
            <path
              d="M442.49697,300 C474.814322,304.601538 498.168326,311.146034 512.558982,319.63349 C526.949638,328.120946 544.454709,345.674696 565.074194,372.294742 L564.93897,372.12 L565.084848,372.644507 L565.084848,558.051282 L539.743342,549.210171 C527.6698,539.665148 517.30671,532.628896 508.65407,528.101416 C494.650899,520.774278 472.598533,513.462096 442.49697,506.16487 L442.49697,506.16487 Z"
              id="Combined-Shape"
              fill="url(#linearGradient-4)"
              opacity="0.2"
            />
            <path
              d="M564,300 C596.317352,304.601538 619.671357,311.146034 634.062013,319.63349 C648.452669,328.120946 665.957739,345.674696 686.577224,372.294742 L686.442,372.12 L686.587879,372.644507 L686.587879,558.051282 L661.246373,549.210171 C649.172831,539.665148 638.80974,532.628896 630.1571,528.101416 C616.15393,520.774278 594.101563,513.462096 564,506.16487 L564,506.16487 Z"
              id="Combined-Shape-Copy-2"
              fill="url(#linearGradient-4)"
              opacity="0.2"
              transform="translate(625.293939, 429.025641) scale(-1, 1) translate(-625.293939, -429.025641) "
            />
            <path
              d="M564.542424,583.430903 C578.023193,583.430903 588.951515,587.729983 588.951515,574.084543 C588.951515,560.439104 578.023193,549.377289 564.542424,549.377289 C551.061656,549.377289 540.133333,560.439104 540.133333,574.084543 C540.133333,587.729983 551.061656,583.430903 564.542424,583.430903 Z"
              id="Oval"
              fill="#D5EEED"
            />
            <path
              d="M581,396 C594.849944,380.157717 613.950126,354 673,354"
              id="Path-29"
              stroke="#9FCAC8"
            />
            <path
              d="M581,407 C594.849944,391.157717 613.950126,365 673,365"
              id="Path-29-Copy"
              stroke="#9FCAC8"
            />
            <path
              d="M579,417 C593.187572,400.979581 600.213928,391.994942 624,382"
              id="Path-29-Copy-2"
              stroke="#9FCAC8"
            />
            <path
              d="M581,427 C594.849944,411.157717 613.950126,385 673,385"
              id="Path-29-Copy-3"
              stroke="#9FCAC8"
            />
            <path
              d="M581,437 C594.849944,421.157717 613.950126,395 673,395"
              id="Path-29-Copy-4"
              stroke="#9FCAC8"
            />
            <path
              d="M460,439 L460,493.318333 C476.063881,496.860662 487.332933,499.897742 493.807155,502.429572 C500.281377,504.961402 508.345658,509.484878 518,516 L518,462.791689 C508.398561,456.894647 500.33428,452.607408 493.807155,449.929972 C487.28003,447.252535 476.010978,443.609211 460,439 Z"
              id="Path-30"
              stroke="#9FCAC8"
            />
            <line
              x1="565.5"
              y1="370"
              x2="565.5"
              y2="549"
              id="Path-31"
              stroke="url(#linearGradient-5)"
              opacity="0.294036156"
            />
            <path
              d="M611.238721,204.196244 L568,300 C585.029126,292.886864 604.470017,288.324207 626.322673,286.312029 C648.175328,284.299851 666.015991,287.301494 679.84466,295.316956 L694,199.170958 C689.262136,192.390319 676.510565,189 655.745288,189 C634.980011,189 620.144489,194.065415 611.238721,204.196244 Z"
              id="Path-32"
              fill="#203C57"
              fillRule="nonzero"
              opacity="0.04"
              filter="url(#filter-6)"
            />
            <path
              d="M400.689103,111.458182 L365,190 C379.055787,184.16851 395.102236,180.427954 413.139349,178.77833 C431.176462,177.128707 445.902088,179.589513 457.316227,186.160748 L469,107.338353 C465.089382,101.779451 454.564276,99 437.424682,99 C420.285089,99 408.039896,103.152727 400.689103,111.458182 Z"
              id="Path-32-Copy"
              fill="#203C57"
              fillRule="nonzero"
              opacity="0.04"
              filter="url(#filter-7)"
              transform="translate(417.000000, 144.500000) scale(-1, 1) translate(-417.000000, -144.500000) "
            />
            <path
              d="M630.678347,77.3510892 L607,130 C616.325474,126.09098 626.971676,123.583573 638.938607,122.477782 C650.905537,121.37199 660.675424,123.021542 668.248266,127.426435 L676,74.5894456 C673.405455,70.8631485 666.422452,69 655.050991,69 C643.67953,69 635.555315,71.7836964 630.678347,77.3510892 Z"
              id="Path-32-Copy-2"
              fill="#203C57"
              fillRule="nonzero"
              opacity="0.04"
              filter="url(#filter-8)"
            />
            <path
              d="M730.815058,31.8868334 L714,69 C720.622438,66.244461 728.182784,64.4769452 736.681039,63.6974528 C745.179294,62.9179604 752.11733,64.0807588 757.495146,67.185848 L763,29.940101 C761.157497,27.313367 756.198553,26 748.123168,26 C740.047782,26 734.278412,27.9622778 730.815058,31.8868334 Z"
              id="Path-32-Copy-3"
              fill="#203C57"
              fillRule="nonzero"
              opacity="0.04"
              filter="url(#filter-9)"
            />
            <path
              d="M611.238721,178.196244 L568,274 C585.029126,266.886864 604.470017,262.324207 626.322673,260.312029 C648.175328,258.299851 666.015991,261.301494 679.84466,269.316956 L694,173.170958 C689.262136,166.390319 676.510565,163 655.745288,163 C634.980011,163 620.144489,168.065415 611.238721,178.196244 Z"
              id="Path-32"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <path
              d="M400.689103,85.4581823 L365,164 C379.055787,158.16851 395.102236,154.427954 413.139349,152.77833 C431.176462,151.128707 445.902088,153.589513 457.316227,160.160748 L469,81.3383533 C465.089382,75.7794511 454.564276,73 437.424682,73 C420.285089,73 408.039896,77.1527274 400.689103,85.4581823 Z"
              id="Path-32-Copy"
              fill="#FFFFFF"
              fillRule="nonzero"
              transform="translate(417.000000, 118.500000) scale(-1, 1) translate(-417.000000, -118.500000) "
            />
            <path
              d="M630.678347,51.3510892 L607,104 C616.325474,100.09098 626.971676,97.5835735 638.938607,96.4777819 C650.905537,95.3719903 660.675424,97.0215415 668.248266,101.426435 L676,48.5894456 C673.405455,44.8631485 666.422452,43 655.050991,43 C643.67953,43 635.555315,45.7836964 630.678347,51.3510892 Z"
              id="Path-32-Copy-2"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <path
              d="M730.815058,5.88683338 L714,43 C720.622438,40.244461 728.182784,38.4769452 736.681039,37.6974528 C745.179294,36.9179604 752.11733,38.0807588 757.495146,41.185848 L763,3.94010102 C761.157497,1.31336701 756.198553,0 748.123168,0 C740.047782,0 734.278412,1.96227779 730.815058,5.88683338 Z"
              id="Path-32-Copy-3"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <circle
              id="Oval"
              fill="#00B8A5"
              opacity="0.543082304"
              cx="787"
              cy="414"
              r="13"
            />
            <circle
              id="Oval-Copy-10"
              fill="#00B8A5"
              opacity="0.543082304"
              cx="479"
              cy="273"
              r="13"
            />
            <circle
              id="Oval-Copy-11"
              fill="#00B8A5"
              opacity="0.543082304"
              cx="341"
              cy="513"
              r="13"
            />
            <circle
              id="Oval-Copy-12"
              fill="#00B8A5"
              opacity="0.543082304"
              cx="479"
              cy="634"
              r="13"
            />
            <circle
              id="Oval-Copy-13"
              fill="#00B8A5"
              opacity="0.543082304"
              cx="694"
              cy="609"
              r="13"
            />
            <line
              x1="365"
              y1="83"
              x2="365"
              y2="342.799993"
              id="Path-34"
              stroke="url(#linearGradient-10)"
              strokeWidth="6"
              opacity="0.133698219"
            />
            <line
              x1="840"
              y1="260"
              x2="840"
              y2="415.799993"
              id="Path-34-Copy"
              stroke="url(#linearGradient-11)"
              strokeWidth="6"
              opacity="0.133698219"
            />
            <line
              x1="532"
              y1="137"
              x2="532"
              y2="273.799993"
              id="Path-34-Copy-6"
              stroke="url(#linearGradient-12)"
              strokeWidth="6"
              opacity="0.133698219"
            />
            <line
              x1="840"
              y1="609"
              x2="840"
              y2="682"
              id="Path-34-Copy-2"
              stroke="url(#linearGradient-13)"
              strokeWidth="6"
              opacity="0.133698219"
              transform="translate(840.000000, 645.500000) scale(-1, -1) translate(-840.000000, -645.500000) "
            />
            <line
              x1="169"
              y1="609"
              x2="407.5"
              y2="608.5"
              id="Path-34-Copy-3"
              stroke="url(#linearGradient-14)"
              strokeWidth="6"
              opacity="0.133698219"
            />
            <line
              x1="219"
              y1="286"
              x2="365.5"
              y2="285.5"
              id="Path-34-Copy-4"
              stroke="url(#linearGradient-15)"
              strokeWidth="6"
              opacity="0.133698219"
            />
            <line
              x1="733"
              y1="513"
              x2="951.5"
              y2="512.5"
              id="Path-34-Copy-5"
              stroke="url(#linearGradient-16)"
              strokeWidth="6"
              opacity="0.133698219"
              transform="translate(842.000000, 513.000000) scale(-1, 1) translate(-842.000000, -513.000000) "
            />
          </g>
          <rect id="Rectangle" x="0" y="0" width="1232" height="1232" />
        </g>
      </g>
    </svg>
  )
}

export default PageNotFoundIllustration
