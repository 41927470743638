import React from 'react'
import { Typography, Paper } from '@material-ui/core'

const QuestionsDrawerTile = ({ question, index, executeScroll }) => {
  // let actualQuestion = ''

  // const checkquestionLength = (ques) => {
  //   actualQuestion = ques

  //   if (ques.length > 28) {
  //     actualQuestion = `${ques.substr(0, 28)}...`
  //     return actualQuestion
  //   }
  //   actualQuestion =
  //     actualQuestion?.charAt(0).toUpperCase() + actualQuestion.slice(1)
  //   return actualQuestion
  // }
  return (
    <>
      <Paper
        onClick={(e) => executeScroll(e, index)}
        elevation={2}
        variant="outlined"
        style={{
          marginRight: '8px',
          padding: '8px',
          display: 'flex',
          marginTop: '8px',
        }}
      >
        <div style={{ minWidth: '0px', paddingLeft: ' 8px' }}>
          <Typography
            style={{
              fontWeight: 500,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {index + 1}. {question?.question?.replace(/<[^>]+>/g, '')}
          </Typography>
          <div style={{ display: 'flex', paddingLeft: '16px' }}>
            <Typography style={{ fontWeight: 400, color: '#00B7A4' }}>
              {question?.type === 'multi-choice'
                ? 'MULTIPLE ANSWER'
                : question?.type?.toUpperCase()}
            </Typography>
            <Typography
              style={{ fontWeight: 400, color: '#00B7A4', paddingLeft: '4px' }}
            >
              {question?.type === 'essay' ? 'N/A ' : question?.marks}
              Marks
            </Typography>
          </div>
        </div>
      </Paper>
    </>
  )
}

export default QuestionsDrawerTile
