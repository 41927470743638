/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import './style.css'

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
]

// Quill Toolbar component
export const QuillToolbar = ({ className }) => {
  return (
    <div
      id={`toolbar${className}`}
      className="toolbar"
      data-testid="QuillToolbar"
    >
      <span className="ql-formats editor-section">
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats editor-section">
        <button className="ql-bold editor-item" />
        <button className="ql-italic editor-item" />
        <button className="ql-underline editor-item" />
      </span>
      <span className="ql-formats editor-section">
        <button className="ql-list editor-item" value="ordered" />
        <button className="ql-list editor-item" value="bullet" />
      </span>
      <span className="ql-formats editor-section">
        <button className="ql-script editor-item" value="super" />
        <button className="ql-script editor-item" value="sub" />
        <button className="ql-blockquote editor-item" />
      </span>
      <span className="ql-formats editor-section">
        <button className="ql-link editor-item" />
        <button className="ql-image editor-item" />
      </span>
      <span className="ql-formats">
        <button className="ql-formula editor-item" />
        <button className="ql-code-block editor-item" />
        <button className="ql-clean editor-item" />
      </span>
    </div>
  )
}

export default QuillToolbar
