import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import parse from 'html-react-parser'
import ErrorTag from './ErrorTag'
import { useStyles } from './ReviewQuizStyles'

const ReviewFields = ({
  errorSnackBar,
  error,
  errorMessage,
  isQuestions = false,
  title,
  details,
}) => {
  const classes = useStyles()
  const renderTitle = () => {
    if (title === 'Total Questions') return details
    if (title === 'Description' && errorSnackBar?.emptyDescription) return ''
    if (typeof details === 'number') {
      return details
    }
    return details?.charAt(0)?.toUpperCase() + details?.slice(1)
  }

  return (
    <>
      <Grid
        container
        xs={12}
        style={{ paddingBottom: '8px', minHeight: '39px' }}
      >
        <Grid item xs={4}>
          <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {(error || errorSnackBar) && <ErrorTag errorMessage={errorMessage} />}
          {isQuestions && details?.length > 0 ? (
            details.map((ques) => (
              <>
                <div style={{ paddingBottom: '8px' }}>
                  <Typography className={classes.question} key={ques?.question}>
                    {ques?.question && parse(ques?.question)}
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontWeight: 400,
                        paddingRight: '4px',
                        opacity: 0.6,
                      }}
                      key={ques}
                    >
                      {ques?.type?.toUpperCase()} .
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontWeight: 400,
                        opacity: 0.6,
                      }}
                      key={ques}
                    >
                      {ques?.marks} Marks
                    </Typography>
                  </div>
                </div>
              </>
            ))
          ) : (
            <>
              {!error && (
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {renderTitle()}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewFields
