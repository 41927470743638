import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  loginPageContainer: {
    backgroundColor: theme.palette.background.default,
    paddingTop: '46px',
  },
  loginPageContent: {
    backgroundColor: theme.palette.background.white,
    display: 'flex',
    width: '92%',
    margin: (props) => (props.aboveTabScreenSize ? '0px' : '4px 4px 0px 0px'),
    minHeight: (props) => {
      return props?.zoomOut && '95%'
    },
    height: (props) => {
      return !props?.zoomOut && '95%'
    },
    '@media only screen and (min-width:600px) and (min-height: 550px)': {
      width: '80%',
      height: '70% !important',
    },
  },
  loginPageTextContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: '10%',
    color: '#262A4C',
    '@media only screen and (min-width:600px) and (min-height: 500px)': {
      order: 1,
      padding: '5%',
      width: '50%',
    },
  },
  signInTitle: {
    fontSize: '26px',
    fontWeight: 700,
    '@media only screen and (min-width:600px) and (min-height: 500px)': {
      fontSize: '28px',
    },
    '@media only screen and (min-width:1440px)': {
      fontSize: '30px',
    },
    '@media only screen and (min-width:2560px)': {
      fontSize: '40px',
    },
  },
  signInContent: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '1.3',
    marginTop: 20,
    marginBottom: 30,
    '@media only screen and (min-width:600px) and (min-height: 500px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width:1440px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:2560px)': {
      fontSize: '30px',
    },
  },
  loginButton: {
    backgroundColor: theme.palette?.primaryButton?.main,
    color: theme.palette?.primaryButton?.contrastText,
    width: 'fit-content',
    height: '44px',
    borderRadius: 4,
    boxShadow: 'none',
    fontWeight: 500,
    padding: '0px 20px 0px 10px',
    ' &:hover': {
      backgroundColor: theme.palette?.primaryButton?.hover,
    },
    '@media only screen and (min-width:600px) and (min-height: 500px)': {
      height: '50px',
    },
    '@media only screen and (min-width:2560px)': {
      height: '60px',
    },
  },
  googleSvg: {
    backgroundColor: 'white',
    width: '30',
    height: '30',
    marginRight: '1%',
  },
  signInGoggleText: {
    fontSize: '14px',
    '@media only screen and (min-width:1440px)': {
      fontSize: '16px',
    },
    '@media only screen and (min-width:2560px)': {
      fontSize: '18px',
    },
  },
  loader: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginMsg: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  loginLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '50%',
    padding: '5% 5% 0% 5%',
    '@media only screen and (min-width:600px) and (min-height: 500px) ': {
      width: '50%',
      height: '100%',
      order: 2,
      padding: '5% 5% 5% 0%',
    },
  },
  loginFailedMsg: {
    fontSize: '20px',
    fontWeight: 500,
    paddingBottom: '10px',
  },
  centerLoader: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '193px',
  },
}))

export { useStyles }
