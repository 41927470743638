import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  tableHeader: {
    fontSize: 18,
    fontWeight: 700,
    backgroundColor: 'white',
  },
  tableBody: {
    height: 'max-content',
  },
  billingTableRow: {
    fontSize: '15px',
    wordBreak: 'break-all',
  },
})
export { useStyles }
