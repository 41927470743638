import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    padding: '4%',
    marginTop: '10%',
    flexGrow: 1,
  },
  questionTitle: {
    paddingLeft: '2%',
  },
  choice: {
    maxWidth: '2000px',
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  options: {
    color: 'grey',
  },
  questionDisplay: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  questMarks: {
    backgroundColor: '',
    borderRadius: '5px',
  },
  questionField: {
    backgroundColor: 'white',
  },
  questionContainer: {
    backgroundColor: '#F0F0FD',
    borderRadius: '5px',
  },
}))
export { useStyles }
