import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useAuth } from '@zopsmart/zs-components'
import Login from './components/Login/Login'
import Header from './components/Header/Header'
import Quiz from './components/Dashboard/Dashboard'
import Results from './components/DisplayResults/DisplayResults'
import Question from './components/Question/Question'
import LiveCandidates from './components/LiveCandidates/LiveCandidates'
import CandidateStream from './components/LiveCandidates/CandidateStream'
import PageNotFound from './components/PageNotFound/PageNotFound'
import ResultSheet from './components/ResultSheet/ResultSheet'
import EditQuizDraft from './components/EditQuizPage/EditQuizMain'
import PrivateRoute from './PrivateRoute'
import PaymentResponse from './components/PaymentResponse/PaymentResponse'
import Payment from './components/Payment/Payment'
import { LOGIN_ENDPOINT, REFRESH_ENDPOINT, MOCK_BASE_URL } from './constant'

export default function Router() {
  const [reload, setReload] = useState(0)

  useAuth('tokenStorage', MOCK_BASE_URL, LOGIN_ENDPOINT, REFRESH_ENDPOINT, [])
  return (
    <>
      <BrowserRouter>
        <Header reload={reload} setReload={setReload} />
        <Switch>
          <PrivateRoute path={['/', '/all-quiz']} component={Quiz} exact>
            <Quiz reload={reload} />
          </PrivateRoute>

          <PrivateRoute path="/edit/:id" component={EditQuizDraft} />
          <PrivateRoute
            path="/live-candidates/:id"
            component={LiveCandidates}
            exact
          />
          <PrivateRoute
            path="/live-candidates/:id/:user"
            component={CandidateStream}
            exact
          />
          <PrivateRoute path="/quiz/:id/responses" component={Results} exact />
          <PrivateRoute
            path="/quiz/:id/response/:candidateId"
            component={ResultSheet}
            exact
          />
          <PrivateRoute path="/question" component={Question} exact />
          <PrivateRoute path="/payment" component={Payment} exact />
          <PrivateRoute
            path="/payment-response"
            component={PaymentResponse}
            exact
          />
          <Route path="/login">
            <Login setReload={setReload} reload={reload} />
          </Route>
          <Route path="/signin">
            <Login setReload={setReload} reload={reload} />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
          <Route component={Quiz} />
        </Switch>
      </BrowserRouter>
    </>
  )
}
