/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Button from '@material-ui/core/Button'
import {
  Avatar,
  Typography,
  MenuItem,
  ClickAwayListener,
  Grid,
} from '@material-ui/core'
import Menu from '@material-ui/core/MenuList'
import Tooltip from '@material-ui/core/Tooltip'
import { useLogoutPostQuery } from '../../Common/Queries/LoginQuery'
import Logo from '../Logo/Logo'
import { isLogin } from '../../utils'
import Credit from '../../icons/Credit.svg'
import { BalanceContext } from '../../stateManagement/Contexts'
import { useStyles } from './HeaderStyles'
import OrgModal from './OrgModal'

export default function Header(props) {
  const [modalState, setOrgModal] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [auth, setAuth] = useState(localStorage.getItem('isLoggedIn'))
  const userName = localStorage?.getItem('userName')
  const anchorRef = React.useRef(null)
  const location = useLocation()
  const { balanceRefetch, balance } = useContext(BalanceContext)
  const mutation = useLogoutPostQuery()
  useEffect(() => {}, [localStorage?.getItem('isLoggedIn')])
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('tokenStorage'))
    if (token?.accessToken) {
      setAuth(() => true)
    }
  }, [location])

  useEffect(() => {
    if (auth && isLogin()) {
      balanceRefetch()
    }
  }, [auth, location])
  const handleOpenOrgModal = () => {
    setOrgModal(true)
  }
  const handleCloseOrgModal = () => {
    setOrgModal(false)
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen?.current === true && open === false) {
      anchorRef?.current?.focus()
    }
    prevOpen.current = open
  }, [open])
  useEffect(() => {
    if (mutation.isSuccess) {
      localStorage.clear()
      history.push('/login')
    }
  }, [mutation.isSuccess])

  function handleLogout() {
    mutation.mutate()
    localStorage.clear()
    setOpen(false)
    props.setReload(props.reload + 1)
    history.push('/login')
  }
  function handlePaymentClick() {
    setOpen(false)
    history.push('/payment')
  }
  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root} data-testid="header-testId">
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() =>
              isLogin() ? history.push('/all-quiz') : history.push('/login')
            }
          >
            <Logo />
          </IconButton>
          <Typography className={classes.title} />
          <OrgModal
            modalState={modalState}
            handleCloseOrgModal={handleCloseOrgModal}
          />
          {auth && (
            <div>
              <Grid className={classes.headerWrapper}>
                <Tooltip
                  arrow
                  title="Credits"
                  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  style={{ display: 'flex' }}
                >
                  <Grid>
                    <Grid className={classes.styleCredit}>
                      <img src={Credit} className={classes.creditIcon} alt="" />
                    </Grid>
                    <Typography className={classes.credits}>
                      {balance}
                    </Typography>
                  </Grid>
                </Tooltip>

                <IconButton
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  color="inherit"
                >
                  <Avatar
                    data-testid="avatar-testId"
                    className={classes.avatar}
                  >
                    {userName}
                  </Avatar>
                </IconButton>
              </Grid>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Menu className={classes.payment}>
                        <div className={classes.MenuItem}>
                          <MenuItem>
                            <Button
                              onClick={handlePaymentClick}
                              className={classes.paymentButton}
                              data-testid="payment-testId"
                            >
                              Payment
                            </Button>
                          </MenuItem>
                          <MenuItem>
                            <Button
                              onClick={handleOpenOrgModal}
                              className={classes.paymentButton}
                            >
                              Organisation
                            </Button>
                          </MenuItem>
                          <MenuItem>
                            <Button
                              onClick={handleLogout}
                              className={classes.logoutBotton}
                              data-testid="logout-button-testId"
                            >
                              Logout
                            </Button>
                          </MenuItem>
                        </div>
                      </Menu>
                    </ClickAwayListener>
                  </Grow>
                )}
              </Popper>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}
