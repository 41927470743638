import React, { useEffect, useState, useRef, createRef } from 'react'
import { Hidden, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Papa from 'papaparse'
import { useQueryClient } from 'react-query'
import { CSVLink } from 'react-csv'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'
import EditQuestionContent from '../EditMcqQuestion2/EditMcqQuestion2'
import AddCodingQuestion from '../EditCodingQuestion2/EditCodingQuestion2'
import AddTrueAndFalseQuestion from '../AddTrueFalseQuestion/AddTrueFalseQuestion'
import AddEssayQuestion from '../EssayQuestion/AddEssayQuestion'
import Loader from '../Loader/Loader'

import {
  useQuestionPostQuery,
  useQuestionDeleteQueryById,
  useQuestionMappingPostQueryById,
  useQuestionMappingDeleteQueryById,
} from '../../Common/Queries/QuestionQuery'
import { isQuizStarted } from '../../utils'
import { useStyle } from './EditQuestionsStyles'
import QuestionsDrawer from './QuestionsDrawer'
import AddFillInTheBlank from '../AddFillInTheBlanks/AddFillInTheBlank'

const headers = [
  { label: 'Question', key: 'question' },
  { label: 'Choice A', key: 'choiceA' },
  { label: 'Choice B', key: 'choiceB' },
  { label: 'Choice C', key: 'choiceC' },
  { label: 'Choice D', key: 'choiceD' },
  { label: 'Correct Choice', key: 'CorrectChoice' },
  { label: 'Marks', key: 'Marks' },
  { label: 'Type', key: 'Type' },
]

const dataCsv = [
  {
    question: 'What is full form of sql?',
    choiceA: 'Structured Query List',
    choiceB: 'Structure Query Language',
    choiceC: 'Sample Query Language',
    choiceD: 'None of these.',
    CorrectChoice: 'B',
    Marks: 2,
    Type: 'MCQ',
  },
  {
    question: 'Select prime numbers?',
    choiceA: '13',
    choiceB: '53',
    choiceC: '50 ',
    choiceD: '10',
    CorrectChoice: 'A,B',
    Marks: 2,
    Type: 'multi-choice',
  },
]

const EditQuestions = (props) => {
  const {
    setTextEditorQuestion,
    textEditorQuestion,
    setLocalQuestion,
    localQuestion,
    setAddQuestion,
    addQuestion,
    setEditViewQuestion,
    editViewQuestion,
    editQuestion,
    setEditQuestion,
    editOngoing,
    setEditOngoing,
  } = props
  const classes = useStyle({
    publish: props?.isPublished,
    isClosed: props?.isClosed,
  })
  const { setCodingQuestionSwitch } = props
  const queryClient = useQueryClient()
  const [questions, setQuestions] = useState([])
  const questionsRef = useRef([])
  const addQuestionRef = useRef(null)
  const fileInput = useRef(null)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [quizId, setQuizId] = useState('')
  const [, setCancelAddQuestion] = useState(false)
  const [questionType, setQuestionType] = useState()
  const [reload, setReload] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [save, setSave] = useState(false)
  const [loader, setLoader] = useState(false)
  const choiceCheck = /^Choice\s[A-Z]$/
  const correctChoiceCheck = /^Correct Choice$/
  const [errorMessage, setErrorMessage] = useState('')
  const [truthValue, setTruthValue] = useState(false)
  const [errorSaveQuestionSnackbar, setErrorSaveQuestionSnackbar] =
    useState(false)
  const [saveQuestionSnackbar, setSaveQuestionSnackbar] = useState(false)
  const [errors, setErrors] = useState({
    emptyWeightage: false,
    weightageNotValid: false,
    weightageLessThanZero: false,
    noSelectedLanguage: false,
    emptyQuestion: false,
    emptyInputTypes: false,
    inavlidInputTypes: false,
    emptyLanguage: false,
    emptyFunction: false,
    invalidFunctionName: false,
    emptyReturnType: false,
    invalidInputArgLength: false,
    invalidInputType: false,
    xyz: false,
  })

  const [invalidTestCaseInput, setInvalidTestCaseInput] = useState([])
  const [invalidTestCaseArguments, setInvalidTestCaseArguments] = useState([])

  const [csvError, setCsvError] = useState({
    emptyQuestionType: false,
    EmptyMarks: false,
    EmptyCorrectChoice: false,
    EmptyChoice: false,
    EmptyQuestion: false,
    columnHeadingError: false,
  })
  const [deleteQuestionId, setDeleteQuestionId] = useState()

  const postQuestionMapping = useQuestionMappingPostQueryById(quizId, {
    staleTime: 0,
    retry: false,
    onSuccess: async () => {
      await props?.refetchGetQueryById()
      await props?.refetchGetQuizQuestionQueryById()
      setTruthValue(true)
      setErrorMessage('Question Saved Successfully!')
      setAddQuestion(false)
      setReload(reload + 1)
      setSave(false)
      await props?.refetchGetQueryById()
    },

    onError: (err) => {
      setErrorMessage('Failed')
      setSave(false)
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const deleteQuestion = useQuestionDeleteQueryById({
    staleTime: 0,
    onSuccess: () => {
      setErrorMessage('Question deleted successfully')
      setTruthValue(true)
      queryClient.invalidateQueries('quizGetQuizQuestionQueryById')
      setReload(reload + 1)
      setCodingQuestionSwitch(false)
    },
    onError: (err) => {
      setErrorMessage('Question deletion failed, Please try again')
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })
  const postQuestion = useQuestionPostQuery({
    staleTime: 0,
    retry: false,
    onSuccess: (successResponse) => {
      const questionData = JSON.parse(successResponse?.config?.data)
      postQuestionMapping.mutate({
        questionId: successResponse?.data?.data?.question?.id,
        type: questionData?.type,
      })
      setErrorMessage('Successful !')
      setTruthValue(true)
      setAddQuestion(false)
      setReload(reload + 1)
      queryClient.invalidateQueries('quizGetQueryById')
      setSave(false)
    },

    onError: () => {
      setErrorMessage('Failed')
      setSave(false)
    },
  })
  const deleteMappingQuestion = useQuestionMappingDeleteQueryById({
    onSuccess: () => queryClient.invalidateQueries('quizGetQueryById'),
    onError: (err) => {
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  useEffect(() => {
    setQuestions(props.questions)
    setQuizId(props.quizId)
    if (props.questions?.length === 0) {
      setAddQuestion(true)
    }
    if (props?.questions?.length > 0) {
      setLoader(false)
    }
  }, [props])
  // console.log('## questions', questions)
  useEffect(() => {
    if (Object.keys(editViewQuestion || {})?.length > 0) {
      setQuestionType(editViewQuestion?.type)
    } else {
      setQuestionType('mcq')
    }
  }, [])

  const executeScroll = (event, index) => {
    event.preventDefault()
    setCurrentQuestion(index)
    window.scrollTo({
      top: questionsRef?.current[index]?.current?.offsetTop - 189,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleQuestionTypeChange = (event) => {
    setQuestionType(event?.target?.value)
  }
  const handleAddQuestion = () => {
    setEditViewQuestion({})
    setEditOngoing(true)
    setQuestionType('mcq')
    if (addQuestion && questions?.length === 0) {
      setErrorMessage('Save the previous question!')
    }
    setAddQuestion(true)
  }

  const scrollToAddQuestion = (event) => {
    event.preventDefault()
    window.scrollTo({
      top: addQuestionRef?.current?.offsetTop - 189,
      left: 0,
      behavior: 'smooth',
    })
    handleAddQuestion(event)
  }

  const handleClick = () => {
    deleteQuestion.mutate()
    setOpenDialog(false)
  }
  const handleClose = () => {
    setAddQuestion(false)
    setOpenDialog(false)
  }

  const RemoveId = (data) => {
    return data.map((item) => {
      const { id, ...newItem } = item
      return newItem
    })
  }
  let error = false
  const parsing = (element) => {
    let correctChoiceValue
    const tempArray = []
    let multiChoice = []
    const temp = {
      id: '',
      value: '',
      isCorrect: false,
    }
    const replica = {
      type: '',
      question: ' ',
      choices: [],
      isRandomize: false,
      marks: 2,
    }
    if (!error) {
      Object.keys(element || {})
        .reverse()
        .forEach((data, index) => {
          if (correctChoiceCheck.test(data)) {
            if (!element[data]) {
              error = true
              // return setErrorMessage('Please check Correct Choice column')
              return setCsvError({ ...csvError, EmptyCorrectChoice: true })
            }
            if (replica?.type === 'multi-choice') {
              multiChoice = element[data]?.split(',')
            } else {
              correctChoiceValue = element[data]
            }
          }
          if (choiceCheck.test(data)) {
            if (!element[data]) {
              error = true
              // return setErrorMessage('Please check Choice column')
              return setCsvError({ ...csvError, EmptyChoice: true })
            }
            if (
              data.split(' ')[1] === correctChoiceValue ||
              (replica?.type === 'multi-choice' &&
                multiChoice.includes(data.split(' ')[1]))
            ) {
              temp.id = correctChoiceValue
              temp.value = element[data]
              temp.isCorrect = true
              tempArray.push(JSON.parse(JSON.stringify(temp)))
            } else {
              const id = data.split(' ')[1]
              temp.id = id
              temp.value = element[data]
              temp.isCorrect = false
              tempArray.push(JSON.parse(JSON.stringify(temp)))
            }
          }
          if (data === 'Marks') {
            if (!element[data]) {
              error = true
              // return setErrorMessage('Please check Marks column')
              return setCsvError({ ...csvError, EmptyMarks: true })
            }
            replica.marks = element[data] * 1
          }
          if (data === 'Question') {
            if (!element[data]) {
              error = true
              // return setErrorMessage('Please check Question column')
              return setCsvError({ ...csvError, EmptyQuestion: true })
            }
            replica.question = `<p>${element[data]}</p>`
          }
          if (data === 'Type') {
            if (!element[data]) {
              error = true
              // return setErrorMessage('Please check Question Type')
              return setCsvError({ ...csvError, emptyQuestionType: true })
            }
            replica.type = element[data].toLowerCase()
          }
          if (
            index === Object.keys(element || {}).length - 1 &&
            data !== 'Question'
          ) {
            error = true
            // return setErrorMessage(
            //   ' Failed! Please provide correct Question Column'
            // )
            return setCsvError({ ...csvError, columnHeadingError: true })
          }
          return null
        })
      replica.choices = RemoveId(tempArray.reverse())
      if (replica.choices.length < 1) {
        error = true
        // setErrorMessage('Please check Choice column')
        setCsvError({ ...csvError, EmptyChoice: true })
      }
      if (!replica.marks) {
        error = true
        // setErrorMessage('Please check Marks column')
        setCsvError({ ...csvError, EmptyMarks: true })
      }
      if (!replica.type) {
        error = true
        // setErrorMessage('Please check Question Type')
        setCsvError({ ...csvError, emptyQuestionType: true })
      }

      // need to remove this question for the post question call for the csv
      if (
        !csvError?.EmptyChoice &&
        !csvError?.EmptyCorrectChoice &&
        !csvError?.EmptyMarks &&
        !csvError?.EmptyQuestion &&
        !csvError?.emptyQuestionType &&
        !csvError?.columnHeadingError &&
        !error
      )
        // if (!error && errorMessage !== '') {
        replica.quizID = quizId
      postQuestion.mutate(replica)
      // }
    }
  }
  const csvFileUpload = (event) => {
    setLoader(true)
    Papa.parse(event.target.files[0], {
      header: true,
      worker: true,
      step: (results) => parsing(results.data),
      complete: async () => {
        await props?.refetchGetQueryById()
        await props?.refetchGetQuizQuestionQueryById()
      },
    })
    fileInput.current = null
    setAddQuestion(false)
  }

  if (questionsRef?.current?.length !== questions?.length) {
    // add or remove refs
    questionsRef.current = Array(questions?.length)
      .fill()
      .map((_, i) => questionsRef?.current[i] || createRef())
  }

  const handleDrawerAdd = (e) => {
    scrollToAddQuestion(e)
    setEditQuestion(false)
    if (!addQuestion) {
      setLocalQuestion({})
      setEditViewQuestion({})
    }
  }

  return (
    <>
      <Grid container xs={12}>
        <Hidden only={['xs', 'sm']}>
          <Grid item xs={3} style={{ paddingTop: '0px' }}>
            <QuestionsDrawer
              scrollToAddQuestion={scrollToAddQuestion}
              isClosed={props?.isClosed}
              handleDrawerAdd={handleDrawerAdd}
              currentQuestion={currentQuestion}
              questions={questions}
              executeScroll={executeScroll}
              questionRef={questionsRef}
              quizStartTime={
                props.quizStartTime === undefined ? '' : props.quizStartTime
              }
              isPublished={
                props.isPublished === undefined ? false : props.isPublished
              }
              isWriteAllowed={props?.isWriteAllowed}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={9}>
          <div className={classes.root}>
            {loader ||
            postQuestionMapping?.isLoading ||
            postQuestion?.isLoading ||
            deleteQuestion?.isLoading ||
            deleteMappingQuestion?.isLoading ||
            props?.isQuiQuestionLoading ||
            props?.isQuizQuestionFetching ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '8rem',
                }}
              >
                <Loader size="7rem" />
              </div>
            ) : (
              <>
                {questions?.map((question, index) => {
                  return (
                    <div
                      ref={questionsRef.current[index]}
                      key={question?.id}
                      className={index}
                      style={{ paddingBottom: '24px' }}
                      // className={classes.question}
                    >
                      <Container>
                        <Grid container className={classes.questionHeader}>
                          {(question?.type === 'mcq' ||
                            question?.type === 'multi-choice') && (
                            <Grid item xs={12}>
                              <EditQuestionContent
                                setAddQuestion={setAddQuestion}
                                questions={questions}
                                question={question}
                                questionIndex={index}
                                quizId={quizId}
                                reload={reload}
                                setReload={setReload}
                                quizStartTime={
                                  props.quizStartTime === undefined
                                    ? ''
                                    : props.quizStartTime
                                }
                                isPublished={
                                  props.isPublished === undefined
                                    ? false
                                    : props.isPublished
                                }
                                isClosed={props?.isClosed}
                                setErrorMessages={setErrorMessage}
                                setTruthValue={setTruthValue}
                                refetchGetQueryById={props?.refetchGetQueryById}
                                refetchGetQuizQuestionQueryById={
                                  props?.refetchGetQuizQuestionQueryById
                                }
                                postQuestionMapping={postQuestionMapping}
                                deleteMappingQuestion={deleteMappingQuestion}
                                deleteQuestion={deleteQuestion}
                                isWriteAllowed={props?.isWriteAllowed}
                                setLocalQuestion={setLocalQuestion}
                                localQuestion={localQuestion}
                                setTextEditorQuestion={setTextEditorQuestion}
                                textEditorQuestion={textEditorQuestion}
                                addQuestion={false}
                                setEditViewQuestion={setEditViewQuestion}
                                setSave={setSave}
                                editQuestion={editQuestion}
                                setEditQuestion={setEditQuestion}
                                deleteQuestionId={deleteQuestionId}
                                setDeleteQuestionId={setDeleteQuestionId}
                                editOngoing={editOngoing}
                                setEditOngoing={setEditOngoing}
                                setCancelAddQuestion={setCancelAddQuestion}
                              />
                            </Grid>
                          )}
                          {question?.type === 'coding' && (
                            <Grid item xs={12}>
                              <AddCodingQuestion
                                setQuestionType={setQuestionType}
                                question={question}
                                questionIndex={index}
                                setAddQuestion={setAddQuestion}
                                quizId={quizId}
                                reload={reload}
                                setErrorMessage={setErrorMessage}
                                setCancelAddQuestion={setCancelAddQuestion}
                                setReload={setReload}
                                quizStartTime={
                                  props.quizStartTime === undefined
                                    ? false
                                    : props.quizStartTime
                                }
                                isPublished={
                                  props.isPublished === undefined
                                    ? false
                                    : props.isPublished
                                }
                                isClosed={props?.isClosed}
                                setTruthValue={setTruthValue}
                                errors={errors}
                                setErrors={setErrors}
                                setInvalidTestCaseInput={
                                  setInvalidTestCaseInput
                                }
                                invalidTestCaseInput={invalidTestCaseInput}
                                invalidTestCaseArguments={
                                  invalidTestCaseArguments
                                }
                                setInvalidTestCaseArguments={
                                  setInvalidTestCaseArguments
                                }
                                errorSaveQuestionSnackbar={
                                  errorSaveQuestionSnackbar
                                }
                                refetchGetQueryById={props?.refetchGetQueryById}
                                refetchGetQuizQuestionQueryById={
                                  props?.refetchGetQuizQuestionQueryById
                                }
                                postQuestionMapping={postQuestionMapping}
                                deleteMappingQuestion={deleteMappingQuestion}
                                setCodingQuestionSwitch={
                                  setCodingQuestionSwitch
                                }
                                deleteQuestion={deleteQuestion}
                                isWriteAllowed={props?.isWriteAllowed}
                                setLocalQuestion={setLocalQuestion}
                                localQuestion={localQuestion}
                                setTextEditorQuestion={setTextEditorQuestion}
                                textEditorQuestion={textEditorQuestion}
                                addQuestion={false}
                                setEditViewQuestion={setEditViewQuestion}
                                setSave={setSave}
                                deleteQuestionId={deleteQuestionId}
                                setDeleteQuestionId={setDeleteQuestionId}
                                editOngoing={editOngoing}
                                setEditOngoing={setEditOngoing}
                                editQuestion={editQuestion}
                                setEditQuestion={setEditQuestion}
                              />
                            </Grid>
                          )}
                          {question?.type === 't/f' && (
                            <Grid item xs={12}>
                              <AddTrueAndFalseQuestion
                                questions={questions}
                                question={question}
                                quizId={quizId}
                                questionIndex={index}
                                setAddQuestion={setAddQuestion}
                                setCancelAddQuestion={setCancelAddQuestion}
                                reload={reload}
                                setReload={setReload}
                                quizStartTime={
                                  props.quizStartTime === undefined
                                    ? false
                                    : props.quizStartTime
                                }
                                isPublished={
                                  props.isPublished === undefined
                                    ? false
                                    : props.isPublished
                                }
                                isClosed={props?.isClosed}
                                setErrorMessage={setErrorMessage}
                                setTruthValue={setTruthValue}
                                postQuestionMapping={postQuestionMapping}
                                deleteMappingQuestion={deleteMappingQuestion}
                                refetchGetQueryById={props?.refetchGetQueryById}
                                refetchGetQuizQuestionQueryById={
                                  props?.refetchGetQuizQuestionQueryById
                                }
                                deleteQuestion={deleteQuestion}
                                isWriteAllowed={props?.isWriteAllowed}
                                setLocalQuestion={setLocalQuestion}
                                localQuestion={localQuestion}
                                setTextEditorQuestion={setTextEditorQuestion}
                                textEditorQuestion={textEditorQuestion}
                                addQuestion={false}
                                setEditViewQuestion={setEditViewQuestion}
                                setSave={setSave}
                                deleteQuestionId={deleteQuestionId}
                                setDeleteQuestionId={setDeleteQuestionId}
                                editOngoing={editOngoing}
                                setEditOngoing={setEditOngoing}
                                editQuestion={editQuestion}
                                setEditQuestion={setEditQuestion}
                              />
                            </Grid>
                          )}
                          {question?.type === 'essay' && (
                            <Grid item xs={12}>
                              <AddEssayQuestion
                                question={question}
                                quizId={quizId}
                                questionIndex={index}
                                setAddQuestion={setAddQuestion}
                                setCancelAddQuestion={setCancelAddQuestion}
                                reload={reload}
                                setReload={setReload}
                                quizStartTime={
                                  props.quizStartTime === undefined
                                    ? false
                                    : props.quizStartTime
                                }
                                isPublished={
                                  props.isPublished === undefined
                                    ? false
                                    : props.isPublished
                                }
                                isClosed={props?.isClosed}
                                setErrorMessage={setErrorMessage}
                                setTruthValue={setTruthValue}
                                postQuestionMapping={postQuestionMapping}
                                deleteMappingQuestion={deleteMappingQuestion}
                                refetchGetQueryById={props?.refetchGetQueryById}
                                refetchGetQuizQuestionQueryById={
                                  props?.refetchGetQuizQuestionQueryById
                                }
                                deleteQuestion={deleteQuestion}
                                isWriteAllowed={props?.isWriteAllowed}
                                setLocalQuestion={setLocalQuestion}
                                localQuestion={localQuestion}
                                setTextEditorQuestion={setTextEditorQuestion}
                                textEditorQuestion={textEditorQuestion}
                                addQuestion={false}
                                setEditViewQuestion={setEditViewQuestion}
                                setSave={setSave}
                                deleteQuestionId={deleteQuestionId}
                                setDeleteQuestionId={setDeleteQuestionId}
                                editOngoing={editOngoing}
                                setEditOngoing={setEditOngoing}
                                editQuestion={editQuestion}
                                setEditQuestion={setEditQuestion}
                              />
                            </Grid>
                          )}
                          {question?.type === 'fillInBlanks' && (
                            <Grid item xs={12}>
                              <AddFillInTheBlank
                                question={question}
                                quizId={quizId}
                                questionIndex={index}
                                setAddQuestion={setAddQuestion}
                                setCancelAddQuestion={setCancelAddQuestion}
                                reload={reload}
                                setReload={setReload}
                                quizStartTime={
                                  props.quizStartTime === undefined
                                    ? false
                                    : props.quizStartTime
                                }
                                isPublished={
                                  props.isPublished === undefined
                                    ? false
                                    : props.isPublished
                                }
                                isClosed={props?.isClosed}
                                setErrorSaveQuestionSnackbar={
                                  setErrorSaveQuestionSnackbar
                                }
                                setSaveQuestionSnackbar={
                                  setSaveQuestionSnackbar
                                }
                                saveQuestionSnackbar={saveQuestionSnackbar}
                                postQuestionMapping={postQuestionMapping}
                                deleteMappingQuestion={deleteMappingQuestion}
                                refetchGetQueryById={props?.refetchGetQueryById}
                                refetchGetQuizQuestionQueryById={
                                  props?.refetchGetQuizQuestionQueryById
                                }
                                deleteQuestion={deleteQuestion}
                                isWriteAllowed={props?.isWriteAllowed}
                                setErrorMessage={setErrorMessage}
                                setLocalQuestion={setLocalQuestion}
                                localQuestion={localQuestion}
                                setTextEditorQuestion={setTextEditorQuestion}
                                textEditorQuestion={textEditorQuestion}
                                addQuestion={false}
                                setEditViewQuestion={setEditViewQuestion}
                                setTruthValue={setTruthValue}
                                deleteQuestionId={deleteQuestionId}
                                setDeleteQuestionId={setDeleteQuestionId}
                                editOngoing={editOngoing}
                                setEditOngoing={setEditOngoing}
                                editQuestion={editQuestion}
                                setEditQuestion={setEditQuestion}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Container>
                    </div>
                  )
                })}
                <div
                  ref={addQuestionRef}
                  className={classes.NewQuestionPlaceholder}
                />
                <div className={classes.question}>
                  {addQuestion && props?.isWriteAllowed ? (
                    <Container>
                      <Grid container>
                        {(questionType === 'mcq' ||
                          questionType === 'multi-choice') && (
                          <Grid className={classes.questionTab} item xs={12}>
                            <EditQuestionContent
                              setQuestionType={setQuestionType}
                              setEditViewQuestion={setEditViewQuestion}
                              editViewQuestion={editViewQuestion}
                              addQuestion={addQuestion}
                              questionTypeFromParent={questionType}
                              quizType={props?.quizType}
                              codingQuestionSwitch={props?.codingQuestionSwitch}
                              handleQuestionTypeChange={
                                handleQuestionTypeChange
                              }
                              setTextEditorQuestion={setTextEditorQuestion}
                              textEditorQuestion={textEditorQuestion}
                              questions={questions}
                              quizId={quizId}
                              save={save}
                              setSave={setSave}
                              setAddQuestion={setAddQuestion}
                              setCancelAddQuestion={setCancelAddQuestion}
                              reload={reload}
                              setReload={setReload}
                              quizStartTime={
                                props.quizStartTime === undefined
                                  ? ''
                                  : props.quizStartTime
                              }
                              isPublished={
                                props.isPublished === undefined
                                  ? false
                                  : props.isPublished
                              }
                              setErrorMessages={setErrorMessage}
                              setTruthValue={setTruthValue}
                              postQuestionMapping={postQuestionMapping}
                              deleteMappingQuestion={deleteMappingQuestion}
                              refetchGetQueryById={props?.refetchGetQueryById}
                              refetchGetQuizQuestionQueryById={
                                props?.refetchGetQuizQuestionQueryById
                              }
                              deleteQuestion={deleteQuestion}
                              isWriteAllowed={props?.isWriteAllowed}
                              setLocalQuestion={setLocalQuestion}
                              localQuestion={localQuestion}
                              deleteQuestionId={deleteQuestionId}
                              setDeleteQuestionId={setDeleteQuestionId}
                              editOngoing={editOngoing}
                              setEditOngoing={setEditOngoing}
                              editQuestion={editQuestion}
                              setEditQuestion={setEditQuestion}
                            />
                          </Grid>
                        )}
                        {questionType === 'coding' && (
                          <Grid className={classes.questionTab} item xs={12}>
                            <AddCodingQuestion
                              setQuestionType={setQuestionType}
                              quizId={quizId}
                              save={save}
                              setSave={setSave}
                              setAddQuestion={setAddQuestion}
                              reload={reload}
                              setReload={setReload}
                              setCancelAddQuestion={setCancelAddQuestion}
                              quizStartTime={
                                props.quizStartTime === undefined
                                  ? ''
                                  : props.quizStartTime
                              }
                              isPublished={
                                props.isPublished === undefined
                                  ? false
                                  : props.isPublished
                              }
                              errors={errors}
                              setErrors={setErrors}
                              setTruthValue={setTruthValue}
                              setErrorMessage={setErrorMessage}
                              questionTypeFromParent={questionType}
                              handleQuestionTypeChange={
                                handleQuestionTypeChange
                              }
                              questions={questions}
                              postQuestionMapping={postQuestionMapping}
                              deleteMappingQuestion={deleteMappingQuestion}
                              refetchGetQueryById={props?.refetchGetQueryById}
                              refetchGetQuizQuestionQueryById={
                                props?.refetchGetQuizQuestionQueryById
                              }
                              invalidTestCaseInput={invalidTestCaseInput}
                              setInvalidTestCaseInput={setInvalidTestCaseInput}
                              invalidTestCaseArguments={
                                invalidTestCaseArguments
                              }
                              setInvalidTestCaseArguments={
                                setInvalidTestCaseArguments
                              }
                              deleteQuestion={deleteQuestion}
                              isWriteAllowed={props?.isWriteAllowed}
                              setEditViewQuestion={setEditViewQuestion}
                              editViewQuestion={editViewQuestion}
                              addQuestion={addQuestion}
                              setTextEditorQuestion={setTextEditorQuestion}
                              textEditorQuestion={textEditorQuestion}
                              setLocalQuestion={setLocalQuestion}
                              localQuestion={localQuestion}
                              deleteQuestionId={deleteQuestionId}
                              setDeleteQuestionId={setDeleteQuestionId}
                              editOngoing={editOngoing}
                              setEditOngoing={setEditOngoing}
                              editQuestion={editQuestion}
                              setEditQuestion={setEditQuestion}
                            />
                          </Grid>
                        )}
                        {questionType === 't/f' && (
                          <Grid className={classes.questionTab} item xs={12}>
                            <AddTrueAndFalseQuestion
                              setEditViewQuestion={setEditViewQuestion}
                              editViewQuestion={editViewQuestion}
                              addQuestion={addQuestion}
                              setTextEditorQuestion={setTextEditorQuestion}
                              textEditorQuestion={textEditorQuestion}
                              setLocalQuestion={setLocalQuestion}
                              localQuestion={localQuestion}
                              questionTypeFromParent={questionType}
                              quizType={props?.quizType}
                              codingQuestionSwitch={props?.codingQuestionSwitch}
                              handleQuestionTypeChange={
                                handleQuestionTypeChange
                              }
                              questions={questions}
                              quizId={quizId}
                              save={save}
                              setSave={setSave}
                              setAddQuestion={setAddQuestion}
                              setCancelAddQuestion={setCancelAddQuestion}
                              reload={reload}
                              setReload={setReload}
                              quizStartTime={
                                props.quizStartTime === undefined
                                  ? ''
                                  : props.quizStartTime
                              }
                              isPublished={
                                props.isPublished === undefined
                                  ? false
                                  : props.isPublished
                              }
                              setErrorMessage={setErrorMessage}
                              setTruthValue={setTruthValue}
                              postQuestionMapping={postQuestionMapping}
                              deleteMappingQuestion={deleteMappingQuestion}
                              refetchGetQueryById={props?.refetchGetQueryById}
                              refetchGetQuizQuestionQueryById={
                                props?.refetchGetQuizQuestionQueryById
                              }
                              deleteQuestion={deleteQuestion}
                              isWriteAllowed={props?.isWriteAllowed}
                              deleteQuestionId={deleteQuestionId}
                              setDeleteQuestionId={setDeleteQuestionId}
                              editOngoing={editOngoing}
                              setEditOngoing={setEditOngoing}
                              editQuestion={editQuestion}
                              setEditQuestion={setEditQuestion}
                            />
                          </Grid>
                        )}
                        {questionType === 'essay' && (
                          <Grid className={classes.questionTab} item xs={12}>
                            <AddEssayQuestion
                              setEditViewQuestion={setEditViewQuestion}
                              editViewQuestion={editViewQuestion}
                              addQuestion={addQuestion}
                              setTextEditorQuestion={setTextEditorQuestion}
                              textEditorQuestion={textEditorQuestion}
                              setLocalQuestion={setLocalQuestion}
                              localQuestion={localQuestion}
                              questionTypeFromParent={questionType}
                              quizType={props?.quizType}
                              codingQuestionSwitch={props?.codingQuestionSwitch}
                              handleQuestionTypeChange={
                                handleQuestionTypeChange
                              }
                              questions={questions}
                              quizId={quizId}
                              save={save}
                              setSave={setSave}
                              setAddQuestion={setAddQuestion}
                              setCancelAddQuestion={setCancelAddQuestion}
                              reload={reload}
                              setReload={setReload}
                              quizStartTime={
                                props.quizStartTime === undefined
                                  ? ''
                                  : props.quizStartTime
                              }
                              isPublished={
                                props.isPublished === undefined
                                  ? false
                                  : props.isPublished
                              }
                              setErrorMessage={setErrorMessage}
                              setTruthValue={setTruthValue}
                              postQuestionMapping={postQuestionMapping}
                              deleteMappingQuestion={deleteMappingQuestion}
                              refetchGetQueryById={props?.refetchGetQueryById}
                              refetchGetQuizQuestionQueryById={
                                props?.refetchGetQuizQuestionQueryById
                              }
                              deleteQuestion={deleteQuestion}
                              isWriteAllowed={props?.isWriteAllowed}
                              deleteQuestionId={deleteQuestionId}
                              setDeleteQuestionId={setDeleteQuestionId}
                              editOngoing={editOngoing}
                              setEditOngoing={setEditOngoing}
                              editQuestion={editQuestion}
                              setEditQuestion={setEditQuestion}
                            />
                          </Grid>
                        )}
                        {questionType === 'fillInBlanks' && (
                          <Grid className={classes.questionTab} item xs={12}>
                            <AddFillInTheBlank
                              setEditViewQuestion={setEditViewQuestion}
                              editViewQuestion={editViewQuestion}
                              addQuestion={addQuestion}
                              setTextEditorQuestion={setTextEditorQuestion}
                              textEditorQuestion={textEditorQuestion}
                              setLocalQuestion={setLocalQuestion}
                              localQuestion={localQuestion}
                              questionTypeFromParent={questionType}
                              quizType={props?.quizType}
                              codingQuestionSwitch={props?.codingQuestionSwitch}
                              handleQuestionTypeChange={
                                handleQuestionTypeChange
                              }
                              questions={questions}
                              quizId={quizId}
                              save={save}
                              setSave={setSave}
                              setAddQuestion={setAddQuestion}
                              setCancelAddQuestion={setCancelAddQuestion}
                              reload={reload}
                              setReload={setReload}
                              quizStartTime={
                                props.quizStartTime === undefined
                                  ? ''
                                  : props.quizStartTime
                              }
                              isPublished={
                                props.isPublished === undefined
                                  ? false
                                  : props.isPublished
                              }
                              setErrorSaveQuestionSnackbar={
                                setErrorSaveQuestionSnackbar
                              }
                              setSaveQuestionSnackbar={setSaveQuestionSnackbar}
                              postQuestionMapping={postQuestionMapping}
                              deleteMappingQuestion={deleteMappingQuestion}
                              refetchGetQueryById={props?.refetchGetQueryById}
                              refetchGetQuizQuestionQueryById={
                                props?.refetchGetQuizQuestionQueryById
                              }
                              deleteQuestion={deleteQuestion}
                              isWriteAllowed={props?.isWriteAllowed}
                              setErrorMessage={setErrorMessage}
                              setTruthValue={setTruthValue}
                              deleteQuestionId={deleteQuestionId}
                              setDeleteQuestionId={setDeleteQuestionId}
                              editOngoing={editOngoing}
                              setEditOngoing={setEditOngoing}
                              editQuestion={editQuestion}
                              setEditQuestion={setEditQuestion}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Container>
                  ) : (
                    questions?.length === 0 && (
                      <Typography className={classes.noQuestionsHelperText}>
                        No Questions to show
                      </Typography>
                    )
                  )}
                </div>
                {(!addQuestion || questions?.length === 0) &&
                  (!isQuizStarted(props.quizStartTime) ||
                    !props.isPublished) && (
                    <Container>
                      {props?.isWriteAllowed && (
                        <Grid container xs={12} sm={11} spacing={2}>
                          <Grid item xs={6}>
                            <Button
                              disabled={
                                props?.isClosed ||
                                editOngoing ||
                                (props?.quizStartTime
                                  ? new Date() > props?.quizStartTime
                                  : false)
                              }
                              fullWidth
                              onClick={handleAddQuestion}
                              className={classes.addQuestionButton}
                            >
                              Add Question
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              disabled={
                                props?.isClosed ||
                                (props?.quizStartTime
                                  ? new Date() > props?.quizStartTime
                                  : false)
                              }
                              fullWidth
                              component="label"
                              className={classes.addQuestionButton}
                            >
                              Upload File
                              <input
                                accept="text/csv"
                                type="file"
                                hidden
                                ref={fileInput}
                                onChange={csvFileUpload}
                              />
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            direction="column"
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            style={{ paddingTop: '2px' }}
                          >
                            <CSVLink
                              data={dataCsv}
                              headers={headers}
                              filename="sample-file.csv"
                              className={classes.downloadsampleCsv}
                            >
                              Download Sample CSV
                            </CSVLink>
                          </Grid>
                        </Grid>
                      )}
                    </Container>
                  )}
              </>
            )}
            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete this ?
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleClick} color="primary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            <CommonSnackbar
              open={errorMessage !== ''}
              autoHideDuration={3000}
              onClose={() => {
                setErrorMessage('')
                setTimeout(() => setTruthValue(false), 1000)
              }}
              severity={truthValue ? 'success' : 'error'}
              message={errorMessage}
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default EditQuestions
