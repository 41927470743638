import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import CancelIcon from '@material-ui/icons/Cancel'
import Container from '@material-ui/core/Container'
import { Button, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Chip from '@material-ui/core/Chip'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import InlineEdit from '../InlineEdit/InlineEdit'
import { TabPanel, a11yProps } from '../TabPanel/TabPanel'
import '../InlineEdit/InlineEdit.css'
import EditQuizInfo from '../EditQuizInfo/EditQuizInfo'
import EditQuizConfigurations from '../EditQuizConfirgurations/EditQuizConfigurations'
import EditQuestions from '../EditQuestions/EditQuestions'
import Loader from '../Loader/Loader'
import {
  useQuizGetQueryById,
  useQuizQuestionGetQueryById,
  useQuizPatchById,
} from '../../Common/Queries/QuizQuery'
import { QUIZ_URL } from '../../constant'
import { defaultInstructions } from '../../Data/Instructions'
import QuizNotFound from '../QuizNotFound/QuizNotFound'
import { useStyles } from './EditQuizMainStyles'
import ReviewQuiz from '../ReviewQuiz/ReviewQuiz'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'
import { delay } from '../../utils'

const EditQuizMain = () => {
  const { id } = useParams()
  const history = useHistory()
  const queryClient = useQueryClient()
  const email = localStorage.getItem('email')
  const [value, setValue] = React.useState(0)
  const [title, setTitle] = useState('')
  const [addQuestion, setAddQuestion] = useState(false)
  const [localQuestion, setLocalQuestion] = useState({})
  const [editQuestion, setEditQuestion] = useState(NaN)
  const [editViewQuestion, setEditViewQuestion] = useState({})
  const [textEditorQuestion, setTextEditorQuestion] = useState({
    question: '<p></p>',
  })
  const [quizNotFound, setQuizNotFound] = useState(false)
  const [isDraftLoading, setIsDraftLoading] = useState(false)
  const [isPublishLoading, setIsPublishLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [truthValue, setTruthValue] = useState(false)
  const [publishDialog, setPublishDialog] = useState(false)
  const [copied, setCopied] = useState(false)
  const [quizSaveButtonType, setQuizSaveButtonType] = useState(null)
  const [quizConfigCost, setQuizConfigCost] = useState(0)
  const [privateWithInvitees, setPrivateWithInvitees] = useState(false)
  const [codingQuestionSwitch, setCodingQuestionSwitch] = useState(false)
  const [quizInfo, updateQuizInfo] = useState({
    description: null,
    instructions: defaultInstructions,
    startTime: null,
    endTime: null,
    duration: null,
    error: {
      emptyDescription: false,
      invalidDescription: false,
    },
  })

  const [quizSettings, updateQuizSettings] = useState({
    type: null,
    randomize: null,
    invitees: null,
    domain: null,
    result: false,
    emailDomainsCount: 0,
    proctored: null,
    error: {
      invalidInvitee: false,
    },
    permissions: null,
  })
  const [localQuiz, setLocalQuiz] = useState({
    info: {
      title: '',
      description: '',
      instructions: '',
      // status field must have field values "DRAFT","PUBLISHED","CLOSED","ENDED"
      status: '',
    },
    quizConfig: {
      isProctored: '',
      isRandomize: '',
      showResult: '',
      type: '',
    },
    invitees: [''],
    domainName: '',
    time: {
      start: '',
      end: null,
      duration: '',
    },
    maxMarks: '',
  })

  const [localQuizQuestion, setLocalQuizQuestion] = useState({
    questions: [],
  })
  const classes = useStyles({
    publish: localQuiz?.info?.status === 'PUBLISHED',
  })
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidEmails, setInvalidEmails] = useState([])
  const [validationErrors, setValidationErrors] = useState({
    title: false,
    duration: false,
    startTime: false,
    endTime: false,
    description: false,
    instructions: false,
  })
  const [errors, setErrors] = useState({
    durationError: '',
    startTimeError: '',
    endTimeError: '',
    deleteSavedCodingQuestions: false,
  })
  const [editOngoing, setEditOngoing] = useState(false)

  const {
    data: quizObject,
    isLoading: isQuizLoading,
    refetch: refetchGetQueryById,
  } = useQuizGetQueryById(id, email, {
    staleTime: 0,
    retry: 0,
    onError: (err) => {
      if (
        (err?.response?.status === 404 &&
          typeof err?.response?.data?.errors?.[0]?.code === 'string' &&
          err?.response?.data?.errors?.[0]?.code === 'ENTITY_NOT_FOUND' &&
          typeof err?.response?.data?.errors?.[0]?.reason === 'string' &&
          err?.response?.data?.errors?.[0]?.reason?.toLowerCase() ===
            'quiz not found') ||
        (err?.response?.status === 400 &&
          typeof err?.response?.data?.errors?.[0]?.code === 'string' &&
          err?.response?.data?.errors?.[0]?.code === 'BAD_REQUEST' &&
          typeof err?.response?.data?.errors?.[0]?.reason === 'string' &&
          err?.response?.data?.errors?.[0]?.reason?.toLowerCase() ===
            'quiz id is invalid')
      ) {
        setQuizNotFound(true)
      }
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
  })

  const {
    data: quizQuestionObject,
    isLoading: isQuiQuestionLoading,
    isFetching: isQuizQuestionFetching,
    refetch: refetchGetQuizQuestionQueryById,
  } = useQuizQuestionGetQueryById(id, {
    staleTime: 0,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: (err) => {
      if (
        (err?.response?.status === 404 &&
          typeof err?.response?.data?.errors?.[0]?.code === 'string' &&
          err?.response?.data?.errors?.[0]?.code === 'ENTITY_NOT_FOUND' &&
          typeof err?.response?.data?.errors?.[0]?.reason === 'string' &&
          err?.response?.data?.errors?.[0]?.reason?.toLowerCase() ===
            'quiz not found') ||
        (err?.response?.status === 400 &&
          typeof err?.response?.data?.errors?.[0]?.code === 'string' &&
          err?.response?.data?.errors?.[0]?.code === 'BAD_REQUEST' &&
          typeof err?.response?.data?.errors?.[0]?.reason === 'string' &&
          err?.response?.data?.errors?.[0]?.reason?.toLowerCase() ===
            'quiz id is invalid')
      ) {
        setQuizNotFound(true)
      }
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      }
    },
    onSuccess: (successData) => {
      setLocalQuizQuestion(successData)
    },
  })

  const updateQuiz = useQuizPatchById(localQuiz?.id, {
    retry: false,
    onError: (err) => {
      setIsPublishLoading(false)
      if (
        typeof err?.response?.data === 'string' &&
        (err?.response?.data?.toLowerCase() === 'non existing token' ||
          err?.response?.data?.toLowerCase() === 'expired token')
      ) {
        localStorage.clear()
      } else if (
        err?.message?.toLowerCase() === 'invitee email format is invalid'
      )
        setErrorMessage(' Please Enter valid email in invitee list!')
      else if (err?.message?.toLowerCase() === 'quiz title is invalid') {
        setErrorMessage('Please enter the title')
      } else if (
        err?.message?.toLowerCase() === 'time window is invalid' ||
        err?.message?.toLowerCase() === 'end time is invalid'
      ) {
        setErrorMessage('Please select valid time and duration!')
      }
    },
    onSuccess: async () => {
      setIsPublishLoading(false)
      await delay(0.0001 * 1000)
      await queryClient.invalidateQueries('quizGetQueryById')
    },
  })

  useEffect(() => {
    if (quizQuestionObject && quizQuestionObject?.coding?.length > 0) {
      setCodingQuestionSwitch(() => true)
    }
  }, [quizQuestionObject])

  useEffect(() => {
    if (quizObject === undefined) return
    setLocalQuiz(quizObject)
    if (
      !quizInfo?.description ||
      !quizInfo?.instructions ||
      !quizInfo?.startTime
    ) {
      updateQuizInfo((prevQuizInfo) => {
        const updatedQuizInfo = JSON.parse(JSON.stringify(prevQuizInfo))
        updatedQuizInfo.description = updatedQuizInfo.description
          ? updatedQuizInfo.description
          : quizObject?.info?.description.trim()
        updatedQuizInfo.startTime = quizObject?.time?.start
        updatedQuizInfo.endTime = quizObject?.time?.end
        updatedQuizInfo.duration = quizObject?.time?.duration
        if (quizObject?.info?.instructions === '') {
          updatedQuizInfo.instructions = defaultInstructions
        } else {
          updatedQuizInfo.instructions = quizObject?.info?.instructions.trim()
        }
        return updatedQuizInfo
      })
    }
    if (
      !quizSettings.type ||
      !quizSettings.randomize ||
      !quizSettings.result ||
      !quizSettings.proctored
    ) {
      updateQuizSettings((prevQuizSettings) => {
        const updatePrevQuizSettings = prevQuizSettings
        if (
          quizObject?.invitees === null &&
          quizObject?.quizConfig?.type === 'private'
        ) {
          updatePrevQuizSettings.type = 'private'
        } else if (
          quizObject?.invitees !== null &&
          quizObject?.quizConfig?.type === 'private'
        ) {
          updatePrevQuizSettings.type = 'private'
          setPrivateWithInvitees(() => true)
        } else {
          updatePrevQuizSettings.type = quizObject?.quizConfig?.type
        }

        updatePrevQuizSettings.randomize = quizObject?.quizConfig?.isRandomize
        updatePrevQuizSettings.result = quizObject?.quizConfig?.showResult
        updatePrevQuizSettings.proctored = quizObject?.quizConfig?.isProctored
        return updatePrevQuizSettings
      })
    }
    if (quizInfo.startTime && quizInfo.endTime) {
      if (quizInfo.startTime >= quizInfo.endTime) {
        setErrors({
          ...errors,
          endTimeError: 'End time should be greater than start time',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizObject, quizQuestionObject])

  const redirectPage = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    window.addEventListener('beforeunload', redirectPage)

    return () => {
      window.removeEventListener('beforeunload', redirectPage)
    }
  }, [])
  useEffect(() => {
    if (updateQuiz?.isSuccess && quizSaveButtonType === 'save') {
      setIsDraftLoading(false)
      setTruthValue(true)
      setErrorMessage('Data saved successfully!')
    }
    if (updateQuiz?.isSuccess && quizSaveButtonType === 'publish') {
      setIsPublishLoading(false)
      setPublishDialog(() => true)
    }
  }, [quizSaveButtonType, updateQuiz?.isSuccess])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (quizSettings?.type?.toLowerCase() !== 'private') {
      updateQuizSettings((prevQuizSettings) => {
        const updatePrevQuizSettings = prevQuizSettings
        updatePrevQuizSettings.invitees = null
        return updatePrevQuizSettings
      })
    }
  }

  const updateLocalQuizInfo = () => {
    const updatedLocalQuizWithQuizInfo = localQuiz
    updatedLocalQuizWithQuizInfo.info.description =
      quizInfo?.description?.trim()
    updatedLocalQuizWithQuizInfo.info.instructions =
      quizInfo?.instructions?.trim()
    updatedLocalQuizWithQuizInfo.time.start = quizInfo?.startTime
    updatedLocalQuizWithQuizInfo.time.end = quizInfo?.endTime
    updatedLocalQuizWithQuizInfo.time.duration = quizInfo?.duration
    setLocalQuiz(updatedLocalQuizWithQuizInfo)
  }

  const updateLocalQuizSetting = () => {
    const updatedLocalQuizWithQuizSettings = localQuiz
    updatedLocalQuizWithQuizSettings.quizConfig.type = quizSettings.type
    updatedLocalQuizWithQuizSettings.quizConfig.isRandomize =
      quizSettings.randomize
    updatedLocalQuizWithQuizSettings.invitees = quizSettings.invitees
    updatedLocalQuizWithQuizSettings.quizConfig.showResult = quizSettings.result
    updatedLocalQuizWithQuizSettings.quizConfig.isProctored =
      quizSettings.proctored
    setLocalQuiz(updatedLocalQuizWithQuizSettings)
  }

  const handleSave = (isPublished, receivedFrom = '') => {
    updateLocalQuizInfo()
    updateLocalQuizSetting()
    const dummyLocalQuiz = JSON.parse(JSON.stringify(localQuiz))
    // remove unrequired field from get
    delete dummyLocalQuiz.createdAt
    delete dummyLocalQuiz.createdBy
    delete dummyLocalQuiz.info.slug
    delete dummyLocalQuiz.questions
    delete dummyLocalQuiz.questionCount
    delete dummyLocalQuiz.info.isClosed
    delete dummyLocalQuiz.info.isPublished
    if (isPublished) {
      dummyLocalQuiz.info.status = 'PUBLISHED'
    }

    const quizType = dummyLocalQuiz?.quizConfig?.type
    if (quizType?.toLowerCase() !== 'private') {
      dummyLocalQuiz.invitees = null
    }

    if (quizType?.toLowerCase() === 'private') {
      dummyLocalQuiz.quizConfig.type = 'private'
    } else dummyLocalQuiz.quizConfig.type = quizType
    const startTime = dummyLocalQuiz?.time?.start
      ? dummyLocalQuiz?.time?.start
      : null
    const endTime = dummyLocalQuiz?.time?.end ? dummyLocalQuiz?.time?.end : null
    if (startTime !== null) {
      const dateTimeObjectStart = Math.floor(
        new Date(startTime).getTime() / 1000
      )
      dummyLocalQuiz.time.start = dateTimeObjectStart
    }
    if (endTime !== null) {
      const dateTimeObjectEnd = Math.floor(new Date(endTime).getTime() / 1000)
      dummyLocalQuiz.time.end = dateTimeObjectEnd
    }

    dummyLocalQuiz.time.duration = parseInt(dummyLocalQuiz?.time?.duration, 10)
    const currentTime = moment()
    const dateToCompare = moment(startTime)
    if (
      isPublished &&
      dummyLocalQuiz?.info?.status !== 'ENDED' &&
      dummyLocalQuiz?.isWriteAllowed
    ) {
      if (dateToCompare.isBefore(currentTime)) {
        if (receivedFrom === 'save') {
          updateQuiz.mutate(dummyLocalQuiz)
        }
      } else if (receivedFrom === 'save' || receivedFrom === 'publish') {
        updateQuiz.mutate(dummyLocalQuiz)
      }
    } else if (
      dummyLocalQuiz?.info?.status !== 'ENDED' &&
      dummyLocalQuiz?.isWriteAllowed
    ) {
      updateQuiz.mutate(dummyLocalQuiz)
    }
    return ''
  }

  const handleTitle = (titleValue) => {
    const dummyQuiz = JSON.parse(JSON.stringify(localQuiz))
    setTitle(titleValue)
    dummyQuiz.info.title = titleValue
    setLocalQuiz(dummyQuiz)
  }
  const handleCloseSnackBar = () => {
    setErrorMessage('')
    setTimeout(() => setTruthValue(false), 1000)
  }

  const handleCloseDialog = () => {
    setPublishDialog(false)
    setQuizSaveButtonType('save')
  }

  const handlePublish = () => {
    if (value === 3) {
      setQuizSaveButtonType(() => 'publish')
    }
    if (value !== 3) {
      setValue(3)
    } else {
      handleSave(true, 'publish')
    }
  }
  const copy = () => {
    const el = document.createElement('input')
    el.value = `${QUIZ_URL}/quiz/${localQuiz?.info?.slug}`
    navigator.clipboard.writeText(el.value)
    setCopied(true)
  }
  const isPublishable = () => {
    if (quizSettings?.type === 'public' && localQuizQuestion?.coding)
      return false
    if (
      (codingQuestionSwitch &&
        localQuizQuestion?.questionCount &&
        !localQuizQuestion?.questionCount[2]?.count) ||
      (quizInfo?.startTime && new Date() > quizInfo?.startTime)
    ) {
      return false
    }
    if (
      quizInfo?.startTime &&
      quizInfo?.endTime &&
      quizInfo?.startTime >= quizInfo?.endTime
    ) {
      return false
    }
    return (
      quizInfo?.description !== '' &&
      localQuizQuestion?.questions?.length > 0 &&
      localQuiz?.info?.title !== '' &&
      quizInfo?.duration !== ''
    )
  }
  const handleInvalidDuration = () => {
    if (
      Number.isNaN(Number(quizInfo?.duration)) ||
      Number(quizInfo?.duration) <= 0
    ) {
      setErrorMessage('Please enter valid Duration ')
      setQuizSaveButtonType(null)
    }
  }
  const handleRedirectAfterPublish = () => {
    setPublishDialog(false)
    history.push('/')
  }

  useEffect(() => {
    if (quizSettings?.type === 'public') {
      setQuizConfigCost(() => 0)
    } else if (quizSettings?.type === 'private') {
      setQuizConfigCost(() => 1)
      if (quizSettings?.proctored && codingQuestionSwitch)
        setQuizConfigCost(() => 7)
      else if (quizSettings?.proctored) setQuizConfigCost(() => 5)
      else if (codingQuestionSwitch) setQuizConfigCost(() => 3)
    }
  }, [quizSettings, localQuiz, codingQuestionSwitch])

  const handleBackButton = () => {
    if (value !== 0) setValue(value - 1)
  }

  const handleNextButton = () => {
    handleSave(localQuiz?.info?.status === 'PUBLISHED')
    if (value !== 3) setValue(value + 1)
  }

  const handleSaveAsDraft = () => {
    if (quizInfo?.description.trim() === '' && value !== 0) {
      return setErrorMessage('Please enter Description')
    }
    if (quizInfo?.instructions.trim() === '' && value !== 0) {
      return setErrorMessage('Instructions cannot be empty.')
    }
    if (
      codingQuestionSwitch &&
      value === 2 &&
      localQuizQuestion?.questionCount &&
      !localQuizQuestion?.questionCount[2]?.count
    ) {
      return setErrorMessage(
        'Please add Coding Question as it is enabled, Else disable Coding Question in Configurations tab'
      )
    }
    handleInvalidDuration()
    setQuizSaveButtonType('save')
    handleSave(false)
    return ''
  }
  const handlePublishButton = () => {
    handleInvalidDuration()
    if (
      codingQuestionSwitch &&
      localQuizQuestion?.questionCount &&
      [2]?.count
    ) {
      return setErrorMessage(
        'Please add Coding Question as it is enabled, Else disable Coding Question in Configurations tab'
      )
    }
    if (
      quizSettings?.type === 'private' &&
      (quizSettings?.invitees?.length >= 1 || quizSettings?.domain?.length > 1)
    ) {
      handlePublish()
    }
    if (quizSettings?.type !== 'private' || quizSettings?.type === 'private') {
      handlePublish()
    }
    if (quizSettings?.type === 'private') {
      handlePublish()
    }
    return ''
  }
  if (quizNotFound) {
    return <QuizNotFound reason="Quiz not found" />
  }

  return (
    <>
      <Helmet>
        <title>{localQuiz?.info?.title}</title>
      </Helmet>
      {isQuizLoading || isQuiQuestionLoading ? (
        <div className={classes.loader}>
          <Loader size="8rem" />
        </div>
      ) : (
        <div className={classes.root}>
          <div className={classes.editQuizHeader}>
            <Container className={classes.container}>
              <Grid className={classes.titleAndCreditsWrapper}>
                <div className={classes.quizTitle}>
                  <InlineEdit
                    text={title === '' ? localQuiz?.info?.title.trim() : title}
                    onSetText={(text) => handleTitle(text)}
                    quizStartTime={quizInfo.startTime}
                    isPublished={localQuiz?.info?.status === 'PUBLISHED'}
                    isClosed={
                      localQuiz?.info?.status === 'CLOSED' ||
                      localQuiz?.info?.status === 'ENDED' ||
                      !localQuiz?.isWriteAllowed
                    }
                    isWriteAllowed={localQuiz?.isWriteAllowed}
                    setErrorMessage={setErrorMessage}
                  />
                </div>
                {/* <div className={classes.spaceBetweenTitleAndCredits} /> */}
                <div className={classes.linkAndCreditsContainer}>
                  {localQuiz?.info?.status === 'PUBLISHED' ? (
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      className={classes.buttonWrapper}
                    >
                      <Button
                        disabled={
                          localQuiz?.info?.status === 'CLOSED' ||
                          localQuiz?.info?.status === 'ENDED' ||
                          !localQuiz?.isWriteAllowed
                        }
                        onClick={copy}
                        variant="outlined"
                        className={classes.copyUrlButton}
                      >
                        {!copied ? 'Copy quiz link' : 'Copied!'}
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid className={classes.quizConfigCostWrapper}>
                    <div className={classes.quizConfigCostDiv}>
                      <Typography className={classes.creditMessage}>
                        Cost Per Attempt
                      </Typography>
                      <Typography className={classes.creditSubMessage}>
                        Amount chargeable
                      </Typography>
                    </div>
                    <div className={classes.creditsContainer}>
                      <Typography className={classes.credits}>
                        {quizConfigCost}C
                      </Typography>
                    </div>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Tabs
                  classes={{ flexContainer: classes.tabFlexContainer }}
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  indicatorColor="secondary"
                  className={classes.tabs}
                >
                  <Tab
                    classes={{
                      flexContainer: classes.tabFlexContainer,
                      wrapper: classes.iconLabelWrapper,
                      labelContainer: classes.labelContainer,
                      labelIcon: classes.labelIcon,
                    }}
                    label="Config"
                    icon={
                      (validationErrors?.duration ||
                        validationErrors?.startTime ||
                        validationErrors?.endTime) && (
                        <CancelIcon
                          style={{
                            color: 'red',
                            marginBottom: '0px',
                            marginRight: '4px',
                          }}
                        />
                      )
                    }
                    onClick={() => {
                      setQuizSaveButtonType(() => '')
                      if (
                        !(localQuiz?.info?.status === 'CLOSED') &&
                        !(localQuiz?.info?.status === 'PUBLISHED') &&
                        localQuiz?.isWriteAllowed
                      ) {
                        handleSave(false)
                      } else handleSave(true)
                    }}
                    {...a11yProps(0)}
                    className={classes.tab}
                  />
                  <Tab
                    classes={{
                      wrapper: classes.iconLabelWrapper,
                      labelContainer: classes.labelContainer,
                      labelIcon: classes.labelIcon,
                    }}
                    label="Info"
                    icon={
                      (validationErrors?.description ||
                        validationErrors?.instructions) && (
                        <CancelIcon
                          style={{
                            color: 'red',
                            marginBottom: '0px',
                            marginRight: '4px',
                          }}
                        />
                      )
                    }
                    onClick={() => {
                      setQuizSaveButtonType(() => '')
                      if (
                        !(localQuiz?.info?.status === 'CLOSED') &&
                        !(localQuiz?.info?.status === 'PUBLISHED') &&
                        localQuiz?.isWriteAllowed
                      ) {
                        handleSave(false)
                      } else handleSave(true)
                    }}
                    {...a11yProps(1)}
                    className={classes.tab}
                  />
                  <Tab
                    label="Questions"
                    onClick={() => {
                      setQuizSaveButtonType(() => '')
                      if (
                        !(localQuiz?.info?.status === 'CLOSED') &&
                        !(localQuiz?.info?.status === 'PUBLISHED') &&
                        localQuiz?.isWriteAllowed
                      ) {
                        handleSave(false)
                      } else handleSave(true)
                    }}
                    {...a11yProps(2)}
                    className={classes.tab}
                  />
                  <Tab
                    label="Review"
                    onClick={() => {
                      setQuizSaveButtonType(() => '')
                      if (
                        !(localQuiz?.info?.status === 'CLOSED') &&
                        !(localQuiz?.info?.status === 'PUBLISHED') &&
                        localQuiz?.isWriteAllowed
                      ) {
                        handleSave(false)
                      } else handleSave(true)
                    }}
                    {...a11yProps(3)}
                    className={classes.tab}
                  />
                </Tabs>
              </Grid>
            </Container>
          </div>
          <div className={classes.editQuizBody}>
            <Container className={classes.container}>
              <TabPanel value={value} index={0}>
                <EditQuizConfigurations
                  quizId={quizObject?.id}
                  quizObject={quizObject}
                  questionCount={localQuizQuestion?.questionCount}
                  questions={localQuizQuestion?.questions}
                  quizInfo={quizInfo}
                  updateQuizInfo={updateQuizInfo}
                  isPublished={localQuiz?.info?.status === 'PUBLISHED'}
                  isClosed={
                    localQuiz?.info?.status === 'CLOSED' ||
                    localQuiz?.info?.status === 'ENDED' ||
                    !localQuiz?.isWriteAllowed
                  }
                  quizSettings={quizSettings}
                  updateQuizSettings={updateQuizSettings}
                  invalidEmail={invalidEmail}
                  setInvalidEmail={setInvalidEmail}
                  invalidEmails={invalidEmails}
                  setInvalidEmails={setInvalidEmails}
                  privateWithInvitees={privateWithInvitees}
                  setPrivateWithInvitees={setPrivateWithInvitees}
                  codingQuestionSwitch={codingQuestionSwitch}
                  setCodingQuestionSwitch={setCodingQuestionSwitch}
                  errors={errors}
                  setErrors={setErrors}
                  isWriteAllowed={localQuiz?.isWriteAllowed}
                />
              </TabPanel>
            </Container>
            <Container className={classes.container}>
              <TabPanel value={value} index={1}>
                <EditQuizInfo
                  quizInfo={quizInfo}
                  updateQuizInfo={updateQuizInfo}
                  isPublished={localQuiz?.info?.status === 'PUBLISHED'}
                  isClosed={
                    localQuiz?.info?.status === 'CLOSED' ||
                    !localQuiz?.isWriteAllowed
                  }
                  isWriteAllowed={localQuiz?.isWriteAllowed}
                  setErrorMessage={setErrorMessage}
                />
              </TabPanel>
            </Container>
            <TabPanel value={value} index={2}>
              <EditQuestions
                quizInfo={quizInfo}
                quizObject={quizObject}
                quizType={quizSettings?.type}
                questions={localQuizQuestion?.questions}
                quizId={localQuiz?.id}
                quizStartTime={quizInfo.startTime}
                isPublished={localQuiz?.info?.status === 'PUBLISHED'}
                isClosed={
                  localQuiz?.info?.status === 'CLOSED' ||
                  localQuiz?.info?.status === 'ENDED' ||
                  !localQuiz?.isWriteAllowed
                }
                codingQuestionSwitch={codingQuestionSwitch}
                setCodingQuestionSwitch={setCodingQuestionSwitch}
                refetchGetQueryById={refetchGetQueryById}
                refetchGetQuizQuestionQueryById={
                  refetchGetQuizQuestionQueryById
                }
                isQuiQuestionLoading={isQuiQuestionLoading}
                isQuizQuestionFetching={isQuizQuestionFetching}
                isWriteAllowed={localQuiz?.isWriteAllowed}
                setTextEditorQuestion={setTextEditorQuestion}
                textEditorQuestion={textEditorQuestion}
                setLocalQuestion={setLocalQuestion}
                localQuestion={localQuestion}
                setAddQuestion={setAddQuestion}
                addQuestion={addQuestion}
                setEditViewQuestion={setEditViewQuestion}
                editViewQuestion={editViewQuestion}
                editQuestion={editQuestion}
                setEditQuestion={setEditQuestion}
                editOngoing={editOngoing}
                setEditOngoing={setEditOngoing}
              />
            </TabPanel>
            <Container className={classes.container}>
              <TabPanel value={value} index={3}>
                <ReviewQuiz
                  isPublishLoading={isPublishLoading}
                  setIsPublishLoading={setIsPublishLoading}
                  isDraftLoading={isDraftLoading}
                  setIsDraftLoading={setIsDraftLoading}
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                  updateQuiz={updateQuiz}
                  handleSaveAsDraft={handleSaveAsDraft}
                  isPublishable={isPublishable}
                  handlePublishButton={handlePublishButton}
                  setQuizSaveButtonType={setQuizSaveButtonType}
                  handleSave={handleSave}
                  localQuiz={localQuiz}
                  quizInfo={quizInfo}
                  quizSettings={quizSettings}
                  codingQuestionSwitch={codingQuestionSwitch}
                  questions={localQuizQuestion?.questions}
                  localQuizQuestion={localQuizQuestion}
                  isWriteAllowed={localQuiz?.isWriteAllowed}
                />
              </TabPanel>
            </Container>
          </div>

          {/* Edit Quiz Footer */}
          <div
            className={classes.editQuizFooter}
            style={{ backgroundColor: 'white' }}
          >
            <Container className={classes.container}>
              <Grid
                item
                container
                xs={12}
                direction="row"
                alignItems="center"
                className={classes.editQuizFooterButtons}
              >
                <Button
                  className={classes.publishButton}
                  onClick={() => handleBackButton()}
                  disabled={value === 0}
                  startIcon={<NavigateBeforeIcon />}
                >
                  Back
                </Button>
                <Button
                  className={classes.publishButton}
                  onClick={() => handleNextButton()}
                  disabled={value === 3}
                  endIcon={<NavigateNextIcon />}
                >
                  Next
                </Button>
              </Grid>
            </Container>
          </div>
        </div>
      )}
      <CommonSnackbar
        open={errorMessage !== ''}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        severity={truthValue ? 'success' : 'error'}
        message={errorMessage}
      />
      <Dialog
        open={publishDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quiz has been published successfully. <br />
            <br />
            <Chip
              className={classes.alertCopyLinkButton}
              onClick={copy}
              label={!copied ? 'Copy quiz link' : 'Copied!'}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRedirectAfterPublish} autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditQuizMain
