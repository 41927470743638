/**
 * @description End point for Quiz
 */
export const QUIZ_ENDPOINT = '/quiz'
/**
 * @description End point for Login
 */
export const LOGIN_ENDPOINT = '/login'
export const LOGOUT_ENDPOINT = '/logout'
export const RESULT_ENDPOINT = '/response'
/**
 * @description end point for admin
 */
export const ADMIN_ENDPOINT = '/admin'

/**
 * @description End point for Compile
 */
export const COMPILE_ENDPOINT = '/compile'
/**
 * @description End point for Live Candidate
 */
export const LIVE_CANDIDATES_ENDPOINT = '/candidates'

/**
 * @description End point for post admin credentials
 */
export const STREAM_CREDENTIALS_ENDPOINT = '/stream-credentials'
/**
 * @description End point for invitees
 */
export const INVITEES_END_POINT = '/invitees'
/**
 * @description End point for billing config
 */
export const BILLING_CONFIG_ENDPOINT = '/billing-config'
/**
 * @description End point for payment
 */
export const PAYMENT_ENDPOINT = '/payment'
export const BILLING_ENDPOINT = '/billing-log'
/**
 * @description Client ID for GAuth
 */

export const CLIENT_ID =
  window.env?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID

/**
 * @description base URL for mock server
 */
export const MOCK_BASE_URL =
  window.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL

export const QUIZ_URL =
  window.env?.REACT_APP_QUIZ_URL || process.env.REACT_APP_QUIZ_URL

export const BUGSNAG_KEY =
  window.env?.REACT_APP_BUGSNAG_KEY || process.env.REACT_APP_BUGSNAG_KEY

export const FARO_APP_NAME =
  window.env?.REACT_APP_FARO_APP_NAME || process.env.REACT_APP_FARO_APP_NAME

export const FARO_APP_URL =
  window.env?.REACT_APP_FARO_URL || process.env.REACT_APP_FARO_URL

export const FARO_API_KEY =
  window.env?.REACT_APP_FARO_API_KEY || process.env.REACT_APP_FARO_API_KEY

export const BALANCE_ENDPOINT = '/balance'

export const MOBILE_VIEW_WIDTH = 960

export const REFRESH_ENDPOINT = '/refresh'
