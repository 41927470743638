import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import QuillTextEditor from '../TextEditorToolbar/TextEditor'
import UpdateQuestionsInQuizesModal from '../UpdateQuestionInQuizesModal/UpdateQuestionInQuizesModal'

import { useStyles } from './AddTrueFalseQuestionStyles'
import Loader from '../Loader/Loader'

const EditTrueFalseTile = ({
  addQuestion,
  questions,
  questionIndex,
  localQuestion,
  errors,
  handleEdit,
  isQuizStarted,
  quizStartTime,
  isPublished,
  questionTypeFromParent,
  handleQuestionTypeChange,
  quizType,
  codingQuestionSwitch,
  textEditorQuestion,
  setTextEditorQuestion,
  handleCorrectChoice,
  editQuestionQuery,
  postQuestion,
  editQuestion,
  handleCancel,
  handleCancelAddTf,
  handleSave,
  question,
  showQuizesListModal,
  setShowQuizesListModal,
  affectedQuizes,
  updateQuestionInSelectedQuizzes,
  setSelectedQuizzes,
  selectedQuizzes,
  quizId,
  setLocalQuestion,
  setEditViewQuestion,
  editViewQuestion,
}) => {
  const classes = useStyles()

  const handleSaveButtonClick = async () => {
    if (affectedQuizes?.length > 1) setShowQuizesListModal(true)
    else handleSave()
  }

  useEffect(() => {
    if (addQuestion && Object.keys(editViewQuestion || {})?.length > 0) {
      setLocalQuestion(editViewQuestion)
    } else if (!Number.isNaN(editQuestion)) {
      setLocalQuestion(localQuestion)
    }
  }, [])

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} sm={12} md={11} className={classes.editQuestion}>
        <Paper variant="outlined" className={classes.editPaper}>
          {/* Header */}
          <Grid container xs={12} style={{ marginBottom: '16px' }}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ alignItems: 'end', display: 'flex' }}
            >
              <Typography variant="h6" className={classes.questionIndex}>
                Question{' '}
                {addQuestion ? questions?.length + 1 : questionIndex + 1}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              container
              className={classes.marksType}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                id="filled-number"
                value={localQuestion?.marks}
                variant="outlined"
                autoComplete="off"
                size="small"
                error={
                  errors?.emptyMarks ||
                  errors?.marksNotValid ||
                  errors?.marksLessThanZero
                }
                className={classes.mcqMarksContainer}
                inputProps={{
                  style: {
                    fontSize: 16,
                    color: '#262B4B',
                    textAlign: 'center',
                    height: '32px',
                    width: '40px',
                  },
                }}
                onChange={(event) => handleEdit(event, 'marks', 0)}
                disabled={isQuizStarted(quizStartTime) && isPublished}
                label="Marks"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                data-testid="render-text-field"
              />
              {addQuestion && (
                <FormControl
                  variant="outlined"
                  style={{ marginLeft: '8px' }}
                  data-testid="question-type-drop-down"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Question Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={questionTypeFromParent}
                    onChange={handleQuestionTypeChange}
                    label="Question Type"
                  >
                    <MenuItem value="mcq">Multiple Choice Question</MenuItem>
                    <MenuItem
                      value="coding"
                      disabled={quizType === 'public' || !codingQuestionSwitch}
                    >
                      Coding Question
                    </MenuItem>
                    <MenuItem value="t/f">True/False Question</MenuItem>
                    <MenuItem value="multi-choice">
                      Multiple Answer Question
                    </MenuItem>
                    <MenuItem value="essay">Essay Question</MenuItem>
                    <MenuItem value="fillInBlanks">Fill In The Blanks</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid xs={12} style={{ marginBottom: '16px' }}>
            <QuillTextEditor
              textEditorQuestion={textEditorQuestion}
              setTextEditorQuestion={setTextEditorQuestion}
              className={localQuestion?.id?.toString()}
              isQuizStarted={isQuizStarted(quizStartTime) && isPublished}
              setLocalQuestion={setLocalQuestion}
              localQuestion={localQuestion}
              question={question}
              setEditViewQuestion={setEditViewQuestion}
              editViewQuestion={editViewQuestion}
              addQuestion={addQuestion}
            />

            <Grid
              container
              direction="row"
              className={classes.marksQuestionValidationContainer}
            >
              <Grid item sm={8} xs={12}>
                {errors?.emptyQuestion && (
                  <Grid>
                    <Typography
                      className={classes.validationText}
                      data-testid="render-empty-question-error"
                    >
                      Question should not be empty
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                className={classes.marksValidationContainer}
              >
                {errors?.emptyMarks && (
                  <Grid>
                    <Typography
                      className={classes.validationText}
                      data-testid="render-empty-marks-error"
                    >
                      Marks should not be empty
                    </Typography>
                  </Grid>
                )}
                {errors?.marksLessThanZero && (
                  <Grid>
                    <Typography
                      className={classes.validationText}
                      data-testid="render-empty-marksLessThanZero-error"
                    >
                      Marks should be more than 0
                    </Typography>
                  </Grid>
                )}
                {errors?.marksNotValid && (
                  <Grid data-testid="render-only-numeric-value">
                    <Typography
                      className={classes.validationText}
                      datat-testid="render"
                    >
                      Only numeric values are allowed
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              style={{ paddingBottom: '0px' }}
              item
              xs={12}
              sm={6}
              lg={4}
              data-testid="firstGrid-testId"
              onClick={() => {
                handleCorrectChoice('True')
              }}
            >
              <Paper
                elevation={2}
                variant="outlined"
                className={
                  localQuestion?.choices?.length > 0 &&
                  localQuestion?.choices[0]?.isCorrect
                    ? classes.isCorrect
                    : ''
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px',
                  marginBottom: '8px',
                }}
              >
                <Grid container xs={12}>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    className={classes.outerColumn}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <RadioGroup
                      style={{ marginLeft: '6px' }}
                      onChange={(event) =>
                        handleCorrectChoice(event?.target?.value)
                      }
                      data-testid="on-change-handle-correct-choice"
                    >
                      <FormControlLabel
                        value={
                          localQuestion?.choices?.length > 0 &&
                          localQuestion?.choices[0]?.value
                        }
                        control={<Radio />}
                        checked={
                          localQuestion?.choices?.length > 0 &&
                          localQuestion?.choices[0]?.isCorrect
                        }
                        disabled={isQuizStarted(quizStartTime) && isPublished}
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    sm={10}
                    md={10}
                    lg={10}
                    direction="row"
                    style={{ display: 'flex' }}
                    justifyContent="flex-start"
                  >
                    <InputBase
                      value="True"
                      autoComplete="off"
                      className={classes.inputChoiceValue}
                      inputProps={{
                        'aria-label': 'naked',
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              onClick={() => {
                handleCorrectChoice('False')
              }}
              data-testid="handle-correct-choice-grid-two"
            >
              <Paper
                elevation={2}
                variant="outlined"
                className={
                  localQuestion?.choices?.length > 0 &&
                  localQuestion?.choices[1]?.isCorrect
                    ? classes.isCorrect
                    : ''
                }
                style={{
                  // width: '40%',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px',
                  marginBottom: '8px',
                }}
              >
                <Grid container xs={12}>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    className={classes.outerColumn}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <RadioGroup
                      style={{ marginLeft: '6px' }}
                      onChange={(event) =>
                        handleCorrectChoice(event?.target?.value)
                      }
                      data-testid="on-change-handle-correct-choice-two"
                    >
                      <FormControlLabel
                        value={
                          localQuestion?.choices?.length > 0 &&
                          localQuestion?.choices[1]?.value
                        }
                        control={<Radio />}
                        checked={
                          localQuestion?.choices?.length > 0 &&
                          localQuestion?.choices[1]?.isCorrect
                        }
                        disabled={isQuizStarted(quizStartTime) && isPublished}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sm={10}
                    md={10}
                    lg={10}
                    direction="row"
                    style={{ display: 'flex' }}
                    justifyContent="flex-start"
                  >
                    <InputBase
                      value="False"
                      autoComplete="off"
                      inputProps={{
                        'aria-label': 'naked',
                      }}
                      className={classes.inputChoiceValue}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <UpdateQuestionsInQuizesModal
            isLoading={false}
            showQuizesListModal={showQuizesListModal}
            setShowQuizesListModal={setShowQuizesListModal}
            affectedQuizes={affectedQuizes}
            handleSave={handleSave}
            currentQuestion={localQuestion}
            updateQuestionInSelectedQuizzes={updateQuestionInSelectedQuizzes}
            setSelectedQuizzes={setSelectedQuizzes}
            selectedQuizzes={selectedQuizzes}
            quizId={quizId}
          />
          {editQuestionQuery?.isLoading || postQuestion?.isLoading ? (
            <div className={classes.loader} data-testid="render-loader">
              <Loader size={30} />
            </div>
          ) : (
            <div className={classes.cancelAndSaveButtonWrapper}>
              <Button
                onClick={
                  !Number.isNaN(editQuestion) ? handleCancel : handleCancelAddTf
                }
                variant="filled"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveButtonClick}
                variant="filled"
                disabled={isQuizStarted(quizStartTime) && isPublished}
              >
                Save
              </Button>
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default EditTrueFalseTile
