import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './LogoStyles'
import LogoIcon from '../../icons/LogoIcon'

const Logo = ({ size }) => {
  const classes = useStyles()
  if (size !== 'small') {
    return (
      <div data-testid="render-logo-icon">
        <LogoIcon />
      </div>
    )
  }

  return (
    <Typography className={classes.logo} data-testid="render-logo">
      <span className={classes.quizzing}>T</span>
      <span className={classes.pro}>P</span>
    </Typography>
  )
}

export default Logo
