const styles = (theme) => ({
  defaultRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  additionalRoot: {
    margin: 0,
    padding: theme.spacing(3),
    '&:hover ': {
      border: '1px solid #00B7A4',
    },
  },
  dialogTitleText: {
    flexGrow: '1',
  },
  closeButton: {
    width: '24px',
    height: '24px',
    color: theme.palette.primary.main,
  },
})

export { styles }
