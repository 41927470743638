import React from 'react'
import parse from 'html-react-parser'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import GreenTickCircleOutlinedIcon from '../../icons/GreenTickCircleOutlinedIcon'
import RedWrongCircleOutlinedIcon from '../../icons/RedWrongCircleOutlinedIcon'
import GrayThreeDotsCircleOutlinedIcon from '../../icons/GrayThreeDotsCircleOutlinedIcon'
import { useStyles } from './FillInTheBlankResultRowItemMobileStyles'

function FillInTheBlankResultRowItemMobile({ question }) {
  const classes = useStyles()
  const isCorrect = question?.result?.isCorrect
  const isNotAttempted = !question?.result?.userResponse.length > 0
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container style={{ width: '100%' }}>
        <Grid container className={classes.iconWrapper}>
          <Grid item className={classes.icon}>
            {isNotAttempted ? <GrayThreeDotsCircleOutlinedIcon /> : null}
            {isCorrect ? <GreenTickCircleOutlinedIcon /> : null}
            {!isCorrect && !isNotAttempted ? (
              <RedWrongCircleOutlinedIcon />
            ) : null}
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.contentWrapper}>
          <Grid item className={classes.questionWrapper}>
            <Grid item className={classes.question}>
              {parse(question?.question)}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.selectedChoiceWrapper}
          >
            <Typography className={classes.selectedChoiceHeading}>
              Answer
            </Typography>
            <Typography
              className={classes.selectedChoice}
              data-testid="selectedChoiceMobile-testId"
            >
              {isNotAttempted ? <>NA</> : <>{question?.result?.userResponse}</>}
            </Typography>
          </Grid>

          <Grid
            container
            direction="column"
            className={classes.correctChoiceWrapper}
          >
            <Typography
              className={classes.correctChoiceHeading}
              data-testid="correctChoiceMobile-testId"
            >
              Correct Answer
            </Typography>
            <Typography className={classes.correctChoice}>
              {question?.result?.acceptedAnswers.join(', ')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.marksContainer}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.marksWrapper}
        >
          <Typography data-testid="maximumMarksMobile-testId">
            <span className={classes.marks}>
              {question?.result?.marks}/{question?.maximumMarks}
            </span>{' '}
            Marks
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FillInTheBlankResultRowItemMobile
