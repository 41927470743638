import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  root: {
    marginTop: '46px',
  },
  marksWrapper: {
    width: '100%',
    height: '54.63px',
    background: theme?.palette?.lightBackground?.main,
    borderBottom: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    '@media only screen and (min-width:426px)': {
      height: '76px',
    },
    '@media only screen and (min-width:769px)': {
      height: '96px',
    },
  },

  evaluatedMarks: {
    fontSize: '16px',
    fontWeight: 600,
    '@media only screen and (min-width:426px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '30px',
    },
  },
  evaluationErrorMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '450px',
  },

  resultPageWrapper: {
    height: 'calc(100% - 46px - 54.63px)',
    width: '100%',
    '@media only screen and (min-width:426px)': {
      height: 'calc(100% - 46px - 76px)',
    },
    '@media only screen and (min-width:769px)': {
      height: 'calc(100% - 46px - 96px)',
    },
  },
  loader: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
export { styles }
