/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, TextField, Typography } from '@material-ui/core'
import useWindowSize from '../../hooks/useWindowSize'
import Loader from '../Loader/Loader'
import {
  usePaymentPostQuery,
  usePaymentGetBillingLog,
} from '../../Common/Queries/PaymentQueries'
import { useBillingConfigGetQuery } from '../../Common/Queries/BillingConfigQueries'
import { isLogin } from '../../utils'
import BillingPagination from './billingPagination'
import BillingTable from './billingTable'
import { useStyles } from './PaymentStyles'
import CommonSnackbar from '../CommonSnackbar/CommonSnackbar'

export default function Payment() {
  const windowSize = useWindowSize()
  const classes = useStyles()
  const [userInputPrice, setUserInputPrice] = useState('')
  const token = localStorage.getItem('token')
  const [perUnitCredit, setPerUnitCredit] = useState()
  const [billingPagesArray, setBillingPagesArray] = useState([])
  const [billingPageLimit, setBillingPageLimit] = useState(8)
  const [transactionType, setTransactionType] = useState('Credit')
  const [totalBillingCount, updateTotalBillingCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [amount, setAmount] = useState(0.0)
  const resourceId = localStorage.getItem('id')
  const [transactionList, updateTransactionList] = useState([])
  const [invalidInputOpenSnackbar, setInvalidInputOpenSnackbar] =
    useState(false)
  const [disablePayButton, setDisablePayButton] = useState(true)
  const [paymentLoader, setPaymentLoader] = useState(false)
  const billingConfig = useBillingConfigGetQuery({
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (successResponse) => {
      setPerUnitCredit(() => successResponse?.unitCreditCost)
    },
  })

  function postForm(path, params, method = 'post') {
    const form = document?.createElement('form')
    form.method = method
    form.action = path

    const checkIfObjectPropertyExists = (objectParam, keyParam) => {
      const hasProperty = Object?.prototype?.hasOwnProperty
      return hasProperty?.call(objectParam, keyParam)
    }

    for (const key in params) {
      // eslint-disable-next-line no-prototype-builtins
      if (checkIfObjectPropertyExists(params, key)) {
        const hiddenField = document?.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = params?.[key]

        form?.appendChild(hiddenField)
      }
    }

    document?.body?.appendChild(form)
    form?.submit()
  }

  const userPaymentMutation = usePaymentPostQuery(resourceId, {
    staleTime: 0,
    onSuccess: (successResponse) => {
      postForm(`${successResponse?.paymentGatewayURL}/pay.php`, {
        access_key: successResponse?.accessKey,
        transaction_id: successResponse?.transactionID,
        transaction_amount: successResponse?.amount,
        transaction_hmac: successResponse?.transactionHMAC,
        email: successResponse?.email,
      })
    },
  })
  const {
    isSuccess: isBillingListSuccess,
    refetch: billingListRefetch,
    isLoading: isBillingListLoading,
    isFetching: isBillingListFetching,
  } = usePaymentGetBillingLog(
    resourceId,
    transactionType,
    billingPageLimit,
    offset,
    {
      staleTime: 0,
      enabled: false,
      onSuccess: (successData) => {
        updateTotalBillingCount(() => successData?.data?.data?.totalCount)
        updateTransactionList(successData?.data?.data?.data || [])
        if (successData?.data?.data?.totalCount) {
          setBillingPagesArray(() =>
            new Array(
              Math.ceil(successData?.data?.data?.totalCount / billingPageLimit)
            ).fill(0)
          )
        }
      },
    }
  )
  useEffect(() => {
    if (isLogin) {
      billingListRefetch()
    }
  }, [isLogin])

  const handleChangeUserInputTextField = (value) => {
    setUserInputPrice(value)
    const invalidInputCase = !Number(value) || Number(value) < 1
    const validInputCase = Number(value) > 0
    if (invalidInputCase) {
      if (value !== '') {
        setInvalidInputOpenSnackbar(() => true)
        setDisablePayButton(() => true)
      } else if (value === '') {
        setDisablePayButton(() => true)
      }
      setAmount(() => 0)
    } else if (validInputCase) {
      setAmount(() => (value * perUnitCredit).toFixed(2))
      setDisablePayButton(() => false)
    }
  }
  const handleCloseSnackBar = (value) => {
    if (value === 'invalidInput') {
      setInvalidInputOpenSnackbar(() => false)
    }
  }
  const handlePayment = () => {
    setPaymentLoader(() => true)
    userPaymentMutation.mutate({
      amount: Number(amount),
      email: localStorage.getItem('email'),
    })
  }
  const handlePrev = () => {
    setOffset((prevOffset) => {
      return prevOffset - billingPageLimit >= 0
        ? prevOffset - billingPageLimit
        : 0
    })
  }
  const handleNext = () => {
    setOffset((prevOffset) => {
      return prevOffset + billingPageLimit <= totalBillingCount
        ? prevOffset + billingPageLimit
        : totalBillingCount
    })
  }
  useEffect(() => {
    billingListRefetch(billingPageLimit, offset)
  }, [offset])

  if (userPaymentMutation.isLoading || paymentLoader) {
    return (
      <div className={classes.loader}>
        <Loader size="8rem" />
      </div>
    )
  }
  if (userPaymentMutation.isError) {
    return (
      <>
        <div
          style={{
            height: `${windowSize?.height}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '50px',
          }}
        >
          <p>Error</p>
        </div>
      </>
    )
  }
  return (
    <div
      className={classes.root}
      style={{
        height:
          `${windowSize?.height}px` < '630px'
            ? 'max-content'
            : `${windowSize?.height}px`,
      }}
    >
      <div className={classes.paymentWrapper}>
        <div className={classes.paymentHeaderWrapper}>
          <Grid container className={classes.paymentsHeader}>
            <Typography className={classes.heading}>Payments</Typography>
          </Grid>
        </div>
        <div className={classes.paymentDescriptionWrapper}>
          <Typography className={classes.paymentDescription}>
            Please enter the credits
          </Typography>
        </div>
        <div className={classes.paymentContainer}>
          <TextField
            value={userInputPrice}
            className={classes.paymentPlaceholder}
            variant="outlined"
            label="Enter Credits"
            placeholder="0"
            id="filled"
            onChange={(event) =>
              handleChangeUserInputTextField(event.target.value)
            }
            onKeyPress={(event) => event.key === 'Enter' && handlePayment()}
          />
          <div className={classes.amountAndPayButtonWrapper}>
            <Typography className={classes.amount}>
              {`${'Amount payable - '} `}
            </Typography>
            <Typography className={classes.payAmount}>
              {' '}
              {`${`$${amount}`} `}
            </Typography>
            <div className={classes.paymentButton}>
              <Button
                disabled={disablePayButton}
                className={classes.payButton}
                onClick={() => handlePayment()}
              >
                Pay
              </Button>
            </div>
          </div>
        </div>
        <CommonSnackbar
          open={invalidInputOpenSnackbar}
          autoHideDuration={4000}
          onClose={() => handleCloseSnackBar('invalidInput')}
          severity="error"
          message="Please enter an amount more than 0"
        />
      </div>

      {isBillingListFetching || isBillingListLoading ? (
        <div className={classes.loader}>
          <Loader size="6rem" />
        </div>
      ) : isBillingListSuccess && totalBillingCount ? (
        <div className={classes.billingTableAndPaginationWrapper}>
          <div className={classes.billingAndPaginationDiv}>
            <div className={classes.billingTableWrapper}>
              <div className={classes.billingContainer}>
                <div className={classes.billingTableHeader}>
                  <Typography className={classes.billingHeader}>
                    Transactions
                  </Typography>
                </div>
                <div className={classes.billingBodyWrapper}>
                  <div className={classes.billingBodyDiv}>
                    <BillingTable transactionList={transactionList} />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.paginationWrapper}>
              <div className={classes.paginationGrid}>
                <BillingPagination
                  className={classes.pagination}
                  totalBillingCount={totalBillingCount}
                  handleNext={handleNext}
                  handlePrevious={handlePrev}
                  offset={offset}
                  billingPagesArray={billingPagesArray}
                  billingPageLimit={billingPageLimit}
                  setOffset={setOffset}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Typography className={classes.billingLogsMsg}>
            There are no transactions found
          </Typography>{' '}
        </div>
      )}
    </div>
  )
}
