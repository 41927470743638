import { useMutation } from 'react-query'
import PaymentResponse from '../../Repository/PaymentResponse/PaymentResponse'

const PaymentResponseReq = PaymentResponse.getInstance()

/**
 * this function is to Query get All application
 * @function usePaymentResponsePostQuery
 ** @param { options} default parameter
 */
export function usePaymentResponsePostQuery(
  transactionID,
  resourceId,
  options
) {
  return useMutation(
    (args) =>
      PaymentResponseReq.postPaymentResponse(args, resourceId, transactionID),
    {
      ...options,
    }
  )
}
