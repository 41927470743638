import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, Paper, Checkbox } from '@material-ui/core'

import PersonIcon from '@material-ui/icons/Person'

import { useStyle } from './EditQuizConfigurationsStyles'
import Loader from '../Loader/Loader'

const AddedInviteesModal = ({
  isPublished,
  quizInfo,
  isClosed,
  validEmails,
  quizSettings,
  inviteesType,
  showAddedInviteesModal,
  setShowAddedInviteesModal,
  createInvitee,
  addDomainName,
  isLoading,
  getInviteesIsFetching,
  getInviteesIsLoading,
  isDomainFetching,
  isDomainLoading,
  //   setOpenSendInvitesModal,
}) => {
  const quizStartTime =
    quizInfo?.startTime && new Date(quizInfo?.startTime).getTime()
  const [domainAndEmail, setDomainAndEmail] = useState([])
  const [confirmDelete, setConfirmDelete] = useState(false)
  const classes = useStyle({
    publish: isPublished,
    startTime: quizInfo?.startTime,
    endTime: quizInfo?.endTime,
    isClosed,
  })
  const [selectAll, setSelectAll] = useState(false)
  const renderDomainAndEmail =
    (inviteesType === 'Email'
      ? quizSettings?.invitees
      : quizSettings?.domain) !== null &&
    (inviteesType === 'Email'
      ? quizSettings?.invitees?.length > 0
      : quizSettings?.domain?.length > 0) &&
    (inviteesType === 'Email'
      ? quizSettings?.invitees
      : quizSettings?.domain
    )?.sort()
  const handleClose = () => {
    return setShowAddedInviteesModal(false)
  }

  const handleConfirmDeleteDomain = async () => {
    await addDomainName.mutate({
      action: 'DELETE',
      emailDomain: domainAndEmail,
    })

    setDomainAndEmail([])
    setConfirmDelete(false)
  }

  const handleDeleteInvitees = async () => {
    await createInvitee.mutate({
      action: 'DELETE',
      inviteesEmail: domainAndEmail,
    })
    setDomainAndEmail([])
    setConfirmDelete(false)
  }

  const handleDeleteOne = () => {
    if (confirmDelete && domainAndEmail?.length > 0) {
      if (inviteesType === 'Email') {
        handleDeleteInvitees()
      } else {
        handleConfirmDeleteDomain()
      }
    }
    return setConfirmDelete(true)
  }

  const toggleSelectAll = () => {
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    if (selectAll) {
      setDomainAndEmail(renderDomainAndEmail)
    } else {
      setDomainAndEmail([])
    }
  }, [selectAll])

  const handleOnClickEmailAndDomain = (value) => {
    if (!domainAndEmail?.includes(value)) {
      setDomainAndEmail((preValue) => [...preValue, value])
    }
    if (domainAndEmail.includes(value)) {
      const temp = domainAndEmail?.filter((data) => data !== value)
      setDomainAndEmail(temp)
    }
  }

  const renderData = () => {
    if (
      isLoading ||
      getInviteesIsFetching ||
      getInviteesIsLoading ||
      isDomainFetching ||
      isDomainLoading
    ) {
      return (
        <div
          style={{
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'scroll',
            backgroundColor: validEmails?.length === 0 ? 'F3F7F7' : '',
          }}
        >
          <Loader size="3rem" />
        </div>
      )
    }
    if (!confirmDelete) {
      if (renderDomainAndEmail?.length > 0) {
        return (
          <div
            style={{
              height: '300px',
              overflowY: 'scroll',
              backgroundColor: validEmails?.length === 0 ? 'F3F7F7' : '',
            }}
          >
            {renderDomainAndEmail?.length > 0
              ? renderDomainAndEmail?.map((data, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px 14px ',
                      borderBottom: '1px solid #dadce0',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      {confirmDelete ? null : (
                        <Checkbox
                          onClick={() => handleOnClickEmailAndDomain(data)}
                          style={{ marginRight: 8 }}
                          checked={selectAll || domainAndEmail?.includes(data)}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <PersonIcon />
                        <Typography style={{ paddingLeft: '8px' }}>
                          {data}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        )
      }
      return (
        <div
          style={{
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'scroll',
            backgroundColor: validEmails?.length === 0 ? 'F3F7F7' : '',
          }}
        >
          {isLoading ||
          getInviteesIsFetching ||
          getInviteesIsLoading ||
          isDomainFetching ||
          isDomainLoading ? (
            <Loader size="2rem" />
          ) : (
            <Typography>
              {inviteesType === 'Email'
                ? 'No Emails Present'
                : 'No Domains Present'}
            </Typography>
          )}
        </div>
      )
    }
    return (
      <div
        style={{
          height: '300px',

          overflowY: 'scroll',
          backgroundColor: validEmails?.length === 0 ? 'F3F7F7' : '',
        }}
      >
        {domainAndEmail?.sort()?.map((data, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 14px ',
              borderBottom: '1px solid #dadce0',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              <div style={{ display: 'flex' }}>
                <PersonIcon />
                <Typography
                  onClick={() => handleOnClickEmailAndDomain(data)}
                  style={{ paddingLeft: '8px' }}
                >
                  {data}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
  return (
    <div>
      <Dialog
        open={showAddedInviteesModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography
            className={classes.dialogTitle}
            style={{ maxWidth: '450px' }}
          >
            {confirmDelete
              ? 'Confirm Delete'
              : `Added ${inviteesType === 'Email' ? 'Invitees' : 'Domains'}`}
          </Typography>
          <Typography
            style={{ fontSize: '16px', paddingTop: '8px', maxWidth: '450px' }}
          >
            {confirmDelete
              ? `Are you sure want to delete the below ${
                  inviteesType === 'Email' ? 'Emails' : 'Domains'
                }`
              : `View the added
            ${
              inviteesType === 'Email'
                ? 'Email addresses of the test takers you want to share the test.'
                : 'Domain addresses of the test takers you want to share the test.'
            }`}
          </Typography>
        </DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2px 14px ',
            alignItems: 'center',
          }}
        >
          <div>
            {confirmDelete ? null : (
              <Checkbox onClick={() => toggleSelectAll()} checked={selectAll} />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
            }}
          >
            <div style={{ display: 'flex' }}>
              <Typography>Select All</Typography>
            </div>
          </div>
        </div>
        <DialogContent>
          <Paper
            variant="outlined"
            style={{ border: '2px solid #00B7A4', width: '450px' }}
          >
            {renderData()}
          </Paper>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24 24px' }}>
          <Button className={classes.dialogCancelButton} onClick={handleClose}>
            Close
          </Button>{' '}
          {/* <Button onClick={handleEditButton}>Edit</Button> */}
          <Button
            disabled={
              domainAndEmail?.length < 1 ||
              (quizStartTime && quizStartTime <= new Date().getTime()) ||
              isClosed
            }
            onClick={() => handleDeleteOne()}
          >
            {confirmDelete ? 'Confirm Delete' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddedInviteesModal
