import { useQuery } from 'react-query'
import { createLiveCandidates } from '../../Repository/LiveCandidates/LiveCandidates'

const liveCandidatesRequest = createLiveCandidates()
export function useQuizIdGetAll(reqId, options) {
  return useQuery(
    ['allLiveCandidates', reqId],
    () => liveCandidatesRequest.getAllLiveCandidates(reqId),
    { ...options }
  )
}
export function useMyViewCandidatesGetAll(
  reqId,
  limit,
  offset,
  filterStatus,
  sortBy,
  userInputArray,
  options
) {
  return useQuery(
    ['myViewCandidates', reqId],
    () =>
      liveCandidatesRequest.getMyViewCandidates(
        reqId,
        limit,
        offset,
        filterStatus,
        sortBy,
        userInputArray
      ),
    { ...options }
  )
}
export function useQuizIdGetSortFilter(
  reqId,
  limit,
  offset,
  sortBy,
  filterStatus,
  options
) {
  return useQuery(
    ['allSortFilterCandidates', reqId],
    () =>
      liveCandidatesRequest.getAllSortandFilterLiveCandidates(
        reqId,
        limit,
        offset,
        sortBy,
        filterStatus
      ),
    { ...options }
  )
}
