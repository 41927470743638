import React, { useState } from 'react'
import parse from 'html-react-parser'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CodeEditor from '../CodeEditor/CodeEditor'
import GreenTickCircleOutlinedIcon from '../../icons/GreenTickCircleOutlinedIcon'
import RedWrongCircleOutlinedIcon from '../../icons/RedWrongCircleOutlinedIcon'
import GrayThreeDotsCircleOutlinedIcon from '../../icons/GrayThreeDotsCircleOutlinedIcon'
import YellowTickCircleOutlinedIcon from '../../icons/YellowTickCircleOutlinedIcon'
import ResultNormalTestcase from '../ResultNormalTestcase/ResultNormalTestcase'
import ResultSampleTestcase from '../ResultSampleTestcase/ResultSampleTestcase'
import { useStyles } from './CodingQuestionResultRowItemTabStyles'

function CodingQuestionResultRowItemTab({ question }) {
  const [openViewCodeDialog, setOpenViewCodeDialog] = useState(false)
  const [openViewAllTheTestcaseDetails, setOpenViewAllTheTestcaseDetails] =
    useState(false)

  const doesCodeExist = !!question?.code
  const isCorrect =
    question?.result?.testcasesPassed === question?.result?.totalTestcases
  const isPartiallyCorrect =
    question?.result?.testcasesPassed &&
    question?.result?.testcasesPassed < question?.result?.totalTestcases
  const isWrong = question?.language && question?.result?.testcasesPassed === 0
  const isNotAttempted = !question?.language
  const classes = useStyles()
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container className={classes.codeResultsWrapper}>
        <Grid container className={classes.iconWrapper}>
          <Grid item className={classes.icon}>
            {isCorrect ? <GreenTickCircleOutlinedIcon /> : null}
            {isWrong ? <RedWrongCircleOutlinedIcon /> : null}
            {isNotAttempted ? <GrayThreeDotsCircleOutlinedIcon /> : null}
            {isPartiallyCorrect ? <YellowTickCircleOutlinedIcon /> : null}
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.contentWrapper}>
          <Grid item className={classes.questionWrapper}>
            <Grid item className={classes.question}>
              {parse(question?.question)}
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={
              classes.testCasesPassedAndViewAllTheTestcaseDetailsWrapper
            }
          >
            <Grid
              container
              direction="column"
              className={classes.testCasesPassedWrapper}
            >
              <Typography className={classes.testCasesPassedHeading}>
                Testcases passed
              </Typography>
              <Typography className={classes.testCasesPassed}>
                {question?.result?.testcasesPassed}/
                {question?.result?.totalTestcases}
              </Typography>
            </Grid>
            <Grid item className={classes.viewAllTheTestcaseDetailsWrapper}>
              <Dialog
                fullWidth
                maxWidth="md"
                open={openViewAllTheTestcaseDetails}
              >
                <DialogTitle className={classes.testCaseClose}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    className={classes.codeResultsWrapper}
                  >
                    <IconButton
                      onClick={() =>
                        setOpenViewAllTheTestcaseDetails(() => false)
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Typography className={classes.testCaseDisplay}>
                    {question?.testcases?.length} Testcases
                  </Typography>
                </DialogTitle>
                <DialogContent className={classes.sampleAndNormalTestCase}>
                  <Grid item>
                    {question?.compilationOutput?.codeRunOutputList
                      ? question?.compilationOutput?.codeRunOutputList.map(
                          (testcase, index) => {
                            return (
                              <Grid
                                item
                                key={index.toString(2)}
                                className={classes.testCases}
                              >
                                <ResultSampleTestcase
                                  testcase={testcase}
                                  index={index}
                                  maximumMarks={
                                    question?.testcases?.filter(
                                      (filterTestcase) => {
                                        return (
                                          filterTestcase?.id ===
                                          testcase?.testCaseInfo?.testCaseId
                                        )
                                      }
                                    )?.[0]?.marks
                                  }
                                />
                              </Grid>
                            )
                          }
                        )
                      : question?.testcases?.map((testcase, index) => {
                          return (
                            <Grid
                              item
                              key={index.toString(2)}
                              className={classes.testCases}
                            >
                              <ResultNormalTestcase
                                testcase={testcase}
                                index={index}
                                maximumMarks={testcase?.marks}
                              />
                            </Grid>
                          )
                        })}
                  </Grid>
                </DialogContent>
              </Dialog>
              <Button
                variant="text"
                className={classes.viewAllTheTestcaseDetails}
                onClick={() => setOpenViewAllTheTestcaseDetails(() => true)}
                data-testid="viewAllTabTestcase-testId"
              >
                View all the testcase details
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            className={classes.languageAndViewCodeWrapper}
          >
            <Grid
              container
              direction="column"
              className={classes.languageWrapper}
            >
              <Typography className={classes.languageHeading}>
                Language
              </Typography>
              <Typography className={classes.language}>
                {question?.language ? (
                  <> {question?.language}</>
                ) : (
                  <>Not Selected</>
                )}
              </Typography>
            </Grid>
            <Grid item className={classes.viewCodeWrapper}>
              <Dialog fullWidth maxWidth="md" open={openViewCodeDialog}>
                <DialogTitle className={classes.testCaseClose}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    className={classes.codeResultsWrapper}
                  >
                    <IconButton
                      onClick={() => setOpenViewCodeDialog(() => false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Typography className={classes.codeHeader}>Code</Typography>
                </DialogTitle>
                <DialogContent className={classes.codeEditor}>
                  <CodeEditor
                    language={question?.language}
                    isDarkMode
                    code={question?.code}
                    readOnly
                    width="100%"
                  />
                </DialogContent>
              </Dialog>
              <Button
                variant="text"
                disabled={!doesCodeExist}
                onClick={() => setOpenViewCodeDialog(() => true)}
                className={classes.viewCode}
              >
                View Code
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.marksContainer}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.marksWrapper}
        >
          <Typography>
            <span className={classes.marks}>
              {question?.result?.marks}/{question?.maximumMarks}
            </span>{' '}
            Marks
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CodingQuestionResultRowItemTab
