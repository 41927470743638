import React from 'react'

function RedWrongCircleOutlinedIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="redIcon-testId"
    >
      <g
        id="Red-icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" fillRule="nonzero">
          <g id="utility/messaging/failure">
            <path
              d="M12,2.8125 C17.0717859,2.81812136 21.1818786,6.92821413 21.1875,12 C21.1875,17.0741161 17.0741161,21.1875 12,21.1875 C6.92588386,21.1875 2.8125,17.0741161 2.8125,12 C2.8125,6.92588386 6.92588386,2.8125 12,2.8125 Z M12,3.9375 C7.5472042,3.9375 3.9375,7.5472042 3.9375,12 C3.9375,16.4527958 7.5472042,20.0625 12,20.0625 C16.4507397,20.05754 20.05754,16.4507397 20.0625,12 C20.0625,7.5472042 16.4527958,3.9375 12,3.9375 Z M15.2299875,8.20751562 C15.3791668,8.20751562 15.5222318,8.26679688 15.62769,8.37231 C15.7332057,8.47777083 15.7924844,8.62084016 15.7924844,8.77002311 C15.7924844,8.91920606 15.7331947,9.06227315 15.627675,9.16773 L15.627675,9.16773 L12.795405,12 L15.627675,14.83227 C15.8417142,15.0528522 15.8390682,15.4043976 15.6217329,15.6217329 C15.4043976,15.8390682 15.0528522,15.8417142 14.83227,15.627675 L14.83227,15.627675 L12,12.795405 L9.167715,15.627675 C9.02636321,15.7733478 8.81749252,15.8315673 8.62116774,15.7800166 C8.42484296,15.728466 8.27151899,15.575142 8.21996836,15.3788173 C8.16841772,15.1824925 8.22663722,14.9736218 8.37231,14.83227 L8.37231,14.83227 L11.204595,12 L8.372325,9.167715 C8.15828581,8.94713282 8.16093182,8.59558744 8.37826713,8.37825213 C8.59560244,8.16091682 8.94714782,8.15827081 9.16773,8.37231 L9.16773,8.37231 L12,11.204595 L14.832285,8.37231 C14.9377432,8.26679688 15.0808082,8.20751562 15.2299875,8.20751562 Z"
              id="Color"
              fill="#D70022"
            />
            <rect
              id="failure"
              fillOpacity="0"
              fill="#FFFFFF"
              x="-1.70530257e-13"
              y="0"
              width="24"
              height="24"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default RedWrongCircleOutlinedIcon
