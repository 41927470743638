import React from 'react'

function Showresult() {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      //   xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Show result</title>
      <g
        id="Admin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-8">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="40"
            height="40"
          />
          <path
            d="M11.101107,4 L11.101107,6 L7,6 C6.48123698,6 6.0636461,6.36966391 6.00663701,6.8341179 L6,6.94285714 L6,37.0571429 C6,37.5345535 6.38053638,37.9383181 6.88219949,37.993563 L7,38 L33,38 C33.518763,38 33.9363539,37.6303361 33.993363,37.1658821 L34,37.0571429 L34,28.6314426 L36,28.6314426 L36,37.0571429 C36,38.6307474 34.7451447,39.9059499 33.1756369,39.9950286 L33,40 L7,40 C5.40847427,40 4.09677877,38.7809977 4.00511625,37.2307147 L4,37.0571429 L4,6.94285714 C4,5.36925255 5.25485529,4.09405011 6.82436314,4.00497137 L7,4 L11.101107,4 Z M33,4 C34.5915257,4 35.9032212,5.21900228 35.9948838,6.76928528 L36,6.94285714 L36,21.3197791 L34,21.3197791 L34,6.94285714 C34,6.46544653 33.6194636,6.06168187 33.1178005,6.00643697 L33,6 L28.9782987,6 L28.9782987,4 L33,4 Z"
            id="Shape"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M28,0 C29.1045695,0 30,0.8954305 30,2 L30,10 C30,11.1045695 29.1045695,12 28,12 L12,12 C10.8954305,12 10,11.1045695 10,10 L10,2 C10,0.8954305 10.8954305,0 12,0 L28,0 Z M28,2 L12,2 L12,10 L28,10 L28,2 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <polygon
            id="Path-9"
            fill="#2A2A2A"
            fillRule="nonzero"
            points="29 18 29 20 11 20 11 18"
          />
          <polygon
            id="Path-9"
            fill="#2A2A2A"
            fillRule="nonzero"
            points="29 23 29 25 11 25 11 23"
          />
          <polygon
            id="Shape"
            fill="#2A2A2A"
            fillRule="nonzero"
            points="17.7257584 28 17.7257584 30 11 30 11 28"
          />
          <polygon
            id="Path-10"
            fill="#00B7A4"
            fillRule="nonzero"
            points="38.303028 20.2829016 39.696972 21.7170984 25.8219967 35.2026522 18.2945111 27.7094952 19.7054889 26.2920533 25.838 32.397"
          />
        </g>
      </g>
    </svg>
  )
}

export default Showresult
