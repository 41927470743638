import React from 'react'
import parse from 'html-react-parser'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import Loader from '../Loader/Loader'

import { useStyles } from './AddFillInTheBlankStyles'

const FillInTheBlankTile = ({
  questionIndex,
  editQuestion,
  addQuestion,
  question,
  isPublished,
  isQuizStarted,
  quizStartTime,
  deleteQuestion,
  handleDelete,
  isWriteAllowed,
  handleChange,
}) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} xs={12}>
      <Grid item xs={10} sm={11}>
        <Paper elevation={2} variant="outlined" className={classes.paper}>
          <Grid item xs={12} className={classes.editQuestion}>
            <Grid container xs={12}>
              <Grid item xs={7} sm={4} alignItems="center">
                <Typography className={classes.questionIndex}>
                  Question {questionIndex === undefined ? 1 : questionIndex + 1}
                  .
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sm={8}
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                {!Number.isNaN(editQuestion) || addQuestion ? (
                  <></>
                ) : (
                  <Typography
                    variant="h4"
                    style={{ color: '#00B7A4' }}
                    className={classes.tileMarks}
                  >
                    {question?.marks} marks
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography className={classes.question}>
                  {parse(question?.question)}
                </Typography>
              </Grid>
              <Typography
                className={classes.question}
                style={{ fontSize: '20px' }}
              >
                Correct Answers:
              </Typography>
              {question?.acceptedAnswers?.map((value, index) => {
                return (
                  <Grid item xs={12} key={`value_${index}`}>
                    <Paper
                      elevation={2}
                      variant="outlined"
                      className={classes.isCorrect}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        marginBottom: '8px',
                      }}
                    >
                      <Grid container xs={12} spacing={2}>
                        <Grid
                          item
                          xs={8}
                          sm={10}
                          md={10}
                          lg={10}
                          direction="row"
                          style={{ display: 'flex' }}
                          justifyContent="flex-start"
                        >
                          <Typography className={classes.selectedChoiceValue}>
                            {value?.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {isWriteAllowed && (
        <Grid item xs={2} sm={1}>
          {editQuestion === questionIndex || addQuestion ? (
            <></>
          ) : (
            <>
              <Button
                color="secondary"
                className={classes.editButton}
                variant="outlined"
                onClick={handleChange}
                disabled={isQuizStarted(quizStartTime)} // disabled in closed quiz
              >
                <EditOutlinedIcon data-testid="question-testId" />
              </Button>
              {(!isQuizStarted(quizStartTime) || !isPublished) && (
                <>
                  {deleteQuestion?.isLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '12px',
                      }}
                    >
                      <Loader size={30} />
                    </div>
                  ) : (
                    <Button
                      disabled={isQuizStarted(quizStartTime)} // disabled in closed quiz
                      className={classes.deleteButton}
                      variant="outlined"
                      onClick={() => handleDelete()}
                      data-testid="delete-button-on-click"
                    >
                      <DeleteOutlineOutlinedIcon />
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default FillInTheBlankTile
