import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, Paper, Tooltip } from '@material-ui/core'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import PersonIcon from '@material-ui/icons/Person'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { useStyle } from './EditQuizConfigurationsStyles'

const SendInviteModal = ({
  handleRemoveDialogEmail,
  handleConfirmButton,
  validEmails,
  invalidEmails,
  handleBackbutton,
  openSendInvitesModal,
  handleClose,
  isPublished,
  quizInfo,
  isClosed,
  handleSubmitEmailDomain,
  inviteesType,
}) => {
  const classes = useStyle({
    publish: isPublished,
    startTime: quizInfo?.startTime,
    endTime: quizInfo?.endTime,
    isClosed,
  })

  const toolTipRender = () => {
    if (invalidEmails?.length > 0)
      return 'Clear all the invalid values to confirm'
    return ''
  }

  return (
    <div>
      <Dialog
        open={openSendInvitesModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            Step 2/2 - Verify and Confirm{' '}
            {inviteesType === 'Email' ? 'Invitees' : 'Domain'}
          </Typography>
          <Typography style={{ fontSize: '16px', paddingTop: '8px' }}>
            Please verify the values and confirm
            {inviteesType === 'Email' ? ' Invitees' : ' Domain'} for the test
          </Typography>
        </DialogTitle>
        {invalidEmails?.length === 0 && (
          <div
            style={{
              margin: '0px 24px 8px 24px',
              display: 'flex',
              alignItems: 'center',
              padding: '12px 14px',
              border: '1px solid #00B7A4',
              backgroundColor: '#ECFBF6',
              borderRadius: '6px',
            }}
          >
            <CheckCircleOutlineIcon
              fontSize="small"
              style={{ color: '#00B7A4' }}
            />
            <Typography style={{ paddingLeft: '9px' }}>
              No Invalid Values present
            </Typography>
          </div>
        )}
        <DialogContent>
          {invalidEmails?.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    paddingBottom: '8px',
                  }}
                >
                  Invalid Values
                </Typography>
                <Typography
                  style={{
                    fontSize: '14px',
                    // fontWeight: 500,
                    paddingBottom: '8px',
                  }}
                  data-testid="totalInvalidEmailCount-testId"
                >
                  Total: {invalidEmails?.length}
                </Typography>
              </div>

              <Paper variant="outlined" style={{ border: '2px solid red' }}>
                <div
                  style={{
                    height: '160px',
                    overflowY: 'scroll',
                    backgroundColor: validEmails?.length === 0 ? 'F3F7F7' : '',
                  }}
                >
                  {invalidEmails?.length > 0 &&
                    invalidEmails?.sort()?.map((email, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '8px 14px ',
                          borderBottom: '1px solid #dadce0',
                          //   backgroundColor: { (index-2) === 0? "#F3F7F7":""}
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <PersonIcon />
                          <Typography style={{ paddingLeft: '8px' }}>
                            {email}
                          </Typography>
                        </div>
                        <HighlightOffIcon
                          onClick={() =>
                            handleRemoveDialogEmail('invalid', index, email)
                          }
                          fontSize="small"
                          style={{ color: 'red', cursor: 'pointer' }}
                        />
                      </div>
                    ))}
                </div>
              </Paper>
              <Typography className={classes.addingInfo}>
                * Please go back and edit these invalid values.
              </Typography>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 500,
                paddingBottom: '8px',
              }}
            >
              Valid Values
            </Typography>
            <Typography
              style={{
                fontSize: '14px',
                // fontWeight: 500,
                paddingBottom: '8px',
              }}
              data-testid="totalValidEmailCount-testId"
            >
              Total: {validEmails?.length}
            </Typography>
          </div>
          <Paper variant="outlined" style={{ border: '2px solid #00B7A4' }}>
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '14px',
                borderBottom: '1px solid #00B7A4',
                backgroundColor: '#ECFBF6',
                borderRadius: '4px 4px 0 0',
              }}
            >
              <Typography style={{ paddingLeft: '32px' }}>Email</Typography>
              <Typography>Action</Typography>
            </div> */}
            <div
              style={{
                height: '160px',
                overflowY: 'scroll',
                backgroundColor: validEmails?.length === 0 ? 'F3F7F7' : '',
              }}
            >
              {validEmails?.length > 0 &&
                validEmails?.sort()?.map((email, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px 14px ',
                      borderBottom: '1px solid #dadce0',
                      //   backgroundColor: { (index-2) === 0? "#F3F7F7":""}
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <PersonIcon />
                      <Typography style={{ paddingLeft: '8px' }}>
                        {email}
                      </Typography>
                    </div>
                    <HighlightOffIcon
                      onClick={() =>
                        handleRemoveDialogEmail('valid', index, email)
                      }
                      fontSize="small"
                      style={{ color: 'red', cursor: 'pointer' }}
                    />
                  </div>
                ))}
              {validEmails?.length === 0 && (
                <Typography
                  style={{
                    margin: ' 12%',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontStyle: 'italic',
                  }}
                >
                  No Valid Values
                </Typography>
              )}
            </div>
          </Paper>
        </DialogContent>
        <DialogActions style={{ padding: '24px 24px 24px 24px' }}>
          <Button
            onClick={handleBackbutton}
            className={classes.dialogCancelButton}
          >
            Back
          </Button>
          <Tooltip
            arrow
            placement="bottom-start"
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            title={toolTipRender()}
          >
            <span>
              <Button
                onClick={
                  inviteesType === 'Email'
                    ? handleConfirmButton
                    : handleSubmitEmailDomain
                }
                disabled={invalidEmails?.length > 0 || validEmails?.length < 1}
                color="tertiary"
                autoFocus
                data-testid="sendInviteModal-testId"
              >
                Confirm
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SendInviteModal
