import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { styles } from './QuizPaginationStyles'

function QuizPagination({
  quizCount,
  handleNext,
  handlePrevious,
  offset,
  quizPagesArray,
  quizPageLimit,
  setOffset,
}) {
  const classes = styles({ offset, quizPagesArray, quizPageLimit })
  return (
    <Grid className={classes.paginationMainGrid}>
      {quizCount > 0 && (
        <IconButton onClick={handlePrevious} disabled={offset === 0}>
          <ArrowBackIosIcon className={classes.ArrowBackIosIcon} />
        </IconButton>
      )}
      <Grid className={classes.paginationDottedButtons}>
        {quizPagesArray?.map((_, index) => {
          return (
            <Grid
              key={index}
              className={classes.paginationNumberedButtonsChild}
            >
              <Grid
                key={index}
                className={classes.paginationStyle}
                style={{
                  color:
                    index === offset / quizPageLimit ? '#00B7A4' : '#262A4C',
                }}
                onClick={() => setOffset(index * quizPageLimit)}
              >
                {index + 1}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      {quizCount > 0 && (
        <IconButton
          onClick={handleNext}
          disabled={quizPagesArray.length - 1 === offset / quizPageLimit}
        >
          <ArrowForwardIosIcon
            className={classes.ArrowForwardIosIcon}
            disabled={quizPagesArray.length - 1 < 0}
          />
        </IconButton>
      )}
    </Grid>
  )
}

export default QuizPagination
