import { createFetcher } from '../../Fetcher/Fetcher'
import {
  MOCK_BASE_URL,
  QUIZ_ENDPOINT,
  ADMIN_ENDPOINT,
  LIVE_CANDIDATES_ENDPOINT,
} from '../../constant'
import { unixTimeStamp } from '../../utils'

export class LiveCandidates {
  static getInstance() {
    if (!this.instance) {
      this.instance = new LiveCandidates(
        MOCK_BASE_URL,
        QUIZ_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(baseUrl, endPoint, fetcherObj) {
    this.base_url = baseUrl
    this.endPoint = endPoint
    this.fetcher = fetcherObj
  }

  async getAllLiveCandidates(reqId) {
    const url = `${
      this.base_url + ADMIN_ENDPOINT + this.endPoint
    }/${reqId}${LIVE_CANDIDATES_ENDPOINT}`
    const response = await this.fetcher.getData(url)
    const candidatesList = response?.data?.data?.users
    if (response.status === 200) {
      candidatesList?.forEach((candidate) => {
        const copyCandidate = JSON.parse(JSON.stringify(candidate))
        copyCandidate.quizStartTime = new Date(
          unixTimeStamp(candidate?.quizStartTime)
        )
        copyCandidate.quizEndTime = new Date(
          unixTimeStamp(candidate?.quizEndTime)
        )
      })
      return candidatesList
    }
    return response
  }

  async getAllSortandFilterLiveCandidates(
    reqId,
    limit,
    offset,
    sortBy,
    filterStatus
  ) {
    const url = `${
      this.base_url + ADMIN_ENDPOINT + this.endPoint
    }/${reqId}${LIVE_CANDIDATES_ENDPOINT}${
      filterStatus ? `?status=${filterStatus}` : ''
    }${filterStatus ? `&limit=${limit}` : `?limit=${limit}`}&offset=${offset}${
      sortBy ? `&sortBy=${sortBy}` : ''
    }`

    const response = await this.fetcher.getData(url)
    return response
  }

  async getMyViewCandidates(
    reqId,
    limit,
    offset,
    filterStatus,
    sortBy,
    userInputArray
  ) {
    const url = `${
      this.base_url + ADMIN_ENDPOINT + this.endPoint
    }/${reqId}${LIVE_CANDIDATES_ENDPOINT}${
      filterStatus ? `?status=${filterStatus}` : ''
    }${
      filterStatus ? `&email=${userInputArray}` : `?email=${userInputArray}`
    }&limit=${limit}&offset=${offset}${sortBy ? `&sortBy=${sortBy}` : ''}`
    const response = await this.fetcher.getData(url)
    return response
  }
}

export const createLiveCandidates = () => LiveCandidates.getInstance()
