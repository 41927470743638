import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    paddingBottom: '100px',
    marginTop: '30px',
    '@media only screen and (max-width:815px)': {
      paddingTop: (props) => (props?.publish ? '40px' : ''),
    },
    '@media only screen and (max-width:665px)': {
      paddingTop: (props) => (props?.publish ? '100px' : '50px'),
    },
  },
  questionTab: {
    '@media only screen and (min-width:743px) and (max-width:801px)': {
      paddingTop: '42px',
    },
    '@media only screen and (min-width:802px) and (max-width:865px)': {
      paddingTop: '4px',
    },
  },
  radioButton: {
    border: `1px solid ${theme.palette.borders.main}`,
    color: theme.palette.text.primary,
    backgroundColor: '#FFFFFF',
    padding: 0,
    marginRight: '1%',
    paddingLeft: '1%',
    marginBottom: '5px',
    '&:hover': {
      border: `1px solid ${theme.palette.borders.main}`,
      color: theme.palette.text.primary,
      backgroundColor: '#FFFFFF',
      fontWeight: 500,
    },
  },
  selectedRadioButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.primary,
    backgroundColor: '#FFFFFF',
    padding: 0,
    marginRight: '1%',
    paddingLeft: '1%',
    fontWeight: 500,
    marginBottom: '5px',
    '&:hover': {
      border: `1px solid ${theme.palette.borders.main}`,
      color: theme.palette.text.primary,
      backgroundColor: '#FFFFFF',
      fontWeight: 500,
    },
    '&:disabled': {
      border: `1px solid ${theme.palette.borders.main}`,
    },
  },

  CloseIcon: {
    color: theme.palette.primary.main,
  },
  question: {
    // paddingTop: '2%',
  },
  addQuestionButton: {
    backgroundColor: 'white',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    marginTop: '3%',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  downloadsampleCsv: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '12px',
    height: '14px',
    maxWidth: '130px',
    cursor: (props) => (props?.isClosed ? 'default' : 'pointer'),
    pointerEvents: (props) => (props?.isClosed ? 'none' : 'auto'),
    opacity: (props) => (props?.isClosed ? '0.4' : '80%'),
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  radioButtonContainer: {
    margin: 0,
    paddingTop: '10px',
    paddingBottom: '15px',
    '@media only screen and (min-width:743px)': {
      height: '70px',
    },
  },
  questionIndex: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '1.1',
    paddingTop: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  questionHeader: {
    marginBottom: '10px',
  },
  cancelButton: {
    marginTop: '10px',
    marginRight: '10px',
    '@media only screen and (max-width:460px)': {
      marginRight: '4px',
    },
  },
  noQuestionsHelperText: {
    margin: '18%',
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'italic',
  },
}))

export { useStyle }
