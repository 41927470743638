import React, { useState, useMemo } from 'react'
import { BalanceContext } from './Contexts'
import { useQuizGetBalance } from '../Common/Queries/QuizQuery'
import { delay, isLogin } from '../utils'

export const BalanceProvider = function BalanceProvider({ children }) {
  const [balance, setBalance] = useState(0)
  const [balanceCallCountLeft, setBalanceCallCountLeft] = useState(15)

  const { refetch: balanceRefetch } = useQuizGetBalance({
    stale: 0,
    cacheTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: false,
    onSuccess: (response) => {
      if (response?.errors && balanceCallCountLeft > 0) {
        setBalanceCallCountLeft(balanceCallCountLeft - 1)
        balanceRefetch()
      }
      setBalance(response?.data?.data)
      if (isLogin() && balanceCallCountLeft > 0) {
        const waitAndRefetch = async () => {
          await delay(30 * 1000)
          setBalanceCallCountLeft(balanceCallCountLeft - 1)
          if (balanceCallCountLeft > 0) balanceRefetch()
        }
        waitAndRefetch()
      }
    },
    onerror: () => {
      if (isLogin() && balanceCallCountLeft > 0) {
        setBalanceCallCountLeft(balanceCallCountLeft - 1)
        balanceRefetch()
      }
    },
  })

  const balanceValue = useMemo(
    () => ({
      balanceRefetch,
      balance,
    }),
    [balanceRefetch, balance]
  )

  return (
    <BalanceContext.Provider value={balanceValue}>
      {children}
    </BalanceContext.Provider>
  )
}
