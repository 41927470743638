import React from 'react'
import parse from 'html-react-parser'

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import Loader from '../Loader/Loader'

import { useStyles } from './EditMcqQuestion2Styles'
import Choice from './Choice'

const McqQuestionTile = ({
  questionIndex,
  editQuestion,
  addQuestion,
  question,
  isPublished,
  isQuizStarted,
  quizStartTime,
  deleteQuestion,
  handleDelete,
  questionTypeFromParent,
  isWriteAllowed,
  handleChange,
}) => {
  const classes = useStyles()
  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={10} sm={11} md={11} className={classes.editQuestion}>
        <Paper
          elevation={2}
          variant="outlined"
          className={classes.paper}
          style={{
            width: '100%',
          }}
        >
          <Grid container xs={12} style={{ display: 'flex', width: '100%' }}>
            <Grid item xs={7} sm={4}>
              <Typography className={classes.questionIndex}>
                Question {questionIndex === undefined ? 1 : questionIndex + 1}.
              </Typography>
            </Grid>

            <Grid
              item
              xs={5}
              sm={8}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {(Number.isNaN(editQuestion) || !addQuestion) && (
                <Typography
                  variant="h4"
                  style={{ color: '#00B7A4' }}
                  className={classes.tileMarks}
                >
                  {question?.marks} marks
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.editMcqQuestion}>
            <Grid container>
              <Typography
                className={classes.question}
                style={{ color: '#000000', wordBreak: 'break-word' }}
              >
                {parse(question?.question)}
              </Typography>
              {question?.choices?.map((choice, index) => {
                return (
                  <Choice
                    key={index}
                    choice={choice}
                    questionTypeFromParent={questionTypeFromParent}
                  />
                )
              })}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {isWriteAllowed && (
        <Grid item xs={2} sm={1} md={1}>
          {editQuestion === questionIndex || addQuestion ? (
            <></>
          ) : (
            <>
              <Button
                className={classes.editButton}
                variant="outlined"
                onClick={handleChange}
                disabled={isQuizStarted(quizStartTime)} // disabled in closed quiz
              >
                {/* Edit */}
                <EditOutlinedIcon />
              </Button>
              {(!isQuizStarted(quizStartTime) || !isPublished) && (
                <>
                  {deleteQuestion?.isLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '12px',
                      }}
                    >
                      <Loader size={30} />
                    </div>
                  ) : (
                    <Button
                      disabled={isQuizStarted(quizStartTime)} // disabled in closed quiz
                      className={classes.deleteButton}
                      variant="outlined"
                      onClick={() => handleDelete()}
                    >
                      {/* Delete */}
                      <DeleteOutlineOutlinedIcon />
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default McqQuestionTile
