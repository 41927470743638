import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: '24px',
    backgroundColor: 'white',
    borderRadius: '8px',
  },
  testCaseHeadingAndMarksContainer: {
    height: '22px',
    marginBottom: '16px',
  },
  testCaseHeading: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme?.palette?.text?.primary,
  },
  errorText: {
    fontSize: '12px',
    color: theme?.palette?.errorColor?.main,
  },

  hiddenText: {
    fontSize: '12px',
    color: theme?.palette?.text?.secondary,
  },
  marksHeading: {
    height: '40px',
    lineHeight: '40px',
    marginRight: '14px',
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  marksValue: {
    color: theme?.palette?.text?.primary,
  },
  marksLabel: {
    color: theme?.palette?.text?.secondary,
    opacity: 0.8,
    fontSize: '12px',
  },
  inputLabel: {
    color: theme?.palette?.text?.primary,
    height: '16px',
  },
  inputValue: {
    borderRadius: '4px',
    background: theme?.palette?.background?.grey,
    height: '40px',
    padding: '10px 15px',
    marginTop: '8px',
    color: theme?.palette?.text?.primary,
  },
  serialNumber: {
    display: 'flex',
    flex: '5%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
    '@media only screen and (max-width:1024px)': {
      display: 'none',
    },
  },
  testCaseWrapper: {
    flex: '45%',
  },
  testCaseDeleteIconWrapper: {
    marginLeft: 'auto',
  },
  testCaseDeleteIconButton: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: '8px',
  },
  testCaseDeleteImg: {
    cursor: 'pointer',
  },
  editTestCaseContainer: {
    display: 'flex',
    flexDirection: 'row',
    // paddingLeft: '24px',
    // paddingRight: '24px',
    '@media only screen and (max-width:1024px)': {
      flexDirection: 'column',
    },
  },
  marksContainer: {
    flex: '15%',
    marginRight: '8px',
    '@media only screen and (max-width:1024px)': {
      marginRight: 0,
    },
  },
  displayForMobile: {
    display: 'none',
    '@media only screen and (max-width:1024px)': {
      display: 'block',
      marginTop: '16px',
    },
  },
  inputField: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    backgroundColor: 'white',
    marginRight: '16px',
    '@media only screen and (max-width:1024px)': {
      marginRight: 0,
    },
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  testcaseHiddenText: {
    fontSize: '1rem',
    color: '#808080',
  },
}))
export { useStyles }
