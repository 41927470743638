import React from 'react'
import parse from 'html-react-parser'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import GreenTickCircleOutlinedIcon from '../../icons/GreenTickCircleOutlinedIcon'
import GrayThreeDotsCircleOutlinedIcon from '../../icons/GrayThreeDotsCircleOutlinedIcon'
import { useStyles } from './EssayResultRowItemMobileStyles'

function EssayResultRowItemMobile({ question }) {
  const classes = useStyles()
  const isAttempted = question?.result?.userResponse.length > 0
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container style={{ width: '100%' }}>
        <Grid container className={classes.iconWrapper}>
          <Grid item className={classes.icon}>
            {isAttempted ? (
              <GreenTickCircleOutlinedIcon />
            ) : (
              <GrayThreeDotsCircleOutlinedIcon />
            )}
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.contentWrapper}>
          <Grid item className={classes.questionWrapper}>
            <Grid item className={classes.question}>
              {parse(question?.question)}
            </Grid>
          </Grid>
          <Grid container className={classes.resultWrapper}>
            <Typography>{question?.result?.userResponse}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.marksContainer}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.marksWrapper}
        >
          <Typography data-testid="maximumMarksMobile-testId">
            <span className={classes.marks}>N/A</span> Marks
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EssayResultRowItemMobile
