import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Avatar, Typography } from '@material-ui/core'
import { useStyles } from './InfoCardStyles'

const InfoCard = ({ quizTitleType, quizCount, avatar }) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      <Grid
        item
        container
        xs={8}
        sm={6}
        direction="column"
        className={classes.quizDetailsContainer}
      >
        <Grid container>
          <Grid container item xs={12}>
            <Typography className={classes.quizTitle}>
              {quizTitleType}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography className={classes.quizCount}>{quizCount}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        sm={6}
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Avatar className={classes.avatar} src={avatar} />
      </Grid>
    </Grid>
  )
}

export default InfoCard
