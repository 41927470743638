import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  body: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  paymentResponseMutationLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '50px',
  },
  responseIconAndTextWrapper: {
    width: '100%',
    paddingBottom: '25%',
    '@media only screen and (min-width:426px)': {
      paddingBottom: '15%',
    },
    '@media only screen and (min-width:769px)': {
      paddingBottom: '10%',
    },
  },
  paymentResponseIconWrapper: {
    width: '75%',

    '@media only screen and (min-width:426px)': {
      width: '55%',
    },
    '@media only screen and (min-width:769px)': {
      width: '43%',
    },
    '@media only screen and (min-width:1025px)': {
      width: '33%',
    },
  },
  textWrapper: {
    width: '75%',

    '@media only screen and (min-width:426px)': {
      width: '55%',
    },
    '@media only screen and (min-width:769px)': {
      width: '45%',
    },
    '@media only screen and (min-width:1025px)': {
      width: '35%',
    },
  },
  paymentInfo: {
    paddingTop: '40px',
    textAlign: 'center',
    '@media only screen and (max-width:397px)': {
      fontSize: '24px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '20px',
    },
  },
  paymentVerificationMessage: {
    fontWeight: 700,
    fontSize: '30px',
  },
  paymentRedirectMessage: {
    fontWeight: 400,
    fontSize: '12px',
    paddingTop: '50px',
  },
  paymentMessage: {
    fontWeight: 700,
    fontSize: '30px',
    '@media only screen and (max-width:398px)': {
      fontSize: '22px',
    },
    '@media only screen and (min-width:399px) and (max-width:425px)': {
      fontSize: '22px',
    },
    '@media only screen and (min-width:425px) and (max-width:543px)': {
      fontSize: '22px',
    },
  },
  paymentEmailMessage: {
    fontWeight: 400,
    fontSize: '16px',
    paddingTop: '10px',
  },
}))
export { useStyles }
