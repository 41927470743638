import { createFetcher } from '../../Fetcher/Fetcher'
import { ADMIN_ENDPOINT, MOCK_BASE_URL, PAYMENT_ENDPOINT } from '../../constant'

/**
 * For billing response.
 * @Applications
 */
export default class Payment {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Payment(
        MOCK_BASE_URL,
        PAYMENT_ENDPOINT,
        ADMIN_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(baseUrl, paymentEndPoint, adminEndPoint, fetcherObj) {
    this.base_url = baseUrl
    this.paymentEndPoint = paymentEndPoint
    this.adminEndPoint = adminEndPoint
    this.fetcher = fetcherObj
  }
  /**
   * this function is to get all applications
   * @function postPaymentResponse
   ** @param {reqParam} default parameter
   */

  async postPaymentResponse(reqData, resourceId, transactionID) {
    const url = `${this.base_url + this.adminEndPoint}/${
      resourceId + this.paymentEndPoint
    }/${transactionID}/response`
    const response = await this.fetcher.postData(url, reqData)
    return response?.data?.data
  }
}
