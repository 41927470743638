/* eslint-disable react/jsx-no-literals */
import React from 'react'
import {
  Button,
  DialogTitle,
  DialogActions,
  Dialog,
  Typography,
} from '@material-ui/core'
import { useStyles } from './CommonStyles'

const ConfirmBox = (props) => {
  const {
    isOpenDialog,
    handleClose,
    styles,
    handleClick,
    boxTitle,
    boxDescription,
    buttonName,
  } = props
  const defaultStyle = useStyles()
  return (
    <Dialog
      open={isOpenDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={styles?.titleDialog}>
        {boxTitle && (
          <Typography className={styles?.dialogTitle}>{boxTitle}</Typography>
        )}
        {boxDescription || 'Are you sure you want to delete?'}
      </DialogTitle>
      <DialogActions
        className={
          styles.dialogActions
            ? styles.dialogActions
            : defaultStyle.dialogActions
        }
      >
        <Button
          onClick={handleClose}
          className={
            styles.dialogCancelButton
              ? styles.dialogCancelButton
              : defaultStyle.dialogCancelButton
          }
        >
          Cancel
        </Button>
        <Button
          onClick={handleClick}
          className={
            styles.dialogDeleteButton
              ? styles.dialogDeleteButton
              : defaultStyle.dialogDeleteButton
          }
          autoFocus
        >
          {buttonName || 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmBox
