import React from 'react'

function Proctoring() {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      //   xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Proctoring</title>
      <g
        id="Admin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-8">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="40"
            height="40"
          />
          <g id="Group-2" transform="translate(5.000000, 0.000000)">
            <path
              d="M15,0 C23.2842712,0 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 Z M15,2 C7.82029825,2 2,7.82029825 2,15 C2,22.1797017 7.82029825,28 15,28 C22.1797017,28 28,22.1797017 28,15 C28,7.82029825 22.1797017,2 15,2 Z"
              id="Oval"
              fill="#00B7A4"
              fillRule="nonzero"
            />
            <path
              d="M23,34 C25.209139,34 27,35.790861 27,38 L27,40 L3,40 L3,38 C3,35.790861 4.790861,34 7,34 L23,34 Z M23,36 L7,36 C5.9456382,36 5.08183488,36.8158778 5.00548574,37.8507377 L5,38 L25,38 C25,36.9456382 24.1841222,36.0818349 23.1492623,36.0054857 L23,36 Z"
              id="Rectangle"
              fill="#00B7A4"
              fillRule="nonzero"
            />
            <path
              d="M15,5 C20.5228475,5 25,9.4771525 25,15 C25,20.5228475 20.5228475,25 15,25 C9.4771525,25 5,20.5228475 5,15 C5,9.4771525 9.4771525,5 15,5 Z M15,7 C10.581722,7 7,10.581722 7,15 C7,19.418278 10.581722,23 15,23 C19.418278,23 23,19.418278 23,15 C23,10.581722 19.418278,7 15,7 Z"
              id="Oval"
              fill="#00B7A4"
              fillRule="nonzero"
            />
            <circle id="Oval" fill="#000000" cx="15" cy="15" r="3" />
            <polygon
              id="Path-8"
              fill="#00B7A4"
              fillRule="nonzero"
              points="11 28 11 34 9 34 9 28"
            />
            <polygon
              id="Path-8"
              fill="#00B7A4"
              fillRule="nonzero"
              points="21 28 21 34 19 34 19 28"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Proctoring
