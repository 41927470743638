import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { styles } from './billingPaginationStyles'

function BillingPagination({
  totalBillingCount,
  handleNext,
  handlePrevious,
  offset,
  billingPagesArray,
  billingPageLimit,
  setOffset,
}) {
  const classes = styles({ offset, billingPagesArray, billingPageLimit })
  return (
    <Grid className={classes.paginationMainGrid}>
      {totalBillingCount > 0 && (
        <IconButton onClick={handlePrevious} disabled={offset === 0}>
          <ArrowBackIosIcon className={classes.ArrowBackIosIcon} />
        </IconButton>
      )}
      <Grid className={classes.paginationDottedButtons}>
        {billingPagesArray?.map((_, index) => {
          return (
            <Grid
              key={index}
              className={classes.paginationNumberedButtonsChild}
            >
              <Grid
                key={index}
                className={classes.paginationStyle}
                style={{
                  color:
                    index === offset / billingPageLimit ? '#00B7A4' : '#262A4C',
                }}
                onClick={() => setOffset(index * billingPageLimit)}
              >
                {index + 1}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      {totalBillingCount > 0 && (
        <IconButton
          onClick={handleNext}
          disabled={billingPagesArray.length - 1 === offset / billingPageLimit}
        >
          <ArrowForwardIosIcon
            className={classes.ArrowForwardIosIcon}
            disabled={billingPagesArray.length - 1 < 0}
          />
        </IconButton>
      )}
    </Grid>
  )
}

export default BillingPagination
