import React from 'react'

function RandomizeQuestionsIcon() {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      // xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Randomize questions</title>
      <g
        id="Admin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-3">
          <rect
            id="Rectangle-Copy-2"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="40"
            height="40"
          />
          <path
            d="M10,0 C11.1045695,0 12,0.8954305 12,2 L12,10 C12,11.1045695 11.1045695,12 10,12 L2,12 C0.8954305,12 0,11.1045695 0,10 L0,2 C0,0.8954305 0.8954305,0 2,0 L10,0 Z M10,2 L2,2 L2,10 L10,10 L10,2 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M24,0 C25.1045695,0 26,0.8954305 26,2 L26,10 C26,11.1045695 25.1045695,12 24,12 L16,12 C14.8954305,12 14,11.1045695 14,10 L14,2 C14,0.8954305 14.8954305,0 16,0 L24,0 Z M24,2 L16,2 L16,10 L24,10 L24,2 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M38,0 C39.1045695,0 40,0.8954305 40,2 L40,10 C40,11.1045695 39.1045695,12 38,12 L30,12 C28.8954305,12 28,11.1045695 28,10 L28,2 C28,0.8954305 28.8954305,0 30,0 L38,0 Z M38,2 L30,2 L30,10 L38,10 L38,2 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M10,14 C11.1045695,14 12,14.8954305 12,16 L12,24 C12,25.1045695 11.1045695,26 10,26 L2,26 C0.8954305,26 0,25.1045695 0,24 L0,16 C0,14.8954305 0.8954305,14 2,14 L10,14 Z M10,16 L2,16 L2,24 L10,24 L10,16 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M24,14 C25.1045695,14 26,14.8954305 26,16 L26,24 C26,25.1045695 25.1045695,26 24,26 L16,26 C14.8954305,26 14,25.1045695 14,24 L14,16 C14,14.8954305 14.8954305,14 16,14 L24,14 Z M24,16 L16,16 L16,24 L24,24 L24,16 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M38,14 C39.1045695,14 40,14.8954305 40,16 L40,24 C40,25.1045695 39.1045695,26 38,26 L30,26 C28.8954305,26 28,25.1045695 28,24 L28,16 C28,14.8954305 28.8954305,14 30,14 L38,14 Z M38,16 L30,16 L30,24 L38,24 L38,16 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M10,28 C11.1045695,28 12,28.8954305 12,30 L12,38 C12,39.1045695 11.1045695,40 10,40 L2,40 C0.8954305,40 0,39.1045695 0,38 L0,30 C0,28.8954305 0.8954305,28 2,28 L10,28 Z M10,30 L2,30 L2,38 L10,38 L10,30 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M24,28 C25.1045695,28 26,28.8954305 26,30 L26,38 C26,39.1045695 25.1045695,40 24,40 L16,40 C14.8954305,40 14,39.1045695 14,38 L14,30 C14,28.8954305 14.8954305,28 16,28 L24,28 Z M24,30 L16,30 L16,38 L24,38 L24,30 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M38,28 C39.1045695,28 40,28.8954305 40,30 L40,38 C40,39.1045695 39.1045695,40 38,40 L30,40 C28.8954305,40 28,39.1045695 28,38 L28,30 C28,28.8954305 28.8954305,28 30,28 L38,28 Z M38,30 L30,30 L30,38 L38,38 L38,30 Z"
            id="Rectangle"
            fill="#00B7A4"
            fillRule="nonzero"
          />
          <path
            d="M20.736,7.288 L20.736,6.984 C20.7573333,6.824 20.8093333,6.69066667 20.892,6.584 C20.9746667,6.47733333 21.0693333,6.38266667 21.176,6.3 C21.2826667,6.21733333 21.396,6.13466667 21.516,6.052 C21.636,5.96933333 21.7453333,5.86933333 21.844,5.752 C21.9426667,5.63466667 22.0253333,5.49333333 22.092,5.328 C22.1586667,5.16266667 22.192,4.952 22.192,4.696 C22.192,4.54133333 22.1586667,4.37466667 22.092,4.196 C22.0253333,4.01733333 21.9146667,3.85066667 21.76,3.696 C21.6053333,3.54133333 21.4013333,3.412 21.148,3.308 C20.8946667,3.204 20.5786667,3.152 20.2,3.152 C19.9066667,3.152 19.6413333,3.20133333 19.404,3.3 C19.1666667,3.39866667 18.964,3.536 18.796,3.712 C18.628,3.888 18.4973333,4.096 18.404,4.336 C18.3106667,4.576 18.2613333,4.84 18.256,5.128 L19.432,5.128 C19.432,4.984 19.448,4.84933333 19.48,4.724 C19.512,4.59866667 19.5613333,4.48933333 19.628,4.396 C19.6946667,4.30266667 19.78,4.228 19.884,4.172 C19.988,4.116 20.112,4.088 20.256,4.088 C20.4693333,4.088 20.636,4.14666667 20.756,4.264 C20.876,4.38133333 20.936,4.56266667 20.936,4.808 C20.9413333,4.952 20.916,5.072 20.86,5.168 C20.804,5.264 20.7306667,5.352 20.64,5.432 C20.5493333,5.512 20.4506667,5.592 20.344,5.672 C20.2373333,5.752 20.136,5.84666667 20.04,5.956 C19.944,6.06533333 19.86,6.19733333 19.788,6.352 C19.716,6.50666667 19.672,6.69866667 19.656,6.928 L19.656,7.288 L20.736,7.288 Z M20.8,9 L20.8,7.768 L19.544,7.768 L19.544,9 L20.8,9 Z"
            id="?"
            fill="#2A2A2A"
            fillRule="nonzero"
          />
          <path
            d="M20.736,35.288 L20.736,34.984 C20.7573333,34.824 20.8093333,34.6906667 20.892,34.584 C20.9746667,34.4773333 21.0693333,34.3826667 21.176,34.3 C21.2826667,34.2173333 21.396,34.1346667 21.516,34.052 C21.636,33.9693333 21.7453333,33.8693333 21.844,33.752 C21.9426667,33.6346667 22.0253333,33.4933333 22.092,33.328 C22.1586667,33.1626667 22.192,32.952 22.192,32.696 C22.192,32.5413333 22.1586667,32.3746667 22.092,32.196 C22.0253333,32.0173333 21.9146667,31.8506667 21.76,31.696 C21.6053333,31.5413333 21.4013333,31.412 21.148,31.308 C20.8946667,31.204 20.5786667,31.152 20.2,31.152 C19.9066667,31.152 19.6413333,31.2013333 19.404,31.3 C19.1666667,31.3986667 18.964,31.536 18.796,31.712 C18.628,31.888 18.4973333,32.096 18.404,32.336 C18.3106667,32.576 18.2613333,32.84 18.256,33.128 L19.432,33.128 C19.432,32.984 19.448,32.8493333 19.48,32.724 C19.512,32.5986667 19.5613333,32.4893333 19.628,32.396 C19.6946667,32.3026667 19.78,32.228 19.884,32.172 C19.988,32.116 20.112,32.088 20.256,32.088 C20.4693333,32.088 20.636,32.1466667 20.756,32.264 C20.876,32.3813333 20.936,32.5626667 20.936,32.808 C20.9413333,32.952 20.916,33.072 20.86,33.168 C20.804,33.264 20.7306667,33.352 20.64,33.432 C20.5493333,33.512 20.4506667,33.592 20.344,33.672 C20.2373333,33.752 20.136,33.8466667 20.04,33.956 C19.944,34.0653333 19.86,34.1973333 19.788,34.352 C19.716,34.5066667 19.672,34.6986667 19.656,34.928 L19.656,35.288 L20.736,35.288 Z M20.8,37 L20.8,35.768 L19.544,35.768 L19.544,37 L20.8,37 Z"
            id="?"
            fill="#2A2A2A"
            fillRule="nonzero"
          />
          <path
            d="M6.736,21.288 L6.736,20.984 C6.75733333,20.824 6.80933333,20.6906667 6.892,20.584 C6.97466667,20.4773333 7.06933333,20.3826667 7.176,20.3 C7.28266667,20.2173333 7.396,20.1346667 7.516,20.052 C7.636,19.9693333 7.74533333,19.8693333 7.844,19.752 C7.94266667,19.6346667 8.02533333,19.4933333 8.092,19.328 C8.15866667,19.1626667 8.192,18.952 8.192,18.696 C8.192,18.5413333 8.15866667,18.3746667 8.092,18.196 C8.02533333,18.0173333 7.91466667,17.8506667 7.76,17.696 C7.60533333,17.5413333 7.40133333,17.412 7.148,17.308 C6.89466667,17.204 6.57866667,17.152 6.2,17.152 C5.90666667,17.152 5.64133333,17.2013333 5.404,17.3 C5.16666667,17.3986667 4.964,17.536 4.796,17.712 C4.628,17.888 4.49733333,18.096 4.404,18.336 C4.31066667,18.576 4.26133333,18.84 4.256,19.128 L5.432,19.128 C5.432,18.984 5.448,18.8493333 5.48,18.724 C5.512,18.5986667 5.56133333,18.4893333 5.628,18.396 C5.69466667,18.3026667 5.78,18.228 5.884,18.172 C5.988,18.116 6.112,18.088 6.256,18.088 C6.46933333,18.088 6.636,18.1466667 6.756,18.264 C6.876,18.3813333 6.936,18.5626667 6.936,18.808 C6.94133333,18.952 6.916,19.072 6.86,19.168 C6.804,19.264 6.73066667,19.352 6.64,19.432 C6.54933333,19.512 6.45066667,19.592 6.344,19.672 C6.23733333,19.752 6.136,19.8466667 6.04,19.956 C5.944,20.0653333 5.86,20.1973333 5.788,20.352 C5.716,20.5066667 5.672,20.6986667 5.656,20.928 L5.656,21.288 L6.736,21.288 Z M6.8,23 L6.8,21.768 L5.544,21.768 L5.544,23 L6.8,23 Z"
            id="?"
            fill="#2A2A2A"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  )
}

export default RandomizeQuestionsIcon
