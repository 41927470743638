import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ErrorHandler from './components/ErrorBoundaryFallBack/ErrorHandler'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
// import { BUGSNAG_KEY } from './constant'

Bugsnag.start({
  apiKey: 'f0ba7ea46c43619e65dd07e030899cba',
  plugins: [new BugsnagPluginReact()],
  // otherOptions: value
})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorHandler}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
