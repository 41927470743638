import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  liveCandidatesContainer: {
    width: '100%',
    height: 'calc(100%-46px)',
    color: '#262A4C',
    backgroundColor: '#f3f7f7',
    marginTop: '46px',
    margin: 'auto',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '96px',
    margin: 'auto',
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
    '& > p ': {
      width: '90%',
      fontSize: '30px',
      height: '36px',
      [theme.breakpoints.up('sm')]: {
        width: '80%',
      },
      color: '#262B4B',
      '& > span': {
        color: '#00B8A4',
      },
    },
  },
  liveCandidatesBody: {
    width: '90%',
    margin: 'auto',
    marginTop: '40px',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
  quizDetail: {
    width: '100%',
    lineHeight: 'normal',
  },
  quizTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '0rem',
  },
  quizDuration: {
    margin: '5px 0px',
  },
  candidateCountWrapper: {
    borderTop: '2px solid #E6E6E6',
    borderBottom: '2px solid #E6E6E6',
    display: 'flex',
    margin: '12px 0px',
    padding: '4px 0px',
    alignItems: 'center',
    '&>button': {
      marginLeft: 'auto',
    },
  },
  candidatesCount: {
    padding: '4px 0px 4px 12px',
    display: 'flex',
    margin: '0rem',
    alignItems: 'center',
  },
  candidateCard: {
    height: '54px',
    backgroundColor: '#FFFFFF',
    paddingLeft: '12px',
    borderRadius: '4px',
    marginTop: '8px',
  },
  personIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  candidateNames: {
    fontWeight: '500',
    fontSize: '14px',
    overflowX: 'hidden',
  },
  watchBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '6px',
  },
  watchBtn: {
    height: 'fit-content',
    fontSize: '12px',
    padding: '5px 15px',
    color: '#00B8A4',
    borderRadius: '4px',
    border: '1px solid #00B8A4 ',
    outline: 'none',
    background: '#FFFFFF',
    [theme.breakpoints.up('sm')]: {
      height: '30px',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  disabledBtn: {
    height: 'fit-content',
    fontSize: '12px',
    padding: '5px 15px',
    color: '#00B8A4',
    borderRadius: '4px',
    border: '1px solid #E6E6E6',
    outline: 'none',
    background: '#FFFFFF',
    [theme.breakpoints.up('sm')]: {
      height: '30px',
    },
  },
  styledPersonIcon: {
    width: '24px',
    height: '24px',
    border: '1px solid #f3f7f7',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    padding: '2px',
    marginRight: '1rem',
  },
  personIcon: {
    width: '16px',
    height: '16px',
  },
  liveCandidatesList: { marginTop: '10px' },
  loader: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  candidateNameHeader: {
    fontSize: '12px',
    paddingLeft: '52px',
  },
  link: {
    textDecoration: 'none',
  },
}))

export { styles }
