import { useMutation } from 'react-query'
import Login from '../../Repository/Login/Login'

const loginReq = Login.getInstance()

/**
 * this function is to Query get All application
 * @function useLoginPostQuery
 ** @param {options} default parameter
 */
export function useLoginPostQuery(options) {
  return useMutation((args) => loginReq.postLogin(args), {
    ...options,
  })
}

export function useLogoutPostQuery(options) {
  return useMutation((args) => loginReq.postLogout(args), {
    ...options,
  })
}
