import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import FormControl from '@material-ui/core/FormControl'
import AddIcon from '@material-ui/icons/Add'
import QuillTextEditor from '../TextEditorToolbar/TextEditor'
import UpdateQuestionsInQuizesModal from '../UpdateQuestionInQuizesModal/UpdateQuestionInQuizesModal'
import { useStyles } from './AddFillInTheBlankStyles'
import Loader from '../Loader/Loader'
import DeleteIcon from '../../icons/Delete.svg'

const EditFillInTheBlank = ({
  addQuestion,
  questions,
  questionIndex,
  localQuestion,
  errors,
  handleEdit,
  isQuizStarted,
  quizStartTime,
  isPublished,
  questionTypeFromParent,
  handleQuestionTypeChange,
  quizType,
  codingQuestionSwitch,
  textEditorQuestion,
  setTextEditorQuestion,
  editQuestionQuery,
  postQuestion,
  editQuestion,
  handleCancel,
  handleCancelAddTf,
  handleSave,
  showQuizesListModal,
  setShowQuizesListModal,
  affectedQuizes,
  updateQuestionInSelectedQuizzes,
  setSelectedQuizzes,
  selectedQuizzes,
  quizId,
  handleAddAnswer,
  handleRemoveAnswer,
  question,
  setLocalQuestion,
  setEditViewQuestion,
  editViewQuestion,
}) => {
  const classes = useStyles()

  const handleSaveButtonClick = async () => {
    if (affectedQuizes?.length > 1) {
      setShowQuizesListModal(true)
    } else handleSave()
  }
  useEffect(() => {
    if (addQuestion && Object.keys(editViewQuestion || {})?.length > 0) {
      setLocalQuestion(editViewQuestion)
    } else if (!Number.isNaN(editQuestion)) {
      setLocalQuestion(localQuestion)
    }
  }, [])

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} sm={12} md={11} className={classes.editQuestion}>
        <Paper variant="outlined" className={classes.editPaper}>
          {/* Header */}
          <Grid container xs={12} style={{ marginBottom: '16px' }}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ alignItems: 'end', display: 'flex' }}
            >
              <Typography variant="h6" className={classes.questionIndex}>
                Question{' '}
                {addQuestion ? questions?.length + 1 : questionIndex + 1}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              container
              className={classes.marksType}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                id="filled-number"
                value={localQuestion?.marks}
                variant="outlined"
                autoComplete="off"
                size="small"
                error={
                  errors?.emptyMarks ||
                  errors?.marksNotValid ||
                  errors?.marksLessThanZero
                }
                className={classes.mcqMarksContainer}
                inputProps={{
                  style: {
                    fontSize: 16,
                    color: '#262B4B',
                    textAlign: 'center',
                    height: '32px',
                    width: '40px',
                  },
                }}
                onChange={(event) => handleEdit(event, 'marks', 0)}
                disabled={isQuizStarted(quizStartTime) && isPublished}
                label="Marks"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                data-testid="render-text-field"
              />
              {addQuestion && (
                <FormControl
                  variant="outlined"
                  style={{ marginLeft: '8px' }}
                  data-testid="question-type-drop-down"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Question Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={questionTypeFromParent}
                    onChange={handleQuestionTypeChange}
                    label="Question Type"
                  >
                    <MenuItem value="mcq">Multiple Choice Question</MenuItem>
                    <MenuItem
                      value="coding"
                      disabled={quizType === 'public' || !codingQuestionSwitch}
                    >
                      Coding Question
                    </MenuItem>
                    <MenuItem value="t/f">True/False Question</MenuItem>
                    <MenuItem value="multi-choice">
                      Multiple Answer Question
                    </MenuItem>
                    <MenuItem value="essay">Essay Question</MenuItem>
                    <MenuItem value="fillInBlanks">Fill In The Blanks</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid xs={12} style={{ marginBottom: '16px' }}>
            <QuillTextEditor
              textEditorQuestion={textEditorQuestion}
              setTextEditorQuestion={setTextEditorQuestion}
              className={localQuestion?.id?.toString()}
              isQuizStarted={isQuizStarted(quizStartTime) && isPublished}
              setLocalQuestion={setLocalQuestion}
              localQuestion={localQuestion}
              question={question}
              setEditViewQuestion={setEditViewQuestion}
              editViewQuestion={editViewQuestion}
              addQuestion={addQuestion}
            />

            <Grid
              container
              direction="row"
              className={classes.marksQuestionValidationContainer}
            >
              <Grid item sm={8} xs={12}>
                {errors?.emptyQuestion && (
                  <Grid>
                    <Typography
                      className={classes.validationText}
                      data-testid="render-empty-question-error"
                    >
                      Question should not be empty
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                className={classes.marksValidationContainer}
              >
                {errors?.emptyMarks && (
                  <Grid>
                    <Typography
                      className={classes.validationText}
                      data-testid="render-empty-marks-error"
                    >
                      Marks should not be empty
                    </Typography>
                  </Grid>
                )}
                {errors?.marksLessThanZero && (
                  <Grid>
                    <Typography
                      className={classes.validationText}
                      data-testid="render-empty-marksLessThanZero-error"
                    >
                      Marks should be more than 0
                    </Typography>
                  </Grid>
                )}
                {errors?.marksNotValid && (
                  <Grid data-testid="render-only-numeric-value">
                    <Typography
                      className={classes.validationText}
                      datat-testid="render"
                    >
                      Only numeric values are allowed
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {localQuestion?.acceptedAnswers?.map((value, index) => {
                    return (
                      <Grid item xs={12} key={`value_${index}`}>
                        <Paper
                          elevation={2}
                          variant="outlined"
                          className={classes.isCorrect}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 0px 8px 0px',
                            marginBottom: '8px',
                          }}
                        >
                          <Grid
                            container
                            style={{ justifyContent: 'space-around' }}
                          >
                            <Grid
                              item
                              xs={8}
                              sm={10}
                              md={10}
                              lg={10}
                              direction="row"
                              style={{ display: 'flex' }}
                              justifyContent="flex-start"
                            >
                              <InputBase
                                defaultValue={value?.value}
                                placeholder="Enter Correct Answer"
                                multiline
                                autoComplete="off"
                                className={classes.inputChoiceValue}
                                inputProps={{
                                  'aria-label': 'naked',
                                }}
                                size="small"
                                fullWidth
                                onChange={(event) =>
                                  handleEdit(event, 'answers', value?.id)
                                }
                                disabled={
                                  isQuizStarted(quizStartTime) && isPublished
                                }
                              />
                            </Grid>

                            {(!isQuizStarted(quizStartTime) ||
                              !isPublished) && (
                              <Grid
                                item
                                xs={2}
                                sm={1}
                                md={1}
                                lg={1}
                                style={{ display: 'flex' }}
                                alignItems="center"
                                justifyContent="flex-end"
                                onClick={() => {
                                  handleRemoveAnswer(value?.id, value?.value)
                                }}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt="delete"
                                  className={classes.closeChoice}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  className={classes.correctChoiceButtonContainer}
                >
                  {(!isQuizStarted(quizStartTime) || !isPublished) && (
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => handleAddAnswer('Add')}
                      className={classes.addCorrectChoiceButton}
                      disabled={isQuizStarted(quizStartTime) && isPublished}
                    >
                      Add Correct Choice
                    </Button>
                  )}
                </Grid>
                <UpdateQuestionsInQuizesModal
                  isLoading={false}
                  showQuizesListModal={showQuizesListModal}
                  setShowQuizesListModal={setShowQuizesListModal}
                  affectedQuizes={affectedQuizes}
                  handleSave={handleSave}
                  currentQuestion={localQuestion}
                  updateQuestionInSelectedQuizzes={
                    updateQuestionInSelectedQuizzes
                  }
                  setSelectedQuizzes={setSelectedQuizzes}
                  selectedQuizzes={selectedQuizzes}
                  quizId={quizId}
                />
              </Grid>
            </Grid>
          </Grid>

          <UpdateQuestionsInQuizesModal
            isLoading={false}
            showQuizesListModal={showQuizesListModal}
            setShowQuizesListModal={setShowQuizesListModal}
            affectedQuizes={affectedQuizes}
            handleSave={handleSave}
            currentQuestion={localQuestion}
            updateQuestionInSelectedQuizzes={updateQuestionInSelectedQuizzes}
            setSelectedQuizzes={setSelectedQuizzes}
            selectedQuizzes={selectedQuizzes}
            quizId={quizId}
          />
          {editQuestionQuery?.isLoading || postQuestion?.isLoading ? (
            <div className={classes.loader} data-testid="render-loader">
              <Loader size={30} />
            </div>
          ) : (
            <div className={classes.cancelAndSaveButtonWrapper}>
              <Button
                onClick={
                  !Number.isNaN(editQuestion) ? handleCancel : handleCancelAddTf
                }
                variant="filled"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveButtonClick}
                variant="filled"
                disabled={isQuizStarted(quizStartTime) && isPublished}
              >
                Save
              </Button>
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default EditFillInTheBlank
