import axios from 'axios'
import { faro } from '@grafana/faro-web-sdk'
import { MOCK_BASE_URL, REFRESH_ENDPOINT } from '../constant'
import { getRequestHeaders } from '../utils'

export class Fetcher {
  /**
   * this function is to get the refresh token
   * @function refreshToken
   */
  static instance

  static isRefreshing = false

  // constructor() {
  //   // Ensure the cleanup function is called when the instance is destroyed
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('beforeunload', () => {
  //       this.cleanup()
  //     })
  //   }
  // }

  static getInstance() {
    if (this.instance !== undefined) return this.instance
    this.instance = new Fetcher()
    return this.instance
  }

  refreshToken = async () => {
    if (this.isRefreshing) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.isRefreshing) {
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
    }
    this.isRefreshing = true
    const refreshToken = JSON.parse(localStorage.getItem('tokenStorage'))
    const reqData = {
      refreshToken: refreshToken?.refreshToken,
      tenantID: refreshToken?.tenants[0]?.id,
    }
    try {
      const response = await axios({
        method: 'post',
        url: `${MOCK_BASE_URL + REFRESH_ENDPOINT}`,
        data: reqData,
        headers: { 'Content-Type': 'application/json' },
      })
      const result = JSON.parse(localStorage?.getItem('tokenStorage'))

      result.accessToken = response?.data?.data?.accessToken

      localStorage.setItem('tokenStorage', JSON.stringify(result))
      this.isRefreshing = false
    } catch (error) {
      localStorage.clear()
      window.location.href = '/login'
      throw error
    }
    return null
  }

  /**
   * this function is to get data using fetch
   * @function getData
   ** @param {url} default parameter
   */
  getData = async (url, ct = 1) => {
    try {
      const response = await axios({
        method: 'get',
        url,
        headers: getRequestHeaders(),
      })
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        return this.refreshToken()
          .then(() => this.getData(url, ct + 1))
          .catch((err) => {
            if (faro && faro.api && faro.api.pushError) {
              faro.api.pushError(err)
            }
          })
      }
      if (faro && faro.api && faro.api.pushError) {
        faro.api.pushError(error)
      }
      // return error?.response?.data
      throw new Error(
        error?.response?.data?.errors?.[0]?.reason,
        'Error in put call'
      )
      // throw new Error(response?.status, 'error in axios')
    }
    // return undefined
  }

  /**
   * this function is to update data using fetch
   * @function putData
   ** @param {url,reqBody} default parameter
   */

  putData = async (url, reqData, ct = 1) => {
    try {
      const response = await axios({
        method: 'put',
        url,
        data: reqData,
        headers: getRequestHeaders(),
      })
      return response
    } catch (error) {
      if (error?.response?.status === 400) {
        throw new Error(
          error?.response?.data?.errors?.[0]?.reason,
          'error in axios'
        )
      } else if (error?.response?.status === 401 && ct === 1) {
        this.refreshToken()
          .then(() => this.putData(url, reqData, ct + 1))
          .catch((err) => {
            if (faro && faro.api && faro.api.pushError) {
              faro.api.pushError(err)
            }
          })
      }
      throw new Error(
        error?.response?.data?.errors?.[0]?.reason,
        'Error in put call'
      )
    }
  }
  /**
   * this function is to post data using fetch
   * @function postData
   ** @param {url,reqBody} default parameter
   */

  postData = async (url, reqData, ct = 1) => {
    try {
      const response = await axios({
        method: 'post',
        url,
        data: reqData,
        headers: getRequestHeaders(),
      })
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        this.refreshToken()
          .then(() => this.postData(url, reqData, ct + 1))
          .catch((err) => {
            if (faro && faro.api && faro.api.pushError) {
              faro.api.pushError(err)
            }
          })
      }
      throw new Error(error?.response?.status, 'error in axios')
    }
  }
  /**
   * this function is to patch data using fetch
   * @function patchData
   ** @param {url,reqBody} default parameter
   */

  patchData = async (url, reqData, ct = 1) => {
    try {
      const response = await axios({
        method: 'patch',
        url,
        data: reqData,
        headers: getRequestHeaders(),
      })
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        this.refreshToken()
          .then(() => this.patchData(url, reqData, ct + 1))
          .catch((err) => {
            if (faro && faro.api && faro.api.pushError) {
              faro.api.pushError(err)
            }
          })
      }
      throw new Error(error?.response?.status, 'error in axios')
    }
  }
  /**
   * this function is to delete data using fetch
   * @function deleteData
   ** @param {url} default parameter
   */

  deleteData = async (url, ct = 1) => {
    try {
      const response = await axios({
        method: 'delete',
        url,
        headers: getRequestHeaders(),
      })
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        this.refreshToken()
          .then(() => this.deleteData(url, ct + 1))
          .catch((err) => {
            if (faro && faro.api && faro.api.pushError) {
              faro.api.pushError(err)
            }
          })
      }
      throw new Error(error?.response?.status, 'error in axios')
    }
  }
}

export const createFetcher = () => Fetcher.getInstance()
