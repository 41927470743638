import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  titleDialog: {
    padding: '24px',
    pointerEvents: 'none',
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    paddingBottom: '4px',
  },
  dialogActions: {
    padding: '0px 24px 24px',
  },
  dialogCancelButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    // border: `1px solid ${theme?.palette?.cancelButton?.text}`,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },
  },
  dialogDeleteButton: {
    backgroundColor: theme?.palette?.dangerPrimaryButton?.main,
    color: theme?.palette?.dangerPrimaryButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.dangerPrimaryButton?.hover,
    },
  },
  deleteButton: {
    backgroundColor: theme?.palette?.background?.white,
    border: `1px solid ${theme?.palette?.dangerSecondaryButton?.main}`,
    color: theme?.palette?.dangerSecondaryButton?.main,
    lineHeight: '14px',
    padding: '10px 20px',
    marginRight: '10px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: theme?.palette?.dangerSecondaryButton?.hover,
    },
    [theme.breakpoints.down('xs')]: {
      width: '50px',
    },
  },
  cancelButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    // border: `1px solid ${theme?.palette?.cancelButton?.text}`,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },

    marginTop: '10px',
    marginRight: '10px',
    '@media only screen and (max-width:460px)': {
      marginRight: '4px',
    },
  },
}))
export { useStyles }
