import React, { useEffect } from 'react'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import QuillTextEditor from '../TextEditorToolbar/TextEditor'
import UpdateQuestionsInQuizesModal from '../UpdateQuestionInQuizesModal/UpdateQuestionInQuizesModal'

import { useStyles } from './AddEssayQuestionStyles'
import Loader from '../Loader/Loader'

const EditEssayTile = ({
  addQuestion,
  questions,
  questionIndex,
  localQuestion,
  errors,
  isQuizStarted,
  quizStartTime,
  isPublished,
  questionTypeFromParent,
  handleQuestionTypeChange,
  quizType,
  codingQuestionSwitch,
  textEditorQuestion,
  setTextEditorQuestion,
  editQuestionQuery,
  postQuestion,
  editQuestion,
  handleCancel,
  handleCancelAddTf,
  handleSave,
  showQuizesListModal,
  setShowQuizesListModal,
  affectedQuizes,
  updateQuestionInSelectedQuizzes,
  setSelectedQuizzes,
  selectedQuizzes,
  quizId,
  question,
  setLocalQuestion,
  setEditViewQuestion,
  editViewQuestion,
}) => {
  const classes = useStyles()

  const handleSaveButtonClick = async () => {
    if (affectedQuizes.length > 1) setShowQuizesListModal(true)
    else handleSave()
  }

  useEffect(() => {
    if (addQuestion && Object.keys(editViewQuestion || {})?.length > 0) {
      setLocalQuestion(editViewQuestion)
    } else if (!Number.isNaN(editQuestion)) {
      setLocalQuestion(localQuestion)
    }
  }, [])
  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} sm={12} md={11} className={classes.editQuestion}>
        <Paper variant="outlined" className={classes.editPaper}>
          {/* Header */}
          <Grid container xs={12} style={{ marginBottom: '16px' }}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ alignItems: 'end', display: 'flex' }}
            >
              <Typography variant="h6" className={classes.questionIndex}>
                Question{' '}
                {addQuestion ? questions?.length + 1 : questionIndex + 1}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              container
              className={classes.marksType}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {addQuestion && (
                <FormControl
                  variant="outlined"
                  style={{ marginLeft: '8px' }}
                  data-testid="question-type-drop-down"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Question Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={questionTypeFromParent}
                    onChange={handleQuestionTypeChange}
                    label="Question Type"
                  >
                    <MenuItem value="mcq">Multiple Choice Question</MenuItem>
                    <MenuItem
                      value="coding"
                      disabled={quizType === 'public' || !codingQuestionSwitch}
                    >
                      Coding Question
                    </MenuItem>
                    <MenuItem value="t/f">True/False Question</MenuItem>
                    <MenuItem value="multi-choice">
                      Multiple Answer Question
                    </MenuItem>
                    <MenuItem value="essay">Essay Question</MenuItem>
                    <MenuItem value="fillInBlanks">Fill In The Blanks</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid xs={12} style={{ marginBottom: '16px' }}>
            <QuillTextEditor
              textEditorQuestion={textEditorQuestion}
              setTextEditorQuestion={setTextEditorQuestion}
              className={localQuestion?.id?.toString()}
              isQuizStarted={isQuizStarted(quizStartTime) && isPublished}
              setLocalQuestion={setLocalQuestion}
              localQuestion={localQuestion}
              question={question}
              setEditViewQuestion={setEditViewQuestion}
              editViewQuestion={editViewQuestion}
              addQuestion={addQuestion}
            />

            <Grid
              container
              direction="row"
              className={classes.marksQuestionValidationContainer}
            >
              <Grid item sm={8} xs={12}>
                {errors?.emptyQuestion && (
                  <Grid>
                    <Typography
                      className={classes.validationText}
                      data-testid="render-empty-question-error"
                    >
                      Question should not be empty
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <UpdateQuestionsInQuizesModal
            isLoading={false}
            showQuizesListModal={showQuizesListModal}
            setShowQuizesListModal={setShowQuizesListModal}
            affectedQuizes={affectedQuizes}
            handleSave={handleSave}
            currentQuestion={localQuestion}
            updateQuestionInSelectedQuizzes={updateQuestionInSelectedQuizzes}
            setSelectedQuizzes={setSelectedQuizzes}
            selectedQuizzes={selectedQuizzes}
            quizId={quizId}
          />
          {editQuestionQuery?.isLoading || postQuestion?.isLoading ? (
            <div className={classes.loader} data-testid="render-loader">
              <Loader size={30} />
            </div>
          ) : (
            <div className={classes.cancelAndSaveButtonWrapper}>
              <Button
                onClick={
                  !Number.isNaN(editQuestion) ? handleCancel : handleCancelAddTf
                }
                variant="filled"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveButtonClick}
                variant="filled"
                disabled={isQuizStarted(quizStartTime) && isPublished}
              >
                Save
              </Button>
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default EditEssayTile
