/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Box,
  InputBase,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IOSSwitch from '../Switch/Switch'
import DeleteIcon from '../../icons/Delete.svg'
import { useStyles } from './EditTestCaseStyles'

function EditTestCase({
  testcase,
  index,
  handleChange,
  handleRemoveChoice,
  handleEdit,
  isQuizStarted,
  editQuestion,
  addQuestion,
  codingQuestionError,
  invalidTestCaseArguments,
  questionIndex,
}) {
  const [errors, setErrors] = useState({
    emptyMarks: false,
    marksNotValid: false,
    marksLessThanZero: false,
    emptyInput: false,
    emptyOutput: false,
  })
  const classes = useStyles()
  const marksValidation = (marksToBeValidated) => {
    if (!marksToBeValidated) {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyMarks: true }
      })
      setErrors((errorsPrev) => {
        return { ...errorsPrev, marksLessThanZero: false }
      })
      setErrors((errorsPrev) => {
        return { ...errorsPrev, marksNotValid: false }
      })
    } else {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, emptyMarks: false }
      })
      if (/^-?\d+\.?\d*$/.test(marksToBeValidated)) {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksNotValid: false }
        })
        if (marksToBeValidated <= 0) {
          setErrors((errorsPrev) => {
            return { ...errorsPrev, marksLessThanZero: true }
          })
        } else {
          setErrors((errorsPrev) => {
            return { ...errorsPrev, marksLessThanZero: false }
          })
        }
      } else {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksLessThanZero: false }
        })
        setErrors((errorsPrev) => {
          return { ...errorsPrev, marksNotValid: true }
        })
      }
    }
  }

  const handleTestCaseEdit = (event, setting) => {
    if (setting === 'input') {
      if (event.target.value.trim() === '') {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyInput: true }
        })
      } else {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyInput: false }
        })
      }
      handleEdit(event, 'input', index)
    } else if (setting === 'output') {
      if (event.target.value.trim() === '') {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyOutput: true }
        })
      } else {
        setErrors((errorsPrev) => {
          return { ...errorsPrev, emptyOutput: false }
        })
      }
      handleEdit(event, 'output', index)
    } else if (setting === 'marks') {
      marksValidation(event.target.value)
      handleEdit(event, 'marks', index)
    }
  }
  return (
    <Grid item xs={12} key={testcase?.id}>
      {editQuestion === questionIndex || addQuestion ? (
        <div className={classes.editTestCaseContainer}>
          <div
            className={classes.serialNumber}
            data-testid="serialNumber-testId"
          >
            {index + 1}
          </div>
          <div className={classes.testCaseWrapper}>
            <Typography className={classes.displayForMobile}>Input</Typography>
            <Box
              marginTop="8px"
              p={0.5}
              flexGrow={1}
              className={classes.inputField}
            >
              <InputBase
                defaultValue={testcase?.input}
                autoComplete="off"
                multiline
                placeholder="Enter Input"
                data-testid="inputField-testId"
                inputProps={{
                  'aria-label': 'naked',
                  style: {
                    fontSize: 14,
                    color: 'grey',
                    paddingLeft: '1%',
                  },
                }}
                size="small"
                fullWidth
                onChange={(event) => handleTestCaseEdit(event, 'input')}
                disabled={isQuizStarted}
              />
            </Box>
            <Typography style={{ fontSize: '12px', color: 'grey' }}>
              Please give comma seperated values incase of more than one value
            </Typography>
            {codingQuestionError?.invalidInputArgLength &&
              invalidTestCaseArguments?.length > 0 &&
              invalidTestCaseArguments?.includes(index) && (
                <Grid>
                  <Typography className={classes.errorText}>
                    Please match with the no of parameters given in the Input
                    Field Type
                  </Typography>
                </Grid>
              )}
          </div>
          <div className={classes.testCaseWrapper}>
            <Typography className={classes.displayForMobile}>Output</Typography>
            <Box
              marginTop="8px"
              p={0.5}
              flexGrow={1}
              className={classes.inputField}
            >
              <InputBase
                defaultValue={testcase?.output}
                autoComplete="off"
                multiline
                placeholder="Enter Output"
                inputProps={{
                  'aria-label': 'naked',
                  style: {
                    fontSize: 14,
                    color: 'grey',
                    paddingLeft: '1%',
                  },
                }}
                size="small"
                fullWidth
                onChange={(event) => handleTestCaseEdit(event, 'output')}
              />
            </Box>
          </div>
          <div className={classes.marksContainer}>
            <Typography className={classes.displayForMobile}>Marks</Typography>
            <Tooltip
              arrow
              title={
                testcase.type === 'sample'
                  ? 'Sample test case does not carry any marks'
                  : ''
              }
              classes={{
                arrow: classes.arrow,
                tooltip: classes.tooltip,
              }}
            >
              <Box
                marginTop="8px"
                p={0.5}
                flexGrow={1}
                className={classes.inputField}
              >
                <InputBase
                  value={testcase?.marks || 0}
                  autoComplete="off"
                  multiline
                  placeholder="Enter Mark"
                  inputProps={{
                    'aria-label': 'naked',
                    style: {
                      fontSize: 14,
                      color: 'grey',
                      paddingLeft: '1%',
                    },
                  }}
                  size="small"
                  fullWidth
                  error={
                    errors?.emptyMarks ||
                    errors?.marksNotValid ||
                    errors?.marksLessThanZero
                  }
                  onChange={(event) => handleTestCaseEdit(event, 'marks')}
                  disabled={testcase.type === 'sample'}
                />
              </Box>
            </Tooltip>
            {errors?.emptyMarks ? (
              <Grid className={classes.emptyMarksWrapper}>
                <Typography className={classes.errorText}>
                  Marks should not be empty
                </Typography>
              </Grid>
            ) : null}
            {errors?.marksLessThanZero ? (
              <Grid className={classes.marksLessThanZeroWrapper}>
                <Typography className={classes.errorText}>
                  Marks should be more than 0
                </Typography>
              </Grid>
            ) : null}
            {errors?.marksNotValid ? (
              <Grid className={classes.marksNotValidWrapper}>
                <Typography className={classes.errorText}>
                  Only numeric values are allowed
                </Typography>
              </Grid>
            ) : null}
          </div>
          <div style={{ flex: '15%' }}>
            <Typography className={classes.displayForMobile}>Hidden</Typography>
            <Box marginTop="8px" display="flex" direction="row">
              <div>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={testcase?.type === 'hidden'}
                      onChange={(event) => handleChange(event, testcase?.id)}
                      name="hidden"
                      data-testid="hiddenSwitch-testId"
                      inputProps={{
                        style: {
                          color: '#00B7A4',
                        },
                      }}
                    />
                  }
                />
              </div>
              <div className={classes.testCaseDeleteIconWrapper}>
                <IconButton
                  className={classes.testCaseDeleteIconButton}
                  onClick={() => {
                    handleRemoveChoice(testcase?.id)
                  }}
                >
                  <img
                    src={DeleteIcon}
                    alt="delete"
                    className={classes.testCaseDeleteImg}
                  />
                </IconButton>
              </div>
            </Box>
          </div>
        </div>
      ) : (
        <div className={classes.paper}>
          <Grid container className={classes.testCaseHeadingAndMarksContainer}>
            <Grid item xs={8}>
              <Typography
                className={classes.testCaseHeading}
                data-testid="testCaseNumber-testId"
              >
                Test Case {index + 1}{' '}
                {testcase?.type === 'hidden' && (
                  <span className={classes.testcaseHiddenText}>( Hidden )</span>
                )}
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              className={classes.outerColumn}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <span className={classes.marksValue}>
                {testcase?.marks ? testcase?.marks : 0}
              </span>
              &nbsp;
              <span className={classes.marksLabel}> Marks</span>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography className={classes.inputLabel}>Input</Typography>
              <div className={classes.inputValue}>
                {typeof testcase?.input === 'string'
                  ? testcase?.input
                  : testcase?.input?.length > 0
                  ? testcase?.input?.join(',')
                  : testcase?.input?.[0]}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.inputLabel}>Output</Typography>
              <div className={classes.inputValue}>{testcase?.output}</div>
            </Grid>
          </Grid>
        </div>
      )}
    </Grid>
  )
}

export default EditTestCase
