import { makeStyles } from '@material-ui/core'
import { isQuizStarted } from '../../utils'

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: '30px',
    '@media only screen and (max-width:815px)': {
      paddingTop: (props) => (props?.publish ? '40px' : ''),
    },
    '@media only screen and (max-width:665px)': {
      paddingTop: (props) => (props?.publish ? '100px' : '50px'),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: '20vw',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    height: '10vh',
  },
  inputLabel: {
    fontSize: '4vh',
    alignSelf: 'center',
  },

  titleDialog: {
    padding: '24px',
    pointerEvents: 'none',
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    paddingBottom: '4px',
  },
  dialogActions: {
    padding: '0px 24px 24px',
  },
  dialogCancelButton: {
    backgroundColor: theme?.palette?.cancelButton?.main,
    color: theme?.palette?.cancelButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.cancelButton?.hover,
      color: theme?.palette?.cancelButton?.text,
    },
  },
  dialogDeleteButton: {
    backgroundColor: theme?.palette?.dangerPrimaryButton?.main,
    color: theme?.palette?.dangerPrimaryButton?.text,
    '&:hover': {
      backgroundColor: theme?.palette?.dangerPrimaryButton?.hover,
    },
  },
  inputwrap: {
    width: '100%',
    fontSize: '16px',
    padding: '10px 16px',
    border: '1px solid #f0f0f0',
    boxSizing: 'border-box',
  },

  dialogPaper: {
    borderRadius: '20px',
  },
  cancelAndProceddButton: {
    borderRadius: '20px',
    height: '32px',
  },
  paper: {
    marginTop: '16px',
    padding: '24px',
    minHeight: '135px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
  },
  inputTitle: {
    fontSize: '20px',
    fontWeight: 500,
    height: 40,
  },
  validationText: {
    fontSize: '12px',
    color: theme?.palette?.inputValidation?.main,
  },
  validityInputStart: {
    height: '40px',
    width: '100%',
    border: (props) =>
      props?.startTime !== null &&
      !props?.publish &&
      !props?.isClosed &&
      !isQuizStarted(props?.startTime) &&
      props?.startTime < new Date()
        ? '1px solid red'
        : '1px solid #D4D5DB',
    borderRadius: '4px',
    paddingLeft: '10px',
    cursor: 'pointer',
  },
  validityInputEnd: {
    height: '40px',
    width: '100%',
    border: (props) =>
      props?.endTime !== null &&
      !props?.publish &&
      !isQuizStarted(props?.startTime) &&
      !props?.isClosed &&
      props?.endTime < new Date()
        ? '1px solid red'
        : '1px solid #D4D5DB',
    borderRadius: '4px',
    paddingLeft: '10px',
    cursor: 'pointer',
  },
  durationError: {
    paddingTop: '4px',
  },
  durationContainer: {
    '@media only screen and (max-width:1280px)': {},
  },
  settingTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    width: 'max-content',
    '@media only screen and (max-width:400px) ': {
      maxWidth: '98%',
    },
  },
  selectedTypeTile: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: '#ECFBF6',
    borderRadius: '8px',
    padding: '16px',
    cursor: 'pointer',
    minHeight: '100px',
  },
  selectedConfigTile: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: '#ECFBF6',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '190px',
  },
  settingTileTitle: {
    fontSize: '18px',
    fontWeight: 500,
    paddingRight: '16px',
  },
  settingTileSubtitle: {
    display: 'flex',
    paddingTop: '4px',
  },
  titleAndDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '0px',
  },
  iconWrapper: {
    paddingTop: '12px',
  },
  tileWrapper: {
    display: 'flex',

    '@media only screen  and (max-width:440px)': {
      flexDirection: 'column',
    },
  },
  CodingTileWrapper: {
    display: 'flex',
    '@media only screen  and (max-width:440px)': {
      flexDirection: 'column',
    },
    '@media only screen  and (min-width:600px)': {
      paddingLeft: '6px',
    },
  },
  settingQuizTypeTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    '@media only screen  and (max-width:600px)': {
      paddingLeft: '0px',
    },
  },
  settingSubTitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    '@media only screen and (min-width:599px)': {
      paddingLeft: '10px',
    },
  },
  settingConfigSubTitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    paddingRight: '16px',
    paddingTop: '8px',
  },
  gapBetweenIconAndSwitch: {
    flex: 1,
  },
  switchWrapper: {
    '@media only screen and (max-width:451px)': {
      paddingLeft: '2px',
    },
  },
  quizTypeContainer: {
    margin: 0,
    '@media only screen and (min-width:409px)': {
      height: '40px',
    },
  },
  radioButton: {
    border: `1px solid ${theme?.palette?.borders?.main}`,
    color: theme.palette.text.primary,
    backgroundColor: '#FFFFFF',
    padding: '3px 5px 3px 20px ',
    marginBottom: '5px',
    marginRight: '1%',
    height: '40px',
    fontSize: '14px',
    '&:hover': {
      border: `1px solid ${theme?.palette?.borders?.main}`,
      color: theme.palette.text.primary,
      backgroundColor: '#FFFFFF',
      fontWeight: 500,
    },
  },
  selectedRadioButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.primary,
    backgroundColor: '#ECFBF6',
    padding: '3px 5px 3px 20px',
    marginRight: '1%',
    marginBottom: '5px',
    fontWeight: 500,
    height: '40px',
    '&:hover': {
      border: `1px solid ${theme?.palette?.borders?.main}`,
      color: theme.palette.text.primary,
      backgroundColor: '#FFFFFF',
      fontWeight: 500,
    },
  },
  tile: {
    border: `1px solid ${theme?.palette?.borders?.main}`,
    borderRadius: '8px',
    padding: '16px',
    cursor: 'pointer',
    minHeight: '100px',
  },
  configTile: {
    border: `1px solid ${theme?.palette?.borders?.main}`,
    borderRadius: '8px',
    padding: '16px',
    minHeight: '190px',
  },
  inviteesWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  clearInviteesWrapper: {
    padding: '0px 0px 5px 0px',
    width: 'max-content',
  },
  clearInvitees: {
    color: 'black',
    background: 'none',
    padding: '0px 5px 0px 0px',
    '&:hover': {
      background: 'none',
    },
    textDecoration: 'underline',
  },
  uploadCSV: {
    color: theme?.palette?.secondary?.main,
    background: 'none',
    padding: '0px 5px 0px 0px',
    '&:hover': {
      background: 'none',
    },
  },
  inviteesGap: {
    height: '20px',
  },
  chipWrapper: {
    width: 'max-content',
    paddingBottom: '9px',
    paddingRight: '5px',
  },
  addingInfo: {
    fontSize: '12px',
    color: 'grey',
    marginTop: '4px',
    marginBottom: '16px',
  },
  quizTypeWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  quizTypeIconAndHeadingWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  inviteesSwitchWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '8px',
  },
  csvIconAndButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
  },
  csvButtonWrapper: { paddingLeft: '10px' },
  inviteesSwitch: { paddingRight: '10px', paddingLeft: '10px' },
  inviteesDiv: { flex: 1, height: '0px', width: '0px' },
  quizTypePublicButton: {
    paddingRight: '20px',
  },
  arrow: {
    '&::before': {
      backgroundColor: 'rgb(0 0 0 / 60%)',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(0 0 0 / 60%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  disabledTile: {
    backgroundColor: '#F0F0F0',
    border: `1px solid ${theme?.palette?.borders?.main}`,
    borderRadius: '8px',
    padding: '16px',
    minHeight: '190px',
  },
  disabledText: {
    color: '#A7A7A7',
  },
  hideCheckbox: {
    display: 'none',
  },
  credits: {
    position: 'absolute',
    right: '16',
    bottom: '16',
    fontWeight: 500,
    color: '#01B7A4',
  },
  disabledCredits: {
    position: 'absolute',
    right: '16',
    bottom: '16',
    fontWeight: 500,
  },
  leftSpace: {
    paddingLeft: '4px',
  },
  dropdownControl: {
    margin: theme.spacing(1),
    width: 'fit-content',
    height: 'fit-content',
  },
  selectValue: {
    padding: '10px 29px 10px 10px',
  },
}))
export { useStyle }
