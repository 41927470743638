import React from 'react'

export default function MuteVolumeIcon({ playerState }) {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Mute-volume-default"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-9">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <path
            d="M4.375,10.125 L4.375,14.875 L7.54166667,14.875 L11.5,18.8333333 L11.5,6.16666667 L7.54166667,10.125 L4.375,10.125 Z M15.0625,12.5 C15.0625,11.1027083 14.255,9.89541667 13.0833333,9.31354167 L13.0833333,15.6904167 C14.255,15.1045833 15.0625,13.8972917 15.0625,12.5 Z M13.0833333,5.55708333 L13.0833333,7.191875 C15.37125,7.87270833 17.0416667,9.99041667 17.0416667,12.5 C17.0416667,15.0095833 15.37125,17.1272917 13.0833333,17.808125 L13.0833333,19.4429167 C16.2539583,18.7225 18.625,15.8922917 18.625,12.5 C18.625,9.10770833 16.2539583,6.2775 13.0833333,5.55708333 Z"
            id="Shape"
            fill={playerState?.isMuted ? '#00B8A4' : '#262A4C'}
            fillRule="nonzero"
          />
          <line
            x1="4.375"
            y1="5.97025115"
            x2="19.7939345"
            y2="18.3333333"
            id="Path-12"
            stroke={playerState?.isMuted ? '#00B8A4' : '#262A4C'}
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  )
}
