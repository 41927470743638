import { Grid, Typography, Tooltip, Hidden } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import React, { useEffect, useState } from 'react'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import './style.css'
import { useStyle } from './EditQuizConfigurationsStyles'

const QuizType = ({
  publish,
  startTime,
  endTime,
  isClosed,
  handleChangeType,
  quizSettings,
  toolTipRender,
  isOngoing,
  codingQuestionSwitch,
  // isPublished,
}) => {
  const classes = useStyle({
    publish,
    startTime,
    endTime,
    isClosed,
  })
  const [publicTest, setPublicTest] = useState()
  const [privateTest, setPrivateTest] = useState()

  useEffect(() => {
    if (quizSettings?.type === 'private') {
      setPublicTest(false)
      setPrivateTest(true)
    }
    if (quizSettings?.type === 'public') {
      setPublicTest(true)
      setPrivateTest(false)
    }
  }, [quizSettings?.type])

  const handlePublicTile = () => {
    if (!isOngoing && !isClosed) {
      setPublicTest(true)
      setPrivateTest(false)
      handleChangeType('public')
    }
  }
  const handlePrivateTile = () => {
    if (!isOngoing && !isClosed) {
      setPrivateTest(true)
      setPublicTest(false)
      handleChangeType('private')
    }
  }
  const getTitle = () => {
    if (isOngoing || isClosed) return toolTipRender('private')
    if (quizSettings?.type === 'public')
      return 'Select private quiz type to enable coding questions and proctoring settings'
    return ''
  }
  return (
    <Grid container xs={12} spacing={2} style={{ marginTop: '8px' }}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Tooltip
          arrow
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          title={toolTipRender('public')}
        >
          <FormControl fullWidth>
            <FormGroup>
              <div
                role="presentation"
                className={publicTest ? classes.selectedTypeTile : classes.tile}
                style={{
                  backgroundColor:
                    (quizSettings?.type === 'private' && isOngoing) ||
                    (quizSettings?.type === 'private' && isClosed)
                      ? '#F0F0F0'
                      : ' ',
                  pointerEvents:
                    codingQuestionSwitch ||
                    quizSettings?.proctored ||
                    quizSettings?.invitees ||
                    quizSettings?.domain ||
                    quizSettings?.permissions?.length > 1
                      ? 'none'
                      : '',
                  cursor:
                    codingQuestionSwitch ||
                    quizSettings?.proctored ||
                    quizSettings?.domain ||
                    quizSettings?.permissions?.length > 1
                      ? 'not-Allowed'
                      : 'pointer',
                  opacity:
                    isOngoing ||
                    isClosed ||
                    codingQuestionSwitch ||
                    quizSettings?.proctored ||
                    quizSettings?.invitees ||
                    quizSettings?.domain ||
                    quizSettings?.permissions?.length > 1
                      ? 0.5
                      : 1,
                }}
                onClick={() => handlePublicTile()}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '16px',
                  }}
                >
                  <Typography className={classes.settingTileTitle}>
                    Public
                  </Typography>
                  {privateTest && <RadioButtonUncheckedIcon />}
                  {publicTest && (
                    <RadioButtonCheckedIcon style={{ color: '#01B7A4' }} />
                  )}
                  <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
                    <FormControlLabel
                      value="public"
                      className={classes.hideCheckbox}
                      control={
                        <Checkbox
                          checked={publicTest}
                          onChange={handlePublicTile}
                          name="public"
                        />
                      }
                    />
                  </Hidden>
                </div>
              </div>
            </FormGroup>
          </FormControl>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Tooltip
          arrow
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          title={getTitle()}
        >
          <FormControl fullWidth>
            <FormGroup>
              <div
                role="presentation"
                onClick={() => handlePrivateTile()}
                className={
                  privateTest ? classes.selectedTypeTile : classes.tile
                }
                style={{
                  backgroundColor:
                    (quizSettings?.type === 'public' && isOngoing) ||
                    (quizSettings?.type === 'public' && isClosed)
                      ? '#F0F0F0'
                      : ' ',
                  opacity: isOngoing || isClosed ? 0.5 : 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '16px',
                  }}
                >
                  <Typography
                    className={classes.settingTileTitle}
                    // style={{
                    //   color:
                    //     (quizSettings?.type === 'public' && isOngoing) ||
                    //     (quizSettings?.type === 'public' && publish) ||
                    //     (quizSettings?.type === 'public' && isClosed)
                    //       ? '#A0A0A0'
                    //       : ' ',
                    // }}
                  >
                    Private
                  </Typography>
                  {publicTest && <RadioButtonUncheckedIcon />}
                  {privateTest && (
                    <RadioButtonCheckedIcon style={{ color: '#01B7A4' }} />
                  )}
                  <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
                    <FormControlLabel
                      value="private"
                      // disabled={new Date() > startTime && isPublished}
                      className={classes.hideCheckbox}
                      control={
                        <Checkbox
                          checked={privateTest}
                          onChange={handlePrivateTile}
                          name="private"
                        />
                      }
                    />
                  </Hidden>
                </div>

                <Typography
                  className={
                    (quizSettings?.type === 'public' && isOngoing) ||
                    // (quizSettings?.type === 'public' && publish) ||
                    (quizSettings?.type === 'public' && isClosed)
                      ? classes.disabledCredits
                      : classes.credits
                  }
                >
                  1 credits
                </Typography>
              </div>
            </FormGroup>
          </FormControl>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default QuizType
