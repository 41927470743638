/* eslint-disable no-nested-ternary */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './ResultSampleTestCaseStyles'

function ResultSampleTestcase({ testcase, index, maximumMarks }) {
  const isTestcasePassed = testcase?.testCaseInfo?.status === 'PASSED'
  const testcaseStatus = isTestcasePassed ? 'passed' : 'failed'
  const currentMarks = isTestcasePassed ? maximumMarks : 0
  const classes = useStyles({ isTestcasePassed })
  return (
    <Grid container direction="column" data-testid="sample-test-case-wrapper">
      <Grid
        container
        alignItems="center"
        className={classes.testCaseContainer}
        data-testid="test-case-container"
      >
        <Typography
          className={classes.testCaseStatus}
          data-testid="test-index-testcase"
        >
          Testcase {index + 1} {testcaseStatus}
        </Typography>
        <Grid item className={classes.iconWrapper}>
          {isTestcasePassed ? (
            <DoneIcon className={classes.doneIcon} data-testid="render-icons" />
          ) : (
            <CloseIcon className={classes.closeIcon} />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        className={classes.testCaseDuration}
        data-testid="test-case-duration"
      >
        <Typography
          className={classes.testCaseDurationText}
          data-testid="render-duration"
        >
          Finished in {(testcase?.duration / 1000).toFixed(2)} seconds
        </Typography>
        {currentMarks >= 0 && maximumMarks ? (
          <Typography data-testid="positive-marks">
            <span className={classes.totalMarks}>
              {currentMarks}/{maximumMarks}
            </span>{' '}
            Marks
          </Typography>
        ) : (
          <Typography>
            <span className={classes.totalMarks}>0</span> Marks
          </Typography>
        )}
      </Grid>
      <Grid
        item
        className={classes.resultContainer}
        data-testid="result-container"
      >
        <Grid container direction="column" className={classes.resultWrapper}>
          <Grid container direction="column" className={classes.inputWrapper}>
            <Typography
              className={classes.inputHeading}
              data-testid="render-input-label"
            >
              Input:
            </Typography>
            <Typography
              className={classes.inputText}
              data-testid="render-input-value"
            >
              {typeof testcase?.input === 'string'
                ? testcase?.input
                : testcase?.input?.length > 0
                ? testcase?.input?.join(',')
                : testcase?.input?.[0]}
            </Typography>
          </Grid>
          <Grid container direction="column" className={classes.outputWrapper}>
            <Typography className={classes.outputHeading}>Output:</Typography>
            <Typography className={classes.outputText}>
              {testcase?.outputLines?.join('\n')}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.expectedOutputWrapper}
          >
            <Typography className={classes.expectedOutputHeading}>
              Expected Output:
            </Typography>
            <Typography className={classes.expectedOutputText}>
              {testcase?.expectedOutput}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResultSampleTestcase
