import { createFetcher } from '../../Fetcher/Fetcher'
import {
  ADMIN_ENDPOINT,
  MOCK_BASE_URL,
  STREAM_CREDENTIALS_ENDPOINT,
} from '../../constant'

export class CandidateStream {
  static getInstance() {
    if (!this.instance) {
      this.instance = new CandidateStream(
        MOCK_BASE_URL,
        STREAM_CREDENTIALS_ENDPOINT,
        ADMIN_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(baseUrl, streamCredentialsEndPoint, adminEndPoint, fetcherObj) {
    this.base_url = baseUrl
    this.streamCredentialsEndPoint = streamCredentialsEndPoint
    this.adminEndPoint = adminEndPoint
    this.fetcher = fetcherObj
  }

  async postAdminAnswer(reqId, reqUser, reqData) {
    const url = `${
      this.base_url + this.adminEndPoint + this.streamCredentialsEndPoint
    }?email=${reqUser}`
    const response = await this.fetcher.postData(url, reqData)
    return response
  }
}

export const createCandidateStream = () => CandidateStream.getInstance()
