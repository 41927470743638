import {
  MOCK_BASE_URL,
  ADMIN_ENDPOINT,
  BILLING_CONFIG_ENDPOINT,
} from '../../constant'
import { createFetcher } from '../../Fetcher/Fetcher'

/**
 * For BillingConfig
 * @Applications
 */
export class BillingConfig {
  static getInstance() {
    if (!this.instance) {
      this.instance = new BillingConfig(
        MOCK_BASE_URL,
        BILLING_CONFIG_ENDPOINT,
        ADMIN_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(baseUrl, endpoint, adminEndPoint, fetcherObj) {
    this.base_url = baseUrl
    this.endpoint = endpoint
    this.adminEndPoint = adminEndPoint
    this.fetcher = fetcherObj
  }
  /**
   * this function is to get all applications
   * @function getBillingConfig
   ** @param {reqParam} default parameter
   */

  async getBillingConfig() {
    const url = `${this.base_url + this.adminEndPoint + this.endpoint}`
    const response = await this.fetcher.getData(url)
    return response?.data?.data
  }
}
export const createBillingConfigs = () => BillingConfig.getInstance()
