import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { styles } from './ResultsPaginationStyles'

function ResultsPagination({
  totalResultsCount,
  handleNext,
  handlePrevious,
  resultsPageOffset,
  resultPagesArray,
  resultPageLimit,
  setResultPageOffset,
}) {
  const classes = styles({
    resultsPageOffset,
    resultPagesArray,
    resultPageLimit,
  })
  return (
    <Grid className={classes.paginationMainGrid}>
      {totalResultsCount > 0 && (
        <IconButton onClick={handlePrevious} disabled={resultsPageOffset === 0}>
          <ArrowBackIosIcon className={classes.ArrowBackIosIcon} />
        </IconButton>
      )}
      <Grid className={classes.paginationDottedButtons}>
        {resultPagesArray?.map((_, index) => {
          return (
            <Grid
              key={index}
              className={classes.paginationNumberedButtonsChild}
            >
              <Grid
                key={index}
                className={classes.paginationStyle}
                style={{
                  color:
                    index === resultsPageOffset / resultPageLimit
                      ? '#00B7A4'
                      : '#262A4C',
                }}
                onClick={() => setResultPageOffset(index * resultPageLimit)}
              >
                {index + 1}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      {totalResultsCount > 0 && (
        <IconButton
          onClick={handleNext}
          disabled={
            resultPagesArray.length - 1 === resultsPageOffset / resultPageLimit
          }
        >
          <ArrowForwardIosIcon
            className={classes.ArrowForwardIosIcon}
            disabled={resultPagesArray.length - 1 < 0}
          />
        </IconButton>
      )}
    </Grid>
  )
}

export default ResultsPagination
