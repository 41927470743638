import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
  streamContainer: {
    paddingTop: (props) => (props.singleStream ? '36px' : '0px'),
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#202124',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10%',
  },
  streamVideo: {
    width: '100%',
    height: '100%',
    outline: 'none',
  },
  videoContainer: {
    width: '100%',
    height: '100%',
  },
  videoBtn: {
    width: '100%',
    height: 'fit-content',
  },
  micBtnContainer: {
    fontSize: '4rem',
    padding: '1.5rem',
    backgroundColor: 'grey',
    borderRadius: '50%',
  },
  micBtn: {
    width: '3rem',
    height: '3rem',
  },
  userAvatar: {
    width: (props) => (props.singleStream ? '12rem' : '6rem'),
    height: (props) => (props.singleStream ? '12rem' : '6rem'),
    backgroundColor: (props) =>
      props?.userInitialBackground?.[props?.userInitial],
  },
  videoLoaderContainer: {
    width: '100%',
    height: '100%',
  },
  loadingText: {
    color: 'white',
    textAlign: 'center',
    fontSize: '1.2rem',
    marginRight: '0.5rem',
  },
  controlsContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  controlsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: 'rgba(38, 43, 75, 0.5)',
  },
  controlIconButtonsContainer: {
    width: '100%',
    height: 'max-content',
    paddingTop: '55px',
  },
  controlIconButtonsWrapper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    height: 'max-content',
    justifyContent: 'center',
  },
  controlIconButtonWrapper: {
    height: 'max-content',
    width: 'max-content',
    paddingRight: '10px',
  },
  controlIconButton: {
    height: 'max-content',
    width: 'max-content',
    padding: '10px',
    background: 'white',
    borderRadius: '30px',
    '&:hover': {
      background: 'white',
    },
  },
  controlIconWrapper: {
    height: '24px',
    width: '24px',
  },
  infoAndSettingsContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  infoAndSettingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  settingsIconButtonWrapper: {
    height: 'max-content',
    width: 'max-content',
    padding: '12px 0px 0px 16px',
  },
  settingsIconButton: {
    height: 'max-content',
    width: 'max-content',
    padding: '0px',
  },
  settingsIconWrapper: {
    height: '24px',
    width: '24px',
  },
  infoWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: 'auto',
    height: 'max-content',
    justifyContent: 'space-between',
    padding: '20px 15px 12px 15px',
    color: 'white',
    fontWeight: 700,
    fontSize: '20px',
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)',
  },
  nameAndUsernameTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '0px',
    height: 'max-content',
    marginTop: 'auto',
  },
  nameText: {
    fontWeight: 600,
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
  },
  usernameText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  timerText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    padding: '2px 10px',
    borderRadius: '10px',
    background: 'RGB(32, 33, 36, 0.5)',
  },
  tabCountTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    height: 'max-content',
    marginTop: 'auto',
    paddingLeft: '10px',
  },
  tabCountText: {
    display: 'flex',
    justifyContent: 'center',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
  },
}))
export { styles }
